import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { PipefyService } from 'src/app/services/pipefy.service';
import { TrataInputsService } from 'src/app/services/trata-inputs.service';
import Swal from 'sweetalert2';
import { empresas } from './empresa';
import { filiais_agrichem } from './filiais_agrichem';
//import { filiais_agrosema } from './filiais_agrosema';
import { filiais_nutrien } from './filiais_nutrien';
//import { filiais_tec_agro } from './filiais_tec_agro';
import { filiais_tec_agro_frc } from './filiais_tec_agro_frc';
import { filiais_tec_agro_sgl } from './filiais_tec_agro_sgl';
import { impostos } from './impostos';
import { pipe_fields_id } from './pipe_fields_id';
import { pipe_fields_incidentes } from '../../pipe_fields_incidentes';
import { MsalService } from '@azure/msal-angular';
import { UserInfosService } from 'src/app/autenticacao/usuario/user-infos.service';


@Component({
  selector: 'app-pagamento-de-taxas',
  templateUrl: './pagamento-de-taxas.component.html',
  styleUrls: ['./pagamento-de-taxas.component.css']
})
export class PagamentoDeTaxasComponent implements OnInit {

  formulario!: FormGroup;
  impostos = impostos
  empresas = empresas
  documentos: any[] = []
  filiais_agrichem = filiais_agrichem
  filiais_nutrien = filiais_nutrien
  //filiais_agrosema = filiais_agrosema
  //filiais_tec_agro = filiais_tec_agro
  filiais_tec_agro_frc = filiais_tec_agro_frc
  filiais_tec_agro_sgl = filiais_tec_agro_sgl

  documentosUrl: any []= []

  error_counter: number = 0;
  erro: boolean = false

  emailApi: string = ''
  userNameApi: string = ''

  constructor(private formBuilder: FormBuilder,
    private trataInput: TrataInputsService,
    private pipefy: PipefyService,
    private spinner: NgxSpinnerService,
    private msalService: MsalService,
    private userInfo: UserInfosService) { }

  ngOnInit(): void {


    this.formulario = this.formBuilder.group({
      empresa: ['', [Validators.required]],
      filial_agrichem: ['', []],
      //filial_agrosema: ['', []],
      filial_nutrien: ['', []],
      //filial_tec_agro: ['', []],
      filial_tec_agro_frc: ['', []],
      filial_tec_agro_sgl: ['', []],
      impostos_taxas_e_despesas: ['', [Validators.required]],
      numero_do_pedido: ['', []],
      descricao_da_solicitacao: ['', [Validators.required]],
      valor_da_guia_ou_nfe: ['', [Validators.required]],
      data_de_vencimento: ['', [Validators.required]],
      email_para_quem_deve_ser_enviado_a_guia: ['', [Validators.required, Validators.email]],
      requisitante: ['', []],
      email_do_requisitante:['',[Validators.required, Validators.email]],
      anexar_documento: ['', [Validators.required]],
      numero_de_documentos: ['', [Validators.required]],
      observacao: ['', []]
    })

    this.emailApi = this.userInfo.getUserEmail()
    // this.userNameApi = this.userInfo.getName()

    // this.formulario.get('email_do_requisitante')?.setValue(this.emailApi)
    // this.formulario.get('requisitante')?.setValue(this.userNameApi)


    // if (this.formulario.getRawValue().email_do_requisitante != '') {
    //   this.formulario.controls['email_do_requisitante'].disable()
    // }
    // if (this.formulario.getRawValue().email_do_requisitante == undefined) {
    //   this.formulario.controls['email_do_requisitante'].enable()
    // }

    // if (this.formulario.getRawValue().requisitante != '') {
    //   this.formulario.controls['requisitante'].disable()
    // }
    // if (this.formulario.getRawValue().requisitante == undefined) {
    //   this.formulario.controls['requisitante'].enable()
    // }
  }


  enviarFormulario() {
    this.spinner.show()
    const criarBody = async () => {

      await this.getPipesUrlDocumentos(this.documentos);
      if(this.documentos.length >0){
        while (this.documentos.length != this.documentosUrl.length) {
          // console.log("esperando....")
          await this.delay(100);
          if(this.erro){
            break;
          }
        }
        if(this.erro){
          return
        }
      }

      if(this.documentos.length>0){
        var documentosUrl2 = ''
        documentosUrl2 = '['
        for(var i = 0; i < this.documentosUrl.length ; i++){
          documentosUrl2 = documentosUrl2+'"'+this.documentosUrl[i]+'",'
        }
        documentosUrl2 = documentosUrl2.slice(0, -1)
        documentosUrl2 = documentosUrl2+"]"

      }else{
        documentosUrl2 =''
      }

    let empresa = this.trataInput.removeCaracters(this.formulario.getRawValue().empresa)
    //let filial_tec_agro = this.trataInput.removeCaracters(this.formulario.getRawValue().filial_tec_agro)
    let filial_tec_agro_sgl = this.trataInput.removeCaracters(this.formulario.getRawValue().filial_tec_agro_sgl)
    let filial_tec_agro_frc = this.trataInput.removeCaracters(this.formulario.getRawValue().filial_tec_agro_frc)
    let filial_nutrien = this.trataInput.removeCaracters(this.formulario.getRawValue().filial_nutrien)
    let filial_agrichem = this.trataInput.removeCaracters(this.formulario.getRawValue().filial_agrichem)
    //let filial_agrosema = this.trataInput.removeCaracters(this.formulario.getRawValue().filial_agrosema)
    let impostos_taxas_e_despesas = this.formulario.getRawValue().impostos_taxas_e_despesas
    let numero_do_pedido = this.trataInput.removeCaracters(this.formulario.getRawValue().numero_do_pedido)
    let descricao_da_solicitacao = this.trataInput.removeCaracters(this.formulario.getRawValue().descricao_da_solicitacao)
    let valor_da_guia_ou_nfe = this.trataInput.removeCaracters(this.formulario.getRawValue().valor_da_guia_ou_nfe)
    let data_de_vencimento = this.trataInput.ajustaData(this.trataInput.removeCaracters(this.formulario.getRawValue().data_de_vencimento))
    let email_para_quem_deve_ser_enviado_a_guia = this.trataInput.removeCaracters(this.formulario.getRawValue().email_para_quem_deve_ser_enviado_a_guia)
    let requisitante = this.trataInput.removeCaracters(this.formulario.getRawValue().requisitante)
    let email_do_requisitante = this.trataInput.removeCaracters(this.formulario.getRawValue().email_do_requisitante)
    let numero_de_documentos = this.trataInput.removeCaracters(this.formulario.getRawValue().numero_de_documentos)
    let observacao = this.trataInput.removeCaracters(this.formulario.getRawValue().observacao)

    let pipe_fields = pipe_fields_id
    let fields_values = [empresa, filial_nutrien,filial_agrichem,filial_tec_agro_frc, filial_tec_agro_sgl, impostos_taxas_e_despesas,
      numero_do_pedido,descricao_da_solicitacao,valor_da_guia_ou_nfe,data_de_vencimento,email_para_quem_deve_ser_enviado_a_guia,
      requisitante, email_do_requisitante, documentosUrl2,numero_de_documentos, observacao
     ]
     let pipe_id = '302443513'

     this.pipefy.createCard(this.pipefy.criaBody(pipe_fields, fields_values, pipe_id)).subscribe((res) => {
      // console.log(res)
      this.spinner.hide()

      if (res.data.result_creation_card.id != undefined) {
        let card_id = res.data.result_creation_card.id
        Swal.fire({
          title: 'Chamado aberto com Sucesso!',
          text: 'O chamado ' + card_id + ' foi aberto com sucesso. Nossa equipe irá tratar e retornar em breve. Obrigado!',
          icon: 'success',
          confirmButtonText: 'Ok',
          confirmButtonColor: '#88D600',
        })
        this.resetform()
      }

      if (res.data.result_creation_card.errors != undefined) {
        var erro = res.data.result_creation_card.errors[0].message
        Swal.fire({
          title: 'Desculpe!',
          html:'<p>Ocorreu um erro.</p>'+'<a href="https://app.pipefy.com/public/form/jjF2PhTT" target="_blank">Clique aqui </a>'+'<span>para fazer sua solicitação</span>',
          text: erro,
          icon: 'error',
          confirmButtonColor: '#88D600',
        })
        this.documentosUrl = []

        let pipe_fields_incidentes_values = ['Incidente', 'Correção', 'Portal NBS', 'F&A', 'Fiscal', "Fiscal - criação de card -- Guias Fiscais - Emissão - Taxas e tributos diversos", erro, 'Portal NBS Error', 'incidente@portal.com',
          'Sim', 'Guias fiscais - Emissão - taxas e tributos diversos', 'criação de card via portal', 'Página web com problemas',this.emailApi]

        var pipe_fields_incidentes_values2 = []
        for (var i = 0; i<pipe_fields_incidentes_values.length; i++ ){
          pipe_fields_incidentes_values2.push(this.trataInput.removeCaractersPipeIncidentes(pipe_fields_incidentes_values[i]))
        }
        // console.log(pipe_fields_incidentes_values2)

        let pipe_incidentes_id = '302487533'

        this.pipefy.createCard(this.pipefy.criaBody(pipe_fields_incidentes, pipe_fields_incidentes_values2, pipe_incidentes_id)).subscribe((res) => {
          // console.log(res)
        })
      }
    },(error: any) => {
      this.spinner.hide()
      this.error_counter = this.error_counter + 1
      if (this.error_counter < 2) {
        if (error.status == 401) {
          // console.log("entrei em error.status")
          Swal.fire({
            title: 'Ops, sua sessão expirou!',
            text: 'Por favor, saia e logue novamente.',
            icon: 'error',
            confirmButtonText: 'Sair',
            confirmButtonColor: '#ff6961',
            allowOutsideClick: false,
            allowEscapeKey: false
          })
            .then(() => {
              this.msalService.logoutRedirect()
            }
            )
        } else {
          Swal.fire({
            title: 'Desculpe!',
            text: 'Ocorreu algum erro durante a sua solicitação. Por favor, tente novamente.',
            icon: 'error',
            confirmButtonText: 'Ok',
            confirmButtonColor: '#88D600',
            allowOutsideClick: false,
            allowEscapeKey: false
          })
        }
      }
      else {
        this.spinner.hide()
        Swal.fire({
          title: 'Desculpe!',
          html: '<p>Ocorreu um erro.</p>' + '<a href="https://app.pipefy.com/public/form/jjF2PhTT" target="_blank">Clique aqui </a>' + '<span>para fazer sua solicitação</span>',
          icon: 'error',
          confirmButtonColor: '#88D600',
        })
        this.documentosUrl = []
      }
    })
    }
    criarBody()


  }

  delay(time: any) {
    return new Promise(resolve => setTimeout(resolve, time));
  }

  saveDocumentos(event: any): void {
    var files = event.target.files;

    for (var i = 0; i < files.length; i++) {
      // console.log(files[i].name)
      this.documentos.push(files[i])
    }
  }

  removeFileDocumentos(file: File) {
    const index: number = this.documentos.indexOf(file);
    this.documentos.splice(index, 1)
    Swal.fire({
      position: 'top-end',
      icon: 'success',
      title: 'Arquivo Removido com Sucesso.',
      showConfirmButton: false,
      timer: 1500
    })
    if(this.documentos.length == 0){
      this.formulario.controls['anexar_documento'].markAsTouched()
      this.formulario.get('anexar_documento')?.setValue('')
    }
  }

  // async getPipesUrlDocumentos(files: any) {
  //   this.erro = false
  //   for (var i = 0; i < files.length; i++) {
  //     var fileContent = files[i]
  //     let fileName = files[i].name
  //     const reader = new FileReader();
  //     reader.readAsDataURL(fileContent);
  //     reader.onload = () => {
  //       fileContent = reader.result?.toString()
  //       var fileContentStart = fileContent.indexOf(",")

  //       fileContent = fileContent.substring(fileContentStart + 1)
  //       this.pipefy.getFileUrl(fileContent, fileName).subscribe((res) => {
  //         let url = res.data.attachments_url_pipefy[0]
  //         this.documentosUrl.push(url)
  //       },
  //       (error: any) => {
  //         this.erro = true
  //         this.spinner.hide()
  //         this.error_counter = this.error_counter + 1
  //         if (this.error_counter < 2) {
  //           if (error.status == 401) {
  //             // console.log("entrei em error.status")
  //             Swal.fire({
  //               title: 'Ops, sua sessão expirou!',
  //               text: 'Por favor, saia e logue novamente.',
  //               icon: 'error',
  //               confirmButtonText: 'Sair',
  //               confirmButtonColor: '#ff6961',
  //               allowOutsideClick: false,
  //               allowEscapeKey: false
  //             })
  //               .then(() => {
  //                 this.msalService.logoutRedirect()
  //               }
  //               )
  //           } else {
  //             Swal.fire({
  //               title: 'Desculpe!',
  //               text: 'Ocorreu algum erro durante a sua solicitação. Por favor, tente novamente.',
  //               icon: 'error',
  //               confirmButtonText: 'Ok',
  //               confirmButtonColor: '#88D600',
  //               allowOutsideClick: false,
  //               allowEscapeKey: false
  //             })
  //           }
  //         }
  //         else {
  //           this.spinner.hide()
  //           Swal.fire({
  //             title: 'Desculpe!',
  //             html: '<p>Ocorreu um erro.</p>' + '<a href="https://app.pipefy.com/public/form/jjF2PhTT" target="_blank">Clique aqui </a>' + '<span>para fazer sua solicitação</span>',
  //             icon: 'error',
  //             confirmButtonColor: '#88D600',
  //           })
  //           this.documentosUrl = []
  //         }
  //       })
  //     }
  //   }
  //   return new Promise<void>((resolve) => {resolve();});
  // }

  async getPipesUrlDocumentos(files: any) {
    this.erro = false;

  // Função utilitária para ler o arquivo usando FileReader como uma Promise
  const readFileAsBase64 = (file: File) => {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const result = reader.result?.toString();
        if (result) {
          const fileContentStart = result.indexOf(",");
          resolve(result.substring(fileContentStart + 1)); // Retorna o conteúdo Base64
        } else {
          reject(new Error("Falha ao ler o arquivo"));
        }
      };
      reader.onerror = (error) => {
        reject(error);
      };
    });
  };

  // Loop sobre os arquivos, esperando cada leitura e operação assíncrona
  for (let i = 0; i < files.length; i++) {
    const fileContent = files[i];
    const fileName = files[i].name;

    try {
      const fileBase64 = await readFileAsBase64(fileContent); // Aguarda a leitura do arquivo
      const urlResposta = await this.pipefy.getFileUrl3(fileBase64, fileName); // Obtém a URL após o upload do arquivo

      var fileUrlTemp = "";
        var startIndex = 0;

        if (urlResposta.indexOf("orgs") > 0) {
          startIndex = urlResposta.indexOf("orgs/");
        } else {
          startIndex = urlResposta.indexOf("uploads/");
        }

        var endIndex = urlResposta.indexOf("?");
        fileUrlTemp = urlResposta.substring(startIndex, endIndex);

        this.documentosUrl.push(fileUrlTemp)

      //this.invociesUrl.push(url); // Adiciona a URL à lista

    } catch (error: any) {
      this.erro = true;
      this.spinner.hide();
      this.error_counter = this.error_counter + 1;
      if (this.error_counter < 2) {
        if (error.status === 401) {
          Swal.fire({
            title: 'Ops, sua sessão expirou!',
            text: 'Por favor, saia e logue novamente.',
            icon: 'error',
            confirmButtonText: 'Sair',
            confirmButtonColor: '#ff6961',
            allowOutsideClick: false,
            allowEscapeKey: false
          }).then(() => {
            this.msalService.logoutRedirect();
          });
        } else {
          Swal.fire({
            title: 'Desculpe!',
            text: 'Ocorreu algum erro durante a sua solicitação. Por favor, tente novamente.',
            icon: 'error',
            confirmButtonText: 'Ok',
            confirmButtonColor: '#88D600',
            allowOutsideClick: false,
            allowEscapeKey: false
          });
        }
      } else {
        this.spinner.hide();
        Swal.fire({
          title: 'Desculpe!',
          html: '<p>Ocorreu um erro.</p>' + '<a href="https://app.pipefy.com/public/form/3Sd_ATrN" target="_blank">Clique aqui </a>' + '<span>para fazer sua solicitação</span>',
          icon: 'error',
          confirmButtonColor: '#88D600',
        });
        this.documentosUrl = [];
      }
    }
  }
    return new Promise<void>((resolve) => {resolve();});
  }



  onEmpresaChange(event: any) {
    let empresa = event.target.value

    if (empresa === 'Tec Agro-SGL') {

      this.formulario.get('filial_tec_agro_sgl')?.setValidators([Validators.required])
      this.formulario.get('filial_tec_agro_sgl')?.updateValueAndValidity();

      this.formulario.get('filial_agrichem')?.clearValidators()
      this.formulario.get('filial_agrichem')?.updateValueAndValidity();

      this.formulario.get('filial_nutrien')?.clearValidators()
      this.formulario.get('filial_nutrien')?.updateValueAndValidity();

      // this.formulario.get('filial_agrosema')?.clearValidators()
      // this.formulario.get('filial_agrosema')?.updateValueAndValidity();
    }


    if (empresa === 'Tec Agro-FRC') {

      this.formulario.get('filial_tec_agro_frc')?.setValidators([Validators.required])
      this.formulario.get('filial_tec_agro_frc')?.updateValueAndValidity();

      this.formulario.get('filial_agrichem')?.clearValidators()
      this.formulario.get('filial_agrichem')?.updateValueAndValidity();

      this.formulario.get('filial_nutrien')?.clearValidators()
      this.formulario.get('filial_nutrien')?.updateValueAndValidity();

      // this.formulario.get('filial_agrosema')?.clearValidators()
      // this.formulario.get('filial_agrosema')?.updateValueAndValidity();
    }

    if (empresa === 'Nutrien') {

      this.formulario.get('filial_tec_agro')?.clearValidators()
      this.formulario.get('filial_tec_agro')?.updateValueAndValidity();

      this.formulario.get('filial_agrichem')?.clearValidators()
      this.formulario.get('filial_agrichem')?.updateValueAndValidity();

      this.formulario.get('filial_nutrien')?.setValidators([Validators.required])
      this.formulario.get('filial_nutrien')?.updateValueAndValidity();

      // this.formulario.get('filial_agrosema')?.clearValidators()
      // this.formulario.get('filial_agrosema')?.updateValueAndValidity();
    }

      if (empresa === 'Agrichem') {

        this.formulario.get('filial_tec_agro')?.clearValidators()
        this.formulario.get('filial_tec_agro')?.updateValueAndValidity();

        this.formulario.get('filial_agrichem')?.setValidators([Validators.required])
        this.formulario.get('filial_agrichem')?.updateValueAndValidity();

        this.formulario.get('filial_nutrien')?.clearValidators()
        this.formulario.get('filial_nutrien')?.updateValueAndValidity();

        this.formulario.get('filial_agrosema')?.clearValidators()
        this.formulario.get('filial_agrosema')?.updateValueAndValidity();
    }

    // if (empresa === 'Agrosema') {

    //    this.formulario.get('filial_tec_agro')?.clearValidators()
    //     this.formulario.get('filial_tec_agro')?.updateValueAndValidity();

    //     this.formulario.get('filial_agrichem')?.clearValidators()
    //     this.formulario.get('filial_agrichem')?.updateValueAndValidity();

    //     this.formulario.get('filial_nutrien')?.clearValidators()
    //     this.formulario.get('filial_nutrien')?.updateValueAndValidity();

    //     this.formulario.get('filial_agrosema')?.setValidators([Validators.required])
    //     this.formulario.get('filial_agrosema')?.updateValueAndValidity();
    // }
  }


  resetform() {
    for (let field in this.formulario.controls) {
      this.formulario.controls[field].setValue('');
    }
    this.formulario.markAsUntouched()
    this.documentos = []
    this.documentosUrl = []

    // this.formulario.get('filial_tec_agro')?.clearValidators()
    // this.formulario.get('filial_tec_agro')?.updateValueAndValidity();


    this.formulario.get('filial_tec_agro_frc')?.clearValidators()
    this.formulario.get('filial_tec_agro_frc')?.updateValueAndValidity();


    this.formulario.get('filial_tec_agro_sgl')?.clearValidators()
    this.formulario.get('filial_tec_agro_sgl')?.updateValueAndValidity();

    this.formulario.get('filial_agrichem')?.clearValidators()
    this.formulario.get('filial_agrichem')?.updateValueAndValidity();

    this.formulario.get('filial_nutrien')?.clearValidators()
    this.formulario.get('filial_nutrien')?.updateValueAndValidity();

    // this.formulario.get('filial_agrosema')?.clearValidators()
    // this.formulario.get('filial_agrosema')?.updateValueAndValidity();
  }

  abrirPipePublico() {
    window.open('https://app.pipefy.com/public/form/jjF2PhTT', '_blank')
  }
}
