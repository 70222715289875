import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, from } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { TokenServiceService } from './token-service.service';
import { UserInfosService } from '../autenticacao/usuario/user-infos.service';
import Swal from 'sweetalert2';
import { environment } from 'src/environments/environment';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(
    private token: TokenServiceService,
    private user: UserInfosService
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Checa se é contingência; se sim, pula aguardarToken
    if (environment.contigencia) {
      return this.processarRequisicao(req, next);
    }

    // Aguarda os tokens antes de continuar
    return from(this.aguardarToken(req)).pipe(
      switchMap(() => this.processarRequisicao(req, next))
    );
  }

  private processarRequisicao(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let clonedRequest = req;

    // Lógica para o ambiente normal (sem contingência)
    let body = req.body || {}; // Verifica se body existe

    // Evita erro caso body não tenha data
    if (body && typeof body === 'object') {
      body = {
        ...body,
        data: {
          ...body.data,
        },
      };
      if (!environment.contigencia){
        if (req.url.startsWith('https://api.nutrien.nbs.')) {
          body.data = {
            ...body.data,
            'jwt-email': this.user.getUserEmail(),
          };
        }
      }
    }

    // Configuração de cabeçalhos baseada na URL
    const headers: { [key: string]: string } = {};

    if (req.url.startsWith('https://mostqiapi.com')) {
      headers['Authorization'] = 'Token ' + this.token.secrets.tokens.mostqi;
      headers['Content-Type'] = 'application/json';
    } else if (req.url.startsWith('https://api.nutrien.nbs.')) {
      if (!environment.contigencia){
        headers['Token'] = this.token.userAccessToken;
        headers['Authorization'] = 'Bearer ' + this.token.jwt;
      }
    } else if (req.url.startsWith('https://app.pipefy.com/queries')) {
      headers['Content-Type'] = 'application/json';
      headers['Authorization'] = this.token.secrets.tokens.pipefy;
    } else if (req.url.startsWith('https://api.godigibee.io/')) {
      headers['Content-Type'] = 'application/json';
      headers['Authorization'] = this.token.getToken();
      const apiKey = this.token.secrets.apikeys.digibee;

      // Clona a requisição adicionando o parâmetro apikey na URL e cabeçalhos
      clonedRequest = req.clone({
        url: `${req.url}?apikey=${apiKey}`,
        setHeaders: headers,
      });

      return next.handle(clonedRequest); // Retorna a requisição clonada com a URL modificada
    }

    // Clona a requisição com os headers e body atualizados
    clonedRequest = clonedRequest.clone({
      setHeaders: headers,
      body: body,
    });

    return next.handle(clonedRequest); // Envia a requisição clonada com cabeçalhos atualizados
  }

  private async aguardarToken(req: HttpRequest<any>): Promise<void> {
    // Aguarda até que os tokens estejam disponíveis ou timeout
    const timeout = 10000; // 10 segundos
    const startTime = Date.now();

    while (
      (!this.token.userAccessToken || this.user.getUserEmail() === undefined) &&
      !req.url.includes('microsoft') &&
      !environment.production
    ) {
      if (Date.now() - startTime > timeout) {
        throw new Error('Timeout aguardando token.');
      }
      await new Promise((resolve) => setTimeout(resolve, 100)); // Aguarda 100ms
    }
  }
}
