import { pipe_fields_reembolso_beneficios } from './pipe_fields_ids';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MsalService } from '@azure/msal-angular';
import { NgxSpinnerService } from 'ngx-spinner';
import { UserInfosService } from 'src/app/autenticacao/usuario/user-infos.service';
import { PipefyService } from 'src/app/services/pipefy.service';
import { TrataInputsService } from 'src/app/services/trata-inputs.service';
import Swal from 'sweetalert2';
import { pipe_fields_incidentes } from '../../pipe_fields_incidentes';
import { empresas } from './empresas';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-reembolso-beneficios',
  templateUrl: './reembolso-beneficios.component.html',
  styleUrls: ['./reembolso-beneficios.component.css']
})
export class ReembolsoBeneficiosComponent implements OnInit {

  formulario!: FormGroup;
  // comprovantesAnexo: any[] = [];
  // comprovantesAnexoUrl: any[] = []

  documentacaoAnexo: any[] = []
  documentacaoAnexoUrl: any[] = []

  ipvaAnexo: any[] = [];
  ipvaAnexoUrl: any[] = [];

  seguroAnexo: any[] = [];
  seguroAnexoUrl: any[] = [];

  centroCusto: string = '';

  porcentagemReembolsoDocumentacao: number = 0;
  porcentagemReembolsoIpva: number = 0;
  porcentagemReembolsoSeguro: number = 0;
  valorCalculadoDocumentacao: number = 0;
  valorCalculadoIpva: number = 0;
  valorCalculadoSeguro: number = 0;
  totalValorReembolsar: number = 0;

  totalValorReembolsoCalculado: boolean = false;

  usuarioSemPermissao: boolean = false;


  faltamCamposObrigatorios: boolean = true
  error_counter: number = 0;
  erro: boolean = false

  email: string = ''

  nomeAuto: string = '';
  emailAuto: string = '';


  tipo: any[] = []
  emailApi: string = ''

  empresas = empresas;


  comprovantes: boolean = false;
  documentacao: boolean = false;
  ipva: boolean = false;
  seguroCarro: boolean = false;


  mostrarComprovantes: boolean = false
  mostrarDocumentacao: boolean = false
  mostrarIpva: boolean = false
  mostrarSeguro: boolean = false

  constructor(private formBuilder: FormBuilder,
              private trataInput: TrataInputsService,
              private pipefy: PipefyService,
              private spinner: NgxSpinnerService,
              private msalService: MsalService,
              private userInfo: UserInfosService) { }

  ngOnInit(): void {

    this.setUserData()

    this.formulario = this.formBuilder.group({
      nomeAuto:['',[Validators.required]],
      cpf:['',[Validators.required]],
      emailAuto:['',[Validators.required, Validators.email]],
      empresa:['',[Validators.required]],
      // valor_do_ve_culo:['',[Validators.required]],
      tipo:['',[]],
      valor_integral_documenta_o:['',[]],
      ipva:['',[]],
      seguro_carro:['',[]],
      obs:['',[]],
      anexo_documenta_o:['',[]],
      anexo_ipva:['',[]],
      anexo_seguro:['',[]]
    });
      this.getUsernameEmail();
      this.getInfoReembolso();
  }

  enviarFormulario() {

    if (this.formulario.invalid || (!this.documentacao && !this.ipva && !this.seguroCarro)) {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'Campo(s) ou Anexo(s) obrigatório(s) não preenchido(s), verifique e tente novamente',
        showConfirmButton: false,
        timer: 10000
      })
      return
    }

    this.spinner.show()

    const criarBody = async () => {

      await this.getDocumentacaoPipesUrl(this.documentacaoAnexo);
      if (this.documentacaoAnexo.length > 0) {
        while (this.documentacaoAnexo.length != this.documentacaoAnexoUrl.length) {
          // console.log("esperando....")
          await this.delay(100);
          if(this.erro){
            break;
          }
        }
        if(this.erro){
          return
        }
      }

      if (this.documentacaoAnexo.length > 0) {
        var documentacaoAnexoUrl2 = ''
        documentacaoAnexoUrl2 = '['
        for (var i = 0; i < this.documentacaoAnexoUrl.length; i++) {
          documentacaoAnexoUrl2 = documentacaoAnexoUrl2 + '"' + this.documentacaoAnexoUrl[i] + '",'
        }
        documentacaoAnexoUrl2 = documentacaoAnexoUrl2.slice(0, -1)
        documentacaoAnexoUrl2 = documentacaoAnexoUrl2 + "]"

      } else {
        documentacaoAnexoUrl2 = ''
      }

      await this.getIpvaPipesUrl(this.ipvaAnexo);
      if (this.ipvaAnexo.length > 0) {
        while (this.ipvaAnexo.length != this.ipvaAnexoUrl.length) {
          // console.log("esperando....")
          await this.delay(100);
          if(this.erro){
            break;
          }
        }
        if(this.erro){
          return
        }
      }

      if (this.ipvaAnexo.length > 0) {
        var ipvaAnexoUrl2 = ''
        ipvaAnexoUrl2 = '['
        for (var i = 0; i < this.ipvaAnexoUrl.length; i++) {
          ipvaAnexoUrl2 = ipvaAnexoUrl2 + '"' + this.ipvaAnexoUrl[i] + '",'
        }
        ipvaAnexoUrl2 = ipvaAnexoUrl2.slice(0, -1)
        ipvaAnexoUrl2 = ipvaAnexoUrl2 + "]"

      } else {
        ipvaAnexoUrl2 = ''
      }

      await this.getSeguroPipesUrl(this.seguroAnexo);
      if (this.seguroAnexo.length > 0) {
        while (this.seguroAnexo.length != this.seguroAnexoUrl.length) {
          // console.log("esperando....")
          await this.delay(100);
          if(this.erro){
            break;
          }
        }
        if(this.erro){
          return
        }
      }

      if (this.seguroAnexo.length > 0) {
        var seguroAnexoUrl2 = ''
        seguroAnexoUrl2 = '['
        for (var i = 0; i < this.seguroAnexoUrl.length; i++) {
          seguroAnexoUrl2 = seguroAnexoUrl2 + '"' + this.seguroAnexoUrl[i] + '",'
        }
        seguroAnexoUrl2 = seguroAnexoUrl2.slice(0, -1)
        seguroAnexoUrl2 = seguroAnexoUrl2 + "]"

      } else {
        seguroAnexoUrl2 = ''
      }

      // await this.getComprovantesPipesUrl(this.comprovantesAnexo);
      // if (this.comprovantesAnexo.length > 0) {
      //   while (this.comprovantesAnexo.length != this.comprovantesAnexoUrl.length) {
      //     // console.log("esperando....")
      //     await this.delay(100);
      //     if(this.erro){
      //       break;
      //     }
      //   }
      //   if(this.erro){
      //     return
      //   }
      // }

      // if (this.comprovantesAnexo.length > 0) {
      //   var comprovantesAnexoUrl2 = ''
      //   comprovantesAnexoUrl2 = '['
      //   for (var i = 0; i < this.comprovantesAnexoUrl.length; i++) {
      //     comprovantesAnexoUrl2 = comprovantesAnexoUrl2 + '"' + this.comprovantesAnexoUrl[i] + '",'
      //   }
      //   comprovantesAnexoUrl2 = comprovantesAnexoUrl2.slice(0, -1)
      //   comprovantesAnexoUrl2 = comprovantesAnexoUrl2 + "]"

      // } else {
      //   comprovantesAnexoUrl2 = ''
      // }


      let nome= this.trataInput.removeCaracters(this.formulario.getRawValue().nomeAuto)
      let cpf= this.trataInput.removeCaracters(this.formulario.getRawValue().cpf)
      let e_mail= this.trataInput.removeCaracters(this.formulario.getRawValue().emailAuto)
      let empresa= this.trataInput.removeCaracters(this.formulario.getRawValue().empresa)
      // let valor_do_ve_culo= this.trataInput.removeCaracters(this.formulario.getRawValue().valor_do_ve_culo)
      let tipo = '['+this.tipo+']'
      let valor_integral_documenta_o = this.trataInput.removeCaracters(this.formulario.getRawValue().valor_integral_documenta_o)
      let ipva= this.trataInput.removeCaracters(this.formulario.getRawValue().ipva)
      let seguro_carro= this.trataInput.removeCaracters(this.formulario.getRawValue().seguro_carro)



      let pipe_fields = pipe_fields_reembolso_beneficios
      let fields_values = [
        nome,
        cpf,
        e_mail,
        empresa,
        this.centroCusto,
        tipo,
        valor_integral_documenta_o,
        ipva,
        seguro_carro,
        this.valorCalculadoDocumentacao,
        this.valorCalculadoIpva,
        this.valorCalculadoSeguro,
        this.totalValorReembolsar,
        documentacaoAnexoUrl2,
        ipvaAnexoUrl2,
        seguroAnexoUrl2,
      ]; //comprovantesAnexoUrl2
      let pipe_id = "302756317"

      this.pipefy.createCard(this.pipefy.criaBody(pipe_fields, fields_values, pipe_id)).subscribe((res) => {
        // console.log(res)
        this.spinner.hide()
        console.log(res)

        if(res.code == 504){
          Swal.fire({
            title: 'Desculpe!',
            text: 'Ocorreu algum erro durante a sua solicitação. Por favor, tente novamente.',
            icon: 'error',
            confirmButtonText: 'Ok',
            confirmButtonColor: '#88D600',
            allowOutsideClick: false,
            allowEscapeKey: false
          })
        }

        if (res.data.result_creation_card.id != undefined) {
          let card_id = res.data.result_creation_card.id
          Swal.fire({
            title: 'Chamado aberto com Sucesso!',
            text: 'O chamado ' + card_id + ' foi aberto com sucesso. Nossa equipe irá tratar e retornar em breve. Obrigado!',
            icon: 'success',
            confirmButtonText: 'Ok',
            confirmButtonColor: '#88D600',
          })
          this.resetform();
          this.getUsernameEmail();
        }

        if (res.data.result_creation_card.errors != undefined) {
          var erro = res.data.result_creation_card.errors[0].message
          Swal.fire({
            title: 'Desculpe!',
            html: '<p>Ocorreu um erro.</p>' + '<a href="https://app.pipefy.com/public/form/eAAITVU6" target="_blank">Clique aqui </a>' + '<span>para fazer sua solicitação</span>',
            text: erro,
            icon: 'error',
            confirmButtonColor: '#88D600',
          })
          this.documentacaoAnexoUrl = []
          this.ipvaAnexoUrl = []
          this.seguroAnexoUrl = []
          // this.comprovantesAnexoUrl = []

          let pipe_fields_incidentes_values = ['Incidente', 'Correção', 'Portal NBS', 'F&A', 'Contabilidade', "Contabilidade - criação de card -- Auditoria - Documentos Suporte", erro, 'Portal NBS Error', 'incidente@portal.com',
            'Sim', 'Outros', 'criação de card via portal', 'Página web com problemas',this.emailApi]

          var pipe_fields_incidentes_values2 = []
          for (var i = 0; i < pipe_fields_incidentes_values.length; i++) {
            pipe_fields_incidentes_values2.push(this.trataInput.removeCaractersPipeIncidentes(pipe_fields_incidentes_values[i]))
          }
          // console.log(pipe_fields_incidentes_values2)

          let pipe_incidentes_id = '302487533'

          this.pipefy.createCard(this.pipefy.criaBody(pipe_fields_incidentes, pipe_fields_incidentes_values2, pipe_incidentes_id)).subscribe((res) => {
            // console.log(res)
          })
        }
      }, (error: any) => {
        this.spinner.hide()
        // console.log(error.status)
        this.error_counter = this.error_counter + 1
        if (this.error_counter < 2) {
          if (error.status == 401) {
            // console.log("entrei em error.status")
            Swal.fire({
              title: 'Ops, sua sessão expirou!',
              text: 'Por favor, saia e logue novamente.',
              icon: 'error',
              confirmButtonText: 'Sair',
              confirmButtonColor: '#ff6961',
              allowOutsideClick: false,
              allowEscapeKey: false
            })
              .then(() => {
                this.msalService.logoutRedirect()
              }
              )
          } else {
            Swal.fire({
              title: 'Desculpe!',
              text: 'Ocorreu algum erro durante a sua solicitação. Por favor, tente novamente.',
              icon: 'error',
              confirmButtonText: 'Ok',
              confirmButtonColor: '#88D600',
              allowOutsideClick: false,
              allowEscapeKey: false
            })
          }
        }
        else {
          this.spinner.hide()
          Swal.fire({
            title: 'Desculpe!',
            html: '<p>Ocorreu um erro.</p>' + '<a href="https://app.pipefy.com/public/form/eAAITVU6" target="_blank">Clique aqui </a>' + '<span>para fazer sua solicitação</span>',
            icon: 'error',
            confirmButtonColor: '#88D600',
          })
          this.documentacaoAnexoUrl = []
          this.ipvaAnexoUrl = []
          this.seguroAnexoUrl = []
          // this.comprovantesAnexoUrl = []
        }
      })
    }

    criarBody()
  }

  delay(time: any) {
    return new Promise(resolve => setTimeout(resolve, time));
  }

  inserirNoArray(event:any){
    // console.log(this.formulario.getRawValue().servicos_a_delegar)
    let valor:any = event.target.value
    // console.log(valor)
    if(this.formulario.getRawValue().tipo){
      let valorTratado = '"'+valor+'"'
    this.tipo.push(valorTratado)
    console.log(this.tipo)

    }
    if(!this.formulario.getRawValue().tipo){

      const index: number = this.tipo.indexOf('"'+valor+'"');
      this.tipo.splice(index, 1)
      console.log(this.tipo)
    }
  }


  saveDocumentacao(event: any): void {
    var files = event.target.files;
    for (var i = 0; i < files.length; i++) {
      this.documentacaoAnexo.push(files[i])
    }
  }

  async getDocumentacaoPipesUrl(files: any) {
    this.erro = false;

  // Função utilitária para ler o arquivo usando FileReader como uma Promise
  const readFileAsBase64 = (file: File) => {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const result = reader.result?.toString();
        if (result) {
          const fileContentStart = result.indexOf(",");
          resolve(result.substring(fileContentStart + 1)); // Retorna o conteúdo Base64
        } else {
          reject(new Error("Falha ao ler o arquivo"));
        }
      };
      reader.onerror = (error) => {
        reject(error);
      };
    });
  };

  // Loop sobre os arquivos, esperando cada leitura e operação assíncrona
  for (let i = 0; i < files.length; i++) {
    const fileContent = files[i];
    const fileName = files[i].name;

    try {
      const fileBase64 = await readFileAsBase64(fileContent); // Aguarda a leitura do arquivo
      const urlResposta = await this.pipefy.getFileUrl3(fileBase64, fileName); // Obtém a URL após o upload do arquivo

      var fileUrlTemp = "";
        var startIndex = 0;

        if (urlResposta.indexOf("orgs") > 0) {
          startIndex = urlResposta.indexOf("orgs/");
        } else {
          startIndex = urlResposta.indexOf("uploads/");
        }

        var endIndex = urlResposta.indexOf("?");
        fileUrlTemp = urlResposta.substring(startIndex, endIndex);

        this.documentacaoAnexoUrl.push(fileUrlTemp)

      //this.invociesUrl.push(url); // Adiciona a URL à lista

    } catch (error: any) {
      this.erro = true;
      this.spinner.hide();
      this.error_counter = this.error_counter + 1;
      if (this.error_counter < 2) {
        if (error.status === 401) {
          Swal.fire({
            title: 'Ops, sua sessão expirou!',
            text: 'Por favor, saia e logue novamente.',
            icon: 'error',
            confirmButtonText: 'Sair',
            confirmButtonColor: '#ff6961',
            allowOutsideClick: false,
            allowEscapeKey: false
          }).then(() => {
            this.msalService.logoutRedirect();
          });
        } else {
          Swal.fire({
            title: 'Desculpe!',
            text: 'Ocorreu algum erro durante a sua solicitação. Por favor, tente novamente.',
            icon: 'error',
            confirmButtonText: 'Ok',
            confirmButtonColor: '#88D600',
            allowOutsideClick: false,
            allowEscapeKey: false
          });
        }
      } else {
        this.spinner.hide();
        Swal.fire({
          title: 'Desculpe!',
          html: '<p>Ocorreu um erro.</p>' + '<a href="https://app.pipefy.com/public/form/3Sd_ATrN" target="_blank">Clique aqui </a>' + '<span>para fazer sua solicitação</span>',
          icon: 'error',
          confirmButtonColor: '#88D600',
        });
        this.documentacaoAnexoUrl = [];
      }
    }
    }
    return new Promise<void>((resolve) => { resolve(); });
  }

  removeDocumentacaoFile(file: File) {
    const index: number = this.documentacaoAnexo.indexOf(file);
    this.documentacaoAnexo.splice(index, 1)
    Swal.fire({
      position: 'top-end',
      icon: 'success',
      title: 'Arquivo Removido com Sucesso.',
      showConfirmButton: false,
      timer: 1500
    })
    if(this.documentacaoAnexo.length == 0){
      this.formulario.controls['anexo_documenta_o'].markAsTouched()
      this.formulario.get('anexo_documenta_o')?.setValue('')
    }
  }


  saveIpva(event: any): void {
    var files = event.target.files;
    for (var i = 0; i < files.length; i++) {
      this.ipvaAnexo.push(files[i])
    }
  }

  async getIpvaPipesUrl(files: any) {
    this.erro = false;

  // Função utilitária para ler o arquivo usando FileReader como uma Promise
  const readFileAsBase64 = (file: File) => {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const result = reader.result?.toString();
        if (result) {
          const fileContentStart = result.indexOf(",");
          resolve(result.substring(fileContentStart + 1)); // Retorna o conteúdo Base64
        } else {
          reject(new Error("Falha ao ler o arquivo"));
        }
      };
      reader.onerror = (error) => {
        reject(error);
      };
    });
  };

  // Loop sobre os arquivos, esperando cada leitura e operação assíncrona
  for (let i = 0; i < files.length; i++) {
    const fileContent = files[i];
    const fileName = files[i].name;

    try {
      const fileBase64 = await readFileAsBase64(fileContent); // Aguarda a leitura do arquivo
      const urlResposta = await this.pipefy.getFileUrl3(fileBase64, fileName); // Obtém a URL após o upload do arquivo

      var fileUrlTemp = "";
        var startIndex = 0;

        if (urlResposta.indexOf("orgs") > 0) {
          startIndex = urlResposta.indexOf("orgs/");
        } else {
          startIndex = urlResposta.indexOf("uploads/");
        }

        var endIndex = urlResposta.indexOf("?");
        fileUrlTemp = urlResposta.substring(startIndex, endIndex);

        this.ipvaAnexoUrl.push(fileUrlTemp)

      //this.invociesUrl.push(url); // Adiciona a URL à lista

    } catch (error: any) {
      this.erro = true;
      this.spinner.hide();
      this.error_counter = this.error_counter + 1;
      if (this.error_counter < 2) {
        if (error.status === 401) {
          Swal.fire({
            title: 'Ops, sua sessão expirou!',
            text: 'Por favor, saia e logue novamente.',
            icon: 'error',
            confirmButtonText: 'Sair',
            confirmButtonColor: '#ff6961',
            allowOutsideClick: false,
            allowEscapeKey: false
          }).then(() => {
            this.msalService.logoutRedirect();
          });
        } else {
          Swal.fire({
            title: 'Desculpe!',
            text: 'Ocorreu algum erro durante a sua solicitação. Por favor, tente novamente.',
            icon: 'error',
            confirmButtonText: 'Ok',
            confirmButtonColor: '#88D600',
            allowOutsideClick: false,
            allowEscapeKey: false
          });
        }
      } else {
        this.spinner.hide();
        Swal.fire({
          title: 'Desculpe!',
          html: '<p>Ocorreu um erro.</p>' + '<a href="https://app.pipefy.com/public/form/3Sd_ATrN" target="_blank">Clique aqui </a>' + '<span>para fazer sua solicitação</span>',
          icon: 'error',
          confirmButtonColor: '#88D600',
        });
        this.ipvaAnexoUrl = [];
      }
    }
    }
    return new Promise<void>((resolve) => { resolve(); });
  }

  removeIpvaFile(file: File) {
    const index: number = this.ipvaAnexo.indexOf(file);
    this.ipvaAnexo.splice(index, 1)
    Swal.fire({
      position: 'top-end',
      icon: 'success',
      title: 'Arquivo Removido com Sucesso.',
      showConfirmButton: false,
      timer: 1500
    })
    if(this.ipvaAnexo.length == 0){
      this.formulario.controls['anexo_ipva'].markAsTouched()
      this.formulario.get('anexo_ipva')?.setValue('')
    }
  }

  saveSeguro(event: any): void {
    var files = event.target.files;
    for (var i = 0; i < files.length; i++) {
      this.seguroAnexo.push(files[i])
    }
  }

  async getSeguroPipesUrl(files: any) {
    this.erro = false;

  // Função utilitária para ler o arquivo usando FileReader como uma Promise
  const readFileAsBase64 = (file: File) => {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const result = reader.result?.toString();
        if (result) {
          const fileContentStart = result.indexOf(",");
          resolve(result.substring(fileContentStart + 1)); // Retorna o conteúdo Base64
        } else {
          reject(new Error("Falha ao ler o arquivo"));
        }
      };
      reader.onerror = (error) => {
        reject(error);
      };
    });
  };

  // Loop sobre os arquivos, esperando cada leitura e operação assíncrona
  for (let i = 0; i < files.length; i++) {
    const fileContent = files[i];
    const fileName = files[i].name;

    try {
      const fileBase64 = await readFileAsBase64(fileContent); // Aguarda a leitura do arquivo
      const urlResposta = await this.pipefy.getFileUrl3(fileBase64, fileName); // Obtém a URL após o upload do arquivo

      var fileUrlTemp = "";
        var startIndex = 0;

        if (urlResposta.indexOf("orgs") > 0) {
          startIndex = urlResposta.indexOf("orgs/");
        } else {
          startIndex = urlResposta.indexOf("uploads/");
        }

        var endIndex = urlResposta.indexOf("?");
        fileUrlTemp = urlResposta.substring(startIndex, endIndex);

        this.seguroAnexoUrl.push(fileUrlTemp)

      //this.invociesUrl.push(url); // Adiciona a URL à lista

    } catch (error: any) {
      this.erro = true;
      this.spinner.hide();
      this.error_counter = this.error_counter + 1;
      if (this.error_counter < 2) {
        if (error.status === 401) {
          Swal.fire({
            title: 'Ops, sua sessão expirou!',
            text: 'Por favor, saia e logue novamente.',
            icon: 'error',
            confirmButtonText: 'Sair',
            confirmButtonColor: '#ff6961',
            allowOutsideClick: false,
            allowEscapeKey: false
          }).then(() => {
            this.msalService.logoutRedirect();
          });
        } else {
          Swal.fire({
            title: 'Desculpe!',
            text: 'Ocorreu algum erro durante a sua solicitação. Por favor, tente novamente.',
            icon: 'error',
            confirmButtonText: 'Ok',
            confirmButtonColor: '#88D600',
            allowOutsideClick: false,
            allowEscapeKey: false
          });
        }
      } else {
        this.spinner.hide();
        Swal.fire({
          title: 'Desculpe!',
          html: '<p>Ocorreu um erro.</p>' + '<a href="https://app.pipefy.com/public/form/3Sd_ATrN" target="_blank">Clique aqui </a>' + '<span>para fazer sua solicitação</span>',
          icon: 'error',
          confirmButtonColor: '#88D600',
        });
        this.seguroAnexoUrl = [];
      }
    }
    }
    return new Promise<void>((resolve) => { resolve(); });
  }

  removeSeguroFile(file: File) {
    const index: number = this.seguroAnexo.indexOf(file);
    this.seguroAnexo.splice(index, 1)
    Swal.fire({
      position: 'top-end',
      icon: 'success',
      title: 'Arquivo Removido com Sucesso.',
      showConfirmButton: false,
      timer: 1500
    })
    if(this.seguroAnexo.length == 0){
      this.formulario.controls['anexo_seguro'].markAsTouched()
      this.formulario.get('anexo_seguro')?.setValue('')
    }
  }

  // saveComprovantes(event: any): void {
  //   var files = event.target.files;
  //   for (var i = 0; i < files.length; i++) {
  //     this.comprovantesAnexo.push(files[i])
  //   }
  // }

  // async getComprovantesPipesUrl(files: any) {
  //   this.erro = false
  //   for (var i = 0; i < files.length; i++) {
  //     var fileContent = files[i]
  //     let fileName = files[i].name
  //     const reader = new FileReader();
  //     reader.readAsDataURL(fileContent);
  //     reader.onload = () => {
  //       fileContent = reader.result?.toString()
  //       var fileContentStart = fileContent.indexOf(",")

  //       fileContent = fileContent.substring(fileContentStart + 1)
  //       this.pipefy.getFileUrl(fileContent, fileName).subscribe((res) => {
  //         let url = res.data.attachments_url_pipefy[0]
  //         this.comprovantesAnexoUrl.push(url)
  //       },
  //       (error: any) => {
  //         this.erro = true
  //         this.spinner.hide()
  //         this.error_counter = this.error_counter + 1
  //         if (this.error_counter < 2) {
  //           if (error.status == 401) {
  //             // console.log("entrei em error.status")
  //             Swal.fire({
  //               title: 'Ops, sua sessão expirou!',
  //               text: 'Por favor, saia e logue novamente.',
  //               icon: 'error',
  //               confirmButtonText: 'Sair',
  //               confirmButtonColor: '#ff6961',
  //               allowOutsideClick: false,
  //               allowEscapeKey: false
  //             })
  //               .then(() => {
  //                 this.msalService.logoutRedirect()
  //               }
  //               )
  //           } else {
  //             Swal.fire({
  //               title: 'Desculpe!',
  //               text: 'Ocorreu algum erro durante a sua solicitação. Por favor, tente novamente.',
  //               icon: 'error',
  //               confirmButtonText: 'Ok',
  //               confirmButtonColor: '#88D600',
  //               allowOutsideClick: false,
  //               allowEscapeKey: false
  //             })
  //           }
  //         }
  //         else {
  //           this.spinner.hide()
  //           Swal.fire({
  //             title: 'Desculpe!',
  //             html: '<p>Ocorreu um erro.</p>' + '<a href="https://app.pipefy.com/public/form/eAAITVU6" target="_blank">Clique aqui </a>' + '<span>para fazer sua solicitação</span>',
  //             icon: 'error',
  //             confirmButtonColor: '#88D600',
  //           })
  //           this.comprovantesAnexoUrl = []
  //         }
  //       })
  //     }
  //   }
  //   return new Promise<void>((resolve) => { resolve(); });
  // }

  // removeComprovantesFile(file: File) {
  //   const index: number = this.comprovantesAnexo.indexOf(file);
  //   this.comprovantesAnexo.splice(index, 1)
  //   Swal.fire({
  //     position: 'top-end',
  //     icon: 'success',
  //     title: 'Arquivo Removido com Sucesso.',
  //     showConfirmButton: false,
  //     timer: 1500
  //   })
  //   if(this.comprovantesAnexo.length == 0){
  //     this.formulario.controls['comprovantes'].markAsTouched()
  //     this.formulario.get('comprovantes')?.setValue('')
  //   }
  // }

  resetform() {

    for (let field in this.formulario.controls) {
      this.formulario.controls[field].setValue('');
    }
    this.documentacaoAnexo = [];
    this.documentacaoAnexoUrl = [];
    this.ipvaAnexo = [];
    this.ipvaAnexoUrl = [];
    this.seguroAnexo = [];
    this.seguroAnexoUrl = [];

    this.mostrarDocumentacao = false;
    this.mostrarIpva = false;
    this.mostrarSeguro = false;

    this.valorCalculadoDocumentacao = 0;
    this.valorCalculadoIpva = 0;
    this.valorCalculadoSeguro = 0;
    this.totalValorReembolsar = 0;
    this.totalValorReembolsoCalculado = false;
    this.formulario.markAsUntouched()
  }

  abrirPipePublico() {
    window.open('https://app.pipefy.com/public/form/WVHXhf0h', '_blank')
  }



  // comprovanteIsChecked(event : any){
  //   let comprovanteChecked = event.target.checked

  //   if(comprovanteChecked){
  //     this.mostrarComprovantes = true
  //     this.formulario.get('comprovantes')?.setValue('')
  //     this.formulario.get('comprovantes')?.setValidators([Validators.required])
  //     this.formulario.get('comprovantes')?.updateValueAndValidity()


  //   }else{
  //     this.mostrarComprovantes = false
  //     this.comprovantesAnexo = []
  //     this.formulario.get('comprovantes')?.clearValidators()
  //     this.formulario.get('comprovantes')?.updateValueAndValidity()
  //   }
  // }

  documentacaoIsChecked(event : any){
    let documentacaoChecked = event.target.checked

    if(documentacaoChecked){
      this.mostrarDocumentacao = true
      this.formulario.get('valor_integral_documenta_o')?.setValue('')
      this.formulario.get('valor_integral_documenta_o')?.setValidators([Validators.required])
      this.formulario.get('valor_integral_documenta_o')?.updateValueAndValidity()
      this.formulario.get('anexo_documenta_o')?.setValue('')
      this.formulario.get('anexo_documenta_o')?.setValidators([Validators.required])
      this.formulario.get('anexo_documenta_o')?.updateValueAndValidity()

    }else{
      this.mostrarDocumentacao = false
      this.documentacaoAnexo = []
      this.formulario.get('valor_integral_documenta_o')?.setValue('')
      this.formulario.get('valor_integral_documenta_o')?.clearValidators()
      this.formulario.get('valor_integral_documenta_o')?.updateValueAndValidity()
      this.formulario.controls['valor_integral_documenta_o'].markAsUntouched()
      this.formulario.get('anexo_documenta_o')?.setValue('')
      this.formulario.get('anexo_documenta_o')?.clearValidators()
      this.formulario.get('anexo_documenta_o')?.updateValueAndValidity()
      this.formulario.controls['anexo_documenta_o'].markAsUntouched()
      this.documentacaoAnexo = [];
      this.documentacaoAnexoUrl = [];
      this.valorCalculadoDocumentacao = 0;
      this.calcularTotalReembolso();
    }
  }

  ipvaIsChecked(event : any){
    let ipvaChecked = event.target.checked

    if(ipvaChecked){
      this.mostrarIpva = true
      this.formulario.get('ipva')?.setValue('')
      this.formulario.get('ipva')?.setValidators([Validators.required])
      this.formulario.get('ipva')?.updateValueAndValidity()
      this.formulario.get('anexo_ipva')?.setValue('')
      this.formulario.get('anexo_ipva')?.setValidators([Validators.required])
      this.formulario.get('anexo_ipva')?.updateValueAndValidity()
    }else{
      this.mostrarIpva = false
      this.formulario.get('ipva')?.setValue('')
      this.formulario.get('ipva')?.clearValidators()
      this.formulario.get('ipva')?.updateValueAndValidity()
      this.formulario.controls['ipva'].markAsUntouched()
      this.formulario.get('anexo_ipva')?.setValue('')
      this.formulario.get('anexo_ipva')?.clearValidators()
      this.formulario.get('anexo_ipva')?.updateValueAndValidity()
      this.formulario.controls['anexo_ipva'].markAsUntouched()
      this.ipvaAnexo = [];
      this.ipvaAnexoUrl = [];
      this.valorCalculadoIpva = 0;
      this.calcularTotalReembolso();
    }
  }

  seguroIsChecked(event : any){
    let seguroChecked = event.target.checked

    if(seguroChecked){
      this.mostrarSeguro = true
      this.formulario.get('seguro_carro')?.setValue('')
      this.formulario.get('seguro_carro')?.setValidators([Validators.required])
      this.formulario.get('seguro_carro')?.updateValueAndValidity()
      this.formulario.get('anexo_seguro')?.setValue('')
      this.formulario.get('anexo_seguro')?.setValidators([Validators.required])
      this.formulario.get('anexo_seguro')?.updateValueAndValidity()
    }else{
      this.mostrarSeguro = false
      this.formulario.get('seguro_carro')?.setValue('')
      this.formulario.get('seguro_carro')?.clearValidators()
      this.formulario.get('seguro_carro')?.updateValueAndValidity()
      this.formulario.controls['seguro_carro'].markAsUntouched()
      this.formulario.get('anexo_seguro')?.setValue('')
      this.formulario.get('anexo_seguro')?.clearValidators()
      this.formulario.get('anexo_seguro')?.updateValueAndValidity()
      this.formulario.controls['anexo_seguro'].markAsUntouched()
      this.seguroAnexo = [];
      this.seguroAnexoUrl = [];
      this.valorCalculadoSeguro = 0;
      this.calcularTotalReembolso();

    }
  }

  setUserData(){
    this.userInfo.setName(this.msalService.instance.getAllAccounts()[0]?.name);
    this.userInfo.setUserEmail(this.msalService.instance.getAllAccounts()[0]?.username);
  }

  getUsernameEmail() {
    let userName = this.userInfo.getName();
    let userEmail = this.userInfo.getUserEmail();

    userName = userName ? userName.replace(",","") : "";

    this.formulario.get('nomeAuto')?.setValue(this.userInfo.getName());
    this.formulario.get('emailAuto')?.setValue(this.userInfo.getUserEmail());
  }

  getInfoReembolso() {
    this.spinner.show();
    this.pipefy.getReembolso()
    .then((res: any) => {
      let retornoVazio = JSON.stringify(res.body.data.reembolso) === '{}';
      let dataReembolso = res.body.data.reembolso ? res.body.data.reembolso : '';

      if (!retornoVazio || environment.dev) {
        this.porcentagemReembolsoDocumentacao = dataReembolso.reembolso_documentacao;
        this.porcentagemReembolsoIpva = dataReembolso.reembolso_ipva;
        this.porcentagemReembolsoSeguro = dataReembolso.reembolso_ipva;
        this.centroCusto = dataReembolso.centro_custo;
      } else {
        this.usuarioSemPermissao = true;
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: 'Usuário sem permissão para reembolso',
          showConfirmButton: false,
          timer: 10000
        });
      }
      this.spinner.hide();
    },
    (error: any) => {
      console.log(error);
      this.spinner.hide();
    })
  }

  calculoDocumentacao() {
    let percentualReembolsoDocumentacao = this.porcentagemReembolsoDocumentacao / 100;
    this.valorCalculadoDocumentacao = this.formulario.controls.valor_integral_documenta_o.value * percentualReembolsoDocumentacao;
    this.calcularTotalReembolso();
  }

  calculoIpva() {
    let percentualReembolsoIpva = this.porcentagemReembolsoIpva / 100;
    this.valorCalculadoIpva = this.formulario.controls.ipva.value * percentualReembolsoIpva;
    this.calcularTotalReembolso();
  }

  calculoSeguro() {
    let percentualReembolsoSeguro = this.porcentagemReembolsoSeguro / 100;
    this.valorCalculadoSeguro = this.formulario.controls.seguro_carro.value * percentualReembolsoSeguro;
    this.calcularTotalReembolso();
  }

  calcularTotalReembolso() {
    this.totalValorReembolsar = this.valorCalculadoDocumentacao + this.valorCalculadoIpva + this.valorCalculadoSeguro;
    this.totalValorReembolsoCalculado = true;
  }

}
