import { NbsBannerModule } from './componentes/nbs-banner/nbs-banner.module';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, DEFAULT_CURRENCY_CODE, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeModule } from './home/home.module';
import { ServicosFinanceirosModule } from './servicos-financeiros/servicos-financeiros.module';
import { SignInModule } from './sign-in/sign-in.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MsalModule, MsalRedirectComponent, MsalInterceptor, MsalGuard } from '@azure/msal-angular';
import { InteractionType, PublicClientApplication } from '@azure/msal-browser';
import { AzureService } from './autenticacao/azure.service';
import { ComprasModule } from './compras/compras.module';
import { FooterModule } from './componentes/footer/footer.module';
import { UrlInvalidaComponent } from './url-invalida/url-invalida.component';
import { HeaderModule } from './componentes/header/header.module';
import { FaqModule } from './faq/faq.module';
import { ConhecaMaisModule } from './conheca-mais/conheca-mais.module';
import { RecursosHumanosModule } from './recursos-humanos/recursos-humanos.module';
import { FormulariosTituloModule } from './componentes/formularios-titulo/formularios-titulo.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask'; // versão nova
//import { NgxMaskModule } from 'ngx-mask';
import { CancelamentoSolicitacaoModule } from './componentes/cancelamento-solicitacao/cancelamento-solicitacao.module';
import { InterceptorService } from './services/interceptor.service';
import { TesteBackendModule } from './teste-backend/teste-backend.module';
import { NgxSpinnerModule } from 'ngx-spinner';
import { GroupsResolver } from './servicos-financeiros/menu/groups.resolver';
import { environment } from "src/environments/environment";

import localePt from '@angular/common/locales/pt';
import {HashLocationStrategy, LocationStrategy, registerLocaleData} from '@angular/common';
import { TokenServiceService } from './services/token-service.service';
// import { MsalGuard } from './autenticacao/local-guard/local-guard.guard';
import { AccentureComponent } from './accenture/accenture.component';
import { TokenInterceptor } from './services/token.interceptor';
import { MsalGuardAd } from './autenticacao/msal-guard/msal.guard';
import { ControleDeVersoesService } from './services/controle-de-versoes.service';
registerLocaleData(localePt, 'pt');

@NgModule({
  declarations: [
    AppComponent,
    UrlInvalidaComponent,
    AccentureComponent
  ],
  imports: [
  FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    NbsBannerModule,
    AppRoutingModule,
    HeaderModule,
    HomeModule,
    ServicosFinanceirosModule,
    RecursosHumanosModule,
    SignInModule,
    ComprasModule,
    FooterModule,
    HttpClientModule,
    FaqModule,
    ConhecaMaisModule,
    FormulariosTituloModule,
    CancelamentoSolicitacaoModule,
    TesteBackendModule,
    NgxSpinnerModule,
    NgxMaskDirective, NgxMaskPipe, // versão nova
    // NgxMaskModule.forRoot(), // versão antiga
    MsalModule.forRoot( new PublicClientApplication
      (
        {
          auth:{
            clientId: (environment.homolog || environment.production) && localStorage.getItem('sso') != 'accenture'? environment.SSONutrienclientId : environment.SSOAccentureclientId,
            redirectUri: (environment.homolog || environment.production) && localStorage.getItem('sso') != 'accenture'? environment.SSONutrienredirectUri : environment.SSOAccentureredirectUri,
            authority: (environment.homolog || environment.production) && localStorage.getItem('sso') != 'accenture'? environment.SSONutrienauthority : environment.SSOAccentureauthority,
            postLogoutRedirectUri: (environment.homolog || environment.production) && localStorage.getItem('sso') != 'accenture'? environment.SSONutrienpostLogoutRedirectUri : environment.SSOAccenturepostLogoutRedirectUri
          },
          cache:
          {
            // cacheLocation: "sessionStorage",
            storeAuthStateInCookie: false
          }
        }
      ),
      {
        interactionType: InteractionType.Redirect,
        authRequest:{
          scopes: ['User.Read']
        }
      },
      {
        interactionType: InteractionType.Redirect,
        protectedResourceMap: new Map(
          [
            [environment.graph_microsoft,['User.Read']],
          ]
        )
      })
  ],
  providers: [
    provideNgxMask(), // versão nova
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
      // useFactory: MSALInstanceFactory,
    },
    {provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi:true},
    { provide: LOCALE_ID, useValue: 'pt' },
    { provide: DEFAULT_CURRENCY_CODE, useValue: 'BRL' },
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    MsalGuard,MsalGuardAd, AzureService, GroupsResolver,TokenServiceService,
    // {
    //   provide: APP_INITIALIZER,
    //   useFactory: function (service: TokenServiceService) {
    //     return () => service.getAccounts();
    //   },
    //   deps: [TokenServiceService],
    //   multi: true
    // },
    // TokenServiceService,
    // {
    //   provide: APP_INITIALIZER,
    //   useFactory: function (service: TokenServiceService) {
    //     return () => service.getSecrets();
    //   },
    //   deps: [TokenServiceService],
    //   multi: true
    // }
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: function (service: ControleDeVersoesService) {
        return () => service.consultaVersaoPopUp();
      },
      deps: [ControleDeVersoesService],
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: function (service: ControleDeVersoesService) {
        return () => service.procurarAtualizacaoPortal();
      },
      deps: [ControleDeVersoesService],
      multi: true
    },
    // {
    //   provide: APP_INITIALIZER,
    //   useFactory: function (service: ControleDeVersoesService) {
    //     return () => service.consultaVersaoPopUp();
    //   },
    //   deps: [ControleDeVersoesService],
    //   multi: true
    // },
    // {
    //   provide: APP_INITIALIZER,
    //   useFactory: function (service: ControleDeVersoesService) {
    //     return () => service.procurarAtualizacaoPortal();
    //   },
    //   deps: [ControleDeVersoesService],
    //   multi: true
    // },
  ],
  bootstrap: [AppComponent,  MsalRedirectComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
