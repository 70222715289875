import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TrataInputsService {

  constructor() { }

  removeCaracters(field: any) {

    console.log("field =>", field)

    var field = field.toString()
    var fieldFixed = field.replace(/(')/g, "").replace(/(")/g, "").replaceAll("\n", " ").replaceAll("\t", " ").trim()
    return fieldFixed
  }

  removeCaractersPipeIncidentes(field_value:any){
    var field_erro = field_value.toString()
    var field_erro_fixed = field_erro.replace(/(')/g, "").replace(/(")/g, "").replace(/({)/g, "").replace(/(})/g, "").trim()
    return field_erro_fixed
  }

  //a Função abaixo altera o formato para dd/mm/aaaa
  ajustaData(data: string) {
    // console.log('data',data)
    // console.log(typeof(data))
    if(data == ''){
      return ''
    }
    if(data !=='' || data !== undefined || data !== null){
      // console.log('entrei aqui')
      data = data[8] + data[9] + '-' + data[5] + data[6] + '-' + data[0] + data[1] + data[2] + data[3]
      // console.log(data)
      return data
    }
    return
  }

  ajustaDataConsulta(data: string) {
    // console.log('data',data)
    // console.log(typeof(data))
    if(data == ''){
      return ''
    }
    if(data !=='' || data !== undefined || data !== null){
      // console.log('entrei aqui')
      data = data[8] + data[9] + '/' + data[5] + data[6] + '/' + data[0] + data[1] + data[2] + data[3]
      // console.log(data)
      return data
    }
    return
  }
  ajustaDataConsultaDiaMesAno(data: string) {
    // console.log('data',data)
    // console.log(typeof(data))
    if(data == ''){
      return ''
    }
    if(data !=='' || data !== undefined || data !== null){
      // console.log('entrei aqui')
      data = data[5] + data[6] + '/' + data[8] + data[9] + '/' + data[0] + data[1] + data[2] + data[3]
      // console.log(data)
      return data
    }
    return
  }
}
