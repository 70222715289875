<app-header></app-header>
<app-nbs-banner url="../../../assets/img/background_compras.png" descricao="teste" titulo="Compras de Serviços e Materiais Indiretos"></app-nbs-banner>

<div class="nbs_menu container">
    <div class="row">
        <div class="h5 nbs_menu-title col-lg-10">
            <!-- <p class="title"> Para qual área deseja abrir uma solicitação?</p> -->
        </div>
    </div>


    <div class=" d-flex column justify-content-between menu-items">
        <app-box-link-menu descricao="Contabilidade" url="../../../assets/img/graos.png" titulo="Requisição de Compras" subtitulo="Serviços e Materiais Indiretos"  (click)="goToUrl('https://app.pipefy.com/public/form/8CMl776i')"></app-box-link-menu>
        <app-box-link-menu descricao="Pagamentos" url="../../../assets/img/Rectangle230.png" titulo="Suporte para Compras" subtitulo="Serviços e Materiais Indiretos"   (click)="goToUrl('https://app.pipefy.com/public/form/6wliaNG2')"></app-box-link-menu>

        <!-- <app-box-link-menu descricao="Pagamentos" url="../../../assets/img/cadastro.png" titulo="Cadastro" complemento="(Em breve!)" subtitulo="Clientes, Fornecedores, Materiais" ></app-box-link-menu> -->
        <!-- (click)="goToUrl('https://app.mub.one/login')" -->

    </div>
    <div class=" d-flex column justify-content-between menu-items">
  </div>



</div>
<footer>
  <div class="wrapper">
  <!-- <div class="row">
    <div class="col-lg-12 box-duvidas" (click)="faq()" >
      <i class="fa fa-question-circle"></i>
      <span>Dúvidas</span>
    </div>
  </div> -->
  <div class="row linha2">
    <div class="col-lg-3 box-voltar" (click)="navegar()">
      <i class="fa fa-chevron-circle-left icon-voltar" ></i>
      <span class="text-voltar">&nbsp;Voltar</span>
    </div>
    <div class="col-lg-6" >
      <div class="logo  box-logo" (click)="goHome()"><img class="logo_nbs  box-logo" src="../../../assets/img/logo_nbs.png" (click)="goHome()"></div>
    </div>
    <div class="col-lg-3"></div>
    <!-- <div class="col-lg-3 box-duvidas" (click)="faq()">
      <i class="fa fa-question-circle icon-duvidas"></i>
      <span class="text-duvidas">Ficou com alguma duvida? <br>É só mandar por aqui que a gente te ajuda!</span>
    </div> -->
    <!-- <div class="col-lg-9 box-texto">
      <p>
        Escolha a opção desejada<br>
        para ter acesso ao formulário de solicitação.
      </p>
    </div> -->
  </div>
</div>
</footer>


