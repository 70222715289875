import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { EmailRecoverChamados, PipefyService } from 'src/app/services/pipefy.service';
import { TrataInputsService } from 'src/app/services/trata-inputs.service';
import { UserInfosService } from 'src/app/autenticacao/usuario/user-infos.service';
import { status } from './status';
import { fluxos } from './fluxos';
import Swal from 'sweetalert2';
import { pipe_fields_incidentes } from '../formularios/pipe_fields_incidentes';
import * as XLSX from "xlsx";
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-consulta-de-chamados',
  templateUrl: './consulta-de-chamados.component.html',
  styleUrls: ['./consulta-de-chamados.component.css']
})
export class ConsultaDeChamadosComponent implements OnInit {

  formulario!: FormGroup
  status = status
  fluxos = fluxos
  chamados: EmailRecoverChamados[] = [];
  chamadosPorPagina = 10;
  hasNextPage:boolean|"" = false; // fix
  chamadosProximaPaginaCursor = "";
  chamadosTotal: number = 0;
  chamadosExcel: any
  userEmail: string = ''
  quantidade_de_chamados: any
  p: number = 1;
  error_counter: number = 1;

  constructor(private formBuilder: FormBuilder, private trataInput: TrataInputsService, private pipefy: PipefyService, private spinner: NgxSpinnerService, private userInfo: UserInfosService) { }

  ngOnInit(): void {


    this.userEmail = this.userInfo.getUserEmail()
    this.buscarChamadosInicial()

    this.formulario = this.formBuilder.group({
      numero_do_chamado:['',[]],
      data_inicio:['',[]],
      data_fim:['',[]],
      fluxo:['',[]],
      status:['',[]],
      e_mail_solicitante:['',[Validators.email]]
    })
  }

  buscarRegistros(p = 1) {
    if (p == 1) {
      this.chamados = [];
      this.chamadosProximaPaginaCursor = "";
      this.hasNextPage = "";
    }

    this.spinner.show();

    const numero_do_chamado = this.trataInput.removeCaracters(this.formulario.getRawValue().numero_do_chamado);
    const data_inicio = this.trataInput.ajustaDataConsultaDiaMesAno(
      this.trataInput.removeCaracters(this.formulario.getRawValue().data_inicio)
    );
    const data_fim = this.trataInput.ajustaDataConsultaDiaMesAno(
      this.trataInput.removeCaracters(this.formulario.getRawValue().data_fim)
    );
    const fluxo = this.trataInput.removeCaracters(this.formulario.getRawValue().fluxo);
    const status = this.trataInput.removeCaracters(this.formulario.getRawValue().status);
    const e_mail_solicitante = this.trataInput.removeCaracters(this.formulario.getRawValue().e_mail_solicitante);

    const isContingency = environment.contigencia;

    const handleError = (error: any) => {
      this.spinner.hide();
      this.error_counter++;
      const message =
        this.error_counter < 2
          ? "Ocorreu algum erro durante a sua solicitação. Por favor, tente novamente."
          : "Ocorreu algum erro novamente. Por favor, entre em contato com o administrador";

      Swal.fire({
        title: "Desculpe!",
        text: message,
        icon: "error",
        confirmButtonText: "Ok",
        confirmButtonColor: "#88D600",
        allowOutsideClick: false,
        allowEscapeKey: false,
      });
    };

    const clearFormFields = () => {
      ["numero_do_chamado", "data_inicio", "data_fim", "fluxo", "status"].forEach((field) =>
        this.formulario.get(field)?.setValue("")
      );
    };

    if (isContingency) {
      this.pipefy
        .getTicketStatusCont(numero_do_chamado, fluxo, data_inicio, data_fim, status, e_mail_solicitante)
        .subscribe(
          (res: any) => {
            this.spinner.hide();
            this.chamados = res.body.data.email_recover_status; // Ajuste conforme o formato da resposta
            this.quantidade_de_chamados = this.chamados.length;
            clearFormFields();
            this.p = 1;

            this.chamadosExcel = this.chamados.map((value: any) => ({
              "Data de Abertura": value.data_abertura,
              "Data de Conclusão": value.data_conclusao,
              "Data de Vencimento": value.data_de_vencimento,
              "Email do Solicitante": value.email_do_solicitante,
              "Número da Nota Fiscal": value.nota_fiscal,
              "Número do Pedido": value.nr_pedido,
              Área: value.rea,
              Solicitação: value.solicitacao,
              Status: status,
            }));
          },
          (error: any) => {
            this.spinner.hide();
            this.error_counter += 1;

            if (this.error_counter < 2) {
              Swal.fire({
                title: "Desculpe!",
                text: "Ocorreu algum erro durante a sua solicitação. Por favor, tente novamente.",
                icon: "error",
                confirmButtonText: "Ok",
                confirmButtonColor: "#88D600",
                allowOutsideClick: false,
                allowEscapeKey: false,
              });
            } else {
              Swal.fire({
                title: "Desculpe!",
                text: "Ocorreu algum erro novamente. Por favor, entre em contato com o administrador",
                icon: "error",
                confirmButtonColor: "#88D600",
              });
            }
          }
        );
    } else {
      this.pipefy
        .getTicketStatusGCP(
          this.chamadosProximaPaginaCursor,
          this.hasNextPage,
          numero_do_chamado,
          fluxo,
          data_inicio,
          data_fim,
          status,
          e_mail_solicitante
        )
        .then((res) => {
          this.spinner.hide();
          this.chamados.push(...res.chamados);
          this.hasNextPage = res.pagination.hasNextPage;
          this.chamadosProximaPaginaCursor = res.pagination.endCursor;
          this.chamadosTotal = res.pagination.totalCards;
          clearFormFields();
          this.p = p;

          this.chamadosExcel = this.chamados.map((value: any) => ({
            "Data de Abertura": value.data_abertura,
            "Data de Conclusão": value.data_conclusao,
            "Data de Vencimento": value.data_de_vencimento,
            "Email do Solicitante": value.email_do_solicitante,
            "Número da Nota Fiscal": value.nota_fiscal,
            "Número do Pedido": value.nr_pedido,
            Área: value.rea,
            Solicitação: value.solicitacao,
            Status: status,
          }));
        })
        .catch(handleError);
    }
  }


  novoChamado(){

    this.chamados = []
    // console.log(this.chamados)
    this.formulario.get('numero_do_chamado')?.setValue('')
  }

  buscarChamadosInicial() {
    const isContingency = environment.contigencia;

    this.spinner.show()
    if(isContingency){
      this.pipefy.getTicketStatusCont().subscribe((res:any)=>{
        this.spinner.hide()

        if(res.code == 504){
          // console.log("entrei aqui")
          this.buscarChamadosInicial()
        }else{

        this.chamados = res.body.data.email_recover_status
        this.quantidade_de_chamados = this.chamados.length

        this.chamadosExcel = this.chamados.map((value:any)=>({
          'Data de Abertura': value.data_abertura,
          'Data de Conclusão': value.data_conclusao,
          'Data de Vencimento': value.data_de_vencimento,
          'Email do Solicitante': value.email_do_solicitante,
          'Número da Nota Fiscal': value.nota_fiscal,
          'Número do Pedido': value.nr_pedido,
          'Área':value.rea,
          'Solicitação': value.solicitacao,
          'Status': status
        }));




        // for(var i=0; i<this.chamadosExcel.length; i++){

        //   delete this.chamadosExcel[i].aging
        //   delete this.chamadosExcel[i].aging_de_conclus_o
        //   delete this.chamadosExcel[i].atualizar_t_tulo
        //   delete this.chamadosExcel[i].data_de_vencimento_vazio_n_o_apagar
        //   delete this.chamadosExcel[i].pipe_id
        //   delete this.chamadosExcel[i].titulo
        //   delete this.chamadosExcel[i].torre
        //   delete this.chamadosExcel[i].link_de_pend_ncia
        // }
        }
        // console.log(this.chamados)
      },
      (error: any) => {
        this.spinner.hide()
        this.error_counter = this.error_counter + 1
        if (this.error_counter < 2) {{
            Swal.fire({
              title: 'Desculpe!',
              text: 'Ocorreu algum erro durante a sua solicitação. Por favor, tente novamente.',
              icon: 'error',
              confirmButtonText: 'Ok',
              confirmButtonColor: '#88D600',
              allowOutsideClick: false,
              allowEscapeKey: false
            })
          }
        }
        else {
          this.spinner.hide()
          Swal.fire({
            title: 'Desculpe!',
            text: 'Ocorreu algum erro novamente. Por favor, entre em contato com o administrador',
            icon: 'error',
            confirmButtonColor: '#88D600',
          })
        }
      })

    }else{
      this.pipefy.getTicketStatusGCP(this.chamadosProximaPaginaCursor, "").then((res) => {
        this.spinner.hide()

        if (res.status.code == 504) {
          // console.log("entrei aqui")
          this.buscarChamadosInicial()
        } else {

          this.chamados = res.chamados;
          this.chamadosTotal = res.pagination.totalCards;
          this.chamadosProximaPaginaCursor = res.pagination.endCursor;
          this.hasNextPage = res.pagination.hasNextPage;

          this.chamadosExcel = this.chamados.map((value: any) => ({
            'Data de Abertura': value.data_abertura,
            'Data de Conclusão': value.data_conclusao,
            'Data de Vencimento': value.data_de_vencimento,
            'Email do Solicitante': value.email_do_solicitante,
            'Número da Nota Fiscal': value.nota_fiscal,
            'Número do Pedido': value.nr_pedido,
            'Área': value.rea,
            'Solicitação': value.solicitacao,
            'Status': status
          }));




          // for(var i=0; i<this.chamadosExcel.length; i++){

          //   delete this.chamadosExcel[i].aging
          //   delete this.chamadosExcel[i].aging_de_conclus_o
          //   delete this.chamadosExcel[i].atualizar_t_tulo
          //   delete this.chamadosExcel[i].data_de_vencimento_vazio_n_o_apagar
          //   delete this.chamadosExcel[i].pipe_id
          //   delete this.chamadosExcel[i].titulo
          //   delete this.chamadosExcel[i].torre
          //   delete this.chamadosExcel[i].link_de_pend_ncia
          // }
        }
        // console.log(this.chamados)
      }).catch(error => {
        this.spinner.hide()
        this.error_counter = this.error_counter + 1
        if (this.error_counter < 2) {
          {
            Swal.fire({
              title: 'Desculpe!',
              text: 'Ocorreu algum erro durante a sua solicitação. Por favor, tente novamente.',
              icon: 'error',
              confirmButtonText: 'Ok',
              confirmButtonColor: '#88D600',
              allowOutsideClick: false,
              allowEscapeKey: false
            })
          }
        }
        else {
          this.spinner.hide()
          Swal.fire({
            title: 'Desculpe!',
            text: 'Ocorreu algum erro novamente. Por favor, entre em contato com o administrador',
            icon: 'error',
            confirmButtonColor: '#88D600',
          })
        }
      });
    }
  }

  verificaPerfil(pagina:any){
    var permissoes = [
      {
        permissão: 'ativo'
      },
      {
        permissão: 'inativo'
      }
    ]

    var teste = permissoes.map((a=>a.permissão))
    console.log(teste)

    var acessa = teste.includes(pagina)

    return acessa
  }

  openCard(card:any){
    let url = `https://app.pipefy.com/open-cards/${card}`
    window.open(url,'_blank')
  }

  exportExcel(){

    var wb = XLSX.utils.book_new();
    var ws = XLSX.utils.json_to_sheet(this.chamadosExcel)
    XLSX.utils.book_append_sheet(wb,ws,'teste')
    XLSX.writeFile(wb,'teste.xlsx')
  }

}
