import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { tap, map } from 'rxjs/operators';
import { Groups } from './groups';
import { environment } from 'src/environments/environment';


// interface Group {
//   '@odata.context': string,
//   '@odata.count': number,
//   '@odata.nextLink': string,
//   'value': []
// }


@Injectable({
  providedIn: 'root'
})
export class UserInfosService {

  userName: any = "";
  userEmail: any = "";
  userGroups: any[] = []

  constructor(private http: HttpClient) {}

  setName(name: string | undefined){
    this.userName = name
  }

  private async aguardarNome(): Promise<void> {
    while (this.userName === undefined || this.userName === "" || this.userName === null) {
      await new Promise(resolve => setTimeout(resolve, 100));
    }
  }

  private async aguardarEmail(): Promise<void> {
    while (this.userEmail === undefined || this.userEmail === "" || this.userEmail === null) {
      await new Promise(resolve => setTimeout(resolve, 100));
    }
  }

  async getNamePromise(): Promise<string> {
    if(environment.localhost) {
      this.userName = environment.userNameMock;
    }
    await this.aguardarNome();
    return this.userName!;
  }

  async getUserEmailPromise(): Promise<string> {
    if(environment.localhost) {
      this.userEmail = environment.userEmailMock;
    }
    await this.aguardarEmail();
    return this.userEmail!;
  }


  getName(){
    return !!environment.localhost? environment.userNameMock : this.userName
  }

  setUserEmail(email: string | undefined){
    this.userEmail = email
  }

  getUserEmail(){
    return !!environment.localhost? environment.userEmailMock : this.userEmail;
  }

  getUserProfile(){
    return this.http.get(environment.graph_microsoft).pipe(
      tap(res=>{
        // console.log(res)
      })
    )
  }

  getUserGroupsFromAzure(){
    return this.http.get<{value: [{ id: string}]}>(environment.graph_groups_microsoft).pipe(
      tap(res=>{
        // console.log(res)
      })
    )
  }

  setUserGroups(groups: Groups){
    // console.log('setGroups:', groups)
    // var groupValues = Object.values(groups)
    // console.log(groupValues)
    // var group = groupValues[2]
    var groupValues = groups.value
    // console.log(groupValues)
    var ids = groupValues.map((a: { id: any; })=>a.id)
    // console.log(ids)
    this.userGroups = ids
  }

  getUserGroups(){
    // console.log("GETT USER GROUPS:", this.userGroups)
    return this.userGroups
  }

  getVersion(){
    return 'Versão Reembolso na página RH - Release 20/12/2022'
  }


}
