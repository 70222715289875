import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MsalService } from '@azure/msal-angular';
import { NgxSpinnerService } from 'ngx-spinner';
import { PipefyService } from 'src/app/services/pipefy.service';
import { TrataInputsService } from 'src/app/services/trata-inputs.service';
import { pipe_fields_incidentes } from 'src/app/servicos-financeiros/formularios/pipe_fields_incidentes';
import Swal from 'sweetalert2';
import { tiposDeConta } from '../alteracao-dados-bancarios/tipo_de_conta';
import { pipe_fields_id_ponto_eletronico } from '../pipe_fields_ponto_eletronico';

@Component({
  selector: 'app-ponto-eletronico',
  templateUrl: './ponto-eletronico.component.html',
  styleUrls: ['./ponto-eletronico.component.css']
})
export class PontoEletronicoComponent implements OnInit {

  formulario!: FormGroup;
  anexo: any[] = [];
  anexoUrl: any[] = []
  tipoDeConta = tiposDeConta

  error_counter: number = 0;
  erro: boolean = false

  constructor(private formBuilder: FormBuilder, private trataInput: TrataInputsService, private pipefy: PipefyService, private spinner: NgxSpinnerService, private msalService: MsalService) { }

  ngOnInit(): void {

    this.formulario = this.formBuilder.group({
      nome_do_funcionario:['',[Validators.required]],
      email:['',[Validators.required, Validators.email]],
      matricula: ['',[Validators.required]],
      empresa: ['', [Validators.required]],
      justificativa_do_ajuste: ['', [Validators.required]],
      descricao: ['', [Validators.required]],
      anexo: ['', [Validators.required]],

    })
  }

  enviarFormulario() {
    this.spinner.show()

    const criarBody = async () => {

      await this.getPipesUrl(this.anexo);
      if (this.anexo.length > 0) {
        while (this.anexo.length != this.anexoUrl.length) {
          // console.log("esperando....")
          await this.delay(100);
          if(this.erro){
            break;
          }
        }
        if(this.erro){
          return
        }
      }

      if (this.anexo.length > 0) {
        var anexoUrl2 = ''
        anexoUrl2 = '['
        for (var i = 0; i < this.anexoUrl.length; i++) {
          anexoUrl2 = anexoUrl2 + '"' + this.anexoUrl[i] + '",'
        }
        anexoUrl2 = anexoUrl2.slice(0, -1)
        anexoUrl2 = anexoUrl2 + "]"

      } else {
        anexoUrl2 = ''
      }

      let nome_do_funcionario =  this.trataInput.removeCaracters(this.formulario.getRawValue().nome_do_funcionario)
      let email =  this.trataInput.removeCaracters(this.formulario.getRawValue().email)
      let matricula =  this.trataInput.removeCaracters(this.formulario.getRawValue().matricula)
      let empresa =  this.trataInput.removeCaracters(this.formulario.getRawValue().empresa)
      let justificativa_do_ajuste =  this.trataInput.removeCaracters(this.formulario.getRawValue().justificativa_do_ajuste)
      let descricao =  this.trataInput.removeCaracters(this.formulario.getRawValue().descricao)




      let pipe_fields = pipe_fields_id_ponto_eletronico
      let fields_values = ["Ajuste retroativo de ocorrências",nome_do_funcionario,email,matricula,empresa,justificativa_do_ajuste,descricao,anexoUrl2]
      let pipe_id = "302517887"

      this.pipefy.createCard(this.pipefy.criaBody(pipe_fields, fields_values, pipe_id)).subscribe((res) => {
        // console.log(res)
        this.spinner.hide()

        if (res.data.result_creation_card.id != undefined) {
          let card_id = res.data.result_creation_card.id
          Swal.fire({
            title: 'Chamado aberto com Sucesso!',
            text: 'O chamado ' + card_id + ' foi aberto com sucesso. Nossa equipe irá tratar e retornar em breve. Obrigado!',
            icon: 'success',
            confirmButtonText: 'Ok',
            confirmButtonColor: '#88D600',
          })
          this.resetform()
        }

        if (res.data.result_creation_card.errors != undefined) {
          var erro = res.data.result_creation_card.errors[0].message
          Swal.fire({
            title: 'Desculpe!',
            html: '<p>Ocorreu um erro.</p>' + '<a href="https://app.pipefy.com/public/form/WVHXhf0h" target="_blank">Clique aqui </a>' + '<span>para fazer sua solicitação</span>',
            text: erro,
            icon: 'error',
            confirmButtonColor: '#88D600',
          })
          this.anexoUrl = []

          let pipe_fields_incidentes_values = ['Incidente', 'Correção', 'Portal NBS', 'HR', 'RH', "Ponto- criação de card -- Ponto Eletrônico", erro, 'Portal NBS Error', 'incidente@portal.com',
            'Sim', 'Outros', 'criação de card via portal', 'Página web com problemas']

          var pipe_fields_incidentes_values2 = []
          for (var i = 0; i < pipe_fields_incidentes_values.length; i++) {
            pipe_fields_incidentes_values2.push(this.trataInput.removeCaractersPipeIncidentes(pipe_fields_incidentes_values[i]))
          }
          // console.log(pipe_fields_incidentes_values2)

          let pipe_incidentes_id = '302487533'

          this.pipefy.createCard(this.pipefy.criaBody(pipe_fields_incidentes, pipe_fields_incidentes_values2, pipe_incidentes_id)).subscribe((res) => {
            // console.log(res)
          })
        }
      }, (error: any) => {
        this.spinner.hide()
        // console.log(error.status)
        this.error_counter = this.error_counter + 1
        if (this.error_counter < 2) {
          if (error.status == 401) {
            // console.log("entrei em error.status")
            Swal.fire({
              title: 'Ops, sua sessão expirou!',
              text: 'Por favor, saia e logue novamente.',
              icon: 'error',
              confirmButtonText: 'Sair',
              confirmButtonColor: '#ff6961',
              allowOutsideClick: false,
              allowEscapeKey: false
            })
              .then(() => {
                this.msalService.logoutRedirect()
              }
              )
          } else {
            Swal.fire({
              title: 'Desculpe!',
              text: 'Ocorreu algum erro durante a sua solicitação. Por favor, tente novamente.',
              icon: 'error',
              confirmButtonText: 'Ok',
              confirmButtonColor: '#88D600',
              allowOutsideClick: false,
              allowEscapeKey: false
            })
          }
        }
        else {
          this.spinner.hide()
          Swal.fire({
            title: 'Desculpe!',
            html: '<p>Ocorreu um erro.</p>' + '<a href="https://app.pipefy.com/public/form/WVHXhf0h" target="_blank">Clique aqui </a>' + '<span>para fazer sua solicitação</span>',
            icon: 'error',
            confirmButtonColor: '#88D600',
          })
          this.anexoUrl = []
        }
      })
    }

    criarBody()
  }

  delay(time: any) {
    return new Promise(resolve => setTimeout(resolve, time));
  }

  save(event: any): void {
    var files = event.target.files;
    for (var i = 0; i < files.length; i++) {
      this.anexo.push(files[i])
    }
  }

  async getPipesUrl(files: any) {
    this.erro = false;

  // Função utilitária para ler o arquivo usando FileReader como uma Promise
  const readFileAsBase64 = (file: File) => {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const result = reader.result?.toString();
        if (result) {
          const fileContentStart = result.indexOf(",");
          resolve(result.substring(fileContentStart + 1)); // Retorna o conteúdo Base64
        } else {
          reject(new Error("Falha ao ler o arquivo"));
        }
      };
      reader.onerror = (error) => {
        reject(error);
      };
    });
  };

  // Loop sobre os arquivos, esperando cada leitura e operação assíncrona
  for (let i = 0; i < files.length; i++) {
    const fileContent = files[i];
    const fileName = files[i].name;

    try {
      const fileBase64 = await readFileAsBase64(fileContent); // Aguarda a leitura do arquivo
      const urlResposta = await this.pipefy.getFileUrl3(fileBase64, fileName); // Obtém a URL após o upload do arquivo

      var fileUrlTemp = "";
        var startIndex = 0;

        if (urlResposta.indexOf("orgs") > 0) {
          startIndex = urlResposta.indexOf("orgs/");
        } else {
          startIndex = urlResposta.indexOf("uploads/");
        }

        var endIndex = urlResposta.indexOf("?");
        fileUrlTemp = urlResposta.substring(startIndex, endIndex);

        this.anexoUrl.push(fileUrlTemp)

      //this.invociesUrl.push(url); // Adiciona a URL à lista

    } catch (error: any) {
      this.erro = true;
      this.spinner.hide();
      this.error_counter = this.error_counter + 1;
      if (this.error_counter < 2) {
        if (error.status === 401) {
          Swal.fire({
            title: 'Ops, sua sessão expirou!',
            text: 'Por favor, saia e logue novamente.',
            icon: 'error',
            confirmButtonText: 'Sair',
            confirmButtonColor: '#ff6961',
            allowOutsideClick: false,
            allowEscapeKey: false
          }).then(() => {
            this.msalService.logoutRedirect();
          });
        } else {
          Swal.fire({
            title: 'Desculpe!',
            text: 'Ocorreu algum erro durante a sua solicitação. Por favor, tente novamente.',
            icon: 'error',
            confirmButtonText: 'Ok',
            confirmButtonColor: '#88D600',
            allowOutsideClick: false,
            allowEscapeKey: false
          });
        }
      } else {
        this.spinner.hide();
        Swal.fire({
          title: 'Desculpe!',
          html: '<p>Ocorreu um erro.</p>' + '<a href="https://app.pipefy.com/public/form/3Sd_ATrN" target="_blank">Clique aqui </a>' + '<span>para fazer sua solicitação</span>',
          icon: 'error',
          confirmButtonColor: '#88D600',
        });
        this.anexoUrl = [];
      }
    }
    }
    return new Promise<void>((resolve) => { resolve(); });
  }

  removeFile(file: File) {
    const index: number = this.anexo.indexOf(file);
    this.anexo.splice(index, 1)
    alert("Arquivo Removido")
  }

  resetform() {

    for (let field in this.formulario.controls) {
      this.formulario.controls[field].setValue('');
    }
    this.formulario.markAsUntouched()
    this.anexo = []
    this.anexoUrl = []
  }

  abrirPipePublico() {
    window.open('https://app.pipefy.com/public/form/WVHXhf0h', '_blank')
  }
}
