import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TrataInputsService } from './../../../../services/trata-inputs.service';
import { campanha } from './campanha';
import { formasDePagamento } from './formas_de_pagamento';
import { numeroDeProdutos } from './numero_de_produtos';
import { TipoDePedido } from './tipo_de_pedido';
import { FamiliaProdutos } from './familia_produtos';
import { pipe_fields_id } from './pipe_fields_id';
import { estados } from './estados_lojas_nutrien';
import { NgxSpinnerService } from 'ngx-spinner';
import { PipefyService } from 'src/app/services/pipefy.service';
import Swal from 'sweetalert2';
import { pipe_fields_incidentes } from '../../pipe_fields_incidentes';
import { HttpErrorResponse } from '@angular/common/http';
import { MsalService } from '@azure/msal-angular';
import { UserInfosService } from 'src/app/autenticacao/usuario/user-infos.service';
import { empresas } from './empresas';
import { categorias } from './categorias';
import { planta_de_expedicao } from './planta_de_expedicao';
import { condicoes_de_parcelamento } from './condicoes_de_parcelamento';




@Component({
  selector: 'app-pedido-de-venda-e-faturamento',
  templateUrl: './pedido-de-venda-e-faturamento.component.html',
  styleUrls: ['./pedido-de-venda-e-faturamento.component.css']
})
export class PedidoDeVendaEFaturamentoComponent implements OnInit {

  formulario!: FormGroup;
  campanhas = campanha
  tipos_de_pedido = TipoDePedido
  numero_de_produtos = numeroDeProdutos
  formas_de_pagamento = formasDePagamento
  familia_do_produto = FamiliaProdutos
  estados = estados
  quantidade_de_produtos_int: number = 0;

  anexos: any[] = [];
  anexosUrl: any[] = []


  error_counter: number = 0;
  erro: boolean = false

  emailApi: string = ''
  userNameApi: string = ''

  empresas = empresas;

  categorias = categorias;

  planta_de_expedicao = planta_de_expedicao;

  condicoes_de_parcelamento = condicoes_de_parcelamento;

  produtos: any[] = [];
  descricao: any[]= []
  erroCodigoInvalido: boolean = false

  msgSpinner: string = '';

  exibeListaProdutos: boolean = false;

  exibeCondParcelamento: boolean = false;

  exibeReceituarioAgronomico: boolean = false

  constructor(private formBuilder: FormBuilder,
              private trataInput: TrataInputsService,
              private pipefy: PipefyService,
              private spinner: NgxSpinnerService,
              private msalService: MsalService,
              private userInfo: UserInfosService
  ) { }

  ngOnInit(): void {

    this.formulario = this.formBuilder.group({

      empresa: ['', [Validators.required]],
      categoria: ['', [Validators.required]],
      tipo_de_venda: ['', []],
      tipo_de_cliente: ['', [Validators.required]],
      razao_social: ['', []],
      cnpj: ['', []],
      nome_cliente: ['', []],
      cpf: ['', []],
      inscricao_estadual: ['', [Validators.required]],
      nome_da_propriedade: ['', []],
      cliente_novo: ['', []],
      cultura: ['', [Validators.required]],
      necessario_receituario_agronomico: ['',[]],
      i_praga: ['',[]],
      ii_metodo_de_aplicacao: ['',[]],
      iii_periodo_de_aplicacao: ['',[]],
      tipo_de_pedido: ['', []],
      campanha: ['', []],
      numero_de_produtos_no_pedido: ['', [Validators.required]],
      frete: ['', []],
      cif_valor_do_frete: ['', []],
      // pedido_contem_defensivo_quimico: ['', []],
      valor_total_do_pedido: ['', [Validators.required]],
      forma_de_pagamento: ['', []],
      condicao_de_parcelamento: ['', []],
      vencimento_condicao_de_pagamento: ['', []],
      consultor_tecnico_de_vendas: ['', [Validators.required]],
      email_do_solicitante: ['', [Validators.required, Validators.email]],
      e_mail_secund_rio : ['',[]],
      data_do_pedido: ['', [Validators.required]],
      demanda_urgente: ['', [Validators.required]],
      realizar_faturamento_imediato_nbs: ['', [Validators.required]],
      // descreva_a_urgencia: ['', []],
      municipio_loja_nutrien: ['', [Validators.required]],
      estado_loja_nutrien: ['', []],
      observacao: ['', []],
      anexo: ['', []],

      codigo_do_produto_1: ['', []],
      descricao_produto_1: ['', []],
      // familia_produto_1: ['', []],
      quantidade_produto_1: ['', []],
      valor_unitario_produto_1: ['', []],
      embalagem_produto_1: ['', []],
      data_da_entrega_solicitada_produto_1: ['', []],
      planta_de_expedicao_1:['',[]],
      // centro_de_expedicao_1:['',[]],

      codigo_do_produto_2: ['', []],
      descricao_produto_2: ['', []],
      // familia_produto_2: ['', []],
      quantidade_produto_2: ['', []],
      valor_unitario_produto_2: ['', []],
      embalagem_produto_2: ['', []],
      data_da_entrega_solicitada_produto_2: ['', []],
      planta_de_expedicao_2:['',[]],
      // centro_de_expedicao_2:['',[]],


      codigo_do_produto_3: ['', []],
      descricao_produto_3: ['', []],
      // familia_produto_3: ['', []],
      quantidade_produto_3: ['', []],
      valor_unitario_produto_3: ['', []],
      embalagem_produto_3: ['', []],
      data_da_entrega_solicitada_produto_3: ['', []],
      planta_de_expedicao_3:['',[]],
      // centro_de_expedicao_3:['',[]],


      codigo_do_produto_4: ['', []],
      descricao_produto_4: ['', []],
      // familia_produto_4: ['', []],
      quantidade_produto_4: ['', []],
      valor_unitario_produto_4: ['', []],
      embalagem_produto_4: ['', []],
      data_da_entrega_solicitada_produto_4: ['', []],
      planta_de_expedicao_4:['',[]],
      // centro_de_expedicao_4:['',[]],


      codigo_do_produto_5: ['', []],
      descricao_produto_5: ['', []],
      // familia_produto_5: ['', []],
      quantidade_produto_5: ['', []],
      valor_unitario_produto_5: ['', []],
      embalagem_produto_5: ['', []],
      data_da_entrega_solicitada_produto_5: ['', []],
      planta_de_expedicao_5:['',[]],
      // centro_de_expedicao_5:['',[]],


      codigo_do_produto_6: ['', []],
      descricao_produto_6: ['', []],
      // familia_produto_6: ['', []],
      quantidade_produto_6: ['', []],
      valor_unitario_produto_6: ['', []],
      embalagem_produto_6: ['', []],
      data_da_entrega_solicitada_produto_6: ['', []],
      planta_de_expedicao_6:['',[]],
      // centro_de_expedicao_6:['',[]],


      codigo_do_produto_7: ['', []],
      descricao_produto_7: ['', []],
      // familia_produto_7: ['', []],
      quantidade_produto_7: ['', []],
      valor_unitario_produto_7: ['', []],
      embalagem_produto_7: ['', []],
      data_da_entrega_solicitada_produto_7: ['', []],
      planta_de_expedicao_7:['',[]],
      // centro_de_expedicao_7:['',[]],


      codigo_do_produto_8: ['', []],
      descricao_produto_8: ['', []],
      // familia_produto_8: ['', []],
      quantidade_produto_8: ['', []],
      valor_unitario_produto_8: ['', []],
      embalagem_produto_8: ['', []],
      data_da_entrega_solicitada_produto_8: ['', []],
      planta_de_expedicao_8:['',[]],
      // centro_de_expedicao_8:['',[]],


      codigo_do_produto_9: ['', []],
      descricao_produto_9: ['', []],
      // familia_produto_9: ['', []],
      quantidade_produto_9: ['', []],
      valor_unitario_produto_9: ['', []],
      embalagem_produto_9: ['', []],
      data_da_entrega_solicitada_produto_9: ['', []],
      planta_de_expedicao_9:['',[]],
      // centro_de_expedicao_9:['',[]],


      codigo_do_produto_10: ['', []],
      descricao_produto_10: ['', []],
      // familia_produto_10: ['', []],
      quantidade_produto_10: ['', []],
      valor_unitario_produto_10: ['', []],
      embalagem_produto_10: ['', []],
      data_da_entrega_solicitada_produto_10: ['', []],
      planta_de_expedicao_10:['',[]],
      // centro_de_expedicao_10:['',[]],


      codigo_do_produto_11: ['', []],
      descricao_produto_11: ['', []],
      // familia_produto_11: ['', []],
      quantidade_produto_11: ['', []],
      valor_unitario_produto_11: ['', []],
      embalagem_produto_11: ['', []],
      data_da_entrega_solicitada_produto_11: ['', []],
      planta_de_expedicao_11:['',[]],
      // centro_de_expedicao_11:['',[]],


      codigo_do_produto_12: ['', []],
      descricao_produto_12: ['', []],
      // familia_produto_12: ['', []],
      quantidade_produto_12: ['', []],
      valor_unitario_produto_12: ['', []],
      embalagem_produto_12: ['', []],
      data_da_entrega_solicitada_produto_12: ['', []],
      planta_de_expedicao_12:['',[]],
      // centro_de_expedicao_12:['',[]],


      codigo_do_produto_13: ['', []],
      descricao_produto_13: ['', []],
      // familia_produto_13: ['', []],
      quantidade_produto_13: ['', []],
      valor_unitario_produto_13: ['', []],
      embalagem_produto_13: ['', []],
      data_da_entrega_solicitada_produto_13: ['', []],
      planta_de_expedicao_13:['',[]],
      // centro_de_expedicao_13:['',[]],


      codigo_do_produto_14: ['', []],
      descricao_produto_14: ['', []],
      // familia_produto_14: ['', []],
      quantidade_produto_14: ['', []],
      valor_unitario_produto_14: ['', []],
      embalagem_produto_14: ['', []],
      data_da_entrega_solicitada_produto_14: ['', []],
      planta_de_expedicao_14:['',[]],
      // centro_de_expedicao_14:['',[]],


      codigo_do_produto_15: ['', []],
      descricao_produto_15: ['', []],
      // familia_produto_15: ['', []],
      quantidade_produto_15: ['', []],
      valor_unitario_produto_15: ['', []],
      embalagem_produto_15: ['', []],
      data_da_entrega_solicitada_produto_15: ['', []],
      planta_de_expedicao_15:['',[]],
      // centro_de_expedicao_15:['',[]],


      codigo_do_produto_16: ['', []],
      descricao_produto_16: ['', []],
      // familia_produto_16: ['', []],
      quantidade_produto_16: ['', []],
      valor_unitario_produto_16: ['', []],
      embalagem_produto_16: ['', []],
      data_da_entrega_solicitada_produto_16: ['', []],
      planta_de_expedicao_16:['',[]],
      // centro_de_expedicao_16:['',[]],


      codigo_do_produto_17: ['', []],
      descricao_produto_17: ['', []],
      // familia_produto_17: ['', []],
      quantidade_produto_17: ['', []],
      valor_unitario_produto_17: ['', []],
      embalagem_produto_17: ['', []],
      data_da_entrega_solicitada_produto_17: ['', []],
      planta_de_expedicao_17:['',[]],
      // centro_de_expedicao_17:['',[]],


      codigo_do_produto_18: ['', []],
      descricao_produto_18: ['', []],
      // familia_produto_18: ['', []],
      quantidade_produto_18: ['', []],
      valor_unitario_produto_18: ['', []],
      embalagem_produto_18: ['', []],
      data_da_entrega_solicitada_produto_18: ['', []],
      planta_de_expedicao_18:['',[]],
      // centro_de_expedicao_18:['',[]],


      codigo_do_produto_19: ['', []],
      descricao_produto_19: ['', []],
      // familia_produto_19: ['', []],
      quantidade_produto_19: ['', []],
      valor_unitario_produto_19: ['', []],
      embalagem_produto_19: ['', []],
      data_da_entrega_solicitada_produto_19: ['', []],
      planta_de_expedicao_19:['',[]],
      // centro_de_expedicao_19:['',[]],


      codigo_do_produto_20: ['', []],
      descricao_produto_20: ['', []],
      // familia_produto_20: ['', []],
      quantidade_produto_20: ['', []],
      valor_unitario_produto_20: ['', []],
      embalagem_produto_20: ['', []],
      data_da_entrega_solicitada_produto_20: ['', []],
      planta_de_expedicao_20:['',[]]
      // centro_de_expedicao_20:['',[]]
    })


    this.emailApi = this.userInfo.getUserEmail()
    // this.userNameApi = this.userInfo.getName()

    // this.formulario.get('email_do_solicitante')?.setValue(this.emailApi)


    // if (this.formulario.getRawValue().email_do_solicitante != '') {
    //   this.formulario.controls['email_do_solicitante'].disable()
    // }
    // if (this.formulario.getRawValue().email_do_solicitante == undefined) {
    //   this.formulario.controls['email_do_solicitante'].enable()
    // }

    this.getProdutos();
    this.verificaExibicaoCondParcelamento();
  }


  enviarFormulario() {

    this.msgSpinner = "Aguarde. Sua solicitação está sendo processada.";
    this.spinner.show()

    const criarBody = async () => {

      await this.getPipesUrl(this.anexos);
      if (this.anexos.length > 0) {
        while (this.anexos.length != this.anexosUrl.length) {
          // console.log("esperando....")
          await this.delay(100);
          if(this.erro){
            break;
          }
        }
        if(this.erro){
          return
        }
      }

      if (this.anexos.length > 0) {
        var anexosUrl2 = ''
        anexosUrl2 = '['
        for (var i = 0; i < this.anexosUrl.length; i++) {
          anexosUrl2 = anexosUrl2 + '"' + this.anexosUrl[i] + '",'
        }
        anexosUrl2 = anexosUrl2.slice(0, -1)
        anexosUrl2 = anexosUrl2 + "]"

      } else {
        anexosUrl2 = ''
      }

    let empresa = this.trataInput.removeCaracters(this.formulario.getRawValue().empresa)
    let categoria = this.trataInput.removeCaracters(this.formulario.getRawValue().categoria)
    let tipo_de_venda = this.trataInput.removeCaracters(this.formulario.getRawValue().tipo_de_venda)
    let tipo_de_cliente = this.trataInput.removeCaracters(this.formulario.getRawValue().tipo_de_cliente)
    let razao_social = this.trataInput.removeCaracters(this.formulario.getRawValue().razao_social)
    let cnpj = this.trataInput.removeCaracters(this.formulario.getRawValue().cnpj)
    let nome_cliente = this.trataInput.removeCaracters(this.formulario.getRawValue().nome_cliente)
    let cpf = this.trataInput.removeCaracters(this.formulario.getRawValue().cpf)
    let inscricao_estadual = this.trataInput.removeCaracters(this.formulario.getRawValue().inscricao_estadual)
    let nome_da_propriedade = this.trataInput.removeCaracters(this.formulario.getRawValue().nome_da_propriedade)
    let cliente_novo = this.trataInput.removeCaracters(this.formulario.getRawValue().cliente_novo)
    let cultura = this.trataInput.removeCaracters(this.formulario.getRawValue().cultura)
    let necessario_receituario_agronomico = this.trataInput.removeCaracters(this.formulario.getRawValue().necessario_receituario_agronomico)
    let i_praga = this.trataInput.removeCaracters(this.formulario.getRawValue().i_praga)
    let ii_metodo_de_aplicacao = this.trataInput.removeCaracters(this.formulario.getRawValue().ii_metodo_de_aplicacao)
    let iii_periodo_de_aplicacao = this.trataInput.removeCaracters(this.formulario.getRawValue().iii_periodo_de_aplicacao)
    let tipo_de_pedido = this.trataInput.removeCaracters(this.formulario.getRawValue().tipo_de_pedido)
    let campanha = this.trataInput.removeCaracters(this.formulario.getRawValue().campanha)
    let numero_de_produtos_no_pedido = this.trataInput.removeCaracters(this.formulario.getRawValue().numero_de_produtos_no_pedido)
    let frete = this.trataInput.removeCaracters(this.formulario.getRawValue().frete)
    let cif_valor_do_frete = this.trataInput.removeCaracters(this.formulario.getRawValue().cif_valor_do_frete)
    // let pedido_contem_defensivo_quimico = this.trataInput.removeCaracters(this.formulario.getRawValue().pedido_contem_defensivo_quimico)
    let valor_total_do_pedido = this.trataInput.removeCaracters(this.formulario.getRawValue().valor_total_do_pedido)
    let forma_de_pagamento = this.trataInput.removeCaracters(this.formulario.getRawValue().forma_de_pagamento)
    let condicao_de_parcelamento = this.trataInput.removeCaracters(this.formulario.getRawValue().condicao_de_parcelamento)
    let vencimento_condicao_de_pagamento = this.trataInput.removeCaracters(this.formulario.getRawValue().vencimento_condicao_de_pagamento)
    let consultor_tecnico_de_vendas = this.trataInput.removeCaracters(this.formulario.getRawValue().consultor_tecnico_de_vendas)
    let email_do_solicitante = this.trataInput.removeCaracters(this.formulario.getRawValue().email_do_solicitante)
    let e_mail_secund_rio = this.trataInput.removeCaracters(this.formulario.getRawValue().e_mail_secund_rio)
    let data_do_pedido = this.trataInput.ajustaData(this.trataInput.removeCaracters(this.formulario.getRawValue().data_do_pedido))
    let demanda_urgente = this.trataInput.removeCaracters(this.formulario.getRawValue().demanda_urgente)
    let realizar_faturamento_imediato_nbs = this.trataInput.removeCaracters(this.formulario.getRawValue().realizar_faturamento_imediato_nbs)
    // let descreva_a_urgencia = this.trataInput.removeCaracters(this.formulario.getRawValue().descreva_a_urgencia)
    let municipio_loja_nutrien = this.trataInput.removeCaracters(this.formulario.getRawValue().municipio_loja_nutrien)
    let estado_loja_nutrien = this.trataInput.removeCaracters(this.formulario.getRawValue().estado_loja_nutrien)
    let observacao = this.trataInput.removeCaracters(this.formulario.getRawValue().observacao)

    let codigo_do_produto_1 = this.trataInput.removeCaracters(this.formulario.getRawValue().codigo_do_produto_1)
    let descricao_produto_1 = this.trataInput.removeCaracters(this.formulario.getRawValue().descricao_produto_1)
    // let familia_produto_1 = this.trataInput.removeCaracters(this.formulario.getRawValue().familia_produto_1)
    let quantidade_produto_1 = this.trataInput.removeCaracters(this.formulario.getRawValue().quantidade_produto_1)
    let valor_unitario_produto_1 = this.trataInput.removeCaracters(this.formulario.getRawValue().valor_unitario_produto_1)
    let embalagem_produto_1 = this.trataInput.removeCaracters(this.formulario.getRawValue().embalagem_produto_1)
    let data_da_entrega_solicitada_produto_1 = this.trataInput.ajustaData(this.trataInput.removeCaracters(this.formulario.getRawValue().data_da_entrega_solicitada_produto_1))
    let planta_de_expedicao_1 = this.trataInput.removeCaracters(this.formulario.getRawValue().planta_de_expedicao_1)
    // let centro_de_expedicao_1 = this.trataInput.removeCaracters(this.formulario.getRawValue().centro_de_expedicao_1)

    let codigo_do_produto_2 = this.trataInput.removeCaracters(this.formulario.getRawValue().codigo_do_produto_2)
    let descricao_produto_2 = this.trataInput.removeCaracters(this.formulario.getRawValue().descricao_produto_2)
    // let familia_produto_2 = this.trataInput.removeCaracters(this.formulario.getRawValue().familia_produto_2)
    let quantidade_produto_2 = this.trataInput.removeCaracters(this.formulario.getRawValue().quantidade_produto_2)
    let valor_unitario_produto_2 = this.trataInput.removeCaracters(this.formulario.getRawValue().valor_unitario_produto_2)
    let embalagem_produto_2 = this.trataInput.removeCaracters(this.formulario.getRawValue().embalagem_produto_2)
    let data_da_entrega_solicitada_produto_2 = this.trataInput.ajustaData(this.trataInput.removeCaracters(this.formulario.getRawValue().data_da_entrega_solicitada_produto_2))
    let planta_de_expedicao_2 = this.trataInput.removeCaracters(this.formulario.getRawValue().planta_de_expedicao_2)
    // let centro_de_expedicao_2 = this.trataInput.removeCaracters(this.formulario.getRawValue().centro_de_expedicao_2)


    let codigo_do_produto_3 = this.trataInput.removeCaracters(this.formulario.getRawValue().codigo_do_produto_3)
    let descricao_produto_3 = this.trataInput.removeCaracters(this.formulario.getRawValue().descricao_produto_3)
    // let familia_produto_3 = this.trataInput.removeCaracters(this.formulario.getRawValue().familia_produto_3)
    let quantidade_produto_3 = this.trataInput.removeCaracters(this.formulario.getRawValue().quantidade_produto_3)
    let valor_unitario_produto_3 = this.trataInput.removeCaracters(this.formulario.getRawValue().valor_unitario_produto_3)
    let embalagem_produto_3 = this.trataInput.removeCaracters(this.formulario.getRawValue().embalagem_produto_3)
    let data_da_entrega_solicitada_produto_3 = this.trataInput.ajustaData(this.trataInput.removeCaracters(this.formulario.getRawValue().data_da_entrega_solicitada_produto_3))
    let planta_de_expedicao_3 = this.trataInput.removeCaracters(this.formulario.getRawValue().planta_de_expedicao_3)
    // let centro_de_expedicao_3 = this.trataInput.removeCaracters(this.formulario.getRawValue().centro_de_expedicao_3)


    let codigo_do_produto_4 = this.trataInput.removeCaracters(this.formulario.getRawValue().codigo_do_produto_4)
    let descricao_produto_4 = this.trataInput.removeCaracters(this.formulario.getRawValue().descricao_produto_4)
    // let familia_produto_4 = this.trataInput.removeCaracters(this.formulario.getRawValue().familia_produto_4)
    let quantidade_produto_4 = this.trataInput.removeCaracters(this.formulario.getRawValue().quantidade_produto_4)
    let valor_unitario_produto_4 = this.trataInput.removeCaracters(this.formulario.getRawValue().valor_unitario_produto_4)
    let embalagem_produto_4 = this.trataInput.removeCaracters(this.formulario.getRawValue().embalagem_produto_4)
    let data_da_entrega_solicitada_produto_4 = this.trataInput.ajustaData(this.trataInput.removeCaracters(this.formulario.getRawValue().data_da_entrega_solicitada_produto_4))
    let planta_de_expedicao_4 = this.trataInput.removeCaracters(this.formulario.getRawValue().planta_de_expedicao_4)
    // let centro_de_expedicao_4 = this.trataInput.removeCaracters(this.formulario.getRawValue().centro_de_expedicao_4)


    let codigo_do_produto_5 = this.trataInput.removeCaracters(this.formulario.getRawValue().codigo_do_produto_5)
    let descricao_produto_5 = this.trataInput.removeCaracters(this.formulario.getRawValue().descricao_produto_5)
    // let familia_produto_5 = this.trataInput.removeCaracters(this.formulario.getRawValue().familia_produto_5)
    let quantidade_produto_5 = this.trataInput.removeCaracters(this.formulario.getRawValue().quantidade_produto_5)
    let valor_unitario_produto_5 = this.trataInput.removeCaracters(this.formulario.getRawValue().valor_unitario_produto_5)
    let embalagem_produto_5 = this.trataInput.removeCaracters(this.formulario.getRawValue().embalagem_produto_5)
    let data_da_entrega_solicitada_produto_5 = this.trataInput.ajustaData(this.trataInput.removeCaracters(this.formulario.getRawValue().data_da_entrega_solicitada_produto_5))
    let planta_de_expedicao_5 = this.trataInput.removeCaracters(this.formulario.getRawValue().planta_de_expedicao_5)
    // let centro_de_expedicao_5 = this.trataInput.removeCaracters(this.formulario.getRawValue().centro_de_expedicao_5)


    let codigo_do_produto_6 = this.trataInput.removeCaracters(this.formulario.getRawValue().codigo_do_produto_6)
    let descricao_produto_6 = this.trataInput.removeCaracters(this.formulario.getRawValue().descricao_produto_6)
    // let familia_produto_6 = this.trataInput.removeCaracters(this.formulario.getRawValue().familia_produto_6)
    let quantidade_produto_6 = this.trataInput.removeCaracters(this.formulario.getRawValue().quantidade_produto_6)
    let valor_unitario_produto_6 = this.trataInput.removeCaracters(this.formulario.getRawValue().valor_unitario_produto_6)
    let embalagem_produto_6 = this.trataInput.removeCaracters(this.formulario.getRawValue().embalagem_produto_6)
    let data_da_entrega_solicitada_produto_6 = this.trataInput.ajustaData(this.trataInput.removeCaracters(this.formulario.getRawValue().data_da_entrega_solicitada_produto_6))
    let planta_de_expedicao_6 = this.trataInput.removeCaracters(this.formulario.getRawValue().planta_de_expedicao_6)
    // let centro_de_expedicao_6 = this.trataInput.removeCaracters(this.formulario.getRawValue().centro_de_expedicao_6)


    let codigo_do_produto_7 = this.trataInput.removeCaracters(this.formulario.getRawValue().codigo_do_produto_7)
    let descricao_produto_7 = this.trataInput.removeCaracters(this.formulario.getRawValue().descricao_produto_7)
    // let familia_produto_7 = this.trataInput.removeCaracters(this.formulario.getRawValue().familia_produto_7)
    let quantidade_produto_7 = this.trataInput.removeCaracters(this.formulario.getRawValue().quantidade_produto_7)
    let valor_unitario_produto_7 = this.trataInput.removeCaracters(this.formulario.getRawValue().valor_unitario_produto_7)
    let embalagem_produto_7 = this.trataInput.removeCaracters(this.formulario.getRawValue().embalagem_produto_7)
    let data_da_entrega_solicitada_produto_7 = this.trataInput.ajustaData(this.trataInput.removeCaracters(this.formulario.getRawValue().data_da_entrega_solicitada_produto_7))
    let planta_de_expedicao_7 = this.trataInput.removeCaracters(this.formulario.getRawValue().planta_de_expedicao_7)
    // let centro_de_expedicao_7 = this.trataInput.removeCaracters(this.formulario.getRawValue().centro_de_expedicao_7)


    let codigo_do_produto_8 = this.trataInput.removeCaracters(this.formulario.getRawValue().codigo_do_produto_8)
    let descricao_produto_8 = this.trataInput.removeCaracters(this.formulario.getRawValue().descricao_produto_8)
    // let familia_produto_8 = this.trataInput.removeCaracters(this.formulario.getRawValue().familia_produto_8)
    let quantidade_produto_8 = this.trataInput.removeCaracters(this.formulario.getRawValue().quantidade_produto_8)
    let valor_unitario_produto_8 = this.trataInput.removeCaracters(this.formulario.getRawValue().valor_unitario_produto_8)
    let embalagem_produto_8 = this.trataInput.removeCaracters(this.formulario.getRawValue().embalagem_produto_8)
    let data_da_entrega_solicitada_produto_8 = this.trataInput.ajustaData(this.trataInput.removeCaracters(this.formulario.getRawValue().data_da_entrega_solicitada_produto_8))
    let planta_de_expedicao_8 = this.trataInput.removeCaracters(this.formulario.getRawValue().planta_de_expedicao_8)
    // let centro_de_expedicao_8 = this.trataInput.removeCaracters(this.formulario.getRawValue().centro_de_expedicao_8)


    let codigo_do_produto_9 = this.trataInput.removeCaracters(this.formulario.getRawValue().codigo_do_produto_9)
    let descricao_produto_9 = this.trataInput.removeCaracters(this.formulario.getRawValue().descricao_produto_9)
    // let familia_produto_9 = this.trataInput.removeCaracters(this.formulario.getRawValue().familia_produto_9)
    let quantidade_produto_9 = this.trataInput.removeCaracters(this.formulario.getRawValue().quantidade_produto_9)
    let valor_unitario_produto_9 = this.trataInput.removeCaracters(this.formulario.getRawValue().valor_unitario_produto_9)
    let embalagem_produto_9 = this.trataInput.removeCaracters(this.formulario.getRawValue().embalagem_produto_9)
    let data_da_entrega_solicitada_produto_9 = this.trataInput.ajustaData(this.trataInput.removeCaracters(this.formulario.getRawValue().data_da_entrega_solicitada_produto_9))
    let planta_de_expedicao_9 = this.trataInput.removeCaracters(this.formulario.getRawValue().planta_de_expedicao_9)
    // let centro_de_expedicao_9 = this.trataInput.removeCaracters(this.formulario.getRawValue().centro_de_expedicao_9)


    let codigo_do_produto_10 = this.trataInput.removeCaracters(this.formulario.getRawValue().codigo_do_produto_10)
    let descricao_produto_10 = this.trataInput.removeCaracters(this.formulario.getRawValue().descricao_produto_10)
    // let familia_produto_10 = this.trataInput.removeCaracters(this.formulario.getRawValue().familia_produto_10)
    let quantidade_produto_10 = this.trataInput.removeCaracters(this.formulario.getRawValue().quantidade_produto_10)
    let valor_unitario_produto_10 = this.trataInput.removeCaracters(this.formulario.getRawValue().valor_unitario_produto_10)
    let embalagem_produto_10 = this.trataInput.removeCaracters(this.formulario.getRawValue().embalagem_produto_10)
    let data_da_entrega_solicitada_produto_10 = this.trataInput.ajustaData(this.trataInput.removeCaracters(this.formulario.getRawValue().data_da_entrega_solicitada_produto_10))
    let planta_de_expedicao_10 = this.trataInput.removeCaracters(this.formulario.getRawValue().planta_de_expedicao_10)
    // let centro_de_expedicao_10 = this.trataInput.removeCaracters(this.formulario.getRawValue().centro_de_expedicao_10)


    let codigo_do_produto_11 = this.trataInput.removeCaracters(this.formulario.getRawValue().codigo_do_produto_11)
    let descricao_produto_11 = this.trataInput.removeCaracters(this.formulario.getRawValue().descricao_produto_11)
    // let familia_produto_11 = this.trataInput.removeCaracters(this.formulario.getRawValue().familia_produto_11)
    let quantidade_produto_11 = this.trataInput.removeCaracters(this.formulario.getRawValue().quantidade_produto_11)
    let valor_unitario_produto_11 = this.trataInput.removeCaracters(this.formulario.getRawValue().valor_unitario_produto_11)
    let embalagem_produto_11 = this.trataInput.removeCaracters(this.formulario.getRawValue().embalagem_produto_11)
    let data_da_entrega_solicitada_produto_11 = this.trataInput.ajustaData(this.trataInput.removeCaracters(this.formulario.getRawValue().data_da_entrega_solicitada_produto_11))
    let planta_de_expedicao_11 = this.trataInput.removeCaracters(this.formulario.getRawValue().planta_de_expedicao_11)
    // let centro_de_expedicao_11 = this.trataInput.removeCaracters(this.formulario.getRawValue().centro_de_expedicao_11)


    let codigo_do_produto_12 = this.trataInput.removeCaracters(this.formulario.getRawValue().codigo_do_produto_12)
    let descricao_produto_12 = this.trataInput.removeCaracters(this.formulario.getRawValue().descricao_produto_12)
    // let familia_produto_12 = this.trataInput.removeCaracters(this.formulario.getRawValue().familia_produto_12)
    let quantidade_produto_12 = this.trataInput.removeCaracters(this.formulario.getRawValue().quantidade_produto_12)
    let valor_unitario_produto_12 = this.trataInput.removeCaracters(this.formulario.getRawValue().valor_unitario_produto_12)
    let embalagem_produto_12 = this.trataInput.removeCaracters(this.formulario.getRawValue().embalagem_produto_12)
    let data_da_entrega_solicitada_produto_12 = this.trataInput.ajustaData(this.trataInput.removeCaracters(this.formulario.getRawValue().data_da_entrega_solicitada_produto_12))
    let planta_de_expedicao_12 = this.trataInput.removeCaracters(this.formulario.getRawValue().planta_de_expedicao_12)
    // let centro_de_expedicao_12 = this.trataInput.removeCaracters(this.formulario.getRawValue().centro_de_expedicao_12)


    let codigo_do_produto_13 = this.trataInput.removeCaracters(this.formulario.getRawValue().codigo_do_produto_13)
    let descricao_produto_13 = this.trataInput.removeCaracters(this.formulario.getRawValue().descricao_produto_13)
    // let familia_produto_13 = this.trataInput.removeCaracters(this.formulario.getRawValue().familia_produto_13)
    let quantidade_produto_13 = this.trataInput.removeCaracters(this.formulario.getRawValue().quantidade_produto_13)
    let valor_unitario_produto_13 = this.trataInput.removeCaracters(this.formulario.getRawValue().valor_unitario_produto_13)
    let embalagem_produto_13 = this.trataInput.removeCaracters(this.formulario.getRawValue().embalagem_produto_13)
    let data_da_entrega_solicitada_produto_13 = this.trataInput.ajustaData(this.trataInput.removeCaracters(this.formulario.getRawValue().data_da_entrega_solicitada_produto_13))
    let planta_de_expedicao_13 = this.trataInput.removeCaracters(this.formulario.getRawValue().planta_de_expedicao_13)
    // let centro_de_expedicao_13 = this.trataInput.removeCaracters(this.formulario.getRawValue().centro_de_expedicao_13)


    let codigo_do_produto_14 = this.trataInput.removeCaracters(this.formulario.getRawValue().codigo_do_produto_14)
    let descricao_produto_14 = this.trataInput.removeCaracters(this.formulario.getRawValue().descricao_produto_14)
    // let familia_produto_14 = this.trataInput.removeCaracters(this.formulario.getRawValue().familia_produto_14)
    let quantidade_produto_14 = this.trataInput.removeCaracters(this.formulario.getRawValue().quantidade_produto_14)
    let valor_unitario_produto_14 = this.trataInput.removeCaracters(this.formulario.getRawValue().valor_unitario_produto_14)
    let embalagem_produto_14 = this.trataInput.removeCaracters(this.formulario.getRawValue().embalagem_produto_14)
    let data_da_entrega_solicitada_produto_14 = this.trataInput.ajustaData(this.trataInput.removeCaracters(this.formulario.getRawValue().data_da_entrega_solicitada_produto_14))
    let planta_de_expedicao_14 = this.trataInput.removeCaracters(this.formulario.getRawValue().planta_de_expedicao_14)
    // let centro_de_expedicao_14 = this.trataInput.removeCaracters(this.formulario.getRawValue().centro_de_expedicao_14)


    let codigo_do_produto_15 = this.trataInput.removeCaracters(this.formulario.getRawValue().codigo_do_produto_15)
    let descricao_produto_15 = this.trataInput.removeCaracters(this.formulario.getRawValue().descricao_produto_15)
    // let familia_produto_15 = this.trataInput.removeCaracters(this.formulario.getRawValue().familia_produto_15)
    let quantidade_produto_15 = this.trataInput.removeCaracters(this.formulario.getRawValue().quantidade_produto_15)
    let valor_unitario_produto_15 = this.trataInput.removeCaracters(this.formulario.getRawValue().valor_unitario_produto_15)
    let embalagem_produto_15 = this.trataInput.removeCaracters(this.formulario.getRawValue().embalagem_produto_15)
    let data_da_entrega_solicitada_produto_15 = this.trataInput.ajustaData(this.trataInput.removeCaracters(this.formulario.getRawValue().data_da_entrega_solicitada_produto_15))
    let planta_de_expedicao_15 = this.trataInput.removeCaracters(this.formulario.getRawValue().planta_de_expedicao_15)
    // let centro_de_expedicao_15 = this.trataInput.removeCaracters(this.formulario.getRawValue().centro_de_expedicao_15)


    let codigo_do_produto_16 = this.trataInput.removeCaracters(this.formulario.getRawValue().codigo_do_produto_16)
    let descricao_produto_16 = this.trataInput.removeCaracters(this.formulario.getRawValue().descricao_produto_16)
    // let familia_produto_16 = this.trataInput.removeCaracters(this.formulario.getRawValue().familia_produto_16)
    let quantidade_produto_16 = this.trataInput.removeCaracters(this.formulario.getRawValue().quantidade_produto_16)
    let valor_unitario_produto_16 = this.trataInput.removeCaracters(this.formulario.getRawValue().valor_unitario_produto_16)
    let embalagem_produto_16 = this.trataInput.removeCaracters(this.formulario.getRawValue().embalagem_produto_16)
    let data_da_entrega_solicitada_produto_16 = this.trataInput.ajustaData(this.trataInput.removeCaracters(this.formulario.getRawValue().data_da_entrega_solicitada_produto_16))
    let planta_de_expedicao_16 = this.trataInput.removeCaracters(this.formulario.getRawValue().planta_de_expedicao_16)
    // let centro_de_expedicao_16 = this.trataInput.removeCaracters(this.formulario.getRawValue().centro_de_expedicao_16)


    let codigo_do_produto_17 = this.trataInput.removeCaracters(this.formulario.getRawValue().codigo_do_produto_17)
    let descricao_produto_17 = this.trataInput.removeCaracters(this.formulario.getRawValue().descricao_produto_17)
    // let familia_produto_17 = this.trataInput.removeCaracters(this.formulario.getRawValue().familia_produto_17)
    let quantidade_produto_17 = this.trataInput.removeCaracters(this.formulario.getRawValue().quantidade_produto_17)
    let valor_unitario_produto_17 = this.trataInput.removeCaracters(this.formulario.getRawValue().valor_unitario_produto_17)
    let embalagem_produto_17 = this.trataInput.removeCaracters(this.formulario.getRawValue().embalagem_produto_17)
    let data_da_entrega_solicitada_produto_17 = this.trataInput.ajustaData(this.trataInput.removeCaracters(this.formulario.getRawValue().data_da_entrega_solicitada_produto_17))
    let planta_de_expedicao_17 = this.trataInput.removeCaracters(this.formulario.getRawValue().planta_de_expedicao_17)
    // let centro_de_expedicao_17 = this.trataInput.removeCaracters(this.formulario.getRawValue().centro_de_expedicao_17)


    let codigo_do_produto_18 = this.trataInput.removeCaracters(this.formulario.getRawValue().codigo_do_produto_18)
    let descricao_produto_18 = this.trataInput.removeCaracters(this.formulario.getRawValue().descricao_produto_18)
    // let familia_produto_18 = this.trataInput.removeCaracters(this.formulario.getRawValue().familia_produto_18)
    let quantidade_produto_18 = this.trataInput.removeCaracters(this.formulario.getRawValue().quantidade_produto_18)
    let valor_unitario_produto_18 = this.trataInput.removeCaracters(this.formulario.getRawValue().valor_unitario_produto_18)
    let embalagem_produto_18 = this.trataInput.removeCaracters(this.formulario.getRawValue().embalagem_produto_18)
    let data_da_entrega_solicitada_produto_18 = this.trataInput.ajustaData(this.trataInput.removeCaracters(this.formulario.getRawValue().data_da_entrega_solicitada_produto_18))
    let planta_de_expedicao_18 = this.trataInput.removeCaracters(this.formulario.getRawValue().planta_de_expedicao_18)
    // let centro_de_expedicao_18 = this.trataInput.removeCaracters(this.formulario.getRawValue().centro_de_expedicao_18)


    let codigo_do_produto_19 = this.trataInput.removeCaracters(this.formulario.getRawValue().codigo_do_produto_19)
    let descricao_produto_19 = this.trataInput.removeCaracters(this.formulario.getRawValue().descricao_produto_19)
    // let familia_produto_19 = this.trataInput.removeCaracters(this.formulario.getRawValue().familia_produto_19)
    let quantidade_produto_19 = this.trataInput.removeCaracters(this.formulario.getRawValue().quantidade_produto_19)
    let valor_unitario_produto_19 = this.trataInput.removeCaracters(this.formulario.getRawValue().valor_unitario_produto_19)
    let embalagem_produto_19 = this.trataInput.removeCaracters(this.formulario.getRawValue().embalagem_produto_19)
    let data_da_entrega_solicitada_produto_19 = this.trataInput.ajustaData(this.trataInput.removeCaracters(this.formulario.getRawValue().data_da_entrega_solicitada_produto_19))
    let planta_de_expedicao_19 = this.trataInput.removeCaracters(this.formulario.getRawValue().planta_de_expedicao_19)
    // let centro_de_expedicao_19 = this.trataInput.removeCaracters(this.formulario.getRawValue().centro_de_expedicao_19)


    let codigo_do_produto_20 = this.trataInput.removeCaracters(this.formulario.getRawValue().codigo_do_produto_20)
    let descricao_produto_20 = this.trataInput.removeCaracters(this.formulario.getRawValue().descricao_produto_20)
    // let familia_produto_20 = this.trataInput.removeCaracters(this.formulario.getRawValue().familia_produto_20)
    let quantidade_produto_20 = this.trataInput.removeCaracters(this.formulario.getRawValue().quantidade_produto_20)
    let valor_unitario_produto_20 = this.trataInput.removeCaracters(this.formulario.getRawValue().valor_unitario_produto_20)
    let embalagem_produto_20 = this.trataInput.removeCaracters(this.formulario.getRawValue().embalagem_produto_20)
    let data_da_entrega_solicitada_produto_20 = this.trataInput.ajustaData(this.trataInput.removeCaracters(this.formulario.getRawValue().data_da_entrega_solicitada_produto_20))
    let planta_de_expedicao_20 = this.trataInput.removeCaracters(this.formulario.getRawValue().planta_de_expedicao_20)
    // let centro_de_expedicao_20 = this.trataInput.removeCaracters(this.formulario.getRawValue().centro_de_expedicao_20)




    let pipe_fields = pipe_fields_id
    let fields_values = [
      empresa,
      categoria,
      tipo_de_venda,
      tipo_de_cliente,
      razao_social,
      cnpj,
      nome_cliente,
      cpf,
      inscricao_estadual,
      nome_da_propriedade,
      cliente_novo,
      cultura,
      necessario_receituario_agronomico,
      i_praga,
      ii_metodo_de_aplicacao,
      iii_periodo_de_aplicacao,
      tipo_de_pedido,
      campanha,
      numero_de_produtos_no_pedido,
      codigo_do_produto_1,
      descricao_produto_1,
      // familia_produto_1,
      quantidade_produto_1,
      valor_unitario_produto_1,
      embalagem_produto_1,
      data_da_entrega_solicitada_produto_1,
      planta_de_expedicao_1,
      // centro_de_expedicao_1,
      codigo_do_produto_2,
      descricao_produto_2,
      // familia_produto_2,
      quantidade_produto_2,
      valor_unitario_produto_2,
      embalagem_produto_2,
      data_da_entrega_solicitada_produto_2,
      planta_de_expedicao_2,
      // centro_de_expedicao_2,
      codigo_do_produto_3,
      descricao_produto_3,
      // familia_produto_3,
      quantidade_produto_3,
      valor_unitario_produto_3,
      embalagem_produto_3,
      data_da_entrega_solicitada_produto_3,
      planta_de_expedicao_3,
      // centro_de_expedicao_3,
      codigo_do_produto_4,
      descricao_produto_4,
      // familia_produto_4,
      quantidade_produto_4,
      valor_unitario_produto_4,
      embalagem_produto_4,
      data_da_entrega_solicitada_produto_4,
      planta_de_expedicao_4,
      // centro_de_expedicao_4,
      codigo_do_produto_5,
      descricao_produto_5,
      // familia_produto_5,
      quantidade_produto_5,
      valor_unitario_produto_5,
      embalagem_produto_5,
      data_da_entrega_solicitada_produto_5,
      planta_de_expedicao_5,
      // centro_de_expedicao_5,
      codigo_do_produto_6,
      descricao_produto_6,
      // familia_produto_6,
      quantidade_produto_6,
      valor_unitario_produto_6,
      embalagem_produto_6,
      data_da_entrega_solicitada_produto_6,
      planta_de_expedicao_6,
      // centro_de_expedicao_6,
      codigo_do_produto_7,
      descricao_produto_7,
      // familia_produto_7,
      quantidade_produto_7,
      valor_unitario_produto_7,
      embalagem_produto_7,
      data_da_entrega_solicitada_produto_7,
      planta_de_expedicao_7,
      // centro_de_expedicao_7,
      codigo_do_produto_8,
      descricao_produto_8,
      // familia_produto_8,
      quantidade_produto_8,
      valor_unitario_produto_8,
      embalagem_produto_8,
      data_da_entrega_solicitada_produto_8,
      planta_de_expedicao_8,
      // centro_de_expedicao_8,
      codigo_do_produto_9,
      descricao_produto_9,
      // familia_produto_9,
      quantidade_produto_9,
      valor_unitario_produto_9,
      embalagem_produto_9,
      data_da_entrega_solicitada_produto_9,
      planta_de_expedicao_9,
      // centro_de_expedicao_9,
      codigo_do_produto_10,
      descricao_produto_10,
      // familia_produto_10,
      quantidade_produto_10,
      valor_unitario_produto_10,
      embalagem_produto_10,
      data_da_entrega_solicitada_produto_10,
      planta_de_expedicao_10,
      // centro_de_expedicao_10,
      codigo_do_produto_11,
      descricao_produto_11,
      // familia_produto_11,
      quantidade_produto_11,
      valor_unitario_produto_11,
      embalagem_produto_11,
      data_da_entrega_solicitada_produto_11,
      planta_de_expedicao_11,
      // centro_de_expedicao_11,
      codigo_do_produto_12,
      descricao_produto_12,
      // familia_produto_12,
      quantidade_produto_12,
      valor_unitario_produto_12,
      embalagem_produto_12,
      data_da_entrega_solicitada_produto_12,
      planta_de_expedicao_12,
      // centro_de_expedicao_12,
      codigo_do_produto_13,
      descricao_produto_13,
      // familia_produto_13,
      quantidade_produto_13,
      valor_unitario_produto_13,
      embalagem_produto_13,
      data_da_entrega_solicitada_produto_13,
      planta_de_expedicao_13,
      // centro_de_expedicao_13,
      codigo_do_produto_14,
      descricao_produto_14,
      // familia_produto_14,
      quantidade_produto_14,
      valor_unitario_produto_14,
      embalagem_produto_14,
      data_da_entrega_solicitada_produto_14,
      planta_de_expedicao_14,
      // centro_de_expedicao_14,
      codigo_do_produto_15,
      descricao_produto_15,
      // familia_produto_15,
      quantidade_produto_15,
      valor_unitario_produto_15,
      embalagem_produto_15,
      data_da_entrega_solicitada_produto_15,
      planta_de_expedicao_15,
      // centro_de_expedicao_15,
      codigo_do_produto_16,
      descricao_produto_16,
      // familia_produto_16,
      quantidade_produto_16,
      valor_unitario_produto_16,
      embalagem_produto_16,
      data_da_entrega_solicitada_produto_16,
      planta_de_expedicao_16,
      // centro_de_expedicao_16,
      codigo_do_produto_17,
      descricao_produto_17,
      // familia_produto_17,
      quantidade_produto_17,
      valor_unitario_produto_17,
      embalagem_produto_17,
      data_da_entrega_solicitada_produto_17,
      planta_de_expedicao_17,
      // centro_de_expedicao_17,
      codigo_do_produto_18,
      descricao_produto_18,
      // familia_produto_18,
      quantidade_produto_18,
      valor_unitario_produto_18,
      embalagem_produto_18,
      data_da_entrega_solicitada_produto_18,
      planta_de_expedicao_18,
      // centro_de_expedicao_18,
      codigo_do_produto_19,
      descricao_produto_19,
      // familia_produto_19,
      quantidade_produto_19,
      valor_unitario_produto_19,
      embalagem_produto_19,
      data_da_entrega_solicitada_produto_19,
      planta_de_expedicao_19,
      // centro_de_expedicao_19,
      codigo_do_produto_20,
      descricao_produto_20,
      // familia_produto_20,
      quantidade_produto_20,
      valor_unitario_produto_20,
      embalagem_produto_20,
      data_da_entrega_solicitada_produto_20,
      planta_de_expedicao_20,
      // centro_de_expedicao_20,
      frete,
      cif_valor_do_frete,
      // pedido_contem_defensivo_quimico,
      valor_total_do_pedido,
      forma_de_pagamento,
      condicao_de_parcelamento,
      vencimento_condicao_de_pagamento,
      consultor_tecnico_de_vendas,
      email_do_solicitante,
      e_mail_secund_rio,
      data_do_pedido,
      demanda_urgente,
      realizar_faturamento_imediato_nbs,
      // descreva_a_urgencia,
      municipio_loja_nutrien,
      estado_loja_nutrien,
      observacao,
      anexosUrl2
    ]
    let pipe_id = '302443657'


    this.pipefy.createCard(this.pipefy.criaBody(pipe_fields, fields_values, pipe_id)).subscribe((res) => {
      // console.log(res)
      this.spinner.hide()

      if (res.data.result_creation_card.id != undefined) {
        let card_id = res.data.result_creation_card.id
        Swal.fire({
          title: 'Chamado aberto com Sucesso!',
          text: 'O chamado ' + card_id + ' foi aberto com sucesso. Nossa equipe irá tratar e retornar em breve. Obrigado!',
          icon: 'success',
          confirmButtonText: 'Ok',
          confirmButtonColor: '#88D600',
        })
        this.resetForm()
      }

      if (res.data.result_creation_card.errors != undefined) {
        var erro = res.data.result_creation_card.errors[0].message
        Swal.fire({
          title: 'Desculpe!',
          html: '<p>Ocorreu um erro.</p>' + '<a href="https://app.pipefy.com/public/form/oC7ZC0u4" target="_blank">Clique aqui </a>' + '<span>para fazer sua solicitação</span></br>' + erro,
          text: erro,
          icon: 'error',
          confirmButtonColor: '#88D600',
        })
        this.anexosUrl = []


        let pipe_fields_incidentes_values = ['Incidente', 'Correção', 'Portal NBS', 'F&A', 'Lojas Centros de Experiência', "Lojas Centros de Experiência - criação de card -- Venda e Faturamento Pedido", erro, 'Portal NBS Error', 'incidente@portal.com',
          'Sim', 'Venda e Faturamento - Pedido', 'criação de card via portal', 'Página web com problemas',this.emailApi]

        var pipe_fields_incidentes_values2 = []
        for (var i = 0; i < pipe_fields_incidentes_values.length; i++) {
          pipe_fields_incidentes_values2.push(this.trataInput.removeCaractersPipeIncidentes(pipe_fields_incidentes_values[i]))
        }
        // console.log(pipe_fields_incidentes_values2)

        let pipe_incidentes_id = '302487533'

        this.pipefy.createCard(this.pipefy.criaBody(pipe_fields_incidentes, pipe_fields_incidentes_values2, pipe_incidentes_id)).subscribe((res) => {
          // console.log(res)
        })
      }
    },(error: any) => {
      this.spinner.hide()
      this.error_counter = this.error_counter + 1
      if (this.error_counter < 2) {
        if (error.status == 401) {
          // console.log("entrei em error.status")
          Swal.fire({
            title: 'Ops, sua sessão expirou!',
            text: 'Por favor, saia e logue novamente.',
            icon: 'error',
            confirmButtonText: 'Sair',
            confirmButtonColor: '#ff6961',
            allowOutsideClick: false,
            allowEscapeKey: false
          })
            .then(() => {
              this.msalService.logoutRedirect()
            }
            )
        } else {
          Swal.fire({
            title: 'Desculpe!',
            text: 'Ocorreu algum erro durante a sua solicitação. Por favor, tente novamente.',
            icon: 'error',
            confirmButtonText: 'Ok',
            confirmButtonColor: '#88D600',
            allowOutsideClick: false,
            allowEscapeKey: false
          })
        }
      }
      else {
        this.spinner.hide()
        Swal.fire({
          title: 'Desculpe!',
          html: '<p>Ocorreu um erro.</p>' + '<a href="https://app.pipefy.com/public/form/oC7ZC0u4" target="_blank">Clique aqui </a>' + '<span>para fazer sua solicitação</span>',
          icon: 'error',
          confirmButtonColor: '#88D600',
        })
        this.anexosUrl = []

      }
    })
  }
criarBody()
}

  delay(time: any) {
    return new Promise(resolve => setTimeout(resolve, time));
  }

  save(event: any): void {
    var files = event.target.files;
    for (var i = 0; i < files.length; i++) {
      this.anexos.push(files[i])
    }
  }

  async getPipesUrl(files: any) {
    this.erro = false;

  // Função utilitária para ler o arquivo usando FileReader como uma Promise
  const readFileAsBase64 = (file: File) => {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const result = reader.result?.toString();
        if (result) {
          const fileContentStart = result.indexOf(",");
          resolve(result.substring(fileContentStart + 1)); // Retorna o conteúdo Base64
        } else {
          reject(new Error("Falha ao ler o arquivo"));
        }
      };
      reader.onerror = (error) => {
        reject(error);
      };
    });
  };

  // Loop sobre os arquivos, esperando cada leitura e operação assíncrona
  for (let i = 0; i < files.length; i++) {
    const fileContent = files[i];
    const fileName = files[i].name;

    try {
      const fileBase64 = await readFileAsBase64(fileContent); // Aguarda a leitura do arquivo
      const urlResposta = await this.pipefy.getFileUrl3(fileBase64, fileName); // Obtém a URL após o upload do arquivo

      var fileUrlTemp = "";
        var startIndex = 0;

        if (urlResposta.indexOf("orgs") > 0) {
          startIndex = urlResposta.indexOf("orgs/");
        } else {
          startIndex = urlResposta.indexOf("uploads/");
        }

        var endIndex = urlResposta.indexOf("?");
        fileUrlTemp = urlResposta.substring(startIndex, endIndex);

        this.anexosUrl.push(fileUrlTemp)

      //this.invociesUrl.push(url); // Adiciona a URL à lista

    } catch (error: any) {
      this.erro = true;
      this.spinner.hide();
      this.error_counter = this.error_counter + 1;
      if (this.error_counter < 2) {
        if (error.status === 401) {
          Swal.fire({
            title: 'Ops, sua sessão expirou!',
            text: 'Por favor, saia e logue novamente.',
            icon: 'error',
            confirmButtonText: 'Sair',
            confirmButtonColor: '#ff6961',
            allowOutsideClick: false,
            allowEscapeKey: false
          }).then(() => {
            this.msalService.logoutRedirect();
          });
        } else {
          Swal.fire({
            title: 'Desculpe!',
            text: 'Ocorreu algum erro durante a sua solicitação. Por favor, tente novamente.',
            icon: 'error',
            confirmButtonText: 'Ok',
            confirmButtonColor: '#88D600',
            allowOutsideClick: false,
            allowEscapeKey: false
          });
        }
      } else {
        this.spinner.hide();
        Swal.fire({
          title: 'Desculpe!',
          html: '<p>Ocorreu um erro.</p>' + '<a href="https://app.pipefy.com/public/form/3Sd_ATrN" target="_blank">Clique aqui </a>' + '<span>para fazer sua solicitação</span>',
          icon: 'error',
          confirmButtonColor: '#88D600',
        });
        this.anexosUrl = [];
      }
    }
    }
    return new Promise<void>((resolve) => { resolve(); });
  }

  removeFile(file: File) {
    const index: number = this.anexos.indexOf(file);
    this.anexos.splice(index, 1)
    Swal.fire({
      position: 'top-end',
      icon: 'success',
      title: 'Arquivo Removido com Sucesso.',
      showConfirmButton: false,
      timer: 1500
    })
    if(this.anexos.length == 0){
      this.formulario.controls['anexo'].markAsTouched()
      this.formulario.get('anexo')?.setValue('')
    }
  }

  ajustaTipodoNumeroDeProdutosDoPedido(event: any) {
    var quantidade_string = event.target.value
    // console.log(quantidade_string)
    // console.log(typeof (quantidade_string))

    this.quantidade_de_produtos_int = parseInt(quantidade_string)
    // console.log("novo valor:", this.quantidade_de_produtos_int)
    // console.log("novo tipo:", typeof (this.quantidade_de_produtos_int))
  }



  onDocumentoChange(event: any) {
    var tipo_de_pessoa = event.target.value
    // console.log(tipo_de_pessoa)
    if (tipo_de_pessoa === 'Pessoa Física') {
      // console.log("cpf checked")
      this.formulario.get('cpf')?.setValidators([Validators.required])
      this.formulario.get('cpf')?.updateValueAndValidity();
      this.formulario.get('nome_cliente')?.setValidators([Validators.required])
      this.formulario.get('nome_cliente')?.updateValueAndValidity();

      this.formulario.get('cnpj')?.clearValidators()
      this.formulario.get('cnpj')?.updateValueAndValidity();
      this.formulario.get('razao_social')?.clearValidators()
      this.formulario.get('razao_social')?.updateValueAndValidity();
    }
    if (tipo_de_pessoa === 'Pessoa Jurídica'){
      // console.log("cnpj checked")
      this.formulario.get('cnpj')?.setValidators([Validators.required])
      this.formulario.get('cnpj')?.updateValueAndValidity();
      this.formulario.get('razao_social')?.setValidators([Validators.required])
      this.formulario.get('razao_social')?.updateValueAndValidity();

      this.formulario.get('cpf')?.clearValidators()
      this.formulario.get('cpf')?.updateValueAndValidity();
      this.formulario.get('nome_cliente')?.clearValidators()
      this.formulario.get('nome_cliente')?.updateValueAndValidity();

    }
  }



  onFreteChange(event: any) {
    var frete = event.target.value

    if (frete === "CIF") {
      this.formulario.get('cif_valor_do_frete')?.setValidators([Validators.required])
      this.formulario.get('cif_valor_do_frete')?.updateValueAndValidity();
    }

    if (frete !== 'CIF') {
      this.formulario.get('cif_valor_do_frete')?.clearValidators();
      this.formulario.get('cif_valor_do_frete')?.updateValueAndValidity();
    }
  }

  // onDemandaUrgenteChange(event: any) {
  //   var demanda_urgente = event.target.value

  //   if (demanda_urgente === "Sim") {
  //     this.formulario.get('descreva_a_urgencia')?.setValidators([Validators.required])
  //     this.formulario.get('descreva_a_urgencia')?.updateValueAndValidity();
  //   }

  //   if (demanda_urgente === 'Não') {
  //     this.formulario.get('descreva_a_urgencia')?.clearValidators();
  //     this.formulario.get('descreva_a_urgencia')?.updateValueAndValidity();
  //   }
  // }


  onTipoChange() {
    let quantidadeDeItens = this.formulario.getRawValue().numero_de_produtos_no_pedido
    quantidadeDeItens = quantidadeDeItens ? parseInt(quantidadeDeItens) : ''
    this.clearValidatorsDetalhes()
    this.limpaValoresProdutos(quantidadeDeItens);

    if (quantidadeDeItens == '') {
      this.exibeListaProdutos = false;
      return
    } else {
      if (quantidadeDeItens >= 1) {
        if ((this.formulario.getRawValue().empresa === 'Nutrien' && this.formulario.getRawValue().categoria !== '') || (this.formulario.getRawValue().empresa !== 'Nutrien' && this.formulario.getRawValue().empresa !== '' && this.formulario.getRawValue().categoria === 'Fertilizantes')) {
          this.exibeListaProdutos = true;
          this.formulario.get('codigo_do_produto_1')?.setValidators([Validators.required])
          this.formulario.get('planta_de_expedicao_1')?.setValidators([Validators.required])
        } else {
          this.exibeListaProdutos = false;
          this.formulario.get('codigo_do_produto_1')?.clearValidators()
          this.formulario.get('planta_de_expedicao_1')?.clearValidators()
        }
        this.formulario.get('descricao_do_produto_1')?.setValidators([Validators.required])
        // this.formulario.get('familia_produto_1')?.setValidators([Validators.required])
        this.formulario.get('quantidade_produto_1')?.setValidators([Validators.required])
        this.formulario.get('valor_unitario_produto_1')?.setValidators([Validators.required])
        this.formulario.get('embalagem_produto_1')?.setValidators([Validators.required])
        this.formulario.get('data_da_entrega_solicitada_produto_1')?.setValidators([Validators.required])
        // this.formulario.get('centro_de_expedicao_1')?.setValidators([Validators.required])

        this.formulario.get('codigo_do_produto_1')?.updateValueAndValidity();
        this.formulario.get('descricao_produto_1')?.updateValueAndValidity();
        this.formulario.get('codigo_do_produto_obrig_1')?.updateValueAndValidity();
        this.formulario.get('descricao_produto_obrig_1')?.updateValueAndValidity();
        // this.formulario.get('familia_produto_1')?.updateValueAndValidity();
        this.formulario.get('quantidade_produto_1')?.updateValueAndValidity();
        this.formulario.get('valor_unitario_produto_1')?.updateValueAndValidity();
        this.formulario.get('embalagem_produto_1')?.updateValueAndValidity();
        this.formulario.get('data_da_entrega_solicitada_produto_1')?.updateValueAndValidity();
        this.formulario.get('planta_de_expedicao_1')?.updateValueAndValidity()
        // this.formulario.get('centro_de_expedicao_1')?.updateValueAndValidity()
      }

      if (quantidadeDeItens >= 2) {
        if ((this.formulario.getRawValue().empresa === 'Nutrien' && this.formulario.getRawValue().categoria !== '') || (this.formulario.getRawValue().empresa !== 'Nutrien' && this.formulario.getRawValue().empresa !== '' && this.formulario.getRawValue().categoria === 'Fertilizantes')) {
          this.exibeListaProdutos = true;
          this.formulario.get('codigo_do_produto_2')?.setValidators([Validators.required])
          this.formulario.get('planta_de_expedicao_2')?.setValidators([Validators.required])
        } else {
          this.exibeListaProdutos = false;
        }
        this.formulario.get('descricao_produto_2')?.setValidators([Validators.required])
        // this.formulario.get('familia_produto_2')?.setValidators([Validators.required])
        this.formulario.get('quantidade_produto_2')?.setValidators([Validators.required])
        this.formulario.get('valor_unitario_produto_2')?.setValidators([Validators.required])
        this.formulario.get('embalagem_produto_2')?.setValidators([Validators.required])
        this.formulario.get('data_da_entrega_solicitada_produto_2')?.setValidators([Validators.required])
        // this.formulario.get('centro_de_expedicao_2')?.setValidators([Validators.required])


        this.formulario.get('codigo_do_produto_2')?.updateValueAndValidity();
        this.formulario.get('descricao_produto_2')?.updateValueAndValidity();
        // this.formulario.get('familia_produto_2')?.updateValueAndValidity();
        this.formulario.get('quantidade_produto_2')?.updateValueAndValidity();
        this.formulario.get('valor_unitario_produto_2')?.updateValueAndValidity();
        this.formulario.get('embalagem_produto_2')?.updateValueAndValidity();
        this.formulario.get('data_da_entrega_solicitada_produto_2')?.updateValueAndValidity();
        this.formulario.get('planta_de_expedicao_2')?.updateValueAndValidity()
        // this.formulario.get('centro_de_expedicao_2')?.updateValueAndValidity()

      }

      if (quantidadeDeItens >= 3) {
        if ((this.formulario.getRawValue().empresa === 'Nutrien' && this.formulario.getRawValue().categoria !== '') || (this.formulario.getRawValue().empresa !== 'Nutrien' && this.formulario.getRawValue().empresa !== '' && this.formulario.getRawValue().categoria === 'Fertilizantes')) {
          this.exibeListaProdutos = true;
          this.formulario.get('codigo_do_produto_3')?.setValidators([Validators.required])
          this.formulario.get('planta_de_expedicao_3')?.setValidators([Validators.required])
        } else {
          this.exibeListaProdutos = false;
        }
        this.formulario.get('descricao_produto_3')?.setValidators([Validators.required])
        // this.formulario.get('familia_produto_3')?.setValidators([Validators.required])
        this.formulario.get('quantidade_produto_3')?.setValidators([Validators.required])
        this.formulario.get('valor_unitario_produto_3')?.setValidators([Validators.required])
        this.formulario.get('embalagem_produto_3')?.setValidators([Validators.required])
        this.formulario.get('data_da_entrega_solicitada_produto_3')?.setValidators([Validators.required])
        // this.formulario.get('centro_de_expedicao_3')?.setValidators([Validators.required])


        this.formulario.get('codigo_do_produto_3')?.updateValueAndValidity();
        this.formulario.get('descricao_produto_3')?.updateValueAndValidity();
        // this.formulario.get('familia_produto_3')?.updateValueAndValidity();
        this.formulario.get('quantidade_produto_3')?.updateValueAndValidity();
        this.formulario.get('valor_unitario_produto_3')?.updateValueAndValidity();
        this.formulario.get('embalagem_produto_3')?.updateValueAndValidity();
        this.formulario.get('data_da_entrega_solicitada_produto_3')?.updateValueAndValidity();
        this.formulario.get('planta_de_expedicao_3')?.updateValueAndValidity()
        // this.formulario.get('centro_de_expedicao_3')?.updateValueAndValidity()

      }

      if (quantidadeDeItens >= 4) {
        if ((this.formulario.getRawValue().empresa === 'Nutrien' && this.formulario.getRawValue().categoria !== '') || (this.formulario.getRawValue().empresa !== 'Nutrien' && this.formulario.getRawValue().empresa !== '' && this.formulario.getRawValue().categoria === 'Fertilizantes')) {
          this.exibeListaProdutos = true;
          this.formulario.get('codigo_do_produto_4')?.setValidators([Validators.required])
          this.formulario.get('planta_de_expedicao_4')?.setValidators([Validators.required])
        } else {
          this.exibeListaProdutos = false;
        }
        this.formulario.get('descricao_produto_4')?.setValidators([Validators.required])
        // this.formulario.get('familia_produto_4')?.setValidators([Validators.required])
        this.formulario.get('quantidade_produto_4')?.setValidators([Validators.required])
        this.formulario.get('valor_unitario_produto_4')?.setValidators([Validators.required])
        this.formulario.get('embalagem_produto_4')?.setValidators([Validators.required])
        this.formulario.get('data_da_entrega_solicitada_produto_4')?.setValidators([Validators.required])
        // this.formulario.get('centro_de_expedicao_4')?.setValidators([Validators.required])


        this.formulario.get('codigo_do_produto_4')?.updateValueAndValidity();
        this.formulario.get('descricao_produto_4')?.updateValueAndValidity();
        // this.formulario.get('familia_produto_4')?.updateValueAndValidity();
        this.formulario.get('quantidade_produto_4')?.updateValueAndValidity();
        this.formulario.get('valor_unitario_produto_4')?.updateValueAndValidity();
        this.formulario.get('embalagem_produto_4')?.updateValueAndValidity();
        this.formulario.get('data_da_entrega_solicitada_produto_4')?.updateValueAndValidity();
        this.formulario.get('planta_de_expedicao_4')?.updateValueAndValidity()
        // this.formulario.get('centro_de_expedicao_4')?.updateValueAndValidity()

      }

      if (quantidadeDeItens >= 5) {
        if ((this.formulario.getRawValue().empresa === 'Nutrien' && this.formulario.getRawValue().categoria !== '') || (this.formulario.getRawValue().empresa !== 'Nutrien' && this.formulario.getRawValue().empresa !== '' && this.formulario.getRawValue().categoria === 'Fertilizantes')) {
          this.exibeListaProdutos = true;
          this.formulario.get('codigo_do_produto_5')?.setValidators([Validators.required])
          this.formulario.get('planta_de_expedicao_5')?.setValidators([Validators.required])
        } else {
          this.exibeListaProdutos = false;
        }
        this.formulario.get('descricao_produto_5')?.setValidators([Validators.required])
        // this.formulario.get('familia_produto_5')?.setValidators([Validators.required])
        this.formulario.get('quantidade_produto_5')?.setValidators([Validators.required])
        this.formulario.get('valor_unitario_produto_5')?.setValidators([Validators.required])
        this.formulario.get('embalagem_produto_5')?.setValidators([Validators.required])
        this.formulario.get('data_da_entrega_solicitada_produto_5')?.setValidators([Validators.required])
        // this.formulario.get('centro_de_expedicao_5')?.setValidators([Validators.required])


        this.formulario.get('codigo_do_produto_5')?.updateValueAndValidity();
        this.formulario.get('descricao_produto_5')?.updateValueAndValidity();
        // this.formulario.get('familia_produto_5')?.updateValueAndValidity();
        this.formulario.get('quantidade_produto_5')?.updateValueAndValidity();
        this.formulario.get('valor_unitario_produto_5')?.updateValueAndValidity();
        this.formulario.get('embalagem_produto_5')?.updateValueAndValidity();
        this.formulario.get('data_da_entrega_solicitada_produto_5')?.updateValueAndValidity();
        this.formulario.get('planta_de_expedicao_5')?.updateValueAndValidity()
        // this.formulario.get('centro_de_expedicao_5')?.updateValueAndValidity()

      }

      if (quantidadeDeItens >= 6) {
        if ((this.formulario.getRawValue().empresa === 'Nutrien' && this.formulario.getRawValue().categoria !== '') || (this.formulario.getRawValue().empresa !== 'Nutrien' && this.formulario.getRawValue().empresa !== '' && this.formulario.getRawValue().categoria === 'Fertilizantes')) {
          this.exibeListaProdutos = true;
          this.formulario.get('codigo_do_produto_6')?.setValidators([Validators.required])
          this.formulario.get('planta_de_expedicao_6')?.setValidators([Validators.required])
        } else {
          this.exibeListaProdutos = false;
        }
        this.formulario.get('descricao_produto_6')?.setValidators([Validators.required])
        // this.formulario.get('familia_produto_6')?.setValidators([Validators.required])
        this.formulario.get('quantidade_produto_6')?.setValidators([Validators.required])
        this.formulario.get('valor_unitario_produto_6')?.setValidators([Validators.required])
        this.formulario.get('embalagem_produto_6')?.setValidators([Validators.required])
        this.formulario.get('data_da_entrega_solicitada_produto_6')?.setValidators([Validators.required])
        // this.formulario.get('centro_de_expedicao_6')?.setValidators([Validators.required])


        this.formulario.get('codigo_do_produto_6')?.updateValueAndValidity();
        this.formulario.get('descricao_produto_6')?.updateValueAndValidity();
        // this.formulario.get('familia_produto_6')?.updateValueAndValidity();
        this.formulario.get('quantidade_produto_6')?.updateValueAndValidity();
        this.formulario.get('valor_unitario_produto_6')?.updateValueAndValidity();
        this.formulario.get('embalagem_produto_6')?.updateValueAndValidity();
        this.formulario.get('data_da_entrega_solicitada_produto_6')?.updateValueAndValidity();
        this.formulario.get('planta_de_expedicao_6')?.updateValueAndValidity()
        // this.formulario.get('centro_de_expedicao_6')?.updateValueAndValidity()

      }

      if (quantidadeDeItens >= 7) {
        if ((this.formulario.getRawValue().empresa === 'Nutrien' && this.formulario.getRawValue().categoria !== '') || (this.formulario.getRawValue().empresa !== 'Nutrien' && this.formulario.getRawValue().empresa !== '' && this.formulario.getRawValue().categoria === 'Fertilizantes')) {
          this.exibeListaProdutos = true;
          this.formulario.get('codigo_do_produto_7')?.setValidators([Validators.required])
          this.formulario.get('planta_de_expedicao_7')?.setValidators([Validators.required])
        } else {
          this.exibeListaProdutos = false;
        }
        this.formulario.get('descricao_produto_7')?.setValidators([Validators.required])
        // this.formulario.get('familia_produto_7')?.setValidators([Validators.required])
        this.formulario.get('quantidade_produto_7')?.setValidators([Validators.required])
        this.formulario.get('valor_unitario_produto_7')?.setValidators([Validators.required])
        this.formulario.get('embalagem_produto_7')?.setValidators([Validators.required])
        this.formulario.get('data_da_entrega_solicitada_produto_7')?.setValidators([Validators.required])
        // this.formulario.get('centro_de_expedicao_7')?.setValidators([Validators.required])


        this.formulario.get('codigo_do_produto_7')?.updateValueAndValidity();
        this.formulario.get('descricao_produto_7')?.updateValueAndValidity();
        // this.formulario.get('familia_produto_7')?.updateValueAndValidity();
        this.formulario.get('quantidade_produto_7')?.updateValueAndValidity();
        this.formulario.get('valor_unitario_produto_7')?.updateValueAndValidity();
        this.formulario.get('embalagem_produto_7')?.updateValueAndValidity();
        this.formulario.get('data_da_entrega_solicitada_produto_7')?.updateValueAndValidity();
        this.formulario.get('planta_de_expedicao_7')?.updateValueAndValidity()
        // this.formulario.get('centro_de_expedicao_7')?.updateValueAndValidity()

      }

      if (quantidadeDeItens >= 8) {
        if ((this.formulario.getRawValue().empresa === 'Nutrien' && this.formulario.getRawValue().categoria !== '') || (this.formulario.getRawValue().empresa !== 'Nutrien' && this.formulario.getRawValue().empresa !== '' && this.formulario.getRawValue().categoria === 'Fertilizantes')) {
          this.exibeListaProdutos = true;
          this.formulario.get('codigo_do_produto_8')?.setValidators([Validators.required])
          this.formulario.get('planta_de_expedicao_8')?.setValidators([Validators.required])
        } else {
          this.exibeListaProdutos = false;
        }
        this.formulario.get('descricao_produto_8')?.setValidators([Validators.required])
        // this.formulario.get('familia_produto_8')?.setValidators([Validators.required])
        this.formulario.get('quantidade_produto_8')?.setValidators([Validators.required])
        this.formulario.get('valor_unitario_produto_8')?.setValidators([Validators.required])
        this.formulario.get('embalagem_produto_8')?.setValidators([Validators.required])
        this.formulario.get('data_da_entrega_solicitada_produto_8')?.setValidators([Validators.required])
        // this.formulario.get('centro_de_expedicao_8')?.setValidators([Validators.required])


        this.formulario.get('codigo_do_produto_8')?.updateValueAndValidity();
        this.formulario.get('descricao_produto_8')?.updateValueAndValidity();
        // this.formulario.get('familia_produto_8')?.updateValueAndValidity();
        this.formulario.get('quantidade_produto_8')?.updateValueAndValidity();
        this.formulario.get('valor_unitario_produto_8')?.updateValueAndValidity();
        this.formulario.get('embalagem_produto_8')?.updateValueAndValidity();
        this.formulario.get('data_da_entrega_solicitada_produto_8')?.updateValueAndValidity();
        this.formulario.get('planta_de_expedicao_8')?.updateValueAndValidity()
        // this.formulario.get('centro_de_expedicao_8')?.updateValueAndValidity()

      }

      if (quantidadeDeItens >= 9) {
        if ((this.formulario.getRawValue().empresa === 'Nutrien' && this.formulario.getRawValue().categoria !== '') || (this.formulario.getRawValue().empresa !== 'Nutrien' && this.formulario.getRawValue().empresa !== '' && this.formulario.getRawValue().categoria === 'Fertilizantes')) {
          this.exibeListaProdutos = true;
          this.formulario.get('codigo_do_produto_9')?.setValidators([Validators.required])
          this.formulario.get('planta_de_expedicao_9')?.setValidators([Validators.required])
        } else {
          this.exibeListaProdutos = false;
        }
        this.formulario.get('descricao_produto_9')?.setValidators([Validators.required])
        // this.formulario.get('familia_produto_9')?.setValidators([Validators.required])
        this.formulario.get('quantidade_produto_9')?.setValidators([Validators.required])
        this.formulario.get('valor_unitario_produto_9')?.setValidators([Validators.required])
        this.formulario.get('embalagem_produto_9')?.setValidators([Validators.required])
        this.formulario.get('data_da_entrega_solicitada_produto_9')?.setValidators([Validators.required])
        // this.formulario.get('centro_de_expedicao_9')?.setValidators([Validators.required])


        this.formulario.get('codigo_do_produto_9')?.updateValueAndValidity();
        this.formulario.get('descricao_produto_9')?.updateValueAndValidity();
        // this.formulario.get('familia_produto_9')?.updateValueAndValidity();
        this.formulario.get('quantidade_produto_9')?.updateValueAndValidity();
        this.formulario.get('valor_unitario_produto_9')?.updateValueAndValidity();
        this.formulario.get('embalagem_produto_9')?.updateValueAndValidity();
        this.formulario.get('data_da_entrega_solicitada_produto_9')?.updateValueAndValidity();
        this.formulario.get('planta_de_expedicao_9')?.updateValueAndValidity()
        // this.formulario.get('centro_de_expedicao_9')?.updateValueAndValidity()

      }

      if (quantidadeDeItens >= 10) {
        if ((this.formulario.getRawValue().empresa === 'Nutrien' && this.formulario.getRawValue().categoria !== '') || (this.formulario.getRawValue().empresa !== 'Nutrien' && this.formulario.getRawValue().empresa !== '' && this.formulario.getRawValue().categoria === 'Fertilizantes')) {
          this.exibeListaProdutos = true;
          this.formulario.get('codigo_do_produto_10')?.setValidators([Validators.required])
          this.formulario.get('planta_de_expedicao_10')?.setValidators([Validators.required])
        } else {
          this.exibeListaProdutos = false;
        }
        this.formulario.get('descricao_produto_10')?.setValidators([Validators.required])
        // this.formulario.get('familia_produto_10')?.setValidators([Validators.required])
        this.formulario.get('quantidade_produto_10')?.setValidators([Validators.required])
        this.formulario.get('valor_unitario_produto_10')?.setValidators([Validators.required])
        this.formulario.get('embalagem_produto_10')?.setValidators([Validators.required])
        this.formulario.get('data_da_entrega_solicitada_produto_10')?.setValidators([Validators.required])
        // this.formulario.get('centro_de_expedicao_10')?.setValidators([Validators.required])


        this.formulario.get('codigo_do_produto_10')?.updateValueAndValidity();
        this.formulario.get('descricao_produto_10')?.updateValueAndValidity();
        // this.formulario.get('familia_produto_10')?.updateValueAndValidity();
        this.formulario.get('quantidade_produto_10')?.updateValueAndValidity();
        this.formulario.get('valor_unitario_produto_10')?.updateValueAndValidity();
        this.formulario.get('embalagem_produto_10')?.updateValueAndValidity();
        this.formulario.get('data_da_entrega_solicitada_produto_10')?.updateValueAndValidity();
        this.formulario.get('planta_de_expedicao_10')?.updateValueAndValidity()
        // this.formulario.get('centro_de_expedicao_10')?.updateValueAndValidity()

      }

      if (quantidadeDeItens >= 11) {
        if ((this.formulario.getRawValue().empresa === 'Nutrien' && this.formulario.getRawValue().categoria !== '') || (this.formulario.getRawValue().empresa !== 'Nutrien' && this.formulario.getRawValue().empresa !== '' && this.formulario.getRawValue().categoria === 'Fertilizantes')) {
          this.exibeListaProdutos = true;
          this.formulario.get('codigo_do_produto_11')?.setValidators([Validators.required])
          this.formulario.get('planta_de_expedicao_11')?.setValidators([Validators.required])
        } else {
          this.exibeListaProdutos = false;
        }
        this.formulario.get('descricao_produto_11')?.setValidators([Validators.required])
        // this.formulario.get('familia_produto_11')?.setValidators([Validators.required])
        this.formulario.get('quantidade_produto_11')?.setValidators([Validators.required])
        this.formulario.get('valor_unitario_produto_11')?.setValidators([Validators.required])
        this.formulario.get('embalagem_produto_11')?.setValidators([Validators.required])
        this.formulario.get('data_da_entrega_solicitada_produto_11')?.setValidators([Validators.required])
        // this.formulario.get('centro_de_expedicao_11')?.setValidators([Validators.required])


        this.formulario.get('codigo_do_produto_11')?.updateValueAndValidity();
        this.formulario.get('descricao_produto_11')?.updateValueAndValidity();
        // this.formulario.get('familia_produto_11')?.updateValueAndValidity();
        this.formulario.get('quantidade_produto_11')?.updateValueAndValidity();
        this.formulario.get('valor_unitario_produto_11')?.updateValueAndValidity();
        this.formulario.get('embalagem_produto_11')?.updateValueAndValidity();
        this.formulario.get('data_da_entrega_solicitada_produto_11')?.updateValueAndValidity();
        this.formulario.get('planta_de_expedicao_11')?.updateValueAndValidity()
        // this.formulario.get('centro_de_expedicao_11')?.updateValueAndValidity()

      }

      if (quantidadeDeItens >= 12) {
        if ((this.formulario.getRawValue().empresa === 'Nutrien' && this.formulario.getRawValue().categoria !== '') || (this.formulario.getRawValue().empresa !== 'Nutrien' && this.formulario.getRawValue().empresa !== '' && this.formulario.getRawValue().categoria === 'Fertilizantes')) {
          this.exibeListaProdutos = true;
          this.formulario.get('codigo_do_produto_12')?.setValidators([Validators.required])
          this.formulario.get('planta_de_expedicao_12')?.setValidators([Validators.required])
        } else {
          this.exibeListaProdutos = false;
        }
        this.formulario.get('descricao_produto_12')?.setValidators([Validators.required])
        // this.formulario.get('familia_produto_12')?.setValidators([Validators.required])
        this.formulario.get('quantidade_produto_12')?.setValidators([Validators.required])
        this.formulario.get('valor_unitario_produto_12')?.setValidators([Validators.required])
        this.formulario.get('embalagem_produto_12')?.setValidators([Validators.required])
        this.formulario.get('data_da_entrega_solicitada_produto_12')?.setValidators([Validators.required])
        // this.formulario.get('centro_de_expedicao_12')?.setValidators([Validators.required])


        this.formulario.get('codigo_do_produto_12')?.updateValueAndValidity();
        this.formulario.get('descricao_produto_12')?.updateValueAndValidity();
        // this.formulario.get('familia_produto_12')?.updateValueAndValidity();
        this.formulario.get('quantidade_produto_12')?.updateValueAndValidity();
        this.formulario.get('valor_unitario_produto_12')?.updateValueAndValidity();
        this.formulario.get('embalagem_produto_12')?.updateValueAndValidity();
        this.formulario.get('data_da_entrega_solicitada_produto_12')?.updateValueAndValidity();
        this.formulario.get('planta_de_expedicao_12')?.updateValueAndValidity()
        // this.formulario.get('centro_de_expedicao_12')?.updateValueAndValidity()

      }

      if (quantidadeDeItens >= 13) {
        if ((this.formulario.getRawValue().empresa === 'Nutrien' && this.formulario.getRawValue().categoria !== '') || (this.formulario.getRawValue().empresa !== 'Nutrien' && this.formulario.getRawValue().empresa !== '' && this.formulario.getRawValue().categoria === 'Fertilizantes')) {
          this.exibeListaProdutos = true;
          this.formulario.get('codigo_do_produto_13')?.setValidators([Validators.required])
          this.formulario.get('planta_de_expedicao_13')?.setValidators([Validators.required])
        } else {
          this.exibeListaProdutos = false;
        }
        this.formulario.get('descricao_produto_13')?.setValidators([Validators.required])
        // this.formulario.get('familia_produto_13')?.setValidators([Validators.required])
        this.formulario.get('quantidade_produto_13')?.setValidators([Validators.required])
        this.formulario.get('valor_unitario_produto_13')?.setValidators([Validators.required])
        this.formulario.get('embalagem_produto_13')?.setValidators([Validators.required])
        this.formulario.get('data_da_entrega_solicitada_produto_13')?.setValidators([Validators.required])
        // this.formulario.get('centro_de_expedicao_13')?.setValidators([Validators.required])


        this.formulario.get('codigo_do_produto_13')?.updateValueAndValidity();
        this.formulario.get('descricao_produto_13')?.updateValueAndValidity();
        // this.formulario.get('familia_produto_13')?.updateValueAndValidity();
        this.formulario.get('quantidade_produto_13')?.updateValueAndValidity();
        this.formulario.get('valor_unitario_produto_13')?.updateValueAndValidity();
        this.formulario.get('embalagem_produto_13')?.updateValueAndValidity();
        this.formulario.get('data_da_entrega_solicitada_produto_13')?.updateValueAndValidity();
        this.formulario.get('planta_de_expedicao_13')?.updateValueAndValidity()
        // this.formulario.get('centro_de_expedicao_13')?.updateValueAndValidity()

      }

      if (quantidadeDeItens >= 14) {
        if ((this.formulario.getRawValue().empresa === 'Nutrien' && this.formulario.getRawValue().categoria !== '') || (this.formulario.getRawValue().empresa !== 'Nutrien' && this.formulario.getRawValue().empresa !== '' && this.formulario.getRawValue().categoria === 'Fertilizantes')) {
          this.exibeListaProdutos = true;
          this.formulario.get('codigo_do_produto_14')?.setValidators([Validators.required])
          this.formulario.get('planta_de_expedicao_14')?.setValidators([Validators.required])
        } else {
          this.exibeListaProdutos = false;
        }
        this.formulario.get('descricao_produto_14')?.setValidators([Validators.required])
        // this.formulario.get('familia_produto_14')?.setValidators([Validators.required])
        this.formulario.get('quantidade_produto_14')?.setValidators([Validators.required])
        this.formulario.get('valor_unitario_produto_14')?.setValidators([Validators.required])
        this.formulario.get('embalagem_produto_14')?.setValidators([Validators.required])
        this.formulario.get('data_da_entrega_solicitada_produto_14')?.setValidators([Validators.required])
        // this.formulario.get('centro_de_expedicao_14')?.setValidators([Validators.required])


        this.formulario.get('codigo_do_produto_14')?.updateValueAndValidity();
        this.formulario.get('descricao_produto_14')?.updateValueAndValidity();
        // this.formulario.get('familia_produto_14')?.updateValueAndValidity();
        this.formulario.get('quantidade_produto_14')?.updateValueAndValidity();
        this.formulario.get('valor_unitario_produto_14')?.updateValueAndValidity();
        this.formulario.get('embalagem_produto_14')?.updateValueAndValidity();
        this.formulario.get('data_da_entrega_solicitada_produto_14')?.updateValueAndValidity();
        this.formulario.get('planta_de_expedicao_14')?.updateValueAndValidity()
        // this.formulario.get('centro_de_expedicao_14')?.updateValueAndValidity()

      }

      if (quantidadeDeItens >= 15) {
        if ((this.formulario.getRawValue().empresa === 'Nutrien' && this.formulario.getRawValue().categoria !== '') || (this.formulario.getRawValue().empresa !== 'Nutrien' && this.formulario.getRawValue().empresa !== '' && this.formulario.getRawValue().categoria === 'Fertilizantes')) {
          this.exibeListaProdutos = true;
          this.formulario.get('codigo_do_produto_15')?.setValidators([Validators.required])
          this.formulario.get('planta_de_expedicao_15')?.setValidators([Validators.required])
        } else {
          this.exibeListaProdutos = false;
        }
        this.formulario.get('descricao_produto_15')?.setValidators([Validators.required])
        // this.formulario.get('familia_produto_15')?.setValidators([Validators.required])
        this.formulario.get('quantidade_produto_15')?.setValidators([Validators.required])
        this.formulario.get('valor_unitario_produto_15')?.setValidators([Validators.required])
        this.formulario.get('embalagem_produto_15')?.setValidators([Validators.required])
        this.formulario.get('data_da_entrega_solicitada_produto_15')?.setValidators([Validators.required])
        // this.formulario.get('centro_de_expedicao_15')?.setValidators([Validators.required])


        this.formulario.get('codigo_do_produto_15')?.updateValueAndValidity();
        this.formulario.get('descricao_produto_15')?.updateValueAndValidity();
        // this.formulario.get('familia_produto_15')?.updateValueAndValidity();
        this.formulario.get('quantidade_produto_15')?.updateValueAndValidity();
        this.formulario.get('valor_unitario_produto_15')?.updateValueAndValidity();
        this.formulario.get('embalagem_produto_15')?.updateValueAndValidity();
        this.formulario.get('data_da_entrega_solicitada_produto_15')?.updateValueAndValidity();
        this.formulario.get('planta_de_expedicao_15')?.updateValueAndValidity()
        // this.formulario.get('centro_de_expedicao_15')?.updateValueAndValidity()

      }

      if (quantidadeDeItens >= 16) {
        if ((this.formulario.getRawValue().empresa === 'Nutrien' && this.formulario.getRawValue().categoria !== '') || (this.formulario.getRawValue().empresa !== 'Nutrien' && this.formulario.getRawValue().empresa !== '' && this.formulario.getRawValue().categoria === 'Fertilizantes')) {
          this.exibeListaProdutos = true;
          this.formulario.get('codigo_do_produto_16')?.setValidators([Validators.required])
          this.formulario.get('planta_de_expedicao_16')?.setValidators([Validators.required])
        } else {
          this.exibeListaProdutos = false;
        }
        this.formulario.get('descricao_produto_16')?.setValidators([Validators.required])
        // this.formulario.get('familia_produto_16')?.setValidators([Validators.required])
        this.formulario.get('quantidade_produto_16')?.setValidators([Validators.required])
        this.formulario.get('valor_unitario_produto_16')?.setValidators([Validators.required])
        this.formulario.get('embalagem_produto_16')?.setValidators([Validators.required])
        this.formulario.get('data_da_entrega_solicitada_produto_16')?.setValidators([Validators.required])
        // this.formulario.get('centro_de_expedicao_16')?.setValidators([Validators.required])


        this.formulario.get('codigo_do_produto_16')?.updateValueAndValidity();
        this.formulario.get('descricao_produto_16')?.updateValueAndValidity();
        // this.formulario.get('familia_produto_16')?.updateValueAndValidity();
        this.formulario.get('quantidade_produto_16')?.updateValueAndValidity();
        this.formulario.get('valor_unitario_produto_16')?.updateValueAndValidity();
        this.formulario.get('embalagem_produto_16')?.updateValueAndValidity();
        this.formulario.get('data_da_entrega_solicitada_produto_16')?.updateValueAndValidity();
        this.formulario.get('planta_de_expedicao_16')?.updateValueAndValidity()
        // this.formulario.get('centro_de_expedicao_16')?.updateValueAndValidity()

      }

      if (quantidadeDeItens >= 17) {
        if ((this.formulario.getRawValue().empresa === 'Nutrien' && this.formulario.getRawValue().categoria !== '') || (this.formulario.getRawValue().empresa !== 'Nutrien' && this.formulario.getRawValue().empresa !== '' && this.formulario.getRawValue().categoria === 'Fertilizantes')) {
          this.exibeListaProdutos = true;
          this.formulario.get('codigo_do_produto_17')?.setValidators([Validators.required])
          this.formulario.get('planta_de_expedicao_17')?.setValidators([Validators.required])
        } else {
          this.exibeListaProdutos = false;
        }
        this.formulario.get('descricao_produto_17')?.setValidators([Validators.required])
        // this.formulario.get('familia_produto_17')?.setValidators([Validators.required])
        this.formulario.get('quantidade_produto_17')?.setValidators([Validators.required])
        this.formulario.get('valor_unitario_produto_17')?.setValidators([Validators.required])
        this.formulario.get('embalagem_produto_17')?.setValidators([Validators.required])
        this.formulario.get('data_da_entrega_solicitada_produto_17')?.setValidators([Validators.required])
        // this.formulario.get('centro_de_expedicao_17')?.setValidators([Validators.required])


        this.formulario.get('codigo_do_produto_17')?.updateValueAndValidity();
        this.formulario.get('descricao_produto_17')?.updateValueAndValidity();
        // this.formulario.get('familia_produto_17')?.updateValueAndValidity();
        this.formulario.get('quantidade_produto_17')?.updateValueAndValidity();
        this.formulario.get('valor_unitario_produto_17')?.updateValueAndValidity();
        this.formulario.get('embalagem_produto_17')?.updateValueAndValidity();
        this.formulario.get('data_da_entrega_solicitada_produto_17')?.updateValueAndValidity();
        this.formulario.get('planta_de_expedicao_17')?.updateValueAndValidity()
        // this.formulario.get('centro_de_expedicao_17')?.updateValueAndValidity()

      }

      if (quantidadeDeItens >= 18) {
        if ((this.formulario.getRawValue().empresa === 'Nutrien' && this.formulario.getRawValue().categoria !== '') || (this.formulario.getRawValue().empresa !== 'Nutrien' && this.formulario.getRawValue().empresa !== '' && this.formulario.getRawValue().categoria === 'Fertilizantes')) {
          this.exibeListaProdutos = true;
          this.formulario.get('codigo_do_produto_18')?.setValidators([Validators.required])
          this.formulario.get('planta_de_expedicao_18')?.setValidators([Validators.required])
        } else {
          this.exibeListaProdutos = false;
        }
        this.formulario.get('descricao_produto_18')?.setValidators([Validators.required])
        // this.formulario.get('familia_produto_18')?.setValidators([Validators.required])
        this.formulario.get('quantidade_produto_18')?.setValidators([Validators.required])
        this.formulario.get('valor_unitario_produto_18')?.setValidators([Validators.required])
        this.formulario.get('embalagem_produto_18')?.setValidators([Validators.required])
        this.formulario.get('data_da_entrega_solicitada_produto_18')?.setValidators([Validators.required])
        // this.formulario.get('centro_de_expedicao_18')?.setValidators([Validators.required])


        this.formulario.get('codigo_do_produto_18')?.updateValueAndValidity();
        this.formulario.get('descricao_produto_18')?.updateValueAndValidity();
        // this.formulario.get('familia_produto_18')?.updateValueAndValidity();
        this.formulario.get('quantidade_produto_18')?.updateValueAndValidity();
        this.formulario.get('valor_unitario_produto_18')?.updateValueAndValidity();
        this.formulario.get('embalagem_produto_18')?.updateValueAndValidity();
        this.formulario.get('data_da_entrega_solicitada_produto_18')?.updateValueAndValidity();
        this.formulario.get('planta_de_expedicao_18')?.updateValueAndValidity()
        // this.formulario.get('centro_de_expedicao_18')?.updateValueAndValidity()

      }

      if (quantidadeDeItens >= 19) {
        if ((this.formulario.getRawValue().empresa === 'Nutrien' && this.formulario.getRawValue().categoria !== '') || (this.formulario.getRawValue().empresa !== 'Nutrien' && this.formulario.getRawValue().empresa !== '' && this.formulario.getRawValue().categoria === 'Fertilizantes')) {
          this.exibeListaProdutos = true;
          this.formulario.get('codigo_do_produto_19')?.setValidators([Validators.required])
          this.formulario.get('planta_de_expedicao_19')?.setValidators([Validators.required])
        } else {
          this.exibeListaProdutos = false;
        }
        this.formulario.get('descricao_produto_19')?.setValidators([Validators.required])
        // this.formulario.get('familia_produto_19')?.setValidators([Validators.required])
        this.formulario.get('quantidade_produto_19')?.setValidators([Validators.required])
        this.formulario.get('valor_unitario_produto_19')?.setValidators([Validators.required])
        this.formulario.get('embalagem_produto_19')?.setValidators([Validators.required])
        this.formulario.get('data_da_entrega_solicitada_produto_19')?.setValidators([Validators.required])
        // this.formulario.get('centro_de_expedicao_19')?.setValidators([Validators.required])

        this.formulario.get('codigo_do_produto_19')?.updateValueAndValidity();
        this.formulario.get('descricao_produto_19')?.updateValueAndValidity();
        // this.formulario.get('familia_produto_19')?.updateValueAndValidity();
        this.formulario.get('quantidade_produto_19')?.updateValueAndValidity();
        this.formulario.get('valor_unitario_produto_19')?.updateValueAndValidity();
        this.formulario.get('embalagem_produto_19')?.updateValueAndValidity();
        this.formulario.get('data_da_entrega_solicitada_produto_19')?.updateValueAndValidity();
        this.formulario.get('planta_de_expedicao_19')?.updateValueAndValidity()
        // this.formulario.get('centro_de_expedicao_19')?.updateValueAndValidity()

      }

      if (quantidadeDeItens >= 20) {
        if ((this.formulario.getRawValue().empresa === 'Nutrien' && this.formulario.getRawValue().categoria !== '') || (this.formulario.getRawValue().empresa !== 'Nutrien' && this.formulario.getRawValue().empresa !== '' && this.formulario.getRawValue().categoria === 'Fertilizantes')) {
          this.exibeListaProdutos = true;
          this.formulario.get('codigo_do_produto_20')?.setValidators([Validators.required])
          this.formulario.get('planta_de_expedicao_20')?.setValidators([Validators.required])
        } else {
          this.exibeListaProdutos = false;
        }
        this.formulario.get('descricao_produto_20')?.setValidators([Validators.required])
        // this.formulario.get('familia_produto_20')?.setValidators([Validators.required])
        this.formulario.get('quantidade_produto_20')?.setValidators([Validators.required])
        this.formulario.get('valor_unitario_produto_20')?.setValidators([Validators.required])
        this.formulario.get('embalagem_produto_20')?.setValidators([Validators.required])
        this.formulario.get('data_da_entrega_solicitada_produto_20')?.setValidators([Validators.required])
        // this.formulario.get('centro_de_expedicao_20')?.setValidators([Validators.required])


        this.formulario.get('codigo_do_produto_20')?.updateValueAndValidity();
        this.formulario.get('descricao_produto_20')?.updateValueAndValidity();
        // this.formulario.get('familia_produto_20')?.updateValueAndValidity();
        this.formulario.get('quantidade_produto_20')?.updateValueAndValidity();
        this.formulario.get('valor_unitario_produto_20')?.updateValueAndValidity();
        this.formulario.get('embalagem_produto_20')?.updateValueAndValidity();
        this.formulario.get('data_da_entrega_solicitada_produto_20')?.updateValueAndValidity();
        this.formulario.get('planta_de_expedicao_20')?.updateValueAndValidity()
        // this.formulario.get('centro_de_expedicao_20')?.updateValueAndValidity()

      }

      if (quantidadeDeItens == '') {
        this.formulario.get('codigo_do_produto_1')?.clearValidators()
        this.formulario.get('descricao_produto_1')?.clearValidators()
        // this.formulario.get('familia_produto_1')?.clearValidators()
        this.formulario.get('quantidade_produto_1')?.clearValidators()
        this.formulario.get('valor_unitario_produto_1')?.clearValidators()
        this.formulario.get('embalagem_produto_1')?.clearValidators()
        this.formulario.get('data_da_entrega_solicitada_produto_1')?.clearValidators()
        this.formulario.get('planta_de_expedicao_1')?.clearValidators()
        // this.formulario.get('centro_de_expedicao_1')?.clearValidators()

        this.formulario.get('codigo_do_produto_1')?.updateValueAndValidity();
        this.formulario.get('descricao_produto_1')?.updateValueAndValidity();
        // this.formulario.get('familia_produto_1')?.updateValueAndValidity();
        this.formulario.get('quantidade_produto_1')?.updateValueAndValidity();
        this.formulario.get('valor_unitario_produto_1')?.updateValueAndValidity();
        this.formulario.get('embalagem_produto_1')?.updateValueAndValidity();
        this.formulario.get('data_da_entrega_solicitada_produto_1')?.updateValueAndValidity();
        this.formulario.get('planta_de_expedicao_1')?.updateValueAndValidity();
        // this.formulario.get('centro_de_expedicao_1')?.updateValueAndValidity();


        this.formulario.get('codigo_do_produto_2')?.clearValidators()
        this.formulario.get('descricao_produto_2')?.clearValidators()
        // this.formulario.get('familia_produto_2')?.clearValidators()
        this.formulario.get('quantidade_produto_2')?.clearValidators()
        this.formulario.get('valor_unitario_produto_2')?.clearValidators()
        this.formulario.get('embalagem_produto_2')?.clearValidators()
        this.formulario.get('data_da_entrega_solicitada_produto_2')?.clearValidators()
        this.formulario.get('planta_de_expedicao_2')?.clearValidators()
        // this.formulario.get('centro_de_expedicao_2')?.clearValidators()


        this.formulario.get('codigo_do_produto_2')?.updateValueAndValidity();
        this.formulario.get('descricao_produto_2')?.updateValueAndValidity();
        // this.formulario.get('familia_produto_2')?.updateValueAndValidity();
        this.formulario.get('quantidade_produto_2')?.updateValueAndValidity();
        this.formulario.get('valor_unitario_produto_2')?.updateValueAndValidity();
        this.formulario.get('embalagem_produto_2')?.updateValueAndValidity();
        this.formulario.get('data_da_entrega_solicitada_produto_2')?.updateValueAndValidity();
        this.formulario.get('planta_de_expedicao_2')?.updateValueAndValidity();
        // this.formulario.get('centro_de_expedicao_2')?.updateValueAndValidity();


        this.formulario.get('codigo_do_produto_3')?.clearValidators()
        this.formulario.get('descricao_produto_3')?.clearValidators()
        // this.formulario.get('familia_produto_3')?.clearValidators()
        this.formulario.get('quantidade_produto_3')?.clearValidators()
        this.formulario.get('valor_unitario_produto_3')?.clearValidators()
        this.formulario.get('embalagem_produto_3')?.clearValidators()
        this.formulario.get('data_da_entrega_solicitada_produto_3')?.clearValidators()
        this.formulario.get('planta_de_expedicao_3')?.clearValidators()
        // this.formulario.get('centro_de_expedicao_3')?.clearValidators()


        this.formulario.get('codigo_do_produto_3')?.updateValueAndValidity();
        this.formulario.get('descricao_produto_3')?.updateValueAndValidity();
        // this.formulario.get('familia_produto_3')?.updateValueAndValidity();
        this.formulario.get('quantidade_produto_3')?.updateValueAndValidity();
        this.formulario.get('valor_unitario_produto_3')?.updateValueAndValidity();
        this.formulario.get('embalagem_produto_3')?.updateValueAndValidity();
        this.formulario.get('data_da_entrega_solicitada_produto_3')?.updateValueAndValidity();
        this.formulario.get('planta_de_expedicao_3')?.updateValueAndValidity();
        // this.formulario.get('centro_de_expedicao_3')?.updateValueAndValidity();


        this.formulario.get('codigo_do_produto_4')?.clearValidators()
        this.formulario.get('descricao_produto_4')?.clearValidators()
        // this.formulario.get('familia_produto_4')?.clearValidators()
        this.formulario.get('quantidade_produto_4')?.clearValidators()
        this.formulario.get('valor_unitario_produto_4')?.clearValidators()
        this.formulario.get('embalagem_produto_4')?.clearValidators()
        this.formulario.get('data_da_entrega_solicitada_produto_4')?.clearValidators()
        this.formulario.get('planta_de_expedicao_4')?.clearValidators()
        // this.formulario.get('centro_de_expedicao_4')?.clearValidators()


        this.formulario.get('codigo_do_produto_4')?.updateValueAndValidity();
        this.formulario.get('descricao_produto_4')?.updateValueAndValidity();
        // this.formulario.get('familia_produto_4')?.updateValueAndValidity();
        this.formulario.get('quantidade_produto_4')?.updateValueAndValidity();
        this.formulario.get('valor_unitario_produto_4')?.updateValueAndValidity();
        this.formulario.get('embalagem_produto_4')?.updateValueAndValidity();
        this.formulario.get('data_da_entrega_solicitada_produto_4')?.updateValueAndValidity();
        this.formulario.get('planta_de_expedicao_4')?.updateValueAndValidity();
        // this.formulario.get('centro_de_expedicao_4')?.updateValueAndValidity();


        this.formulario.get('codigo_do_produto_5')?.clearValidators()
        this.formulario.get('descricao_produto_5')?.clearValidators()
        // this.formulario.get('familia_produto_5')?.clearValidators()
        this.formulario.get('quantidade_produto_5')?.clearValidators()
        this.formulario.get('valor_unitario_produto_5')?.clearValidators()
        this.formulario.get('embalagem_produto_5')?.clearValidators()
        this.formulario.get('data_da_entrega_solicitada_produto_5')?.clearValidators()
        this.formulario.get('planta_de_expedicao_5')?.clearValidators()
        // this.formulario.get('centro_de_expedicao_5')?.clearValidators()


        this.formulario.get('codigo_do_produto_5')?.updateValueAndValidity();
        this.formulario.get('descricao_produto_5')?.updateValueAndValidity();
        // this.formulario.get('familia_produto_5')?.updateValueAndValidity();
        this.formulario.get('quantidade_produto_5')?.updateValueAndValidity();
        this.formulario.get('valor_unitario_produto_5')?.updateValueAndValidity();
        this.formulario.get('embalagem_produto_5')?.updateValueAndValidity();
        this.formulario.get('data_da_entrega_solicitada_produto_5')?.updateValueAndValidity();
        this.formulario.get('planta_de_expedicao_5')?.updateValueAndValidity();
        // this.formulario.get('centro_de_expedicao_5')?.updateValueAndValidity();


        this.formulario.get('codigo_do_produto_6')?.clearValidators()
        this.formulario.get('descricao_produto_6')?.clearValidators()
        // this.formulario.get('familia_produto_6')?.clearValidators()
        this.formulario.get('quantidade_produto_6')?.clearValidators()
        this.formulario.get('valor_unitario_produto_6')?.clearValidators()
        this.formulario.get('embalagem_produto_6')?.clearValidators()
        this.formulario.get('data_da_entrega_solicitada_produto_6')?.clearValidators()
        this.formulario.get('planta_de_expedicao_6')?.clearValidators()
        // this.formulario.get('centro_de_expedicao_6')?.clearValidators()


        this.formulario.get('codigo_do_produto_6')?.updateValueAndValidity();
        this.formulario.get('descricao_produto_6')?.updateValueAndValidity();
        // this.formulario.get('familia_produto_6')?.updateValueAndValidity();
        this.formulario.get('quantidade_produto_6')?.updateValueAndValidity();
        this.formulario.get('valor_unitario_produto_6')?.updateValueAndValidity();
        this.formulario.get('embalagem_produto_6')?.updateValueAndValidity();
        this.formulario.get('data_da_entrega_solicitada_produto_6')?.updateValueAndValidity();
        this.formulario.get('planta_de_expedicao_6')?.updateValueAndValidity();
        // this.formulario.get('centro_de_expedicao_6')?.updateValueAndValidity();


        this.formulario.get('codigo_do_produto_7')?.clearValidators()
        this.formulario.get('descricao_produto_7')?.clearValidators()
        // this.formulario.get('familia_produto_7')?.clearValidators()
        this.formulario.get('quantidade_produto_7')?.clearValidators()
        this.formulario.get('valor_unitario_produto_7')?.clearValidators()
        this.formulario.get('embalagem_produto_7')?.clearValidators()
        this.formulario.get('data_da_entrega_solicitada_produto_7')?.clearValidators()
        this.formulario.get('planta_de_expedicao_7')?.clearValidators()
        // this.formulario.get('centro_de_expedicao_7')?.clearValidators()


        this.formulario.get('codigo_do_produto_7')?.updateValueAndValidity();
        this.formulario.get('descricao_produto_7')?.updateValueAndValidity();
        // this.formulario.get('familia_produto_7')?.updateValueAndValidity();
        this.formulario.get('quantidade_produto_7')?.updateValueAndValidity();
        this.formulario.get('valor_unitario_produto_7')?.updateValueAndValidity();
        this.formulario.get('embalagem_produto_7')?.updateValueAndValidity();
        this.formulario.get('data_da_entrega_solicitada_produto_7')?.updateValueAndValidity();
        this.formulario.get('planta_de_expedicao_7')?.updateValueAndValidity();
        // this.formulario.get('centro_de_expedicao_7')?.updateValueAndValidity();


        this.formulario.get('codigo_do_produto_8')?.clearValidators()
        this.formulario.get('descricao_produto_8')?.clearValidators()
        // this.formulario.get('familia_produto_8')?.clearValidators()
        this.formulario.get('quantidade_produto_8')?.clearValidators()
        this.formulario.get('valor_unitario_produto_8')?.clearValidators()
        this.formulario.get('embalagem_produto_8')?.clearValidators()
        this.formulario.get('data_da_entrega_solicitada_produto_8')?.clearValidators()
        this.formulario.get('planta_de_expedicao_8')?.clearValidators()
        // this.formulario.get('centro_de_expedicao_8')?.clearValidators()


        this.formulario.get('codigo_do_produto_8')?.updateValueAndValidity();
        this.formulario.get('descricao_produto_8')?.updateValueAndValidity();
        // this.formulario.get('familia_produto_8')?.updateValueAndValidity();
        this.formulario.get('quantidade_produto_8')?.updateValueAndValidity();
        this.formulario.get('valor_unitario_produto_8')?.updateValueAndValidity();
        this.formulario.get('embalagem_produto_8')?.updateValueAndValidity();
        this.formulario.get('data_da_entrega_solicitada_produto_8')?.updateValueAndValidity();
        this.formulario.get('planta_de_expedicao_8')?.updateValueAndValidity();
        // this.formulario.get('centro_de_expedicao_8')?.updateValueAndValidity();


        this.formulario.get('codigo_do_produto_9')?.clearValidators()
        this.formulario.get('descricao_produto_9')?.clearValidators()
        // this.formulario.get('familia_produto_9')?.clearValidators()
        this.formulario.get('quantidade_produto_9')?.clearValidators()
        this.formulario.get('valor_unitario_produto_9')?.clearValidators()
        this.formulario.get('embalagem_produto_9')?.clearValidators()
        this.formulario.get('data_da_entrega_solicitada_produto_9')?.clearValidators()
        this.formulario.get('planta_de_expedicao_9')?.clearValidators()
        // this.formulario.get('centro_de_expedicao_9')?.clearValidators()


        this.formulario.get('codigo_do_produto_9')?.updateValueAndValidity();
        this.formulario.get('descricao_produto_9')?.updateValueAndValidity();
        // this.formulario.get('familia_produto_9')?.updateValueAndValidity();
        this.formulario.get('quantidade_produto_9')?.updateValueAndValidity();
        this.formulario.get('valor_unitario_produto_9')?.updateValueAndValidity();
        this.formulario.get('embalagem_produto_9')?.updateValueAndValidity();
        this.formulario.get('data_da_entrega_solicitada_produto_9')?.updateValueAndValidity();
        this.formulario.get('planta_de_expedicao_9')?.updateValueAndValidity();
        // this.formulario.get('centro_de_expedicao_9')?.updateValueAndValidity();


        this.formulario.get('codigo_do_produto_10')?.clearValidators()
        this.formulario.get('descricao_produto_10')?.clearValidators()
        // this.formulario.get('familia_produto_10')?.clearValidators()
        this.formulario.get('quantidade_produto_10')?.clearValidators()
        this.formulario.get('valor_unitario_produto_10')?.clearValidators()
        this.formulario.get('embalagem_produto_10')?.clearValidators()
        this.formulario.get('data_da_entrega_solicitada_produto_10')?.clearValidators()
        this.formulario.get('planta_de_expedicao_10')?.clearValidators()
        // this.formulario.get('centro_de_expedicao_10')?.clearValidators()


        this.formulario.get('codigo_do_produto_10')?.updateValueAndValidity();
        this.formulario.get('descricao_produto_10')?.updateValueAndValidity();
        // this.formulario.get('familia_produto_10')?.updateValueAndValidity();
        this.formulario.get('quantidade_produto_10')?.updateValueAndValidity();
        this.formulario.get('valor_unitario_produto_10')?.updateValueAndValidity();
        this.formulario.get('embalagem_produto_10')?.updateValueAndValidity();
        this.formulario.get('data_da_entrega_solicitada_produto_10')?.updateValueAndValidity();
        this.formulario.get('planta_de_expedicao_10')?.updateValueAndValidity();
        // this.formulario.get('centro_de_expedicao_10')?.updateValueAndValidity();


        this.formulario.get('codigo_do_produto_11')?.clearValidators()
        this.formulario.get('descricao_produto_11')?.clearValidators()
        // this.formulario.get('familia_produto_11')?.clearValidators()
        this.formulario.get('quantidade_produto_11')?.clearValidators()
        this.formulario.get('valor_unitario_produto_11')?.clearValidators()
        this.formulario.get('embalagem_produto_11')?.clearValidators()
        this.formulario.get('data_da_entrega_solicitada_produto_11')?.clearValidators()
        this.formulario.get('planta_de_expedicao_11')?.clearValidators()
        // this.formulario.get('centro_de_expedicao_11')?.clearValidators()


        this.formulario.get('codigo_do_produto_11')?.updateValueAndValidity();
        this.formulario.get('descricao_produto_11')?.updateValueAndValidity();
        // this.formulario.get('familia_produto_11')?.updateValueAndValidity();
        this.formulario.get('quantidade_produto_11')?.updateValueAndValidity();
        this.formulario.get('valor_unitario_produto_11')?.updateValueAndValidity();
        this.formulario.get('embalagem_produto_11')?.updateValueAndValidity();
        this.formulario.get('data_da_entrega_solicitada_produto_11')?.updateValueAndValidity();
        this.formulario.get('planta_de_expedicao_11')?.updateValueAndValidity();
        // this.formulario.get('centro_de_expedicao_11')?.updateValueAndValidity();


        this.formulario.get('codigo_do_produto_12')?.clearValidators()
        this.formulario.get('descricao_produto_12')?.clearValidators()
        // this.formulario.get('familia_produto_12')?.clearValidators()
        this.formulario.get('quantidade_produto_12')?.clearValidators()
        this.formulario.get('valor_unitario_produto_12')?.clearValidators()
        this.formulario.get('embalagem_produto_12')?.clearValidators()
        this.formulario.get('data_da_entrega_solicitada_produto_12')?.clearValidators()
        this.formulario.get('planta_de_expedicao_12')?.clearValidators()
        // this.formulario.get('centro_de_expedicao_12')?.clearValidators()


        this.formulario.get('codigo_do_produto_12')?.updateValueAndValidity();
        this.formulario.get('descricao_produto_12')?.updateValueAndValidity();
        // this.formulario.get('familia_produto_12')?.updateValueAndValidity();
        this.formulario.get('quantidade_produto_12')?.updateValueAndValidity();
        this.formulario.get('valor_unitario_produto_12')?.updateValueAndValidity();
        this.formulario.get('embalagem_produto_12')?.updateValueAndValidity();
        this.formulario.get('data_da_entrega_solicitada_produto_12')?.updateValueAndValidity();
        this.formulario.get('planta_de_expedicao_12')?.updateValueAndValidity();
        // this.formulario.get('centro_de_expedicao_12')?.updateValueAndValidity();


        this.formulario.get('codigo_do_produto_13')?.clearValidators()
        this.formulario.get('descricao_produto_13')?.clearValidators()
        // this.formulario.get('familia_produto_13')?.clearValidators()
        this.formulario.get('quantidade_produto_13')?.clearValidators()
        this.formulario.get('valor_unitario_produto_13')?.clearValidators()
        this.formulario.get('embalagem_produto_13')?.clearValidators()
        this.formulario.get('data_da_entrega_solicitada_produto_13')?.clearValidators()
        this.formulario.get('planta_de_expedicao_13')?.clearValidators()
        // this.formulario.get('centro_de_expedicao_13')?.clearValidators()


        this.formulario.get('codigo_do_produto_13')?.updateValueAndValidity();
        this.formulario.get('descricao_produto_13')?.updateValueAndValidity();
        // this.formulario.get('familia_produto_13')?.updateValueAndValidity();
        this.formulario.get('quantidade_produto_13')?.updateValueAndValidity();
        this.formulario.get('valor_unitario_produto_13')?.updateValueAndValidity();
        this.formulario.get('embalagem_produto_13')?.updateValueAndValidity();
        this.formulario.get('data_da_entrega_solicitada_produto_13')?.updateValueAndValidity();
        this.formulario.get('planta_de_expedicao_13')?.updateValueAndValidity();
        // this.formulario.get('centro_de_expedicao_13')?.updateValueAndValidity();


        this.formulario.get('codigo_do_produto_14')?.clearValidators()
        this.formulario.get('descricao_produto_14')?.clearValidators()
        // this.formulario.get('familia_produto_14')?.clearValidators()
        this.formulario.get('quantidade_produto_14')?.clearValidators()
        this.formulario.get('valor_unitario_produto_14')?.clearValidators()
        this.formulario.get('embalagem_produto_14')?.clearValidators()
        this.formulario.get('data_da_entrega_solicitada_produto_14')?.clearValidators()
        this.formulario.get('planta_de_expedicao_14')?.clearValidators()
        // this.formulario.get('centro_de_expedicao_14')?.clearValidators()


        this.formulario.get('codigo_do_produto_14')?.updateValueAndValidity();
        this.formulario.get('descricao_produto_14')?.updateValueAndValidity();
        // this.formulario.get('familia_produto_14')?.updateValueAndValidity();
        this.formulario.get('quantidade_produto_14')?.updateValueAndValidity();
        this.formulario.get('valor_unitario_produto_14')?.updateValueAndValidity();
        this.formulario.get('embalagem_produto_14')?.updateValueAndValidity();
        this.formulario.get('data_da_entrega_solicitada_produto_14')?.updateValueAndValidity();
        this.formulario.get('planta_de_expedicao_14')?.updateValueAndValidity();
        // this.formulario.get('centro_de_expedicao_14')?.updateValueAndValidity();


        this.formulario.get('codigo_do_produto_15')?.clearValidators()
        this.formulario.get('descricao_produto_15')?.clearValidators()
        // this.formulario.get('familia_produto_15')?.clearValidators()
        this.formulario.get('quantidade_produto_15')?.clearValidators()
        this.formulario.get('valor_unitario_produto_15')?.clearValidators()
        this.formulario.get('embalagem_produto_15')?.clearValidators()
        this.formulario.get('data_da_entrega_solicitada_produto_15')?.clearValidators()
        this.formulario.get('planta_de_expedicao_15')?.clearValidators()
        // this.formulario.get('centro_de_expedicao_15')?.clearValidators()


        this.formulario.get('codigo_do_produto_15')?.updateValueAndValidity();
        this.formulario.get('descricao_produto_15')?.updateValueAndValidity();
        // this.formulario.get('familia_produto_15')?.updateValueAndValidity();
        this.formulario.get('quantidade_produto_15')?.updateValueAndValidity();
        this.formulario.get('valor_unitario_produto_15')?.updateValueAndValidity();
        this.formulario.get('embalagem_produto_15')?.updateValueAndValidity();
        this.formulario.get('data_da_entrega_solicitada_produto_15')?.updateValueAndValidity();
        this.formulario.get('planta_de_expedicao_15')?.updateValueAndValidity();
        // this.formulario.get('centro_de_expedicao_15')?.updateValueAndValidity();


        this.formulario.get('codigo_do_produto_16')?.clearValidators()
        this.formulario.get('descricao_produto_16')?.clearValidators()
        // this.formulario.get('familia_produto_16')?.clearValidators()
        this.formulario.get('quantidade_produto_16')?.clearValidators()
        this.formulario.get('valor_unitario_produto_16')?.clearValidators()
        this.formulario.get('embalagem_produto_16')?.clearValidators()
        this.formulario.get('data_da_entrega_solicitada_produto_16')?.clearValidators()
        this.formulario.get('planta_de_expedicao_16')?.clearValidators()
        // this.formulario.get('centro_de_expedicao_16')?.clearValidators()


        this.formulario.get('codigo_do_produto_16')?.updateValueAndValidity();
        this.formulario.get('descricao_produto_16')?.updateValueAndValidity();
        // this.formulario.get('familia_produto_16')?.updateValueAndValidity();
        this.formulario.get('quantidade_produto_16')?.updateValueAndValidity();
        this.formulario.get('valor_unitario_produto_16')?.updateValueAndValidity();
        this.formulario.get('embalagem_produto_16')?.updateValueAndValidity();
        this.formulario.get('data_da_entrega_solicitada_produto_16')?.updateValueAndValidity();
        this.formulario.get('planta_de_expedicao_16')?.updateValueAndValidity();
        // this.formulario.get('centro_de_expedicao_16')?.updateValueAndValidity();


        this.formulario.get('codigo_do_produto_17')?.clearValidators()
        this.formulario.get('descricao_produto_17')?.clearValidators()
        // this.formulario.get('familia_produto_17')?.clearValidators()
        this.formulario.get('quantidade_produto_17')?.clearValidators()
        this.formulario.get('valor_unitario_produto_17')?.clearValidators()
        this.formulario.get('embalagem_produto_17')?.clearValidators()
        this.formulario.get('data_da_entrega_solicitada_produto_17')?.clearValidators()
        this.formulario.get('planta_de_expedicao_17')?.clearValidators()
        // this.formulario.get('centro_de_expedicao_17')?.clearValidators()


        this.formulario.get('codigo_do_produto_17')?.updateValueAndValidity();
        this.formulario.get('descricao_produto_17')?.updateValueAndValidity();
        // this.formulario.get('familia_produto_17')?.updateValueAndValidity();
        this.formulario.get('quantidade_produto_17')?.updateValueAndValidity();
        this.formulario.get('valor_unitario_produto_17')?.updateValueAndValidity();
        this.formulario.get('embalagem_produto_17')?.updateValueAndValidity();
        this.formulario.get('data_da_entrega_solicitada_produto_17')?.updateValueAndValidity();
        this.formulario.get('planta_de_expedicao_17')?.updateValueAndValidity();
        // this.formulario.get('centro_de_expedicao_17')?.updateValueAndValidity();


        this.formulario.get('codigo_do_produto_18')?.clearValidators()
        this.formulario.get('descricao_produto_18')?.clearValidators()
        // this.formulario.get('familia_produto_18')?.clearValidators()
        this.formulario.get('quantidade_produto_18')?.clearValidators()
        this.formulario.get('valor_unitario_produto_18')?.clearValidators()
        this.formulario.get('embalagem_produto_18')?.clearValidators()
        this.formulario.get('data_da_entrega_solicitada_produto_18')?.clearValidators()
        this.formulario.get('planta_de_expedicao_18')?.clearValidators()
        // this.formulario.get('centro_de_expedicao_18')?.clearValidators()


        this.formulario.get('codigo_do_produto_18')?.updateValueAndValidity();
        this.formulario.get('descricao_produto_18')?.updateValueAndValidity();
        // this.formulario.get('familia_produto_18')?.updateValueAndValidity();
        this.formulario.get('quantidade_produto_18')?.updateValueAndValidity();
        this.formulario.get('valor_unitario_produto_18')?.updateValueAndValidity();
        this.formulario.get('embalagem_produto_18')?.updateValueAndValidity();
        this.formulario.get('data_da_entrega_solicitada_produto_18')?.updateValueAndValidity();
        this.formulario.get('planta_de_expedicao_18')?.updateValueAndValidity();
        // this.formulario.get('centro_de_expedicao_18')?.updateValueAndValidity();


        this.formulario.get('codigo_do_produto_19')?.clearValidators()
        this.formulario.get('descricao_produto_19')?.clearValidators()
        // this.formulario.get('familia_produto_19')?.clearValidators()
        this.formulario.get('quantidade_produto_19')?.clearValidators()
        this.formulario.get('valor_unitario_produto_19')?.clearValidators()
        this.formulario.get('embalagem_produto_19')?.clearValidators()
        this.formulario.get('data_da_entrega_solicitada_produto_19')?.clearValidators()
        this.formulario.get('planta_de_expedicao_19')?.clearValidators()
        // this.formulario.get('centro_de_expedicao_19')?.clearValidators()


        this.formulario.get('codigo_do_produto_19')?.updateValueAndValidity();
        this.formulario.get('descricao_produto_19')?.updateValueAndValidity();
        // this.formulario.get('familia_produto_19')?.updateValueAndValidity();
        this.formulario.get('quantidade_produto_19')?.updateValueAndValidity();
        this.formulario.get('valor_unitario_produto_19')?.updateValueAndValidity();
        this.formulario.get('embalagem_produto_19')?.updateValueAndValidity();
        this.formulario.get('data_da_entrega_solicitada_produto_19')?.updateValueAndValidity();
        this.formulario.get('planta_de_expedicao_19')?.updateValueAndValidity();
        // this.formulario.get('centro_de_expedicao_19')?.updateValueAndValidity();


        this.formulario.get('codigo_do_produto_20')?.clearValidators()
        this.formulario.get('descricao_produto_20')?.clearValidators()
        // this.formulario.get('familia_produto_20')?.clearValidators()
        this.formulario.get('quantidade_produto_20')?.clearValidators()
        this.formulario.get('valor_unitario_produto_20')?.clearValidators()
        this.formulario.get('embalagem_produto_20')?.clearValidators()
        this.formulario.get('data_da_entrega_solicitada_produto_20')?.clearValidators()
        this.formulario.get('planta_de_expedicao_20')?.clearValidators()
        // this.formulario.get('centro_de_expedicao_20')?.clearValidators()


        this.formulario.get('codigo_do_produto_20')?.updateValueAndValidity();
        this.formulario.get('descricao_produto_20')?.updateValueAndValidity();
        // this.formulario.get('familia_produto_20')?.updateValueAndValidity();
        this.formulario.get('quantidade_produto_20')?.updateValueAndValidity();
        this.formulario.get('valor_unitario_produto_20')?.updateValueAndValidity();
        this.formulario.get('embalagem_produto_20')?.updateValueAndValidity();
        this.formulario.get('data_da_entrega_solicitada_produto_20')?.updateValueAndValidity();
        this.formulario.get('planta_de_expedicao_20')?.updateValueAndValidity();
        // this.formulario.get('centro_de_expedicao_20')?.updateValueAndValidity();

      }

    }

  }

  verificaExibicaoCondParcelamento() {
    console.log(this.formulario.getRawValue().forma_de_pagamento)
    if (
      (
        (this.formulario.getRawValue().empresa === 'Nutrien' && this.formulario.getRawValue().categoria !== '') ||
        (this.formulario.getRawValue().empresa !== 'Nutrien' && this.formulario.getRawValue().empresa !== '' && this.formulario.getRawValue().categoria === 'Fertilizantes')
      )
      && (this.formulario.getRawValue().forma_de_pagamento === 'Parcelado')
    ) {
      this.exibeCondParcelamento = true;
      this.formulario.get('condicao_de_parcelamento')?.setValidators([Validators.required]);
      this.formulario.get('condicao_de_parcelamento')?.updateValueAndValidity();
      this.formulario.get('vencimento_condicao_de_pagamento')?.setValue('');
      this.formulario.get('vencimento_condicao_de_pagamento')?.clearValidators();
      this.formulario.get('vencimento_condicao_de_pagamento')?.updateValueAndValidity();
    } else {
      this.exibeCondParcelamento = false;
      this.formulario.get('vencimento_condicao_de_pagamento')?.setValidators([Validators.required]);
      this.formulario.get('vencimento_condicao_de_pagamento')?.updateValueAndValidity();
      this.formulario.get('condicao_de_parcelamento')?.setValue('');
      this.formulario.get('condicao_de_parcelamento')?.clearValidators();
      this.formulario.get('condicao_de_parcelamento')?.updateValueAndValidity();
    }
  }

  limpaValoresProdutos(quantidadeDeItens: any) {
    switch (quantidadeDeItens) {
      // @ts-ignore
      case 1:
        this.formulario.controls['codigo_do_produto_2']?.setValue('');
        this.formulario.controls['codigo_do_produto_2'].markAsUntouched();
        this.formulario.controls['descricao_produto_2']?.setValue('');
        this.formulario.controls['descricao_produto_2'].markAsUntouched();
        this.formulario.controls['quantidade_produto_2']?.setValue('');
        this.formulario.controls['quantidade_produto_2'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_2']?.setValue('');
        this.formulario.controls['valor_unitario_produto_2'].markAsUntouched();
        this.formulario.controls['embalagem_produto_2']?.setValue('');
        this.formulario.controls['embalagem_produto_2'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_2']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_2'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_2']?.setValue('');
        this.formulario.controls['planta_de_expedicao_2'].markAsUntouched();

        this.formulario.controls['codigo_do_produto_3']?.setValue('');
        this.formulario.controls['codigo_do_produto_3'].markAsUntouched();
        this.formulario.controls['descricao_produto_3']?.setValue('');
        this.formulario.controls['descricao_produto_3'].markAsUntouched();
        this.formulario.controls['quantidade_produto_3']?.setValue('');
        this.formulario.controls['quantidade_produto_3'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_3']?.setValue('');
        this.formulario.controls['valor_unitario_produto_3'].markAsUntouched();
        this.formulario.controls['embalagem_produto_3']?.setValue('');
        this.formulario.controls['embalagem_produto_3'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_3']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_3'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_3']?.setValue('');
        this.formulario.controls['planta_de_expedicao_3'].markAsUntouched();

        this.formulario.controls['codigo_do_produto_4']?.setValue('');
        this.formulario.controls['codigo_do_produto_4'].markAsUntouched();
        this.formulario.controls['descricao_produto_4']?.setValue('');
        this.formulario.controls['descricao_produto_4'].markAsUntouched();
        this.formulario.controls['quantidade_produto_4']?.setValue('');
        this.formulario.controls['quantidade_produto_4'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_4']?.setValue('');
        this.formulario.controls['valor_unitario_produto_4'].markAsUntouched();
        this.formulario.controls['embalagem_produto_4']?.setValue('');
        this.formulario.controls['embalagem_produto_4'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_4']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_4'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_4']?.setValue('');
        this.formulario.controls['planta_de_expedicao_4'].markAsUntouched();

        this.formulario.controls['codigo_do_produto_5']?.setValue('');
        this.formulario.controls['codigo_do_produto_5'].markAsUntouched();
        this.formulario.controls['descricao_produto_5']?.setValue('');
        this.formulario.controls['descricao_produto_5'].markAsUntouched();
        this.formulario.controls['quantidade_produto_5']?.setValue('');
        this.formulario.controls['quantidade_produto_5'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_5']?.setValue('');
        this.formulario.controls['valor_unitario_produto_5'].markAsUntouched();
        this.formulario.controls['embalagem_produto_5']?.setValue('');
        this.formulario.controls['embalagem_produto_5'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_5']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_5'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_5']?.setValue('');
        this.formulario.controls['planta_de_expedicao_5'].markAsUntouched();

        this.formulario.controls['codigo_do_produto_6']?.setValue('');
        this.formulario.controls['codigo_do_produto_6'].markAsUntouched();
        this.formulario.controls['descricao_produto_6']?.setValue('');
        this.formulario.controls['descricao_produto_6'].markAsUntouched();
        this.formulario.controls['quantidade_produto_6']?.setValue('');
        this.formulario.controls['quantidade_produto_6'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_6']?.setValue('');
        this.formulario.controls['valor_unitario_produto_6'].markAsUntouched();
        this.formulario.controls['embalagem_produto_6']?.setValue('');
        this.formulario.controls['embalagem_produto_6'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_6']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_6'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_6']?.setValue('');
        this.formulario.controls['planta_de_expedicao_6'].markAsUntouched();

        this.formulario.controls['codigo_do_produto_7']?.setValue('');
        this.formulario.controls['codigo_do_produto_7'].markAsUntouched();
        this.formulario.controls['descricao_produto_7']?.setValue('');
        this.formulario.controls['descricao_produto_7'].markAsUntouched();
        this.formulario.controls['quantidade_produto_7']?.setValue('');
        this.formulario.controls['quantidade_produto_7'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_7']?.setValue('');
        this.formulario.controls['valor_unitario_produto_7'].markAsUntouched();
        this.formulario.controls['embalagem_produto_7']?.setValue('');
        this.formulario.controls['embalagem_produto_7'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_7']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_7'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_7']?.setValue('');
        this.formulario.controls['planta_de_expedicao_7'].markAsUntouched();

        this.formulario.controls['codigo_do_produto_8']?.setValue('');
        this.formulario.controls['codigo_do_produto_8'].markAsUntouched();
        this.formulario.controls['descricao_produto_8']?.setValue('');
        this.formulario.controls['descricao_produto_8'].markAsUntouched();
        this.formulario.controls['quantidade_produto_8']?.setValue('');
        this.formulario.controls['quantidade_produto_8'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_8']?.setValue('');
        this.formulario.controls['valor_unitario_produto_8'].markAsUntouched();
        this.formulario.controls['embalagem_produto_8']?.setValue('');
        this.formulario.controls['embalagem_produto_8'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_8']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_8'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_8']?.setValue('');
        this.formulario.controls['planta_de_expedicao_8'].markAsUntouched();

        this.formulario.controls['codigo_do_produto_9']?.setValue('');
        this.formulario.controls['codigo_do_produto_9'].markAsUntouched();
        this.formulario.controls['descricao_produto_9']?.setValue('');
        this.formulario.controls['descricao_produto_9'].markAsUntouched();
        this.formulario.controls['quantidade_produto_9']?.setValue('');
        this.formulario.controls['quantidade_produto_9'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_9']?.setValue('');
        this.formulario.controls['valor_unitario_produto_9'].markAsUntouched();
        this.formulario.controls['embalagem_produto_9']?.setValue('');
        this.formulario.controls['embalagem_produto_9'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_9']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_9'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_9']?.setValue('');
        this.formulario.controls['planta_de_expedicao_9'].markAsUntouched();

        this.formulario.controls['codigo_do_produto_10']?.setValue('');
        this.formulario.controls['codigo_do_produto_10'].markAsUntouched();
        this.formulario.controls['descricao_produto_10']?.setValue('');
        this.formulario.controls['descricao_produto_10'].markAsUntouched();
        this.formulario.controls['quantidade_produto_10']?.setValue('');
        this.formulario.controls['quantidade_produto_10'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_10']?.setValue('');
        this.formulario.controls['valor_unitario_produto_10'].markAsUntouched();
        this.formulario.controls['embalagem_produto_10']?.setValue('');
        this.formulario.controls['embalagem_produto_10'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_10']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_10'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_10']?.setValue('');
        this.formulario.controls['planta_de_expedicao_10'].markAsUntouched();

        this.formulario.controls['codigo_do_produto_11']?.setValue('');
        this.formulario.controls['codigo_do_produto_11'].markAsUntouched();
        this.formulario.controls['descricao_produto_11']?.setValue('');
        this.formulario.controls['descricao_produto_11'].markAsUntouched();
        this.formulario.controls['quantidade_produto_11']?.setValue('');
        this.formulario.controls['quantidade_produto_11'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_11']?.setValue('');
        this.formulario.controls['valor_unitario_produto_11'].markAsUntouched();
        this.formulario.controls['embalagem_produto_11']?.setValue('');
        this.formulario.controls['embalagem_produto_11'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_11']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_11'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_11']?.setValue('');
        this.formulario.controls['planta_de_expedicao_11'].markAsUntouched();

        this.formulario.controls['codigo_do_produto_12']?.setValue('');
        this.formulario.controls['codigo_do_produto_12'].markAsUntouched();
        this.formulario.controls['descricao_produto_12']?.setValue('');
        this.formulario.controls['descricao_produto_12'].markAsUntouched();
        this.formulario.controls['quantidade_produto_12']?.setValue('');
        this.formulario.controls['quantidade_produto_12'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_12']?.setValue('');
        this.formulario.controls['valor_unitario_produto_12'].markAsUntouched();
        this.formulario.controls['embalagem_produto_12']?.setValue('');
        this.formulario.controls['embalagem_produto_12'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_12']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_12'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_12']?.setValue('');
        this.formulario.controls['planta_de_expedicao_12'].markAsUntouched();

        this.formulario.controls['codigo_do_produto_13']?.setValue('');
        this.formulario.controls['codigo_do_produto_13'].markAsUntouched();
        this.formulario.controls['descricao_produto_13']?.setValue('');
        this.formulario.controls['descricao_produto_13'].markAsUntouched();
        this.formulario.controls['quantidade_produto_13']?.setValue('');
        this.formulario.controls['quantidade_produto_13'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_13']?.setValue('');
        this.formulario.controls['valor_unitario_produto_13'].markAsUntouched();
        this.formulario.controls['embalagem_produto_13']?.setValue('');
        this.formulario.controls['embalagem_produto_13'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_13']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_13'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_13']?.setValue('');
        this.formulario.controls['planta_de_expedicao_13'].markAsUntouched();

        this.formulario.controls['codigo_do_produto_14']?.setValue('');
        this.formulario.controls['codigo_do_produto_14'].markAsUntouched();
        this.formulario.controls['descricao_produto_14']?.setValue('');
        this.formulario.controls['descricao_produto_14'].markAsUntouched();
        this.formulario.controls['quantidade_produto_14']?.setValue('');
        this.formulario.controls['quantidade_produto_14'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_14']?.setValue('');
        this.formulario.controls['valor_unitario_produto_14'].markAsUntouched();
        this.formulario.controls['embalagem_produto_14']?.setValue('');
        this.formulario.controls['embalagem_produto_14'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_14']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_14'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_14']?.setValue('');
        this.formulario.controls['planta_de_expedicao_14'].markAsUntouched();

        this.formulario.controls['codigo_do_produto_15']?.setValue('');
        this.formulario.controls['codigo_do_produto_15'].markAsUntouched();
        this.formulario.controls['descricao_produto_15']?.setValue('');
        this.formulario.controls['descricao_produto_15'].markAsUntouched();
        this.formulario.controls['quantidade_produto_15']?.setValue('');
        this.formulario.controls['quantidade_produto_15'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_15']?.setValue('');
        this.formulario.controls['valor_unitario_produto_15'].markAsUntouched();
        this.formulario.controls['embalagem_produto_15']?.setValue('');
        this.formulario.controls['embalagem_produto_15'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_15']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_15'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_15']?.setValue('');
        this.formulario.controls['planta_de_expedicao_15'].markAsUntouched();

        this.formulario.controls['codigo_do_produto_16']?.setValue('');
        this.formulario.controls['codigo_do_produto_16'].markAsUntouched();
        this.formulario.controls['descricao_produto_16']?.setValue('');
        this.formulario.controls['descricao_produto_16'].markAsUntouched();
        this.formulario.controls['quantidade_produto_16']?.setValue('');
        this.formulario.controls['quantidade_produto_16'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_16']?.setValue('');
        this.formulario.controls['valor_unitario_produto_16'].markAsUntouched();
        this.formulario.controls['embalagem_produto_16']?.setValue('');
        this.formulario.controls['embalagem_produto_16'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_16']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_16'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_16']?.setValue('');
        this.formulario.controls['planta_de_expedicao_16'].markAsUntouched();

        this.formulario.controls['codigo_do_produto_17']?.setValue('');
        this.formulario.controls['codigo_do_produto_17'].markAsUntouched();
        this.formulario.controls['descricao_produto_17']?.setValue('');
        this.formulario.controls['descricao_produto_17'].markAsUntouched();
        this.formulario.controls['quantidade_produto_17']?.setValue('');
        this.formulario.controls['quantidade_produto_17'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_17']?.setValue('');
        this.formulario.controls['valor_unitario_produto_17'].markAsUntouched();
        this.formulario.controls['embalagem_produto_17']?.setValue('');
        this.formulario.controls['embalagem_produto_17'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_17']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_17'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_17']?.setValue('');
        this.formulario.controls['planta_de_expedicao_17'].markAsUntouched();

        this.formulario.controls['codigo_do_produto_18']?.setValue('');
        this.formulario.controls['codigo_do_produto_18'].markAsUntouched();
        this.formulario.controls['descricao_produto_18']?.setValue('');
        this.formulario.controls['descricao_produto_18'].markAsUntouched();
        this.formulario.controls['quantidade_produto_18']?.setValue('');
        this.formulario.controls['quantidade_produto_18'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_18']?.setValue('');
        this.formulario.controls['valor_unitario_produto_18'].markAsUntouched();
        this.formulario.controls['embalagem_produto_18']?.setValue('');
        this.formulario.controls['embalagem_produto_18'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_18']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_18'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_18']?.setValue('');
        this.formulario.controls['planta_de_expedicao_18'].markAsUntouched();

        this.formulario.controls['codigo_do_produto_19']?.setValue('');
        this.formulario.controls['codigo_do_produto_19'].markAsUntouched();
        this.formulario.controls['descricao_produto_19']?.setValue('');
        this.formulario.controls['descricao_produto_19'].markAsUntouched();
        this.formulario.controls['quantidade_produto_19']?.setValue('');
        this.formulario.controls['quantidade_produto_19'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_19']?.setValue('');
        this.formulario.controls['valor_unitario_produto_19'].markAsUntouched();
        this.formulario.controls['embalagem_produto_19']?.setValue('');
        this.formulario.controls['embalagem_produto_19'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_19']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_19'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_19']?.setValue('');
        this.formulario.controls['planta_de_expedicao_19'].markAsUntouched();

        this.formulario.controls['codigo_do_produto_20']?.setValue('');
        this.formulario.controls['codigo_do_produto_20'].markAsUntouched();
        this.formulario.controls['descricao_produto_20']?.setValue('');
        this.formulario.controls['descricao_produto_20'].markAsUntouched();
        this.formulario.controls['quantidade_produto_20']?.setValue('');
        this.formulario.controls['quantidade_produto_20'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_20']?.setValue('');
        this.formulario.controls['valor_unitario_produto_20'].markAsUntouched();
        this.formulario.controls['embalagem_produto_20']?.setValue('');
        this.formulario.controls['embalagem_produto_20'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_20']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_20'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_20']?.setValue('');
        this.formulario.controls['planta_de_expedicao_20'].markAsUntouched();

      case 2:
        this.formulario.controls['codigo_do_produto_3']?.setValue('');
        this.formulario.controls['codigo_do_produto_3'].markAsUntouched();
        this.formulario.controls['descricao_produto_3']?.setValue('');
        this.formulario.controls['descricao_produto_3'].markAsUntouched();
        this.formulario.controls['quantidade_produto_3']?.setValue('');
        this.formulario.controls['quantidade_produto_3'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_3']?.setValue('');
        this.formulario.controls['valor_unitario_produto_3'].markAsUntouched();
        this.formulario.controls['embalagem_produto_3']?.setValue('');
        this.formulario.controls['embalagem_produto_3'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_3']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_3'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_3']?.setValue('');
        this.formulario.controls['planta_de_expedicao_3'].markAsUntouched();

        this.formulario.controls['codigo_do_produto_4']?.setValue('');
        this.formulario.controls['codigo_do_produto_4'].markAsUntouched();
        this.formulario.controls['descricao_produto_4']?.setValue('');
        this.formulario.controls['descricao_produto_4'].markAsUntouched();
        this.formulario.controls['quantidade_produto_4']?.setValue('');
        this.formulario.controls['quantidade_produto_4'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_4']?.setValue('');
        this.formulario.controls['valor_unitario_produto_4'].markAsUntouched();
        this.formulario.controls['embalagem_produto_4']?.setValue('');
        this.formulario.controls['embalagem_produto_4'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_4']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_4'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_4']?.setValue('');
        this.formulario.controls['planta_de_expedicao_4'].markAsUntouched();

        this.formulario.controls['codigo_do_produto_5']?.setValue('');
        this.formulario.controls['codigo_do_produto_5'].markAsUntouched();
        this.formulario.controls['descricao_produto_5']?.setValue('');
        this.formulario.controls['descricao_produto_5'].markAsUntouched();
        this.formulario.controls['quantidade_produto_5']?.setValue('');
        this.formulario.controls['quantidade_produto_5'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_5']?.setValue('');
        this.formulario.controls['valor_unitario_produto_5'].markAsUntouched();
        this.formulario.controls['embalagem_produto_5']?.setValue('');
        this.formulario.controls['embalagem_produto_5'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_5']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_5'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_5']?.setValue('');
        this.formulario.controls['planta_de_expedicao_5'].markAsUntouched();

        this.formulario.controls['codigo_do_produto_6']?.setValue('');
        this.formulario.controls['codigo_do_produto_6'].markAsUntouched();
        this.formulario.controls['descricao_produto_6']?.setValue('');
        this.formulario.controls['descricao_produto_6'].markAsUntouched();
        this.formulario.controls['quantidade_produto_6']?.setValue('');
        this.formulario.controls['quantidade_produto_6'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_6']?.setValue('');
        this.formulario.controls['valor_unitario_produto_6'].markAsUntouched();
        this.formulario.controls['embalagem_produto_6']?.setValue('');
        this.formulario.controls['embalagem_produto_6'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_6']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_6'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_6']?.setValue('');
        this.formulario.controls['planta_de_expedicao_6'].markAsUntouched();

        this.formulario.controls['codigo_do_produto_7']?.setValue('');
        this.formulario.controls['codigo_do_produto_7'].markAsUntouched();
        this.formulario.controls['descricao_produto_7']?.setValue('');
        this.formulario.controls['descricao_produto_7'].markAsUntouched();
        this.formulario.controls['quantidade_produto_7']?.setValue('');
        this.formulario.controls['quantidade_produto_7'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_7']?.setValue('');
        this.formulario.controls['valor_unitario_produto_7'].markAsUntouched();
        this.formulario.controls['embalagem_produto_7']?.setValue('');
        this.formulario.controls['embalagem_produto_7'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_7']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_7'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_7']?.setValue('');
        this.formulario.controls['planta_de_expedicao_7'].markAsUntouched();

        this.formulario.controls['codigo_do_produto_8']?.setValue('');
        this.formulario.controls['codigo_do_produto_8'].markAsUntouched();
        this.formulario.controls['descricao_produto_8']?.setValue('');
        this.formulario.controls['descricao_produto_8'].markAsUntouched();
        this.formulario.controls['quantidade_produto_8']?.setValue('');
        this.formulario.controls['quantidade_produto_8'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_8']?.setValue('');
        this.formulario.controls['valor_unitario_produto_8'].markAsUntouched();
        this.formulario.controls['embalagem_produto_8']?.setValue('');
        this.formulario.controls['embalagem_produto_8'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_8']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_8'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_8']?.setValue('');
        this.formulario.controls['planta_de_expedicao_8'].markAsUntouched();

        this.formulario.controls['codigo_do_produto_9']?.setValue('');
        this.formulario.controls['codigo_do_produto_9'].markAsUntouched();
        this.formulario.controls['descricao_produto_9']?.setValue('');
        this.formulario.controls['descricao_produto_9'].markAsUntouched();
        this.formulario.controls['quantidade_produto_9']?.setValue('');
        this.formulario.controls['quantidade_produto_9'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_9']?.setValue('');
        this.formulario.controls['valor_unitario_produto_9'].markAsUntouched();
        this.formulario.controls['embalagem_produto_9']?.setValue('');
        this.formulario.controls['embalagem_produto_9'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_9']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_9'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_9']?.setValue('');
        this.formulario.controls['planta_de_expedicao_9'].markAsUntouched();

        this.formulario.controls['codigo_do_produto_10']?.setValue('');
        this.formulario.controls['codigo_do_produto_10'].markAsUntouched();
        this.formulario.controls['descricao_produto_10']?.setValue('');
        this.formulario.controls['descricao_produto_10'].markAsUntouched();
        this.formulario.controls['quantidade_produto_10']?.setValue('');
        this.formulario.controls['quantidade_produto_10'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_10']?.setValue('');
        this.formulario.controls['valor_unitario_produto_10'].markAsUntouched();
        this.formulario.controls['embalagem_produto_10']?.setValue('');
        this.formulario.controls['embalagem_produto_10'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_10']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_10'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_10']?.setValue('');
        this.formulario.controls['planta_de_expedicao_10'].markAsUntouched();

        this.formulario.controls['codigo_do_produto_11']?.setValue('');
        this.formulario.controls['codigo_do_produto_11'].markAsUntouched();
        this.formulario.controls['descricao_produto_11']?.setValue('');
        this.formulario.controls['descricao_produto_11'].markAsUntouched();
        this.formulario.controls['quantidade_produto_11']?.setValue('');
        this.formulario.controls['quantidade_produto_11'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_11']?.setValue('');
        this.formulario.controls['valor_unitario_produto_11'].markAsUntouched();
        this.formulario.controls['embalagem_produto_11']?.setValue('');
        this.formulario.controls['embalagem_produto_11'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_11']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_11'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_11']?.setValue('');
        this.formulario.controls['planta_de_expedicao_11'].markAsUntouched();

        this.formulario.controls['codigo_do_produto_12']?.setValue('');
        this.formulario.controls['codigo_do_produto_12'].markAsUntouched();
        this.formulario.controls['descricao_produto_12']?.setValue('');
        this.formulario.controls['descricao_produto_12'].markAsUntouched();
        this.formulario.controls['quantidade_produto_12']?.setValue('');
        this.formulario.controls['quantidade_produto_12'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_12']?.setValue('');
        this.formulario.controls['valor_unitario_produto_12'].markAsUntouched();
        this.formulario.controls['embalagem_produto_12']?.setValue('');
        this.formulario.controls['embalagem_produto_12'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_12']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_12'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_12']?.setValue('');
        this.formulario.controls['planta_de_expedicao_12'].markAsUntouched();

        this.formulario.controls['codigo_do_produto_13']?.setValue('');
        this.formulario.controls['codigo_do_produto_13'].markAsUntouched();
        this.formulario.controls['descricao_produto_13']?.setValue('');
        this.formulario.controls['descricao_produto_13'].markAsUntouched();
        this.formulario.controls['quantidade_produto_13']?.setValue('');
        this.formulario.controls['quantidade_produto_13'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_13']?.setValue('');
        this.formulario.controls['valor_unitario_produto_13'].markAsUntouched();
        this.formulario.controls['embalagem_produto_13']?.setValue('');
        this.formulario.controls['embalagem_produto_13'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_13']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_13'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_13']?.setValue('');
        this.formulario.controls['planta_de_expedicao_13'].markAsUntouched();

        this.formulario.controls['codigo_do_produto_14']?.setValue('');
        this.formulario.controls['codigo_do_produto_14'].markAsUntouched();
        this.formulario.controls['descricao_produto_14']?.setValue('');
        this.formulario.controls['descricao_produto_14'].markAsUntouched();
        this.formulario.controls['quantidade_produto_14']?.setValue('');
        this.formulario.controls['quantidade_produto_14'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_14']?.setValue('');
        this.formulario.controls['valor_unitario_produto_14'].markAsUntouched();
        this.formulario.controls['embalagem_produto_14']?.setValue('');
        this.formulario.controls['embalagem_produto_14'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_14']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_14'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_14']?.setValue('');
        this.formulario.controls['planta_de_expedicao_14'].markAsUntouched();

        this.formulario.controls['codigo_do_produto_15']?.setValue('');
        this.formulario.controls['codigo_do_produto_15'].markAsUntouched();
        this.formulario.controls['descricao_produto_15']?.setValue('');
        this.formulario.controls['descricao_produto_15'].markAsUntouched();
        this.formulario.controls['quantidade_produto_15']?.setValue('');
        this.formulario.controls['quantidade_produto_15'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_15']?.setValue('');
        this.formulario.controls['valor_unitario_produto_15'].markAsUntouched();
        this.formulario.controls['embalagem_produto_15']?.setValue('');
        this.formulario.controls['embalagem_produto_15'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_15']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_15'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_15']?.setValue('');
        this.formulario.controls['planta_de_expedicao_15'].markAsUntouched();

        this.formulario.controls['codigo_do_produto_16']?.setValue('');
        this.formulario.controls['codigo_do_produto_16'].markAsUntouched();
        this.formulario.controls['descricao_produto_16']?.setValue('');
        this.formulario.controls['descricao_produto_16'].markAsUntouched();
        this.formulario.controls['quantidade_produto_16']?.setValue('');
        this.formulario.controls['quantidade_produto_16'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_16']?.setValue('');
        this.formulario.controls['valor_unitario_produto_16'].markAsUntouched();
        this.formulario.controls['embalagem_produto_16']?.setValue('');
        this.formulario.controls['embalagem_produto_16'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_16']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_16'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_16']?.setValue('');
        this.formulario.controls['planta_de_expedicao_16'].markAsUntouched();

        this.formulario.controls['codigo_do_produto_17']?.setValue('');
        this.formulario.controls['codigo_do_produto_17'].markAsUntouched();
        this.formulario.controls['descricao_produto_17']?.setValue('');
        this.formulario.controls['descricao_produto_17'].markAsUntouched();
        this.formulario.controls['quantidade_produto_17']?.setValue('');
        this.formulario.controls['quantidade_produto_17'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_17']?.setValue('');
        this.formulario.controls['valor_unitario_produto_17'].markAsUntouched();
        this.formulario.controls['embalagem_produto_17']?.setValue('');
        this.formulario.controls['embalagem_produto_17'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_17']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_17'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_17']?.setValue('');
        this.formulario.controls['planta_de_expedicao_17'].markAsUntouched();

        this.formulario.controls['codigo_do_produto_18']?.setValue('');
        this.formulario.controls['codigo_do_produto_18'].markAsUntouched();
        this.formulario.controls['descricao_produto_18']?.setValue('');
        this.formulario.controls['descricao_produto_18'].markAsUntouched();
        this.formulario.controls['quantidade_produto_18']?.setValue('');
        this.formulario.controls['quantidade_produto_18'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_18']?.setValue('');
        this.formulario.controls['valor_unitario_produto_18'].markAsUntouched();
        this.formulario.controls['embalagem_produto_18']?.setValue('');
        this.formulario.controls['embalagem_produto_18'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_18']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_18'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_18']?.setValue('');
        this.formulario.controls['planta_de_expedicao_18'].markAsUntouched();

        this.formulario.controls['codigo_do_produto_19']?.setValue('');
        this.formulario.controls['codigo_do_produto_19'].markAsUntouched();
        this.formulario.controls['descricao_produto_19']?.setValue('');
        this.formulario.controls['descricao_produto_19'].markAsUntouched();
        this.formulario.controls['quantidade_produto_19']?.setValue('');
        this.formulario.controls['quantidade_produto_19'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_19']?.setValue('');
        this.formulario.controls['valor_unitario_produto_19'].markAsUntouched();
        this.formulario.controls['embalagem_produto_19']?.setValue('');
        this.formulario.controls['embalagem_produto_19'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_19']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_19'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_19']?.setValue('');
        this.formulario.controls['planta_de_expedicao_19'].markAsUntouched();

        this.formulario.controls['codigo_do_produto_20']?.setValue('');
        this.formulario.controls['codigo_do_produto_20'].markAsUntouched();
        this.formulario.controls['descricao_produto_20']?.setValue('');
        this.formulario.controls['descricao_produto_20'].markAsUntouched();
        this.formulario.controls['quantidade_produto_20']?.setValue('');
        this.formulario.controls['quantidade_produto_20'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_20']?.setValue('');
        this.formulario.controls['valor_unitario_produto_20'].markAsUntouched();
        this.formulario.controls['embalagem_produto_20']?.setValue('');
        this.formulario.controls['embalagem_produto_20'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_20']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_20'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_20']?.setValue('');
        this.formulario.controls['planta_de_expedicao_20'].markAsUntouched();
        break;

      case 3:
        this.formulario.controls['codigo_do_produto_4']?.setValue('');
        this.formulario.controls['codigo_do_produto_4'].markAsUntouched();
        this.formulario.controls['descricao_produto_4']?.setValue('');
        this.formulario.controls['descricao_produto_4'].markAsUntouched();
        this.formulario.controls['quantidade_produto_4']?.setValue('');
        this.formulario.controls['quantidade_produto_4'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_4']?.setValue('');
        this.formulario.controls['valor_unitario_produto_4'].markAsUntouched();
        this.formulario.controls['embalagem_produto_4']?.setValue('');
        this.formulario.controls['embalagem_produto_4'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_4']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_4'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_4']?.setValue('');
        this.formulario.controls['planta_de_expedicao_4'].markAsUntouched();

        this.formulario.controls['codigo_do_produto_5']?.setValue('');
        this.formulario.controls['codigo_do_produto_5'].markAsUntouched();
        this.formulario.controls['descricao_produto_5']?.setValue('');
        this.formulario.controls['descricao_produto_5'].markAsUntouched();
        this.formulario.controls['quantidade_produto_5']?.setValue('');
        this.formulario.controls['quantidade_produto_5'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_5']?.setValue('');
        this.formulario.controls['valor_unitario_produto_5'].markAsUntouched();
        this.formulario.controls['embalagem_produto_5']?.setValue('');
        this.formulario.controls['embalagem_produto_5'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_5']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_5'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_5']?.setValue('');
        this.formulario.controls['planta_de_expedicao_5'].markAsUntouched();

        this.formulario.controls['codigo_do_produto_6']?.setValue('');
        this.formulario.controls['codigo_do_produto_6'].markAsUntouched();
        this.formulario.controls['descricao_produto_6']?.setValue('');
        this.formulario.controls['descricao_produto_6'].markAsUntouched();
        this.formulario.controls['quantidade_produto_6']?.setValue('');
        this.formulario.controls['quantidade_produto_6'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_6']?.setValue('');
        this.formulario.controls['valor_unitario_produto_6'].markAsUntouched();
        this.formulario.controls['embalagem_produto_6']?.setValue('');
        this.formulario.controls['embalagem_produto_6'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_6']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_6'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_6']?.setValue('');
        this.formulario.controls['planta_de_expedicao_6'].markAsUntouched();

        this.formulario.controls['codigo_do_produto_7']?.setValue('');
        this.formulario.controls['codigo_do_produto_7'].markAsUntouched();
        this.formulario.controls['descricao_produto_7']?.setValue('');
        this.formulario.controls['descricao_produto_7'].markAsUntouched();
        this.formulario.controls['quantidade_produto_7']?.setValue('');
        this.formulario.controls['quantidade_produto_7'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_7']?.setValue('');
        this.formulario.controls['valor_unitario_produto_7'].markAsUntouched();
        this.formulario.controls['embalagem_produto_7']?.setValue('');
        this.formulario.controls['embalagem_produto_7'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_7']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_7'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_7']?.setValue('');
        this.formulario.controls['planta_de_expedicao_7'].markAsUntouched();

        this.formulario.controls['codigo_do_produto_8']?.setValue('');
        this.formulario.controls['codigo_do_produto_8'].markAsUntouched();
        this.formulario.controls['descricao_produto_8']?.setValue('');
        this.formulario.controls['descricao_produto_8'].markAsUntouched();
        this.formulario.controls['quantidade_produto_8']?.setValue('');
        this.formulario.controls['quantidade_produto_8'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_8']?.setValue('');
        this.formulario.controls['valor_unitario_produto_8'].markAsUntouched();
        this.formulario.controls['embalagem_produto_8']?.setValue('');
        this.formulario.controls['embalagem_produto_8'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_8']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_8'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_8']?.setValue('');
        this.formulario.controls['planta_de_expedicao_8'].markAsUntouched();

        this.formulario.controls['codigo_do_produto_9']?.setValue('');
        this.formulario.controls['codigo_do_produto_9'].markAsUntouched();
        this.formulario.controls['descricao_produto_9']?.setValue('');
        this.formulario.controls['descricao_produto_9'].markAsUntouched();
        this.formulario.controls['quantidade_produto_9']?.setValue('');
        this.formulario.controls['quantidade_produto_9'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_9']?.setValue('');
        this.formulario.controls['valor_unitario_produto_9'].markAsUntouched();
        this.formulario.controls['embalagem_produto_9']?.setValue('');
        this.formulario.controls['embalagem_produto_9'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_9']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_9'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_9']?.setValue('');
        this.formulario.controls['planta_de_expedicao_9'].markAsUntouched();

        this.formulario.controls['codigo_do_produto_10']?.setValue('');
        this.formulario.controls['codigo_do_produto_10'].markAsUntouched();
        this.formulario.controls['descricao_produto_10']?.setValue('');
        this.formulario.controls['descricao_produto_10'].markAsUntouched();
        this.formulario.controls['quantidade_produto_10']?.setValue('');
        this.formulario.controls['quantidade_produto_10'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_10']?.setValue('');
        this.formulario.controls['valor_unitario_produto_10'].markAsUntouched();
        this.formulario.controls['embalagem_produto_10']?.setValue('');
        this.formulario.controls['embalagem_produto_10'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_10']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_10'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_10']?.setValue('');
        this.formulario.controls['planta_de_expedicao_10'].markAsUntouched();

        this.formulario.controls['codigo_do_produto_11']?.setValue('');
        this.formulario.controls['codigo_do_produto_11'].markAsUntouched();
        this.formulario.controls['descricao_produto_11']?.setValue('');
        this.formulario.controls['descricao_produto_11'].markAsUntouched();
        this.formulario.controls['quantidade_produto_11']?.setValue('');
        this.formulario.controls['quantidade_produto_11'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_11']?.setValue('');
        this.formulario.controls['valor_unitario_produto_11'].markAsUntouched();
        this.formulario.controls['embalagem_produto_11']?.setValue('');
        this.formulario.controls['embalagem_produto_11'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_11']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_11'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_11']?.setValue('');
        this.formulario.controls['planta_de_expedicao_11'].markAsUntouched();

        this.formulario.controls['codigo_do_produto_12']?.setValue('');
        this.formulario.controls['codigo_do_produto_12'].markAsUntouched();
        this.formulario.controls['descricao_produto_12']?.setValue('');
        this.formulario.controls['descricao_produto_12'].markAsUntouched();
        this.formulario.controls['quantidade_produto_12']?.setValue('');
        this.formulario.controls['quantidade_produto_12'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_12']?.setValue('');
        this.formulario.controls['valor_unitario_produto_12'].markAsUntouched();
        this.formulario.controls['embalagem_produto_12']?.setValue('');
        this.formulario.controls['embalagem_produto_12'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_12']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_12'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_12']?.setValue('');
        this.formulario.controls['planta_de_expedicao_12'].markAsUntouched();

        this.formulario.controls['codigo_do_produto_13']?.setValue('');
        this.formulario.controls['codigo_do_produto_13'].markAsUntouched();
        this.formulario.controls['descricao_produto_13']?.setValue('');
        this.formulario.controls['descricao_produto_13'].markAsUntouched();
        this.formulario.controls['quantidade_produto_13']?.setValue('');
        this.formulario.controls['quantidade_produto_13'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_13']?.setValue('');
        this.formulario.controls['valor_unitario_produto_13'].markAsUntouched();
        this.formulario.controls['embalagem_produto_13']?.setValue('');
        this.formulario.controls['embalagem_produto_13'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_13']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_13'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_13']?.setValue('');
        this.formulario.controls['planta_de_expedicao_13'].markAsUntouched();

        this.formulario.controls['codigo_do_produto_14']?.setValue('');
        this.formulario.controls['codigo_do_produto_14'].markAsUntouched();
        this.formulario.controls['descricao_produto_14']?.setValue('');
        this.formulario.controls['descricao_produto_14'].markAsUntouched();
        this.formulario.controls['quantidade_produto_14']?.setValue('');
        this.formulario.controls['quantidade_produto_14'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_14']?.setValue('');
        this.formulario.controls['valor_unitario_produto_14'].markAsUntouched();
        this.formulario.controls['embalagem_produto_14']?.setValue('');
        this.formulario.controls['embalagem_produto_14'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_14']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_14'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_14']?.setValue('');
        this.formulario.controls['planta_de_expedicao_14'].markAsUntouched();

        this.formulario.controls['codigo_do_produto_15']?.setValue('');
        this.formulario.controls['codigo_do_produto_15'].markAsUntouched();
        this.formulario.controls['descricao_produto_15']?.setValue('');
        this.formulario.controls['descricao_produto_15'].markAsUntouched();
        this.formulario.controls['quantidade_produto_15']?.setValue('');
        this.formulario.controls['quantidade_produto_15'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_15']?.setValue('');
        this.formulario.controls['valor_unitario_produto_15'].markAsUntouched();
        this.formulario.controls['embalagem_produto_15']?.setValue('');
        this.formulario.controls['embalagem_produto_15'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_15']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_15'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_15']?.setValue('');
        this.formulario.controls['planta_de_expedicao_15'].markAsUntouched();

        this.formulario.controls['codigo_do_produto_16']?.setValue('');
        this.formulario.controls['codigo_do_produto_16'].markAsUntouched();
        this.formulario.controls['descricao_produto_16']?.setValue('');
        this.formulario.controls['descricao_produto_16'].markAsUntouched();
        this.formulario.controls['quantidade_produto_16']?.setValue('');
        this.formulario.controls['quantidade_produto_16'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_16']?.setValue('');
        this.formulario.controls['valor_unitario_produto_16'].markAsUntouched();
        this.formulario.controls['embalagem_produto_16']?.setValue('');
        this.formulario.controls['embalagem_produto_16'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_16']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_16'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_16']?.setValue('');
        this.formulario.controls['planta_de_expedicao_16'].markAsUntouched();

        this.formulario.controls['codigo_do_produto_17']?.setValue('');
        this.formulario.controls['codigo_do_produto_17'].markAsUntouched();
        this.formulario.controls['descricao_produto_17']?.setValue('');
        this.formulario.controls['descricao_produto_17'].markAsUntouched();
        this.formulario.controls['quantidade_produto_17']?.setValue('');
        this.formulario.controls['quantidade_produto_17'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_17']?.setValue('');
        this.formulario.controls['valor_unitario_produto_17'].markAsUntouched();
        this.formulario.controls['embalagem_produto_17']?.setValue('');
        this.formulario.controls['embalagem_produto_17'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_17']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_17'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_17']?.setValue('');
        this.formulario.controls['planta_de_expedicao_17'].markAsUntouched();

        this.formulario.controls['codigo_do_produto_18']?.setValue('');
        this.formulario.controls['codigo_do_produto_18'].markAsUntouched();
        this.formulario.controls['descricao_produto_18']?.setValue('');
        this.formulario.controls['descricao_produto_18'].markAsUntouched();
        this.formulario.controls['quantidade_produto_18']?.setValue('');
        this.formulario.controls['quantidade_produto_18'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_18']?.setValue('');
        this.formulario.controls['valor_unitario_produto_18'].markAsUntouched();
        this.formulario.controls['embalagem_produto_18']?.setValue('');
        this.formulario.controls['embalagem_produto_18'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_18']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_18'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_18']?.setValue('');
        this.formulario.controls['planta_de_expedicao_18'].markAsUntouched();

        this.formulario.controls['codigo_do_produto_19']?.setValue('');
        this.formulario.controls['codigo_do_produto_19'].markAsUntouched();
        this.formulario.controls['descricao_produto_19']?.setValue('');
        this.formulario.controls['descricao_produto_19'].markAsUntouched();
        this.formulario.controls['quantidade_produto_19']?.setValue('');
        this.formulario.controls['quantidade_produto_19'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_19']?.setValue('');
        this.formulario.controls['valor_unitario_produto_19'].markAsUntouched();
        this.formulario.controls['embalagem_produto_19']?.setValue('');
        this.formulario.controls['embalagem_produto_19'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_19']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_19'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_19']?.setValue('');
        this.formulario.controls['planta_de_expedicao_19'].markAsUntouched();

        this.formulario.controls['codigo_do_produto_20']?.setValue('');
        this.formulario.controls['codigo_do_produto_20'].markAsUntouched();
        this.formulario.controls['descricao_produto_20']?.setValue('');
        this.formulario.controls['descricao_produto_20'].markAsUntouched();
        this.formulario.controls['quantidade_produto_20']?.setValue('');
        this.formulario.controls['quantidade_produto_20'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_20']?.setValue('');
        this.formulario.controls['valor_unitario_produto_20'].markAsUntouched();
        this.formulario.controls['embalagem_produto_20']?.setValue('');
        this.formulario.controls['embalagem_produto_20'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_20']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_20'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_20']?.setValue('');
        this.formulario.controls['planta_de_expedicao_20'].markAsUntouched();
        break;

      case 4:
        this.formulario.controls['codigo_do_produto_5']?.setValue('');
        this.formulario.controls['codigo_do_produto_5'].markAsUntouched();
        this.formulario.controls['descricao_produto_5']?.setValue('');
        this.formulario.controls['descricao_produto_5'].markAsUntouched();
        this.formulario.controls['quantidade_produto_5']?.setValue('');
        this.formulario.controls['quantidade_produto_5'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_5']?.setValue('');
        this.formulario.controls['valor_unitario_produto_5'].markAsUntouched();
        this.formulario.controls['embalagem_produto_5']?.setValue('');
        this.formulario.controls['embalagem_produto_5'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_5']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_5'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_5']?.setValue('');
        this.formulario.controls['planta_de_expedicao_5'].markAsUntouched();

        this.formulario.controls['codigo_do_produto_6']?.setValue('');
        this.formulario.controls['codigo_do_produto_6'].markAsUntouched();
        this.formulario.controls['descricao_produto_6']?.setValue('');
        this.formulario.controls['descricao_produto_6'].markAsUntouched();
        this.formulario.controls['quantidade_produto_6']?.setValue('');
        this.formulario.controls['quantidade_produto_6'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_6']?.setValue('');
        this.formulario.controls['valor_unitario_produto_6'].markAsUntouched();
        this.formulario.controls['embalagem_produto_6']?.setValue('');
        this.formulario.controls['embalagem_produto_6'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_6']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_6'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_6']?.setValue('');
        this.formulario.controls['planta_de_expedicao_6'].markAsUntouched();

        this.formulario.controls['codigo_do_produto_7']?.setValue('');
        this.formulario.controls['codigo_do_produto_7'].markAsUntouched();
        this.formulario.controls['descricao_produto_7']?.setValue('');
        this.formulario.controls['descricao_produto_7'].markAsUntouched();
        this.formulario.controls['quantidade_produto_7']?.setValue('');
        this.formulario.controls['quantidade_produto_7'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_7']?.setValue('');
        this.formulario.controls['valor_unitario_produto_7'].markAsUntouched();
        this.formulario.controls['embalagem_produto_7']?.setValue('');
        this.formulario.controls['embalagem_produto_7'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_7']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_7'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_7']?.setValue('');
        this.formulario.controls['planta_de_expedicao_7'].markAsUntouched();

        this.formulario.controls['codigo_do_produto_8']?.setValue('');
        this.formulario.controls['codigo_do_produto_8'].markAsUntouched();
        this.formulario.controls['descricao_produto_8']?.setValue('');
        this.formulario.controls['descricao_produto_8'].markAsUntouched();
        this.formulario.controls['quantidade_produto_8']?.setValue('');
        this.formulario.controls['quantidade_produto_8'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_8']?.setValue('');
        this.formulario.controls['valor_unitario_produto_8'].markAsUntouched();
        this.formulario.controls['embalagem_produto_8']?.setValue('');
        this.formulario.controls['embalagem_produto_8'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_8']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_8'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_8']?.setValue('');
        this.formulario.controls['planta_de_expedicao_8'].markAsUntouched();

        this.formulario.controls['codigo_do_produto_9']?.setValue('');
        this.formulario.controls['codigo_do_produto_9'].markAsUntouched();
        this.formulario.controls['descricao_produto_9']?.setValue('');
        this.formulario.controls['descricao_produto_9'].markAsUntouched();
        this.formulario.controls['quantidade_produto_9']?.setValue('');
        this.formulario.controls['quantidade_produto_9'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_9']?.setValue('');
        this.formulario.controls['valor_unitario_produto_9'].markAsUntouched();
        this.formulario.controls['embalagem_produto_9']?.setValue('');
        this.formulario.controls['embalagem_produto_9'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_9']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_9'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_9']?.setValue('');
        this.formulario.controls['planta_de_expedicao_9'].markAsUntouched();

        this.formulario.controls['codigo_do_produto_10']?.setValue('');
        this.formulario.controls['codigo_do_produto_10'].markAsUntouched();
        this.formulario.controls['descricao_produto_10']?.setValue('');
        this.formulario.controls['descricao_produto_10'].markAsUntouched();
        this.formulario.controls['quantidade_produto_10']?.setValue('');
        this.formulario.controls['quantidade_produto_10'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_10']?.setValue('');
        this.formulario.controls['valor_unitario_produto_10'].markAsUntouched();
        this.formulario.controls['embalagem_produto_10']?.setValue('');
        this.formulario.controls['embalagem_produto_10'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_10']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_10'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_10']?.setValue('');
        this.formulario.controls['planta_de_expedicao_10'].markAsUntouched();

        this.formulario.controls['codigo_do_produto_11']?.setValue('');
        this.formulario.controls['codigo_do_produto_11'].markAsUntouched();
        this.formulario.controls['descricao_produto_11']?.setValue('');
        this.formulario.controls['descricao_produto_11'].markAsUntouched();
        this.formulario.controls['quantidade_produto_11']?.setValue('');
        this.formulario.controls['quantidade_produto_11'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_11']?.setValue('');
        this.formulario.controls['valor_unitario_produto_11'].markAsUntouched();
        this.formulario.controls['embalagem_produto_11']?.setValue('');
        this.formulario.controls['embalagem_produto_11'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_11']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_11'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_11']?.setValue('');
        this.formulario.controls['planta_de_expedicao_11'].markAsUntouched();

        this.formulario.controls['codigo_do_produto_12']?.setValue('');
        this.formulario.controls['codigo_do_produto_12'].markAsUntouched();
        this.formulario.controls['descricao_produto_12']?.setValue('');
        this.formulario.controls['descricao_produto_12'].markAsUntouched();
        this.formulario.controls['quantidade_produto_12']?.setValue('');
        this.formulario.controls['quantidade_produto_12'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_12']?.setValue('');
        this.formulario.controls['valor_unitario_produto_12'].markAsUntouched();
        this.formulario.controls['embalagem_produto_12']?.setValue('');
        this.formulario.controls['embalagem_produto_12'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_12']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_12'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_12']?.setValue('');
        this.formulario.controls['planta_de_expedicao_12'].markAsUntouched();

        this.formulario.controls['codigo_do_produto_13']?.setValue('');
        this.formulario.controls['codigo_do_produto_13'].markAsUntouched();
        this.formulario.controls['descricao_produto_13']?.setValue('');
        this.formulario.controls['descricao_produto_13'].markAsUntouched();
        this.formulario.controls['quantidade_produto_13']?.setValue('');
        this.formulario.controls['quantidade_produto_13'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_13']?.setValue('');
        this.formulario.controls['valor_unitario_produto_13'].markAsUntouched();
        this.formulario.controls['embalagem_produto_13']?.setValue('');
        this.formulario.controls['embalagem_produto_13'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_13']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_13'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_13']?.setValue('');
        this.formulario.controls['planta_de_expedicao_13'].markAsUntouched();

        this.formulario.controls['codigo_do_produto_14']?.setValue('');
        this.formulario.controls['codigo_do_produto_14'].markAsUntouched();
        this.formulario.controls['descricao_produto_14']?.setValue('');
        this.formulario.controls['descricao_produto_14'].markAsUntouched();
        this.formulario.controls['quantidade_produto_14']?.setValue('');
        this.formulario.controls['quantidade_produto_14'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_14']?.setValue('');
        this.formulario.controls['valor_unitario_produto_14'].markAsUntouched();
        this.formulario.controls['embalagem_produto_14']?.setValue('');
        this.formulario.controls['embalagem_produto_14'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_14']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_14'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_14']?.setValue('');
        this.formulario.controls['planta_de_expedicao_14'].markAsUntouched();

        this.formulario.controls['codigo_do_produto_15']?.setValue('');
        this.formulario.controls['codigo_do_produto_15'].markAsUntouched();
        this.formulario.controls['descricao_produto_15']?.setValue('');
        this.formulario.controls['descricao_produto_15'].markAsUntouched();
        this.formulario.controls['quantidade_produto_15']?.setValue('');
        this.formulario.controls['quantidade_produto_15'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_15']?.setValue('');
        this.formulario.controls['valor_unitario_produto_15'].markAsUntouched();
        this.formulario.controls['embalagem_produto_15']?.setValue('');
        this.formulario.controls['embalagem_produto_15'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_15']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_15'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_15']?.setValue('');
        this.formulario.controls['planta_de_expedicao_15'].markAsUntouched();

        this.formulario.controls['codigo_do_produto_16']?.setValue('');
        this.formulario.controls['codigo_do_produto_16'].markAsUntouched();
        this.formulario.controls['descricao_produto_16']?.setValue('');
        this.formulario.controls['descricao_produto_16'].markAsUntouched();
        this.formulario.controls['quantidade_produto_16']?.setValue('');
        this.formulario.controls['quantidade_produto_16'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_16']?.setValue('');
        this.formulario.controls['valor_unitario_produto_16'].markAsUntouched();
        this.formulario.controls['embalagem_produto_16']?.setValue('');
        this.formulario.controls['embalagem_produto_16'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_16']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_16'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_16']?.setValue('');
        this.formulario.controls['planta_de_expedicao_16'].markAsUntouched();

        this.formulario.controls['codigo_do_produto_17']?.setValue('');
        this.formulario.controls['codigo_do_produto_17'].markAsUntouched();
        this.formulario.controls['descricao_produto_17']?.setValue('');
        this.formulario.controls['descricao_produto_17'].markAsUntouched();
        this.formulario.controls['quantidade_produto_17']?.setValue('');
        this.formulario.controls['quantidade_produto_17'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_17']?.setValue('');
        this.formulario.controls['valor_unitario_produto_17'].markAsUntouched();
        this.formulario.controls['embalagem_produto_17']?.setValue('');
        this.formulario.controls['embalagem_produto_17'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_17']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_17'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_17']?.setValue('');
        this.formulario.controls['planta_de_expedicao_17'].markAsUntouched();

        this.formulario.controls['codigo_do_produto_18']?.setValue('');
        this.formulario.controls['codigo_do_produto_18'].markAsUntouched();
        this.formulario.controls['descricao_produto_18']?.setValue('');
        this.formulario.controls['descricao_produto_18'].markAsUntouched();
        this.formulario.controls['quantidade_produto_18']?.setValue('');
        this.formulario.controls['quantidade_produto_18'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_18']?.setValue('');
        this.formulario.controls['valor_unitario_produto_18'].markAsUntouched();
        this.formulario.controls['embalagem_produto_18']?.setValue('');
        this.formulario.controls['embalagem_produto_18'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_18']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_18'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_18']?.setValue('');
        this.formulario.controls['planta_de_expedicao_18'].markAsUntouched();

        this.formulario.controls['codigo_do_produto_19']?.setValue('');
        this.formulario.controls['codigo_do_produto_19'].markAsUntouched();
        this.formulario.controls['descricao_produto_19']?.setValue('');
        this.formulario.controls['descricao_produto_19'].markAsUntouched();
        this.formulario.controls['quantidade_produto_19']?.setValue('');
        this.formulario.controls['quantidade_produto_19'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_19']?.setValue('');
        this.formulario.controls['valor_unitario_produto_19'].markAsUntouched();
        this.formulario.controls['embalagem_produto_19']?.setValue('');
        this.formulario.controls['embalagem_produto_19'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_19']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_19'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_19']?.setValue('');
        this.formulario.controls['planta_de_expedicao_19'].markAsUntouched();

        this.formulario.controls['codigo_do_produto_20']?.setValue('');
        this.formulario.controls['codigo_do_produto_20'].markAsUntouched();
        this.formulario.controls['descricao_produto_20']?.setValue('');
        this.formulario.controls['descricao_produto_20'].markAsUntouched();
        this.formulario.controls['quantidade_produto_20']?.setValue('');
        this.formulario.controls['quantidade_produto_20'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_20']?.setValue('');
        this.formulario.controls['valor_unitario_produto_20'].markAsUntouched();
        this.formulario.controls['embalagem_produto_20']?.setValue('');
        this.formulario.controls['embalagem_produto_20'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_20']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_20'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_20']?.setValue('');
        this.formulario.controls['planta_de_expedicao_20'].markAsUntouched();
        break;

      case 5:
        this.formulario.controls['codigo_do_produto_6']?.setValue('');
        this.formulario.controls['codigo_do_produto_6'].markAsUntouched();
        this.formulario.controls['descricao_produto_6']?.setValue('');
        this.formulario.controls['descricao_produto_6'].markAsUntouched();
        this.formulario.controls['quantidade_produto_6']?.setValue('');
        this.formulario.controls['quantidade_produto_6'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_6']?.setValue('');
        this.formulario.controls['valor_unitario_produto_6'].markAsUntouched();
        this.formulario.controls['embalagem_produto_6']?.setValue('');
        this.formulario.controls['embalagem_produto_6'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_6']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_6'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_6']?.setValue('');
        this.formulario.controls['planta_de_expedicao_6'].markAsUntouched();

        this.formulario.controls['codigo_do_produto_7']?.setValue('');
        this.formulario.controls['codigo_do_produto_7'].markAsUntouched();
        this.formulario.controls['descricao_produto_7']?.setValue('');
        this.formulario.controls['descricao_produto_7'].markAsUntouched();
        this.formulario.controls['quantidade_produto_7']?.setValue('');
        this.formulario.controls['quantidade_produto_7'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_7']?.setValue('');
        this.formulario.controls['valor_unitario_produto_7'].markAsUntouched();
        this.formulario.controls['embalagem_produto_7']?.setValue('');
        this.formulario.controls['embalagem_produto_7'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_7']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_7'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_7']?.setValue('');
        this.formulario.controls['planta_de_expedicao_7'].markAsUntouched();

        this.formulario.controls['codigo_do_produto_8']?.setValue('');
        this.formulario.controls['codigo_do_produto_8'].markAsUntouched();
        this.formulario.controls['descricao_produto_8']?.setValue('');
        this.formulario.controls['descricao_produto_8'].markAsUntouched();
        this.formulario.controls['quantidade_produto_8']?.setValue('');
        this.formulario.controls['quantidade_produto_8'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_8']?.setValue('');
        this.formulario.controls['valor_unitario_produto_8'].markAsUntouched();
        this.formulario.controls['embalagem_produto_8']?.setValue('');
        this.formulario.controls['embalagem_produto_8'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_8']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_8'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_8']?.setValue('');
        this.formulario.controls['planta_de_expedicao_8'].markAsUntouched();

        this.formulario.controls['codigo_do_produto_9']?.setValue('');
        this.formulario.controls['codigo_do_produto_9'].markAsUntouched();
        this.formulario.controls['descricao_produto_9']?.setValue('');
        this.formulario.controls['descricao_produto_9'].markAsUntouched();
        this.formulario.controls['quantidade_produto_9']?.setValue('');
        this.formulario.controls['quantidade_produto_9'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_9']?.setValue('');
        this.formulario.controls['valor_unitario_produto_9'].markAsUntouched();
        this.formulario.controls['embalagem_produto_9']?.setValue('');
        this.formulario.controls['embalagem_produto_9'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_9']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_9'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_9']?.setValue('');
        this.formulario.controls['planta_de_expedicao_9'].markAsUntouched();

        this.formulario.controls['codigo_do_produto_10']?.setValue('');
        this.formulario.controls['codigo_do_produto_10'].markAsUntouched();
        this.formulario.controls['descricao_produto_10']?.setValue('');
        this.formulario.controls['descricao_produto_10'].markAsUntouched();
        this.formulario.controls['quantidade_produto_10']?.setValue('');
        this.formulario.controls['quantidade_produto_10'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_10']?.setValue('');
        this.formulario.controls['valor_unitario_produto_10'].markAsUntouched();
        this.formulario.controls['embalagem_produto_10']?.setValue('');
        this.formulario.controls['embalagem_produto_10'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_10']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_10'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_10']?.setValue('');
        this.formulario.controls['planta_de_expedicao_10'].markAsUntouched();

        this.formulario.controls['codigo_do_produto_11']?.setValue('');
        this.formulario.controls['codigo_do_produto_11'].markAsUntouched();
        this.formulario.controls['descricao_produto_11']?.setValue('');
        this.formulario.controls['descricao_produto_11'].markAsUntouched();
        this.formulario.controls['quantidade_produto_11']?.setValue('');
        this.formulario.controls['quantidade_produto_11'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_11']?.setValue('');
        this.formulario.controls['valor_unitario_produto_11'].markAsUntouched();
        this.formulario.controls['embalagem_produto_11']?.setValue('');
        this.formulario.controls['embalagem_produto_11'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_11']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_11'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_11']?.setValue('');
        this.formulario.controls['planta_de_expedicao_11'].markAsUntouched();

        this.formulario.controls['codigo_do_produto_12']?.setValue('');
        this.formulario.controls['codigo_do_produto_12'].markAsUntouched();
        this.formulario.controls['descricao_produto_12']?.setValue('');
        this.formulario.controls['descricao_produto_12'].markAsUntouched();
        this.formulario.controls['quantidade_produto_12']?.setValue('');
        this.formulario.controls['quantidade_produto_12'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_12']?.setValue('');
        this.formulario.controls['valor_unitario_produto_12'].markAsUntouched();
        this.formulario.controls['embalagem_produto_12']?.setValue('');
        this.formulario.controls['embalagem_produto_12'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_12']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_12'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_12']?.setValue('');
        this.formulario.controls['planta_de_expedicao_12'].markAsUntouched();

        this.formulario.controls['codigo_do_produto_13']?.setValue('');
        this.formulario.controls['codigo_do_produto_13'].markAsUntouched();
        this.formulario.controls['descricao_produto_13']?.setValue('');
        this.formulario.controls['descricao_produto_13'].markAsUntouched();
        this.formulario.controls['quantidade_produto_13']?.setValue('');
        this.formulario.controls['quantidade_produto_13'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_13']?.setValue('');
        this.formulario.controls['valor_unitario_produto_13'].markAsUntouched();
        this.formulario.controls['embalagem_produto_13']?.setValue('');
        this.formulario.controls['embalagem_produto_13'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_13']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_13'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_13']?.setValue('');
        this.formulario.controls['planta_de_expedicao_13'].markAsUntouched();

        this.formulario.controls['codigo_do_produto_14']?.setValue('');
        this.formulario.controls['codigo_do_produto_14'].markAsUntouched();
        this.formulario.controls['descricao_produto_14']?.setValue('');
        this.formulario.controls['descricao_produto_14'].markAsUntouched();
        this.formulario.controls['quantidade_produto_14']?.setValue('');
        this.formulario.controls['quantidade_produto_14'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_14']?.setValue('');
        this.formulario.controls['valor_unitario_produto_14'].markAsUntouched();
        this.formulario.controls['embalagem_produto_14']?.setValue('');
        this.formulario.controls['embalagem_produto_14'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_14']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_14'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_14']?.setValue('');
        this.formulario.controls['planta_de_expedicao_14'].markAsUntouched();

        this.formulario.controls['codigo_do_produto_15']?.setValue('');
        this.formulario.controls['codigo_do_produto_15'].markAsUntouched();
        this.formulario.controls['descricao_produto_15']?.setValue('');
        this.formulario.controls['descricao_produto_15'].markAsUntouched();
        this.formulario.controls['quantidade_produto_15']?.setValue('');
        this.formulario.controls['quantidade_produto_15'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_15']?.setValue('');
        this.formulario.controls['valor_unitario_produto_15'].markAsUntouched();
        this.formulario.controls['embalagem_produto_15']?.setValue('');
        this.formulario.controls['embalagem_produto_15'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_15']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_15'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_15']?.setValue('');
        this.formulario.controls['planta_de_expedicao_15'].markAsUntouched();

        this.formulario.controls['codigo_do_produto_16']?.setValue('');
        this.formulario.controls['codigo_do_produto_16'].markAsUntouched();
        this.formulario.controls['descricao_produto_16']?.setValue('');
        this.formulario.controls['descricao_produto_16'].markAsUntouched();
        this.formulario.controls['quantidade_produto_16']?.setValue('');
        this.formulario.controls['quantidade_produto_16'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_16']?.setValue('');
        this.formulario.controls['valor_unitario_produto_16'].markAsUntouched();
        this.formulario.controls['embalagem_produto_16']?.setValue('');
        this.formulario.controls['embalagem_produto_16'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_16']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_16'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_16']?.setValue('');
        this.formulario.controls['planta_de_expedicao_16'].markAsUntouched();

        this.formulario.controls['codigo_do_produto_17']?.setValue('');
        this.formulario.controls['codigo_do_produto_17'].markAsUntouched();
        this.formulario.controls['descricao_produto_17']?.setValue('');
        this.formulario.controls['descricao_produto_17'].markAsUntouched();
        this.formulario.controls['quantidade_produto_17']?.setValue('');
        this.formulario.controls['quantidade_produto_17'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_17']?.setValue('');
        this.formulario.controls['valor_unitario_produto_17'].markAsUntouched();
        this.formulario.controls['embalagem_produto_17']?.setValue('');
        this.formulario.controls['embalagem_produto_17'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_17']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_17'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_17']?.setValue('');
        this.formulario.controls['planta_de_expedicao_17'].markAsUntouched();

        this.formulario.controls['codigo_do_produto_18']?.setValue('');
        this.formulario.controls['codigo_do_produto_18'].markAsUntouched();
        this.formulario.controls['descricao_produto_18']?.setValue('');
        this.formulario.controls['descricao_produto_18'].markAsUntouched();
        this.formulario.controls['quantidade_produto_18']?.setValue('');
        this.formulario.controls['quantidade_produto_18'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_18']?.setValue('');
        this.formulario.controls['valor_unitario_produto_18'].markAsUntouched();
        this.formulario.controls['embalagem_produto_18']?.setValue('');
        this.formulario.controls['embalagem_produto_18'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_18']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_18'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_18']?.setValue('');
        this.formulario.controls['planta_de_expedicao_18'].markAsUntouched();

        this.formulario.controls['codigo_do_produto_19']?.setValue('');
        this.formulario.controls['codigo_do_produto_19'].markAsUntouched();
        this.formulario.controls['descricao_produto_19']?.setValue('');
        this.formulario.controls['descricao_produto_19'].markAsUntouched();
        this.formulario.controls['quantidade_produto_19']?.setValue('');
        this.formulario.controls['quantidade_produto_19'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_19']?.setValue('');
        this.formulario.controls['valor_unitario_produto_19'].markAsUntouched();
        this.formulario.controls['embalagem_produto_19']?.setValue('');
        this.formulario.controls['embalagem_produto_19'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_19']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_19'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_19']?.setValue('');
        this.formulario.controls['planta_de_expedicao_19'].markAsUntouched();

        this.formulario.controls['codigo_do_produto_20']?.setValue('');
        this.formulario.controls['codigo_do_produto_20'].markAsUntouched();
        this.formulario.controls['descricao_produto_20']?.setValue('');
        this.formulario.controls['descricao_produto_20'].markAsUntouched();
        this.formulario.controls['quantidade_produto_20']?.setValue('');
        this.formulario.controls['quantidade_produto_20'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_20']?.setValue('');
        this.formulario.controls['valor_unitario_produto_20'].markAsUntouched();
        this.formulario.controls['embalagem_produto_20']?.setValue('');
        this.formulario.controls['embalagem_produto_20'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_20']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_20'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_20']?.setValue('');
        this.formulario.controls['planta_de_expedicao_20'].markAsUntouched();
        break;

      case 6:
        this.formulario.controls['codigo_do_produto_7']?.setValue('');
        this.formulario.controls['codigo_do_produto_7'].markAsUntouched();
        this.formulario.controls['descricao_produto_7']?.setValue('');
        this.formulario.controls['descricao_produto_7'].markAsUntouched();
        this.formulario.controls['quantidade_produto_7']?.setValue('');
        this.formulario.controls['quantidade_produto_7'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_7']?.setValue('');
        this.formulario.controls['valor_unitario_produto_7'].markAsUntouched();
        this.formulario.controls['embalagem_produto_7']?.setValue('');
        this.formulario.controls['embalagem_produto_7'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_7']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_7'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_7']?.setValue('');
        this.formulario.controls['planta_de_expedicao_7'].markAsUntouched();

        this.formulario.controls['codigo_do_produto_8']?.setValue('');
        this.formulario.controls['codigo_do_produto_8'].markAsUntouched();
        this.formulario.controls['descricao_produto_8']?.setValue('');
        this.formulario.controls['descricao_produto_8'].markAsUntouched();
        this.formulario.controls['quantidade_produto_8']?.setValue('');
        this.formulario.controls['quantidade_produto_8'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_8']?.setValue('');
        this.formulario.controls['valor_unitario_produto_8'].markAsUntouched();
        this.formulario.controls['embalagem_produto_8']?.setValue('');
        this.formulario.controls['embalagem_produto_8'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_8']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_8'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_8']?.setValue('');
        this.formulario.controls['planta_de_expedicao_8'].markAsUntouched();

        this.formulario.controls['codigo_do_produto_9']?.setValue('');
        this.formulario.controls['codigo_do_produto_9'].markAsUntouched();
        this.formulario.controls['descricao_produto_9']?.setValue('');
        this.formulario.controls['descricao_produto_9'].markAsUntouched();
        this.formulario.controls['quantidade_produto_9']?.setValue('');
        this.formulario.controls['quantidade_produto_9'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_9']?.setValue('');
        this.formulario.controls['valor_unitario_produto_9'].markAsUntouched();
        this.formulario.controls['embalagem_produto_9']?.setValue('');
        this.formulario.controls['embalagem_produto_9'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_9']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_9'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_9']?.setValue('');
        this.formulario.controls['planta_de_expedicao_9'].markAsUntouched();

        this.formulario.controls['codigo_do_produto_10']?.setValue('');
        this.formulario.controls['codigo_do_produto_10'].markAsUntouched();
        this.formulario.controls['descricao_produto_10']?.setValue('');
        this.formulario.controls['descricao_produto_10'].markAsUntouched();
        this.formulario.controls['quantidade_produto_10']?.setValue('');
        this.formulario.controls['quantidade_produto_10'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_10']?.setValue('');
        this.formulario.controls['valor_unitario_produto_10'].markAsUntouched();
        this.formulario.controls['embalagem_produto_10']?.setValue('');
        this.formulario.controls['embalagem_produto_10'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_10']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_10'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_10']?.setValue('');
        this.formulario.controls['planta_de_expedicao_10'].markAsUntouched();

        this.formulario.controls['codigo_do_produto_11']?.setValue('');
        this.formulario.controls['codigo_do_produto_11'].markAsUntouched();
        this.formulario.controls['descricao_produto_11']?.setValue('');
        this.formulario.controls['descricao_produto_11'].markAsUntouched();
        this.formulario.controls['quantidade_produto_11']?.setValue('');
        this.formulario.controls['quantidade_produto_11'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_11']?.setValue('');
        this.formulario.controls['valor_unitario_produto_11'].markAsUntouched();
        this.formulario.controls['embalagem_produto_11']?.setValue('');
        this.formulario.controls['embalagem_produto_11'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_11']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_11'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_11']?.setValue('');
        this.formulario.controls['planta_de_expedicao_11'].markAsUntouched();

        this.formulario.controls['codigo_do_produto_12']?.setValue('');
        this.formulario.controls['codigo_do_produto_12'].markAsUntouched();
        this.formulario.controls['descricao_produto_12']?.setValue('');
        this.formulario.controls['descricao_produto_12'].markAsUntouched();
        this.formulario.controls['quantidade_produto_12']?.setValue('');
        this.formulario.controls['quantidade_produto_12'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_12']?.setValue('');
        this.formulario.controls['valor_unitario_produto_12'].markAsUntouched();
        this.formulario.controls['embalagem_produto_12']?.setValue('');
        this.formulario.controls['embalagem_produto_12'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_12']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_12'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_12']?.setValue('');
        this.formulario.controls['planta_de_expedicao_12'].markAsUntouched();

        this.formulario.controls['codigo_do_produto_13']?.setValue('');
        this.formulario.controls['codigo_do_produto_13'].markAsUntouched();
        this.formulario.controls['descricao_produto_13']?.setValue('');
        this.formulario.controls['descricao_produto_13'].markAsUntouched();
        this.formulario.controls['quantidade_produto_13']?.setValue('');
        this.formulario.controls['quantidade_produto_13'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_13']?.setValue('');
        this.formulario.controls['valor_unitario_produto_13'].markAsUntouched();
        this.formulario.controls['embalagem_produto_13']?.setValue('');
        this.formulario.controls['embalagem_produto_13'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_13']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_13'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_13']?.setValue('');
        this.formulario.controls['planta_de_expedicao_13'].markAsUntouched();

        this.formulario.controls['codigo_do_produto_14']?.setValue('');
        this.formulario.controls['codigo_do_produto_14'].markAsUntouched();
        this.formulario.controls['descricao_produto_14']?.setValue('');
        this.formulario.controls['descricao_produto_14'].markAsUntouched();
        this.formulario.controls['quantidade_produto_14']?.setValue('');
        this.formulario.controls['quantidade_produto_14'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_14']?.setValue('');
        this.formulario.controls['valor_unitario_produto_14'].markAsUntouched();
        this.formulario.controls['embalagem_produto_14']?.setValue('');
        this.formulario.controls['embalagem_produto_14'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_14']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_14'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_14']?.setValue('');
        this.formulario.controls['planta_de_expedicao_14'].markAsUntouched();

        this.formulario.controls['codigo_do_produto_15']?.setValue('');
        this.formulario.controls['codigo_do_produto_15'].markAsUntouched();
        this.formulario.controls['descricao_produto_15']?.setValue('');
        this.formulario.controls['descricao_produto_15'].markAsUntouched();
        this.formulario.controls['quantidade_produto_15']?.setValue('');
        this.formulario.controls['quantidade_produto_15'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_15']?.setValue('');
        this.formulario.controls['valor_unitario_produto_15'].markAsUntouched();
        this.formulario.controls['embalagem_produto_15']?.setValue('');
        this.formulario.controls['embalagem_produto_15'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_15']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_15'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_15']?.setValue('');
        this.formulario.controls['planta_de_expedicao_15'].markAsUntouched();

        this.formulario.controls['codigo_do_produto_16']?.setValue('');
        this.formulario.controls['codigo_do_produto_16'].markAsUntouched();
        this.formulario.controls['descricao_produto_16']?.setValue('');
        this.formulario.controls['descricao_produto_16'].markAsUntouched();
        this.formulario.controls['quantidade_produto_16']?.setValue('');
        this.formulario.controls['quantidade_produto_16'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_16']?.setValue('');
        this.formulario.controls['valor_unitario_produto_16'].markAsUntouched();
        this.formulario.controls['embalagem_produto_16']?.setValue('');
        this.formulario.controls['embalagem_produto_16'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_16']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_16'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_16']?.setValue('');
        this.formulario.controls['planta_de_expedicao_16'].markAsUntouched();

        this.formulario.controls['codigo_do_produto_17']?.setValue('');
        this.formulario.controls['codigo_do_produto_17'].markAsUntouched();
        this.formulario.controls['descricao_produto_17']?.setValue('');
        this.formulario.controls['descricao_produto_17'].markAsUntouched();
        this.formulario.controls['quantidade_produto_17']?.setValue('');
        this.formulario.controls['quantidade_produto_17'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_17']?.setValue('');
        this.formulario.controls['valor_unitario_produto_17'].markAsUntouched();
        this.formulario.controls['embalagem_produto_17']?.setValue('');
        this.formulario.controls['embalagem_produto_17'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_17']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_17'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_17']?.setValue('');
        this.formulario.controls['planta_de_expedicao_17'].markAsUntouched();

        this.formulario.controls['codigo_do_produto_18']?.setValue('');
        this.formulario.controls['codigo_do_produto_18'].markAsUntouched();
        this.formulario.controls['descricao_produto_18']?.setValue('');
        this.formulario.controls['descricao_produto_18'].markAsUntouched();
        this.formulario.controls['quantidade_produto_18']?.setValue('');
        this.formulario.controls['quantidade_produto_18'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_18']?.setValue('');
        this.formulario.controls['valor_unitario_produto_18'].markAsUntouched();
        this.formulario.controls['embalagem_produto_18']?.setValue('');
        this.formulario.controls['embalagem_produto_18'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_18']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_18'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_18']?.setValue('');
        this.formulario.controls['planta_de_expedicao_18'].markAsUntouched();

        this.formulario.controls['codigo_do_produto_19']?.setValue('');
        this.formulario.controls['codigo_do_produto_19'].markAsUntouched();
        this.formulario.controls['descricao_produto_19']?.setValue('');
        this.formulario.controls['descricao_produto_19'].markAsUntouched();
        this.formulario.controls['quantidade_produto_19']?.setValue('');
        this.formulario.controls['quantidade_produto_19'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_19']?.setValue('');
        this.formulario.controls['valor_unitario_produto_19'].markAsUntouched();
        this.formulario.controls['embalagem_produto_19']?.setValue('');
        this.formulario.controls['embalagem_produto_19'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_19']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_19'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_19']?.setValue('');
        this.formulario.controls['planta_de_expedicao_19'].markAsUntouched();

        this.formulario.controls['codigo_do_produto_20']?.setValue('');
        this.formulario.controls['codigo_do_produto_20'].markAsUntouched();
        this.formulario.controls['descricao_produto_20']?.setValue('');
        this.formulario.controls['descricao_produto_20'].markAsUntouched();
        this.formulario.controls['quantidade_produto_20']?.setValue('');
        this.formulario.controls['quantidade_produto_20'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_20']?.setValue('');
        this.formulario.controls['valor_unitario_produto_20'].markAsUntouched();
        this.formulario.controls['embalagem_produto_20']?.setValue('');
        this.formulario.controls['embalagem_produto_20'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_20']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_20'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_20']?.setValue('');
        this.formulario.controls['planta_de_expedicao_20'].markAsUntouched();
        break;

      case 7:
        this.formulario.controls['codigo_do_produto_8']?.setValue('');
        this.formulario.controls['codigo_do_produto_8'].markAsUntouched();
        this.formulario.controls['descricao_produto_8']?.setValue('');
        this.formulario.controls['descricao_produto_8'].markAsUntouched();
        this.formulario.controls['quantidade_produto_8']?.setValue('');
        this.formulario.controls['quantidade_produto_8'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_8']?.setValue('');
        this.formulario.controls['valor_unitario_produto_8'].markAsUntouched();
        this.formulario.controls['embalagem_produto_8']?.setValue('');
        this.formulario.controls['embalagem_produto_8'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_8']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_8'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_8']?.setValue('');
        this.formulario.controls['planta_de_expedicao_8'].markAsUntouched();

        this.formulario.controls['codigo_do_produto_9']?.setValue('');
        this.formulario.controls['codigo_do_produto_9'].markAsUntouched();
        this.formulario.controls['descricao_produto_9']?.setValue('');
        this.formulario.controls['descricao_produto_9'].markAsUntouched();
        this.formulario.controls['quantidade_produto_9']?.setValue('');
        this.formulario.controls['quantidade_produto_9'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_9']?.setValue('');
        this.formulario.controls['valor_unitario_produto_9'].markAsUntouched();
        this.formulario.controls['embalagem_produto_9']?.setValue('');
        this.formulario.controls['embalagem_produto_9'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_9']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_9'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_9']?.setValue('');
        this.formulario.controls['planta_de_expedicao_9'].markAsUntouched();

        this.formulario.controls['codigo_do_produto_10']?.setValue('');
        this.formulario.controls['codigo_do_produto_10'].markAsUntouched();
        this.formulario.controls['descricao_produto_10']?.setValue('');
        this.formulario.controls['descricao_produto_10'].markAsUntouched();
        this.formulario.controls['quantidade_produto_10']?.setValue('');
        this.formulario.controls['quantidade_produto_10'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_10']?.setValue('');
        this.formulario.controls['valor_unitario_produto_10'].markAsUntouched();
        this.formulario.controls['embalagem_produto_10']?.setValue('');
        this.formulario.controls['embalagem_produto_10'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_10']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_10'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_10']?.setValue('');
        this.formulario.controls['planta_de_expedicao_10'].markAsUntouched();

        this.formulario.controls['codigo_do_produto_11']?.setValue('');
        this.formulario.controls['codigo_do_produto_11'].markAsUntouched();
        this.formulario.controls['descricao_produto_11']?.setValue('');
        this.formulario.controls['descricao_produto_11'].markAsUntouched();
        this.formulario.controls['quantidade_produto_11']?.setValue('');
        this.formulario.controls['quantidade_produto_11'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_11']?.setValue('');
        this.formulario.controls['valor_unitario_produto_11'].markAsUntouched();
        this.formulario.controls['embalagem_produto_11']?.setValue('');
        this.formulario.controls['embalagem_produto_11'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_11']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_11'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_11']?.setValue('');
        this.formulario.controls['planta_de_expedicao_11'].markAsUntouched();

        this.formulario.controls['codigo_do_produto_12']?.setValue('');
        this.formulario.controls['codigo_do_produto_12'].markAsUntouched();
        this.formulario.controls['descricao_produto_12']?.setValue('');
        this.formulario.controls['descricao_produto_12'].markAsUntouched();
        this.formulario.controls['quantidade_produto_12']?.setValue('');
        this.formulario.controls['quantidade_produto_12'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_12']?.setValue('');
        this.formulario.controls['valor_unitario_produto_12'].markAsUntouched();
        this.formulario.controls['embalagem_produto_12']?.setValue('');
        this.formulario.controls['embalagem_produto_12'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_12']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_12'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_12']?.setValue('');
        this.formulario.controls['planta_de_expedicao_12'].markAsUntouched();

        this.formulario.controls['codigo_do_produto_13']?.setValue('');
        this.formulario.controls['codigo_do_produto_13'].markAsUntouched();
        this.formulario.controls['descricao_produto_13']?.setValue('');
        this.formulario.controls['descricao_produto_13'].markAsUntouched();
        this.formulario.controls['quantidade_produto_13']?.setValue('');
        this.formulario.controls['quantidade_produto_13'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_13']?.setValue('');
        this.formulario.controls['valor_unitario_produto_13'].markAsUntouched();
        this.formulario.controls['embalagem_produto_13']?.setValue('');
        this.formulario.controls['embalagem_produto_13'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_13']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_13'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_13']?.setValue('');
        this.formulario.controls['planta_de_expedicao_13'].markAsUntouched();

        this.formulario.controls['codigo_do_produto_14']?.setValue('');
        this.formulario.controls['codigo_do_produto_14'].markAsUntouched();
        this.formulario.controls['descricao_produto_14']?.setValue('');
        this.formulario.controls['descricao_produto_14'].markAsUntouched();
        this.formulario.controls['quantidade_produto_14']?.setValue('');
        this.formulario.controls['quantidade_produto_14'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_14']?.setValue('');
        this.formulario.controls['valor_unitario_produto_14'].markAsUntouched();
        this.formulario.controls['embalagem_produto_14']?.setValue('');
        this.formulario.controls['embalagem_produto_14'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_14']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_14'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_14']?.setValue('');
        this.formulario.controls['planta_de_expedicao_14'].markAsUntouched();

        this.formulario.controls['codigo_do_produto_15']?.setValue('');
        this.formulario.controls['codigo_do_produto_15'].markAsUntouched();
        this.formulario.controls['descricao_produto_15']?.setValue('');
        this.formulario.controls['descricao_produto_15'].markAsUntouched();
        this.formulario.controls['quantidade_produto_15']?.setValue('');
        this.formulario.controls['quantidade_produto_15'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_15']?.setValue('');
        this.formulario.controls['valor_unitario_produto_15'].markAsUntouched();
        this.formulario.controls['embalagem_produto_15']?.setValue('');
        this.formulario.controls['embalagem_produto_15'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_15']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_15'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_15']?.setValue('');
        this.formulario.controls['planta_de_expedicao_15'].markAsUntouched();

        this.formulario.controls['codigo_do_produto_16']?.setValue('');
        this.formulario.controls['codigo_do_produto_16'].markAsUntouched();
        this.formulario.controls['descricao_produto_16']?.setValue('');
        this.formulario.controls['descricao_produto_16'].markAsUntouched();
        this.formulario.controls['quantidade_produto_16']?.setValue('');
        this.formulario.controls['quantidade_produto_16'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_16']?.setValue('');
        this.formulario.controls['valor_unitario_produto_16'].markAsUntouched();
        this.formulario.controls['embalagem_produto_16']?.setValue('');
        this.formulario.controls['embalagem_produto_16'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_16']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_16'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_16']?.setValue('');
        this.formulario.controls['planta_de_expedicao_16'].markAsUntouched();

        this.formulario.controls['codigo_do_produto_17']?.setValue('');
        this.formulario.controls['codigo_do_produto_17'].markAsUntouched();
        this.formulario.controls['descricao_produto_17']?.setValue('');
        this.formulario.controls['descricao_produto_17'].markAsUntouched();
        this.formulario.controls['quantidade_produto_17']?.setValue('');
        this.formulario.controls['quantidade_produto_17'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_17']?.setValue('');
        this.formulario.controls['valor_unitario_produto_17'].markAsUntouched();
        this.formulario.controls['embalagem_produto_17']?.setValue('');
        this.formulario.controls['embalagem_produto_17'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_17']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_17'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_17']?.setValue('');
        this.formulario.controls['planta_de_expedicao_17'].markAsUntouched();

        this.formulario.controls['codigo_do_produto_18']?.setValue('');
        this.formulario.controls['codigo_do_produto_18'].markAsUntouched();
        this.formulario.controls['descricao_produto_18']?.setValue('');
        this.formulario.controls['descricao_produto_18'].markAsUntouched();
        this.formulario.controls['quantidade_produto_18']?.setValue('');
        this.formulario.controls['quantidade_produto_18'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_18']?.setValue('');
        this.formulario.controls['valor_unitario_produto_18'].markAsUntouched();
        this.formulario.controls['embalagem_produto_18']?.setValue('');
        this.formulario.controls['embalagem_produto_18'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_18']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_18'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_18']?.setValue('');
        this.formulario.controls['planta_de_expedicao_18'].markAsUntouched();

        this.formulario.controls['codigo_do_produto_19']?.setValue('');
        this.formulario.controls['codigo_do_produto_19'].markAsUntouched();
        this.formulario.controls['descricao_produto_19']?.setValue('');
        this.formulario.controls['descricao_produto_19'].markAsUntouched();
        this.formulario.controls['quantidade_produto_19']?.setValue('');
        this.formulario.controls['quantidade_produto_19'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_19']?.setValue('');
        this.formulario.controls['valor_unitario_produto_19'].markAsUntouched();
        this.formulario.controls['embalagem_produto_19']?.setValue('');
        this.formulario.controls['embalagem_produto_19'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_19']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_19'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_19']?.setValue('');
        this.formulario.controls['planta_de_expedicao_19'].markAsUntouched();

        this.formulario.controls['codigo_do_produto_20']?.setValue('');
        this.formulario.controls['codigo_do_produto_20'].markAsUntouched();
        this.formulario.controls['descricao_produto_20']?.setValue('');
        this.formulario.controls['descricao_produto_20'].markAsUntouched();
        this.formulario.controls['quantidade_produto_20']?.setValue('');
        this.formulario.controls['quantidade_produto_20'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_20']?.setValue('');
        this.formulario.controls['valor_unitario_produto_20'].markAsUntouched();
        this.formulario.controls['embalagem_produto_20']?.setValue('');
        this.formulario.controls['embalagem_produto_20'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_20']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_20'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_20']?.setValue('');
        this.formulario.controls['planta_de_expedicao_20'].markAsUntouched();
        break;

      case 8:
        this.formulario.controls['codigo_do_produto_9']?.setValue('');
        this.formulario.controls['codigo_do_produto_9'].markAsUntouched();
        this.formulario.controls['descricao_produto_9']?.setValue('');
        this.formulario.controls['descricao_produto_9'].markAsUntouched();
        this.formulario.controls['quantidade_produto_9']?.setValue('');
        this.formulario.controls['quantidade_produto_9'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_9']?.setValue('');
        this.formulario.controls['valor_unitario_produto_9'].markAsUntouched();
        this.formulario.controls['embalagem_produto_9']?.setValue('');
        this.formulario.controls['embalagem_produto_9'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_9']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_9'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_9']?.setValue('');
        this.formulario.controls['planta_de_expedicao_9'].markAsUntouched();

        this.formulario.controls['codigo_do_produto_10']?.setValue('');
        this.formulario.controls['codigo_do_produto_10'].markAsUntouched();
        this.formulario.controls['descricao_produto_10']?.setValue('');
        this.formulario.controls['descricao_produto_10'].markAsUntouched();
        this.formulario.controls['quantidade_produto_10']?.setValue('');
        this.formulario.controls['quantidade_produto_10'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_10']?.setValue('');
        this.formulario.controls['valor_unitario_produto_10'].markAsUntouched();
        this.formulario.controls['embalagem_produto_10']?.setValue('');
        this.formulario.controls['embalagem_produto_10'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_10']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_10'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_10']?.setValue('');
        this.formulario.controls['planta_de_expedicao_10'].markAsUntouched();

        this.formulario.controls['codigo_do_produto_11']?.setValue('');
        this.formulario.controls['codigo_do_produto_11'].markAsUntouched();
        this.formulario.controls['descricao_produto_11']?.setValue('');
        this.formulario.controls['descricao_produto_11'].markAsUntouched();
        this.formulario.controls['quantidade_produto_11']?.setValue('');
        this.formulario.controls['quantidade_produto_11'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_11']?.setValue('');
        this.formulario.controls['valor_unitario_produto_11'].markAsUntouched();
        this.formulario.controls['embalagem_produto_11']?.setValue('');
        this.formulario.controls['embalagem_produto_11'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_11']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_11'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_11']?.setValue('');
        this.formulario.controls['planta_de_expedicao_11'].markAsUntouched();

        this.formulario.controls['codigo_do_produto_12']?.setValue('');
        this.formulario.controls['codigo_do_produto_12'].markAsUntouched();
        this.formulario.controls['descricao_produto_12']?.setValue('');
        this.formulario.controls['descricao_produto_12'].markAsUntouched();
        this.formulario.controls['quantidade_produto_12']?.setValue('');
        this.formulario.controls['quantidade_produto_12'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_12']?.setValue('');
        this.formulario.controls['valor_unitario_produto_12'].markAsUntouched();
        this.formulario.controls['embalagem_produto_12']?.setValue('');
        this.formulario.controls['embalagem_produto_12'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_12']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_12'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_12']?.setValue('');
        this.formulario.controls['planta_de_expedicao_12'].markAsUntouched();

        this.formulario.controls['codigo_do_produto_13']?.setValue('');
        this.formulario.controls['codigo_do_produto_13'].markAsUntouched();
        this.formulario.controls['descricao_produto_13']?.setValue('');
        this.formulario.controls['descricao_produto_13'].markAsUntouched();
        this.formulario.controls['quantidade_produto_13']?.setValue('');
        this.formulario.controls['quantidade_produto_13'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_13']?.setValue('');
        this.formulario.controls['valor_unitario_produto_13'].markAsUntouched();
        this.formulario.controls['embalagem_produto_13']?.setValue('');
        this.formulario.controls['embalagem_produto_13'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_13']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_13'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_13']?.setValue('');
        this.formulario.controls['planta_de_expedicao_13'].markAsUntouched();

        this.formulario.controls['codigo_do_produto_14']?.setValue('');
        this.formulario.controls['codigo_do_produto_14'].markAsUntouched();
        this.formulario.controls['descricao_produto_14']?.setValue('');
        this.formulario.controls['descricao_produto_14'].markAsUntouched();
        this.formulario.controls['quantidade_produto_14']?.setValue('');
        this.formulario.controls['quantidade_produto_14'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_14']?.setValue('');
        this.formulario.controls['valor_unitario_produto_14'].markAsUntouched();
        this.formulario.controls['embalagem_produto_14']?.setValue('');
        this.formulario.controls['embalagem_produto_14'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_14']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_14'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_14']?.setValue('');
        this.formulario.controls['planta_de_expedicao_14'].markAsUntouched();

        this.formulario.controls['codigo_do_produto_15']?.setValue('');
        this.formulario.controls['codigo_do_produto_15'].markAsUntouched();
        this.formulario.controls['descricao_produto_15']?.setValue('');
        this.formulario.controls['descricao_produto_15'].markAsUntouched();
        this.formulario.controls['quantidade_produto_15']?.setValue('');
        this.formulario.controls['quantidade_produto_15'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_15']?.setValue('');
        this.formulario.controls['valor_unitario_produto_15'].markAsUntouched();
        this.formulario.controls['embalagem_produto_15']?.setValue('');
        this.formulario.controls['embalagem_produto_15'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_15']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_15'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_15']?.setValue('');
        this.formulario.controls['planta_de_expedicao_15'].markAsUntouched();

        this.formulario.controls['codigo_do_produto_16']?.setValue('');
        this.formulario.controls['codigo_do_produto_16'].markAsUntouched();
        this.formulario.controls['descricao_produto_16']?.setValue('');
        this.formulario.controls['descricao_produto_16'].markAsUntouched();
        this.formulario.controls['quantidade_produto_16']?.setValue('');
        this.formulario.controls['quantidade_produto_16'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_16']?.setValue('');
        this.formulario.controls['valor_unitario_produto_16'].markAsUntouched();
        this.formulario.controls['embalagem_produto_16']?.setValue('');
        this.formulario.controls['embalagem_produto_16'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_16']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_16'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_16']?.setValue('');
        this.formulario.controls['planta_de_expedicao_16'].markAsUntouched();

        this.formulario.controls['codigo_do_produto_17']?.setValue('');
        this.formulario.controls['codigo_do_produto_17'].markAsUntouched();
        this.formulario.controls['descricao_produto_17']?.setValue('');
        this.formulario.controls['descricao_produto_17'].markAsUntouched();
        this.formulario.controls['quantidade_produto_17']?.setValue('');
        this.formulario.controls['quantidade_produto_17'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_17']?.setValue('');
        this.formulario.controls['valor_unitario_produto_17'].markAsUntouched();
        this.formulario.controls['embalagem_produto_17']?.setValue('');
        this.formulario.controls['embalagem_produto_17'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_17']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_17'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_17']?.setValue('');
        this.formulario.controls['planta_de_expedicao_17'].markAsUntouched();

        this.formulario.controls['codigo_do_produto_18']?.setValue('');
        this.formulario.controls['codigo_do_produto_18'].markAsUntouched();
        this.formulario.controls['descricao_produto_18']?.setValue('');
        this.formulario.controls['descricao_produto_18'].markAsUntouched();
        this.formulario.controls['quantidade_produto_18']?.setValue('');
        this.formulario.controls['quantidade_produto_18'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_18']?.setValue('');
        this.formulario.controls['valor_unitario_produto_18'].markAsUntouched();
        this.formulario.controls['embalagem_produto_18']?.setValue('');
        this.formulario.controls['embalagem_produto_18'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_18']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_18'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_18']?.setValue('');
        this.formulario.controls['planta_de_expedicao_18'].markAsUntouched();

        this.formulario.controls['codigo_do_produto_19']?.setValue('');
        this.formulario.controls['codigo_do_produto_19'].markAsUntouched();
        this.formulario.controls['descricao_produto_19']?.setValue('');
        this.formulario.controls['descricao_produto_19'].markAsUntouched();
        this.formulario.controls['quantidade_produto_19']?.setValue('');
        this.formulario.controls['quantidade_produto_19'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_19']?.setValue('');
        this.formulario.controls['valor_unitario_produto_19'].markAsUntouched();
        this.formulario.controls['embalagem_produto_19']?.setValue('');
        this.formulario.controls['embalagem_produto_19'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_19']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_19'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_19']?.setValue('');
        this.formulario.controls['planta_de_expedicao_19'].markAsUntouched();

        this.formulario.controls['codigo_do_produto_20']?.setValue('');
        this.formulario.controls['codigo_do_produto_20'].markAsUntouched();
        this.formulario.controls['descricao_produto_20']?.setValue('');
        this.formulario.controls['descricao_produto_20'].markAsUntouched();
        this.formulario.controls['quantidade_produto_20']?.setValue('');
        this.formulario.controls['quantidade_produto_20'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_20']?.setValue('');
        this.formulario.controls['valor_unitario_produto_20'].markAsUntouched();
        this.formulario.controls['embalagem_produto_20']?.setValue('');
        this.formulario.controls['embalagem_produto_20'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_20']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_20'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_20']?.setValue('');
        this.formulario.controls['planta_de_expedicao_20'].markAsUntouched();
        break;

      case 9:
        this.formulario.controls['codigo_do_produto_10']?.setValue('');
        this.formulario.controls['codigo_do_produto_10'].markAsUntouched();
        this.formulario.controls['descricao_produto_10']?.setValue('');
        this.formulario.controls['descricao_produto_10'].markAsUntouched();
        this.formulario.controls['quantidade_produto_10']?.setValue('');
        this.formulario.controls['quantidade_produto_10'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_10']?.setValue('');
        this.formulario.controls['valor_unitario_produto_10'].markAsUntouched();
        this.formulario.controls['embalagem_produto_10']?.setValue('');
        this.formulario.controls['embalagem_produto_10'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_10']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_10'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_10']?.setValue('');
        this.formulario.controls['planta_de_expedicao_10'].markAsUntouched();

        this.formulario.controls['codigo_do_produto_11']?.setValue('');
        this.formulario.controls['codigo_do_produto_11'].markAsUntouched();
        this.formulario.controls['descricao_produto_11']?.setValue('');
        this.formulario.controls['descricao_produto_11'].markAsUntouched();
        this.formulario.controls['quantidade_produto_11']?.setValue('');
        this.formulario.controls['quantidade_produto_11'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_11']?.setValue('');
        this.formulario.controls['valor_unitario_produto_11'].markAsUntouched();
        this.formulario.controls['embalagem_produto_11']?.setValue('');
        this.formulario.controls['embalagem_produto_11'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_11']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_11'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_11']?.setValue('');
        this.formulario.controls['planta_de_expedicao_11'].markAsUntouched();

        this.formulario.controls['codigo_do_produto_12']?.setValue('');
        this.formulario.controls['codigo_do_produto_12'].markAsUntouched();
        this.formulario.controls['descricao_produto_12']?.setValue('');
        this.formulario.controls['descricao_produto_12'].markAsUntouched();
        this.formulario.controls['quantidade_produto_12']?.setValue('');
        this.formulario.controls['quantidade_produto_12'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_12']?.setValue('');
        this.formulario.controls['valor_unitario_produto_12'].markAsUntouched();
        this.formulario.controls['embalagem_produto_12']?.setValue('');
        this.formulario.controls['embalagem_produto_12'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_12']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_12'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_12']?.setValue('');
        this.formulario.controls['planta_de_expedicao_12'].markAsUntouched();

        this.formulario.controls['codigo_do_produto_13']?.setValue('');
        this.formulario.controls['codigo_do_produto_13'].markAsUntouched();
        this.formulario.controls['descricao_produto_13']?.setValue('');
        this.formulario.controls['descricao_produto_13'].markAsUntouched();
        this.formulario.controls['quantidade_produto_13']?.setValue('');
        this.formulario.controls['quantidade_produto_13'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_13']?.setValue('');
        this.formulario.controls['valor_unitario_produto_13'].markAsUntouched();
        this.formulario.controls['embalagem_produto_13']?.setValue('');
        this.formulario.controls['embalagem_produto_13'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_13']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_13'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_13']?.setValue('');
        this.formulario.controls['planta_de_expedicao_13'].markAsUntouched();

        this.formulario.controls['codigo_do_produto_14']?.setValue('');
        this.formulario.controls['codigo_do_produto_14'].markAsUntouched();
        this.formulario.controls['descricao_produto_14']?.setValue('');
        this.formulario.controls['descricao_produto_14'].markAsUntouched();
        this.formulario.controls['quantidade_produto_14']?.setValue('');
        this.formulario.controls['quantidade_produto_14'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_14']?.setValue('');
        this.formulario.controls['valor_unitario_produto_14'].markAsUntouched();
        this.formulario.controls['embalagem_produto_14']?.setValue('');
        this.formulario.controls['embalagem_produto_14'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_14']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_14'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_14']?.setValue('');
        this.formulario.controls['planta_de_expedicao_14'].markAsUntouched();

        this.formulario.controls['codigo_do_produto_15']?.setValue('');
        this.formulario.controls['codigo_do_produto_15'].markAsUntouched();
        this.formulario.controls['descricao_produto_15']?.setValue('');
        this.formulario.controls['descricao_produto_15'].markAsUntouched();
        this.formulario.controls['quantidade_produto_15']?.setValue('');
        this.formulario.controls['quantidade_produto_15'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_15']?.setValue('');
        this.formulario.controls['valor_unitario_produto_15'].markAsUntouched();
        this.formulario.controls['embalagem_produto_15']?.setValue('');
        this.formulario.controls['embalagem_produto_15'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_15']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_15'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_15']?.setValue('');
        this.formulario.controls['planta_de_expedicao_15'].markAsUntouched();

        this.formulario.controls['codigo_do_produto_16']?.setValue('');
        this.formulario.controls['codigo_do_produto_16'].markAsUntouched();
        this.formulario.controls['descricao_produto_16']?.setValue('');
        this.formulario.controls['descricao_produto_16'].markAsUntouched();
        this.formulario.controls['quantidade_produto_16']?.setValue('');
        this.formulario.controls['quantidade_produto_16'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_16']?.setValue('');
        this.formulario.controls['valor_unitario_produto_16'].markAsUntouched();
        this.formulario.controls['embalagem_produto_16']?.setValue('');
        this.formulario.controls['embalagem_produto_16'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_16']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_16'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_16']?.setValue('');
        this.formulario.controls['planta_de_expedicao_16'].markAsUntouched();

        this.formulario.controls['codigo_do_produto_17']?.setValue('');
        this.formulario.controls['codigo_do_produto_17'].markAsUntouched();
        this.formulario.controls['descricao_produto_17']?.setValue('');
        this.formulario.controls['descricao_produto_17'].markAsUntouched();
        this.formulario.controls['quantidade_produto_17']?.setValue('');
        this.formulario.controls['quantidade_produto_17'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_17']?.setValue('');
        this.formulario.controls['valor_unitario_produto_17'].markAsUntouched();
        this.formulario.controls['embalagem_produto_17']?.setValue('');
        this.formulario.controls['embalagem_produto_17'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_17']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_17'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_17']?.setValue('');
        this.formulario.controls['planta_de_expedicao_17'].markAsUntouched();

        this.formulario.controls['codigo_do_produto_18']?.setValue('');
        this.formulario.controls['codigo_do_produto_18'].markAsUntouched();
        this.formulario.controls['descricao_produto_18']?.setValue('');
        this.formulario.controls['descricao_produto_18'].markAsUntouched();
        this.formulario.controls['quantidade_produto_18']?.setValue('');
        this.formulario.controls['quantidade_produto_18'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_18']?.setValue('');
        this.formulario.controls['valor_unitario_produto_18'].markAsUntouched();
        this.formulario.controls['embalagem_produto_18']?.setValue('');
        this.formulario.controls['embalagem_produto_18'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_18']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_18'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_18']?.setValue('');
        this.formulario.controls['planta_de_expedicao_18'].markAsUntouched();

        this.formulario.controls['codigo_do_produto_19']?.setValue('');
        this.formulario.controls['codigo_do_produto_19'].markAsUntouched();
        this.formulario.controls['descricao_produto_19']?.setValue('');
        this.formulario.controls['descricao_produto_19'].markAsUntouched();
        this.formulario.controls['quantidade_produto_19']?.setValue('');
        this.formulario.controls['quantidade_produto_19'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_19']?.setValue('');
        this.formulario.controls['valor_unitario_produto_19'].markAsUntouched();
        this.formulario.controls['embalagem_produto_19']?.setValue('');
        this.formulario.controls['embalagem_produto_19'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_19']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_19'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_19']?.setValue('');
        this.formulario.controls['planta_de_expedicao_19'].markAsUntouched();

        this.formulario.controls['codigo_do_produto_20']?.setValue('');
        this.formulario.controls['codigo_do_produto_20'].markAsUntouched();
        this.formulario.controls['descricao_produto_20']?.setValue('');
        this.formulario.controls['descricao_produto_20'].markAsUntouched();
        this.formulario.controls['quantidade_produto_20']?.setValue('');
        this.formulario.controls['quantidade_produto_20'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_20']?.setValue('');
        this.formulario.controls['valor_unitario_produto_20'].markAsUntouched();
        this.formulario.controls['embalagem_produto_20']?.setValue('');
        this.formulario.controls['embalagem_produto_20'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_20']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_20'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_20']?.setValue('');
        this.formulario.controls['planta_de_expedicao_20'].markAsUntouched();
        break;

      case 10:
        this.formulario.controls['codigo_do_produto_11']?.setValue('');
        this.formulario.controls['codigo_do_produto_11'].markAsUntouched();
        this.formulario.controls['descricao_produto_11']?.setValue('');
        this.formulario.controls['descricao_produto_11'].markAsUntouched();
        this.formulario.controls['quantidade_produto_11']?.setValue('');
        this.formulario.controls['quantidade_produto_11'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_11']?.setValue('');
        this.formulario.controls['valor_unitario_produto_11'].markAsUntouched();
        this.formulario.controls['embalagem_produto_11']?.setValue('');
        this.formulario.controls['embalagem_produto_11'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_11']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_11'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_11']?.setValue('');
        this.formulario.controls['planta_de_expedicao_11'].markAsUntouched();

        this.formulario.controls['codigo_do_produto_12']?.setValue('');
        this.formulario.controls['codigo_do_produto_12'].markAsUntouched();
        this.formulario.controls['descricao_produto_12']?.setValue('');
        this.formulario.controls['descricao_produto_12'].markAsUntouched();
        this.formulario.controls['quantidade_produto_12']?.setValue('');
        this.formulario.controls['quantidade_produto_12'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_12']?.setValue('');
        this.formulario.controls['valor_unitario_produto_12'].markAsUntouched();
        this.formulario.controls['embalagem_produto_12']?.setValue('');
        this.formulario.controls['embalagem_produto_12'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_12']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_12'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_12']?.setValue('');
        this.formulario.controls['planta_de_expedicao_12'].markAsUntouched();

        this.formulario.controls['codigo_do_produto_13']?.setValue('');
        this.formulario.controls['codigo_do_produto_13'].markAsUntouched();
        this.formulario.controls['descricao_produto_13']?.setValue('');
        this.formulario.controls['descricao_produto_13'].markAsUntouched();
        this.formulario.controls['quantidade_produto_13']?.setValue('');
        this.formulario.controls['quantidade_produto_13'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_13']?.setValue('');
        this.formulario.controls['valor_unitario_produto_13'].markAsUntouched();
        this.formulario.controls['embalagem_produto_13']?.setValue('');
        this.formulario.controls['embalagem_produto_13'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_13']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_13'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_13']?.setValue('');
        this.formulario.controls['planta_de_expedicao_13'].markAsUntouched();

        this.formulario.controls['codigo_do_produto_14']?.setValue('');
        this.formulario.controls['codigo_do_produto_14'].markAsUntouched();
        this.formulario.controls['descricao_produto_14']?.setValue('');
        this.formulario.controls['descricao_produto_14'].markAsUntouched();
        this.formulario.controls['quantidade_produto_14']?.setValue('');
        this.formulario.controls['quantidade_produto_14'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_14']?.setValue('');
        this.formulario.controls['valor_unitario_produto_14'].markAsUntouched();
        this.formulario.controls['embalagem_produto_14']?.setValue('');
        this.formulario.controls['embalagem_produto_14'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_14']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_14'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_14']?.setValue('');
        this.formulario.controls['planta_de_expedicao_14'].markAsUntouched();

        this.formulario.controls['codigo_do_produto_15']?.setValue('');
        this.formulario.controls['codigo_do_produto_15'].markAsUntouched();
        this.formulario.controls['descricao_produto_15']?.setValue('');
        this.formulario.controls['descricao_produto_15'].markAsUntouched();
        this.formulario.controls['quantidade_produto_15']?.setValue('');
        this.formulario.controls['quantidade_produto_15'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_15']?.setValue('');
        this.formulario.controls['valor_unitario_produto_15'].markAsUntouched();
        this.formulario.controls['embalagem_produto_15']?.setValue('');
        this.formulario.controls['embalagem_produto_15'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_15']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_15'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_15']?.setValue('');
        this.formulario.controls['planta_de_expedicao_15'].markAsUntouched();

        this.formulario.controls['codigo_do_produto_16']?.setValue('');
        this.formulario.controls['codigo_do_produto_16'].markAsUntouched();
        this.formulario.controls['descricao_produto_16']?.setValue('');
        this.formulario.controls['descricao_produto_16'].markAsUntouched();
        this.formulario.controls['quantidade_produto_16']?.setValue('');
        this.formulario.controls['quantidade_produto_16'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_16']?.setValue('');
        this.formulario.controls['valor_unitario_produto_16'].markAsUntouched();
        this.formulario.controls['embalagem_produto_16']?.setValue('');
        this.formulario.controls['embalagem_produto_16'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_16']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_16'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_16']?.setValue('');
        this.formulario.controls['planta_de_expedicao_16'].markAsUntouched();

        this.formulario.controls['codigo_do_produto_17']?.setValue('');
        this.formulario.controls['codigo_do_produto_17'].markAsUntouched();
        this.formulario.controls['descricao_produto_17']?.setValue('');
        this.formulario.controls['descricao_produto_17'].markAsUntouched();
        this.formulario.controls['quantidade_produto_17']?.setValue('');
        this.formulario.controls['quantidade_produto_17'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_17']?.setValue('');
        this.formulario.controls['valor_unitario_produto_17'].markAsUntouched();
        this.formulario.controls['embalagem_produto_17']?.setValue('');
        this.formulario.controls['embalagem_produto_17'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_17']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_17'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_17']?.setValue('');
        this.formulario.controls['planta_de_expedicao_17'].markAsUntouched();

        this.formulario.controls['codigo_do_produto_18']?.setValue('');
        this.formulario.controls['codigo_do_produto_18'].markAsUntouched();
        this.formulario.controls['descricao_produto_18']?.setValue('');
        this.formulario.controls['descricao_produto_18'].markAsUntouched();
        this.formulario.controls['quantidade_produto_18']?.setValue('');
        this.formulario.controls['quantidade_produto_18'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_18']?.setValue('');
        this.formulario.controls['valor_unitario_produto_18'].markAsUntouched();
        this.formulario.controls['embalagem_produto_18']?.setValue('');
        this.formulario.controls['embalagem_produto_18'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_18']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_18'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_18']?.setValue('');
        this.formulario.controls['planta_de_expedicao_18'].markAsUntouched();

        this.formulario.controls['codigo_do_produto_19']?.setValue('');
        this.formulario.controls['codigo_do_produto_19'].markAsUntouched();
        this.formulario.controls['descricao_produto_19']?.setValue('');
        this.formulario.controls['descricao_produto_19'].markAsUntouched();
        this.formulario.controls['quantidade_produto_19']?.setValue('');
        this.formulario.controls['quantidade_produto_19'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_19']?.setValue('');
        this.formulario.controls['valor_unitario_produto_19'].markAsUntouched();
        this.formulario.controls['embalagem_produto_19']?.setValue('');
        this.formulario.controls['embalagem_produto_19'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_19']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_19'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_19']?.setValue('');
        this.formulario.controls['planta_de_expedicao_19'].markAsUntouched();

        this.formulario.controls['codigo_do_produto_20']?.setValue('');
        this.formulario.controls['codigo_do_produto_20'].markAsUntouched();
        this.formulario.controls['descricao_produto_20']?.setValue('');
        this.formulario.controls['descricao_produto_20'].markAsUntouched();
        this.formulario.controls['quantidade_produto_20']?.setValue('');
        this.formulario.controls['quantidade_produto_20'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_20']?.setValue('');
        this.formulario.controls['valor_unitario_produto_20'].markAsUntouched();
        this.formulario.controls['embalagem_produto_20']?.setValue('');
        this.formulario.controls['embalagem_produto_20'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_20']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_20'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_20']?.setValue('');
        this.formulario.controls['planta_de_expedicao_20'].markAsUntouched();
        break;

      case 11:
        this.formulario.controls['codigo_do_produto_12']?.setValue('');
        this.formulario.controls['codigo_do_produto_12'].markAsUntouched();
        this.formulario.controls['descricao_produto_12']?.setValue('');
        this.formulario.controls['descricao_produto_12'].markAsUntouched();
        this.formulario.controls['quantidade_produto_12']?.setValue('');
        this.formulario.controls['quantidade_produto_12'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_12']?.setValue('');
        this.formulario.controls['valor_unitario_produto_12'].markAsUntouched();
        this.formulario.controls['embalagem_produto_12']?.setValue('');
        this.formulario.controls['embalagem_produto_12'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_12']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_12'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_12']?.setValue('');
        this.formulario.controls['planta_de_expedicao_12'].markAsUntouched();

        this.formulario.controls['codigo_do_produto_13']?.setValue('');
        this.formulario.controls['codigo_do_produto_13'].markAsUntouched();
        this.formulario.controls['descricao_produto_13']?.setValue('');
        this.formulario.controls['descricao_produto_13'].markAsUntouched();
        this.formulario.controls['quantidade_produto_13']?.setValue('');
        this.formulario.controls['quantidade_produto_13'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_13']?.setValue('');
        this.formulario.controls['valor_unitario_produto_13'].markAsUntouched();
        this.formulario.controls['embalagem_produto_13']?.setValue('');
        this.formulario.controls['embalagem_produto_13'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_13']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_13'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_13']?.setValue('');
        this.formulario.controls['planta_de_expedicao_13'].markAsUntouched();

        this.formulario.controls['codigo_do_produto_14']?.setValue('');
        this.formulario.controls['codigo_do_produto_14'].markAsUntouched();
        this.formulario.controls['descricao_produto_14']?.setValue('');
        this.formulario.controls['descricao_produto_14'].markAsUntouched();
        this.formulario.controls['quantidade_produto_14']?.setValue('');
        this.formulario.controls['quantidade_produto_14'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_14']?.setValue('');
        this.formulario.controls['valor_unitario_produto_14'].markAsUntouched();
        this.formulario.controls['embalagem_produto_14']?.setValue('');
        this.formulario.controls['embalagem_produto_14'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_14']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_14'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_14']?.setValue('');
        this.formulario.controls['planta_de_expedicao_14'].markAsUntouched();

        this.formulario.controls['codigo_do_produto_15']?.setValue('');
        this.formulario.controls['codigo_do_produto_15'].markAsUntouched();
        this.formulario.controls['descricao_produto_15']?.setValue('');
        this.formulario.controls['descricao_produto_15'].markAsUntouched();
        this.formulario.controls['quantidade_produto_15']?.setValue('');
        this.formulario.controls['quantidade_produto_15'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_15']?.setValue('');
        this.formulario.controls['valor_unitario_produto_15'].markAsUntouched();
        this.formulario.controls['embalagem_produto_15']?.setValue('');
        this.formulario.controls['embalagem_produto_15'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_15']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_15'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_15']?.setValue('');
        this.formulario.controls['planta_de_expedicao_15'].markAsUntouched();

        this.formulario.controls['codigo_do_produto_16']?.setValue('');
        this.formulario.controls['codigo_do_produto_16'].markAsUntouched();
        this.formulario.controls['descricao_produto_16']?.setValue('');
        this.formulario.controls['descricao_produto_16'].markAsUntouched();
        this.formulario.controls['quantidade_produto_16']?.setValue('');
        this.formulario.controls['quantidade_produto_16'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_16']?.setValue('');
        this.formulario.controls['valor_unitario_produto_16'].markAsUntouched();
        this.formulario.controls['embalagem_produto_16']?.setValue('');
        this.formulario.controls['embalagem_produto_16'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_16']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_16'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_16']?.setValue('');
        this.formulario.controls['planta_de_expedicao_16'].markAsUntouched();

        this.formulario.controls['codigo_do_produto_17']?.setValue('');
        this.formulario.controls['codigo_do_produto_17'].markAsUntouched();
        this.formulario.controls['descricao_produto_17']?.setValue('');
        this.formulario.controls['descricao_produto_17'].markAsUntouched();
        this.formulario.controls['quantidade_produto_17']?.setValue('');
        this.formulario.controls['quantidade_produto_17'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_17']?.setValue('');
        this.formulario.controls['valor_unitario_produto_17'].markAsUntouched();
        this.formulario.controls['embalagem_produto_17']?.setValue('');
        this.formulario.controls['embalagem_produto_17'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_17']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_17'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_17']?.setValue('');
        this.formulario.controls['planta_de_expedicao_17'].markAsUntouched();

        this.formulario.controls['codigo_do_produto_18']?.setValue('');
        this.formulario.controls['codigo_do_produto_18'].markAsUntouched();
        this.formulario.controls['descricao_produto_18']?.setValue('');
        this.formulario.controls['descricao_produto_18'].markAsUntouched();
        this.formulario.controls['quantidade_produto_18']?.setValue('');
        this.formulario.controls['quantidade_produto_18'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_18']?.setValue('');
        this.formulario.controls['valor_unitario_produto_18'].markAsUntouched();
        this.formulario.controls['embalagem_produto_18']?.setValue('');
        this.formulario.controls['embalagem_produto_18'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_18']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_18'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_18']?.setValue('');
        this.formulario.controls['planta_de_expedicao_18'].markAsUntouched();

        this.formulario.controls['codigo_do_produto_19']?.setValue('');
        this.formulario.controls['codigo_do_produto_19'].markAsUntouched();
        this.formulario.controls['descricao_produto_19']?.setValue('');
        this.formulario.controls['descricao_produto_19'].markAsUntouched();
        this.formulario.controls['quantidade_produto_19']?.setValue('');
        this.formulario.controls['quantidade_produto_19'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_19']?.setValue('');
        this.formulario.controls['valor_unitario_produto_19'].markAsUntouched();
        this.formulario.controls['embalagem_produto_19']?.setValue('');
        this.formulario.controls['embalagem_produto_19'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_19']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_19'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_19']?.setValue('');
        this.formulario.controls['planta_de_expedicao_19'].markAsUntouched();

        this.formulario.controls['codigo_do_produto_20']?.setValue('');
        this.formulario.controls['codigo_do_produto_20'].markAsUntouched();
        this.formulario.controls['descricao_produto_20']?.setValue('');
        this.formulario.controls['descricao_produto_20'].markAsUntouched();
        this.formulario.controls['quantidade_produto_20']?.setValue('');
        this.formulario.controls['quantidade_produto_20'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_20']?.setValue('');
        this.formulario.controls['valor_unitario_produto_20'].markAsUntouched();
        this.formulario.controls['embalagem_produto_20']?.setValue('');
        this.formulario.controls['embalagem_produto_20'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_20']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_20'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_20']?.setValue('');
        this.formulario.controls['planta_de_expedicao_20'].markAsUntouched();
        break;

      case 12:
        this.formulario.controls['codigo_do_produto_13']?.setValue('');
        this.formulario.controls['codigo_do_produto_13'].markAsUntouched();
        this.formulario.controls['descricao_produto_13']?.setValue('');
        this.formulario.controls['descricao_produto_13'].markAsUntouched();
        this.formulario.controls['quantidade_produto_13']?.setValue('');
        this.formulario.controls['quantidade_produto_13'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_13']?.setValue('');
        this.formulario.controls['valor_unitario_produto_13'].markAsUntouched();
        this.formulario.controls['embalagem_produto_13']?.setValue('');
        this.formulario.controls['embalagem_produto_13'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_13']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_13'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_13']?.setValue('');
        this.formulario.controls['planta_de_expedicao_13'].markAsUntouched();

        this.formulario.controls['codigo_do_produto_14']?.setValue('');
        this.formulario.controls['codigo_do_produto_14'].markAsUntouched();
        this.formulario.controls['descricao_produto_14']?.setValue('');
        this.formulario.controls['descricao_produto_14'].markAsUntouched();
        this.formulario.controls['quantidade_produto_14']?.setValue('');
        this.formulario.controls['quantidade_produto_14'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_14']?.setValue('');
        this.formulario.controls['valor_unitario_produto_14'].markAsUntouched();
        this.formulario.controls['embalagem_produto_14']?.setValue('');
        this.formulario.controls['embalagem_produto_14'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_14']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_14'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_14']?.setValue('');
        this.formulario.controls['planta_de_expedicao_14'].markAsUntouched();

        this.formulario.controls['codigo_do_produto_15']?.setValue('');
        this.formulario.controls['codigo_do_produto_15'].markAsUntouched();
        this.formulario.controls['descricao_produto_15']?.setValue('');
        this.formulario.controls['descricao_produto_15'].markAsUntouched();
        this.formulario.controls['quantidade_produto_15']?.setValue('');
        this.formulario.controls['quantidade_produto_15'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_15']?.setValue('');
        this.formulario.controls['valor_unitario_produto_15'].markAsUntouched();
        this.formulario.controls['embalagem_produto_15']?.setValue('');
        this.formulario.controls['embalagem_produto_15'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_15']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_15'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_15']?.setValue('');
        this.formulario.controls['planta_de_expedicao_15'].markAsUntouched();

        this.formulario.controls['codigo_do_produto_16']?.setValue('');
        this.formulario.controls['codigo_do_produto_16'].markAsUntouched();
        this.formulario.controls['descricao_produto_16']?.setValue('');
        this.formulario.controls['descricao_produto_16'].markAsUntouched();
        this.formulario.controls['quantidade_produto_16']?.setValue('');
        this.formulario.controls['quantidade_produto_16'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_16']?.setValue('');
        this.formulario.controls['valor_unitario_produto_16'].markAsUntouched();
        this.formulario.controls['embalagem_produto_16']?.setValue('');
        this.formulario.controls['embalagem_produto_16'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_16']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_16'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_16']?.setValue('');
        this.formulario.controls['planta_de_expedicao_16'].markAsUntouched();

        this.formulario.controls['codigo_do_produto_17']?.setValue('');
        this.formulario.controls['codigo_do_produto_17'].markAsUntouched();
        this.formulario.controls['descricao_produto_17']?.setValue('');
        this.formulario.controls['descricao_produto_17'].markAsUntouched();
        this.formulario.controls['quantidade_produto_17']?.setValue('');
        this.formulario.controls['quantidade_produto_17'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_17']?.setValue('');
        this.formulario.controls['valor_unitario_produto_17'].markAsUntouched();
        this.formulario.controls['embalagem_produto_17']?.setValue('');
        this.formulario.controls['embalagem_produto_17'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_17']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_17'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_17']?.setValue('');
        this.formulario.controls['planta_de_expedicao_17'].markAsUntouched();

        this.formulario.controls['codigo_do_produto_18']?.setValue('');
        this.formulario.controls['codigo_do_produto_18'].markAsUntouched();
        this.formulario.controls['descricao_produto_18']?.setValue('');
        this.formulario.controls['descricao_produto_18'].markAsUntouched();
        this.formulario.controls['quantidade_produto_18']?.setValue('');
        this.formulario.controls['quantidade_produto_18'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_18']?.setValue('');
        this.formulario.controls['valor_unitario_produto_18'].markAsUntouched();
        this.formulario.controls['embalagem_produto_18']?.setValue('');
        this.formulario.controls['embalagem_produto_18'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_18']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_18'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_18']?.setValue('');
        this.formulario.controls['planta_de_expedicao_18'].markAsUntouched();

        this.formulario.controls['codigo_do_produto_19']?.setValue('');
        this.formulario.controls['codigo_do_produto_19'].markAsUntouched();
        this.formulario.controls['descricao_produto_19']?.setValue('');
        this.formulario.controls['descricao_produto_19'].markAsUntouched();
        this.formulario.controls['quantidade_produto_19']?.setValue('');
        this.formulario.controls['quantidade_produto_19'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_19']?.setValue('');
        this.formulario.controls['valor_unitario_produto_19'].markAsUntouched();
        this.formulario.controls['embalagem_produto_19']?.setValue('');
        this.formulario.controls['embalagem_produto_19'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_19']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_19'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_19']?.setValue('');
        this.formulario.controls['planta_de_expedicao_19'].markAsUntouched();

        this.formulario.controls['codigo_do_produto_20']?.setValue('');
        this.formulario.controls['codigo_do_produto_20'].markAsUntouched();
        this.formulario.controls['descricao_produto_20']?.setValue('');
        this.formulario.controls['descricao_produto_20'].markAsUntouched();
        this.formulario.controls['quantidade_produto_20']?.setValue('');
        this.formulario.controls['quantidade_produto_20'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_20']?.setValue('');
        this.formulario.controls['valor_unitario_produto_20'].markAsUntouched();
        this.formulario.controls['embalagem_produto_20']?.setValue('');
        this.formulario.controls['embalagem_produto_20'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_20']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_20'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_20']?.setValue('');
        this.formulario.controls['planta_de_expedicao_20'].markAsUntouched();
        break;

      case 13:
        this.formulario.controls['codigo_do_produto_14']?.setValue('');
        this.formulario.controls['codigo_do_produto_14'].markAsUntouched();
        this.formulario.controls['descricao_produto_14']?.setValue('');
        this.formulario.controls['descricao_produto_14'].markAsUntouched();
        this.formulario.controls['quantidade_produto_14']?.setValue('');
        this.formulario.controls['quantidade_produto_14'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_14']?.setValue('');
        this.formulario.controls['valor_unitario_produto_14'].markAsUntouched();
        this.formulario.controls['embalagem_produto_14']?.setValue('');
        this.formulario.controls['embalagem_produto_14'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_14']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_14'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_14']?.setValue('');
        this.formulario.controls['planta_de_expedicao_14'].markAsUntouched();

        this.formulario.controls['codigo_do_produto_15']?.setValue('');
        this.formulario.controls['codigo_do_produto_15'].markAsUntouched();
        this.formulario.controls['descricao_produto_15']?.setValue('');
        this.formulario.controls['descricao_produto_15'].markAsUntouched();
        this.formulario.controls['quantidade_produto_15']?.setValue('');
        this.formulario.controls['quantidade_produto_15'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_15']?.setValue('');
        this.formulario.controls['valor_unitario_produto_15'].markAsUntouched();
        this.formulario.controls['embalagem_produto_15']?.setValue('');
        this.formulario.controls['embalagem_produto_15'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_15']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_15'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_15']?.setValue('');
        this.formulario.controls['planta_de_expedicao_15'].markAsUntouched();

        this.formulario.controls['codigo_do_produto_16']?.setValue('');
        this.formulario.controls['codigo_do_produto_16'].markAsUntouched();
        this.formulario.controls['descricao_produto_16']?.setValue('');
        this.formulario.controls['descricao_produto_16'].markAsUntouched();
        this.formulario.controls['quantidade_produto_16']?.setValue('');
        this.formulario.controls['quantidade_produto_16'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_16']?.setValue('');
        this.formulario.controls['valor_unitario_produto_16'].markAsUntouched();
        this.formulario.controls['embalagem_produto_16']?.setValue('');
        this.formulario.controls['embalagem_produto_16'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_16']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_16'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_16']?.setValue('');
        this.formulario.controls['planta_de_expedicao_16'].markAsUntouched();

        this.formulario.controls['codigo_do_produto_17']?.setValue('');
        this.formulario.controls['codigo_do_produto_17'].markAsUntouched();
        this.formulario.controls['descricao_produto_17']?.setValue('');
        this.formulario.controls['descricao_produto_17'].markAsUntouched();
        this.formulario.controls['quantidade_produto_17']?.setValue('');
        this.formulario.controls['quantidade_produto_17'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_17']?.setValue('');
        this.formulario.controls['valor_unitario_produto_17'].markAsUntouched();
        this.formulario.controls['embalagem_produto_17']?.setValue('');
        this.formulario.controls['embalagem_produto_17'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_17']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_17'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_17']?.setValue('');
        this.formulario.controls['planta_de_expedicao_17'].markAsUntouched();

        this.formulario.controls['codigo_do_produto_18']?.setValue('');
        this.formulario.controls['codigo_do_produto_18'].markAsUntouched();
        this.formulario.controls['descricao_produto_18']?.setValue('');
        this.formulario.controls['descricao_produto_18'].markAsUntouched();
        this.formulario.controls['quantidade_produto_18']?.setValue('');
        this.formulario.controls['quantidade_produto_18'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_18']?.setValue('');
        this.formulario.controls['valor_unitario_produto_18'].markAsUntouched();
        this.formulario.controls['embalagem_produto_18']?.setValue('');
        this.formulario.controls['embalagem_produto_18'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_18']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_18'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_18']?.setValue('');
        this.formulario.controls['planta_de_expedicao_18'].markAsUntouched();

        this.formulario.controls['codigo_do_produto_19']?.setValue('');
        this.formulario.controls['codigo_do_produto_19'].markAsUntouched();
        this.formulario.controls['descricao_produto_19']?.setValue('');
        this.formulario.controls['descricao_produto_19'].markAsUntouched();
        this.formulario.controls['quantidade_produto_19']?.setValue('');
        this.formulario.controls['quantidade_produto_19'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_19']?.setValue('');
        this.formulario.controls['valor_unitario_produto_19'].markAsUntouched();
        this.formulario.controls['embalagem_produto_19']?.setValue('');
        this.formulario.controls['embalagem_produto_19'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_19']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_19'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_19']?.setValue('');
        this.formulario.controls['planta_de_expedicao_19'].markAsUntouched();

        this.formulario.controls['codigo_do_produto_20']?.setValue('');
        this.formulario.controls['codigo_do_produto_20'].markAsUntouched();
        this.formulario.controls['descricao_produto_20']?.setValue('');
        this.formulario.controls['descricao_produto_20'].markAsUntouched();
        this.formulario.controls['quantidade_produto_20']?.setValue('');
        this.formulario.controls['quantidade_produto_20'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_20']?.setValue('');
        this.formulario.controls['valor_unitario_produto_20'].markAsUntouched();
        this.formulario.controls['embalagem_produto_20']?.setValue('');
        this.formulario.controls['embalagem_produto_20'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_20']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_20'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_20']?.setValue('');
        this.formulario.controls['planta_de_expedicao_20'].markAsUntouched();
        break;

      case 14:
        this.formulario.controls['codigo_do_produto_15']?.setValue('');
        this.formulario.controls['codigo_do_produto_15'].markAsUntouched();
        this.formulario.controls['descricao_produto_15']?.setValue('');
        this.formulario.controls['descricao_produto_15'].markAsUntouched();
        this.formulario.controls['quantidade_produto_15']?.setValue('');
        this.formulario.controls['quantidade_produto_15'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_15']?.setValue('');
        this.formulario.controls['valor_unitario_produto_15'].markAsUntouched();
        this.formulario.controls['embalagem_produto_15']?.setValue('');
        this.formulario.controls['embalagem_produto_15'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_15']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_15'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_15']?.setValue('');
        this.formulario.controls['planta_de_expedicao_15'].markAsUntouched();

        this.formulario.controls['codigo_do_produto_16']?.setValue('');
        this.formulario.controls['codigo_do_produto_16'].markAsUntouched();
        this.formulario.controls['descricao_produto_16']?.setValue('');
        this.formulario.controls['descricao_produto_16'].markAsUntouched();
        this.formulario.controls['quantidade_produto_16']?.setValue('');
        this.formulario.controls['quantidade_produto_16'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_16']?.setValue('');
        this.formulario.controls['valor_unitario_produto_16'].markAsUntouched();
        this.formulario.controls['embalagem_produto_16']?.setValue('');
        this.formulario.controls['embalagem_produto_16'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_16']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_16'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_16']?.setValue('');
        this.formulario.controls['planta_de_expedicao_16'].markAsUntouched();

        this.formulario.controls['codigo_do_produto_17']?.setValue('');
        this.formulario.controls['codigo_do_produto_17'].markAsUntouched();
        this.formulario.controls['descricao_produto_17']?.setValue('');
        this.formulario.controls['descricao_produto_17'].markAsUntouched();
        this.formulario.controls['quantidade_produto_17']?.setValue('');
        this.formulario.controls['quantidade_produto_17'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_17']?.setValue('');
        this.formulario.controls['valor_unitario_produto_17'].markAsUntouched();
        this.formulario.controls['embalagem_produto_17']?.setValue('');
        this.formulario.controls['embalagem_produto_17'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_17']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_17'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_17']?.setValue('');
        this.formulario.controls['planta_de_expedicao_17'].markAsUntouched();

        this.formulario.controls['codigo_do_produto_18']?.setValue('');
        this.formulario.controls['codigo_do_produto_18'].markAsUntouched();
        this.formulario.controls['descricao_produto_18']?.setValue('');
        this.formulario.controls['descricao_produto_18'].markAsUntouched();
        this.formulario.controls['quantidade_produto_18']?.setValue('');
        this.formulario.controls['quantidade_produto_18'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_18']?.setValue('');
        this.formulario.controls['valor_unitario_produto_18'].markAsUntouched();
        this.formulario.controls['embalagem_produto_18']?.setValue('');
        this.formulario.controls['embalagem_produto_18'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_18']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_18'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_18']?.setValue('');
        this.formulario.controls['planta_de_expedicao_18'].markAsUntouched();

        this.formulario.controls['codigo_do_produto_19']?.setValue('');
        this.formulario.controls['codigo_do_produto_19'].markAsUntouched();
        this.formulario.controls['descricao_produto_19']?.setValue('');
        this.formulario.controls['descricao_produto_19'].markAsUntouched();
        this.formulario.controls['quantidade_produto_19']?.setValue('');
        this.formulario.controls['quantidade_produto_19'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_19']?.setValue('');
        this.formulario.controls['valor_unitario_produto_19'].markAsUntouched();
        this.formulario.controls['embalagem_produto_19']?.setValue('');
        this.formulario.controls['embalagem_produto_19'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_19']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_19'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_19']?.setValue('');
        this.formulario.controls['planta_de_expedicao_19'].markAsUntouched();

        this.formulario.controls['codigo_do_produto_20']?.setValue('');
        this.formulario.controls['codigo_do_produto_20'].markAsUntouched();
        this.formulario.controls['descricao_produto_20']?.setValue('');
        this.formulario.controls['descricao_produto_20'].markAsUntouched();
        this.formulario.controls['quantidade_produto_20']?.setValue('');
        this.formulario.controls['quantidade_produto_20'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_20']?.setValue('');
        this.formulario.controls['valor_unitario_produto_20'].markAsUntouched();
        this.formulario.controls['embalagem_produto_20']?.setValue('');
        this.formulario.controls['embalagem_produto_20'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_20']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_20'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_20']?.setValue('');
        this.formulario.controls['planta_de_expedicao_20'].markAsUntouched();
        break;

      case 15:
        this.formulario.controls['codigo_do_produto_16']?.setValue('');
        this.formulario.controls['codigo_do_produto_16'].markAsUntouched();
        this.formulario.controls['descricao_produto_16']?.setValue('');
        this.formulario.controls['descricao_produto_16'].markAsUntouched();
        this.formulario.controls['quantidade_produto_16']?.setValue('');
        this.formulario.controls['quantidade_produto_16'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_16']?.setValue('');
        this.formulario.controls['valor_unitario_produto_16'].markAsUntouched();
        this.formulario.controls['embalagem_produto_16']?.setValue('');
        this.formulario.controls['embalagem_produto_16'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_16']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_16'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_16']?.setValue('');
        this.formulario.controls['planta_de_expedicao_16'].markAsUntouched();

        this.formulario.controls['codigo_do_produto_17']?.setValue('');
        this.formulario.controls['codigo_do_produto_17'].markAsUntouched();
        this.formulario.controls['descricao_produto_17']?.setValue('');
        this.formulario.controls['descricao_produto_17'].markAsUntouched();
        this.formulario.controls['quantidade_produto_17']?.setValue('');
        this.formulario.controls['quantidade_produto_17'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_17']?.setValue('');
        this.formulario.controls['valor_unitario_produto_17'].markAsUntouched();
        this.formulario.controls['embalagem_produto_17']?.setValue('');
        this.formulario.controls['embalagem_produto_17'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_17']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_17'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_17']?.setValue('');
        this.formulario.controls['planta_de_expedicao_17'].markAsUntouched();

        this.formulario.controls['codigo_do_produto_18']?.setValue('');
        this.formulario.controls['codigo_do_produto_18'].markAsUntouched();
        this.formulario.controls['descricao_produto_18']?.setValue('');
        this.formulario.controls['descricao_produto_18'].markAsUntouched();
        this.formulario.controls['quantidade_produto_18']?.setValue('');
        this.formulario.controls['quantidade_produto_18'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_18']?.setValue('');
        this.formulario.controls['valor_unitario_produto_18'].markAsUntouched();
        this.formulario.controls['embalagem_produto_18']?.setValue('');
        this.formulario.controls['embalagem_produto_18'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_18']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_18'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_18']?.setValue('');
        this.formulario.controls['planta_de_expedicao_18'].markAsUntouched();

        this.formulario.controls['codigo_do_produto_19']?.setValue('');
        this.formulario.controls['codigo_do_produto_19'].markAsUntouched();
        this.formulario.controls['descricao_produto_19']?.setValue('');
        this.formulario.controls['descricao_produto_19'].markAsUntouched();
        this.formulario.controls['quantidade_produto_19']?.setValue('');
        this.formulario.controls['quantidade_produto_19'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_19']?.setValue('');
        this.formulario.controls['valor_unitario_produto_19'].markAsUntouched();
        this.formulario.controls['embalagem_produto_19']?.setValue('');
        this.formulario.controls['embalagem_produto_19'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_19']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_19'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_19']?.setValue('');
        this.formulario.controls['planta_de_expedicao_19'].markAsUntouched();

        this.formulario.controls['codigo_do_produto_20']?.setValue('');
        this.formulario.controls['codigo_do_produto_20'].markAsUntouched();
        this.formulario.controls['descricao_produto_20']?.setValue('');
        this.formulario.controls['descricao_produto_20'].markAsUntouched();
        this.formulario.controls['quantidade_produto_20']?.setValue('');
        this.formulario.controls['quantidade_produto_20'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_20']?.setValue('');
        this.formulario.controls['valor_unitario_produto_20'].markAsUntouched();
        this.formulario.controls['embalagem_produto_20']?.setValue('');
        this.formulario.controls['embalagem_produto_20'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_20']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_20'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_20']?.setValue('');
        this.formulario.controls['planta_de_expedicao_20'].markAsUntouched();
        break;

      case 16:
        this.formulario.controls['codigo_do_produto_17']?.setValue('');
        this.formulario.controls['codigo_do_produto_17'].markAsUntouched();
        this.formulario.controls['descricao_produto_17']?.setValue('');
        this.formulario.controls['descricao_produto_17'].markAsUntouched();
        this.formulario.controls['quantidade_produto_17']?.setValue('');
        this.formulario.controls['quantidade_produto_17'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_17']?.setValue('');
        this.formulario.controls['valor_unitario_produto_17'].markAsUntouched();
        this.formulario.controls['embalagem_produto_17']?.setValue('');
        this.formulario.controls['embalagem_produto_17'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_17']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_17'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_17']?.setValue('');
        this.formulario.controls['planta_de_expedicao_17'].markAsUntouched();

        this.formulario.controls['codigo_do_produto_18']?.setValue('');
        this.formulario.controls['codigo_do_produto_18'].markAsUntouched();
        this.formulario.controls['descricao_produto_18']?.setValue('');
        this.formulario.controls['descricao_produto_18'].markAsUntouched();
        this.formulario.controls['quantidade_produto_18']?.setValue('');
        this.formulario.controls['quantidade_produto_18'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_18']?.setValue('');
        this.formulario.controls['valor_unitario_produto_18'].markAsUntouched();
        this.formulario.controls['embalagem_produto_18']?.setValue('');
        this.formulario.controls['embalagem_produto_18'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_18']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_18'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_18']?.setValue('');
        this.formulario.controls['planta_de_expedicao_18'].markAsUntouched();

        this.formulario.controls['codigo_do_produto_19']?.setValue('');
        this.formulario.controls['codigo_do_produto_19'].markAsUntouched();
        this.formulario.controls['descricao_produto_19']?.setValue('');
        this.formulario.controls['descricao_produto_19'].markAsUntouched();
        this.formulario.controls['quantidade_produto_19']?.setValue('');
        this.formulario.controls['quantidade_produto_19'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_19']?.setValue('');
        this.formulario.controls['valor_unitario_produto_19'].markAsUntouched();
        this.formulario.controls['embalagem_produto_19']?.setValue('');
        this.formulario.controls['embalagem_produto_19'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_19']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_19'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_19']?.setValue('');
        this.formulario.controls['planta_de_expedicao_19'].markAsUntouched();

        this.formulario.controls['codigo_do_produto_20']?.setValue('');
        this.formulario.controls['codigo_do_produto_20'].markAsUntouched();
        this.formulario.controls['descricao_produto_20']?.setValue('');
        this.formulario.controls['descricao_produto_20'].markAsUntouched();
        this.formulario.controls['quantidade_produto_20']?.setValue('');
        this.formulario.controls['quantidade_produto_20'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_20']?.setValue('');
        this.formulario.controls['valor_unitario_produto_20'].markAsUntouched();
        this.formulario.controls['embalagem_produto_20']?.setValue('');
        this.formulario.controls['embalagem_produto_20'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_20']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_20'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_20']?.setValue('');
        this.formulario.controls['planta_de_expedicao_20'].markAsUntouched();
        break;

      case 17:
        this.formulario.controls['codigo_do_produto_18']?.setValue('');
        this.formulario.controls['codigo_do_produto_18'].markAsUntouched();
        this.formulario.controls['descricao_produto_18']?.setValue('');
        this.formulario.controls['descricao_produto_18'].markAsUntouched();
        this.formulario.controls['quantidade_produto_18']?.setValue('');
        this.formulario.controls['quantidade_produto_18'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_18']?.setValue('');
        this.formulario.controls['valor_unitario_produto_18'].markAsUntouched();
        this.formulario.controls['embalagem_produto_18']?.setValue('');
        this.formulario.controls['embalagem_produto_18'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_18']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_18'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_18']?.setValue('');
        this.formulario.controls['planta_de_expedicao_18'].markAsUntouched();

        this.formulario.controls['codigo_do_produto_19']?.setValue('');
        this.formulario.controls['codigo_do_produto_19'].markAsUntouched();
        this.formulario.controls['descricao_produto_19']?.setValue('');
        this.formulario.controls['descricao_produto_19'].markAsUntouched();
        this.formulario.controls['quantidade_produto_19']?.setValue('');
        this.formulario.controls['quantidade_produto_19'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_19']?.setValue('');
        this.formulario.controls['valor_unitario_produto_19'].markAsUntouched();
        this.formulario.controls['embalagem_produto_19']?.setValue('');
        this.formulario.controls['embalagem_produto_19'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_19']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_19'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_19']?.setValue('');
        this.formulario.controls['planta_de_expedicao_19'].markAsUntouched();

        this.formulario.controls['codigo_do_produto_20']?.setValue('');
        this.formulario.controls['codigo_do_produto_20'].markAsUntouched();
        this.formulario.controls['descricao_produto_20']?.setValue('');
        this.formulario.controls['descricao_produto_20'].markAsUntouched();
        this.formulario.controls['quantidade_produto_20']?.setValue('');
        this.formulario.controls['quantidade_produto_20'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_20']?.setValue('');
        this.formulario.controls['valor_unitario_produto_20'].markAsUntouched();
        this.formulario.controls['embalagem_produto_20']?.setValue('');
        this.formulario.controls['embalagem_produto_20'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_20']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_20'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_20']?.setValue('');
        this.formulario.controls['planta_de_expedicao_20'].markAsUntouched();
        break;

      case 18:
        this.formulario.controls['codigo_do_produto_19']?.setValue('');
        this.formulario.controls['codigo_do_produto_19'].markAsUntouched();
        this.formulario.controls['descricao_produto_19']?.setValue('');
        this.formulario.controls['descricao_produto_19'].markAsUntouched();
        this.formulario.controls['quantidade_produto_19']?.setValue('');
        this.formulario.controls['quantidade_produto_19'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_19']?.setValue('');
        this.formulario.controls['valor_unitario_produto_19'].markAsUntouched();
        this.formulario.controls['embalagem_produto_19']?.setValue('');
        this.formulario.controls['embalagem_produto_19'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_19']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_19'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_19']?.setValue('');
        this.formulario.controls['planta_de_expedicao_19'].markAsUntouched();

        this.formulario.controls['codigo_do_produto_20']?.setValue('');
        this.formulario.controls['codigo_do_produto_20'].markAsUntouched();
        this.formulario.controls['descricao_produto_20']?.setValue('');
        this.formulario.controls['descricao_produto_20'].markAsUntouched();
        this.formulario.controls['quantidade_produto_20']?.setValue('');
        this.formulario.controls['quantidade_produto_20'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_20']?.setValue('');
        this.formulario.controls['valor_unitario_produto_20'].markAsUntouched();
        this.formulario.controls['embalagem_produto_20']?.setValue('');
        this.formulario.controls['embalagem_produto_20'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_20']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_20'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_20']?.setValue('');
        this.formulario.controls['planta_de_expedicao_20'].markAsUntouched();
        break;

      case 19:
        this.formulario.controls['codigo_do_produto_20']?.setValue('');
        this.formulario.controls['codigo_do_produto_20'].markAsUntouched();
        this.formulario.controls['descricao_produto_20']?.setValue('');
        this.formulario.controls['descricao_produto_20'].markAsUntouched();
        this.formulario.controls['quantidade_produto_20']?.setValue('');
        this.formulario.controls['quantidade_produto_20'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_20']?.setValue('');
        this.formulario.controls['valor_unitario_produto_20'].markAsUntouched();
        this.formulario.controls['embalagem_produto_20']?.setValue('');
        this.formulario.controls['embalagem_produto_20'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_20']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_20'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_20']?.setValue('');
        this.formulario.controls['planta_de_expedicao_20'].markAsUntouched();
        break;

      case '':
        this.formulario.controls['codigo_do_produto_1']?.setValue('');
        this.formulario.controls['codigo_do_produto_1'].markAsUntouched();
        this.formulario.controls['descricao_produto_1']?.setValue('');
        this.formulario.controls['descricao_produto_1'].markAsUntouched();
        this.formulario.controls['quantidade_produto_1']?.setValue('');
        this.formulario.controls['quantidade_produto_1'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_1']?.setValue('');
        this.formulario.controls['valor_unitario_produto_1'].markAsUntouched();
        this.formulario.controls['embalagem_produto_1']?.setValue('');
        this.formulario.controls['embalagem_produto_1'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_1']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_1'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_1']?.setValue('');
        this.formulario.controls['planta_de_expedicao_1'].markAsUntouched();

        this.formulario.controls['codigo_do_produto_2']?.setValue('');
        this.formulario.controls['codigo_do_produto_2'].markAsUntouched();
        this.formulario.controls['descricao_produto_2']?.setValue('');
        this.formulario.controls['descricao_produto_2'].markAsUntouched();
        this.formulario.controls['quantidade_produto_2']?.setValue('');
        this.formulario.controls['quantidade_produto_2'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_2']?.setValue('');
        this.formulario.controls['valor_unitario_produto_2'].markAsUntouched();
        this.formulario.controls['embalagem_produto_2']?.setValue('');
        this.formulario.controls['embalagem_produto_2'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_2']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_2'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_2']?.setValue('');
        this.formulario.controls['planta_de_expedicao_2'].markAsUntouched();

        this.formulario.controls['codigo_do_produto_3']?.setValue('');
        this.formulario.controls['codigo_do_produto_3'].markAsUntouched();
        this.formulario.controls['descricao_produto_3']?.setValue('');
        this.formulario.controls['descricao_produto_3'].markAsUntouched();
        this.formulario.controls['quantidade_produto_3']?.setValue('');
        this.formulario.controls['quantidade_produto_3'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_3']?.setValue('');
        this.formulario.controls['valor_unitario_produto_3'].markAsUntouched();
        this.formulario.controls['embalagem_produto_3']?.setValue('');
        this.formulario.controls['embalagem_produto_3'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_3']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_3'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_3']?.setValue('');
        this.formulario.controls['planta_de_expedicao_3'].markAsUntouched();

        this.formulario.controls['codigo_do_produto_4']?.setValue('');
        this.formulario.controls['codigo_do_produto_4'].markAsUntouched();
        this.formulario.controls['descricao_produto_4']?.setValue('');
        this.formulario.controls['descricao_produto_4'].markAsUntouched();
        this.formulario.controls['quantidade_produto_4']?.setValue('');
        this.formulario.controls['quantidade_produto_4'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_4']?.setValue('');
        this.formulario.controls['valor_unitario_produto_4'].markAsUntouched();
        this.formulario.controls['embalagem_produto_4']?.setValue('');
        this.formulario.controls['embalagem_produto_4'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_4']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_4'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_4']?.setValue('');
        this.formulario.controls['planta_de_expedicao_4'].markAsUntouched();

        this.formulario.controls['codigo_do_produto_5']?.setValue('');
        this.formulario.controls['codigo_do_produto_5'].markAsUntouched();
        this.formulario.controls['descricao_produto_5']?.setValue('');
        this.formulario.controls['descricao_produto_5'].markAsUntouched();
        this.formulario.controls['quantidade_produto_5']?.setValue('');
        this.formulario.controls['quantidade_produto_5'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_5']?.setValue('');
        this.formulario.controls['valor_unitario_produto_5'].markAsUntouched();
        this.formulario.controls['embalagem_produto_5']?.setValue('');
        this.formulario.controls['embalagem_produto_5'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_5']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_5'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_5']?.setValue('');
        this.formulario.controls['planta_de_expedicao_5'].markAsUntouched();

        this.formulario.controls['codigo_do_produto_6']?.setValue('');
        this.formulario.controls['codigo_do_produto_6'].markAsUntouched();
        this.formulario.controls['descricao_produto_6']?.setValue('');
        this.formulario.controls['descricao_produto_6'].markAsUntouched();
        this.formulario.controls['quantidade_produto_6']?.setValue('');
        this.formulario.controls['quantidade_produto_6'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_6']?.setValue('');
        this.formulario.controls['valor_unitario_produto_6'].markAsUntouched();
        this.formulario.controls['embalagem_produto_6']?.setValue('');
        this.formulario.controls['embalagem_produto_6'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_6']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_6'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_6']?.setValue('');
        this.formulario.controls['planta_de_expedicao_6'].markAsUntouched();

        this.formulario.controls['codigo_do_produto_7']?.setValue('');
        this.formulario.controls['codigo_do_produto_7'].markAsUntouched();
        this.formulario.controls['descricao_produto_7']?.setValue('');
        this.formulario.controls['descricao_produto_7'].markAsUntouched();
        this.formulario.controls['quantidade_produto_7']?.setValue('');
        this.formulario.controls['quantidade_produto_7'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_7']?.setValue('');
        this.formulario.controls['valor_unitario_produto_7'].markAsUntouched();
        this.formulario.controls['embalagem_produto_7']?.setValue('');
        this.formulario.controls['embalagem_produto_7'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_7']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_7'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_7']?.setValue('');
        this.formulario.controls['planta_de_expedicao_7'].markAsUntouched();

        this.formulario.controls['codigo_do_produto_8']?.setValue('');
        this.formulario.controls['codigo_do_produto_8'].markAsUntouched();
        this.formulario.controls['descricao_produto_8']?.setValue('');
        this.formulario.controls['descricao_produto_8'].markAsUntouched();
        this.formulario.controls['quantidade_produto_8']?.setValue('');
        this.formulario.controls['quantidade_produto_8'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_8']?.setValue('');
        this.formulario.controls['valor_unitario_produto_8'].markAsUntouched();
        this.formulario.controls['embalagem_produto_8']?.setValue('');
        this.formulario.controls['embalagem_produto_8'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_8']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_8'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_8']?.setValue('');
        this.formulario.controls['planta_de_expedicao_8'].markAsUntouched();

        this.formulario.controls['codigo_do_produto_9']?.setValue('');
        this.formulario.controls['codigo_do_produto_9'].markAsUntouched();
        this.formulario.controls['descricao_produto_9']?.setValue('');
        this.formulario.controls['descricao_produto_9'].markAsUntouched();
        this.formulario.controls['quantidade_produto_9']?.setValue('');
        this.formulario.controls['quantidade_produto_9'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_9']?.setValue('');
        this.formulario.controls['valor_unitario_produto_9'].markAsUntouched();
        this.formulario.controls['embalagem_produto_9']?.setValue('');
        this.formulario.controls['embalagem_produto_9'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_9']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_9'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_9']?.setValue('');
        this.formulario.controls['planta_de_expedicao_9'].markAsUntouched();

        this.formulario.controls['codigo_do_produto_10']?.setValue('');
        this.formulario.controls['codigo_do_produto_10'].markAsUntouched();
        this.formulario.controls['descricao_produto_10']?.setValue('');
        this.formulario.controls['descricao_produto_10'].markAsUntouched();
        this.formulario.controls['quantidade_produto_10']?.setValue('');
        this.formulario.controls['quantidade_produto_10'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_10']?.setValue('');
        this.formulario.controls['valor_unitario_produto_10'].markAsUntouched();
        this.formulario.controls['embalagem_produto_10']?.setValue('');
        this.formulario.controls['embalagem_produto_10'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_10']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_10'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_10']?.setValue('');
        this.formulario.controls['planta_de_expedicao_10'].markAsUntouched();

        this.formulario.controls['codigo_do_produto_11']?.setValue('');
        this.formulario.controls['codigo_do_produto_11'].markAsUntouched();
        this.formulario.controls['descricao_produto_11']?.setValue('');
        this.formulario.controls['descricao_produto_11'].markAsUntouched();
        this.formulario.controls['quantidade_produto_11']?.setValue('');
        this.formulario.controls['quantidade_produto_11'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_11']?.setValue('');
        this.formulario.controls['valor_unitario_produto_11'].markAsUntouched();
        this.formulario.controls['embalagem_produto_11']?.setValue('');
        this.formulario.controls['embalagem_produto_11'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_11']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_11'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_11']?.setValue('');
        this.formulario.controls['planta_de_expedicao_11'].markAsUntouched();

        this.formulario.controls['codigo_do_produto_12']?.setValue('');
        this.formulario.controls['codigo_do_produto_12'].markAsUntouched();
        this.formulario.controls['descricao_produto_12']?.setValue('');
        this.formulario.controls['descricao_produto_12'].markAsUntouched();
        this.formulario.controls['quantidade_produto_12']?.setValue('');
        this.formulario.controls['quantidade_produto_12'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_12']?.setValue('');
        this.formulario.controls['valor_unitario_produto_12'].markAsUntouched();
        this.formulario.controls['embalagem_produto_12']?.setValue('');
        this.formulario.controls['embalagem_produto_12'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_12']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_12'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_12']?.setValue('');
        this.formulario.controls['planta_de_expedicao_12'].markAsUntouched();

        this.formulario.controls['codigo_do_produto_13']?.setValue('');
        this.formulario.controls['codigo_do_produto_13'].markAsUntouched();
        this.formulario.controls['descricao_produto_13']?.setValue('');
        this.formulario.controls['descricao_produto_13'].markAsUntouched();
        this.formulario.controls['quantidade_produto_13']?.setValue('');
        this.formulario.controls['quantidade_produto_13'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_13']?.setValue('');
        this.formulario.controls['valor_unitario_produto_13'].markAsUntouched();
        this.formulario.controls['embalagem_produto_13']?.setValue('');
        this.formulario.controls['embalagem_produto_13'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_13']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_13'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_13']?.setValue('');
        this.formulario.controls['planta_de_expedicao_13'].markAsUntouched();

        this.formulario.controls['codigo_do_produto_14']?.setValue('');
        this.formulario.controls['codigo_do_produto_14'].markAsUntouched();
        this.formulario.controls['descricao_produto_14']?.setValue('');
        this.formulario.controls['descricao_produto_14'].markAsUntouched();
        this.formulario.controls['quantidade_produto_14']?.setValue('');
        this.formulario.controls['quantidade_produto_14'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_14']?.setValue('');
        this.formulario.controls['valor_unitario_produto_14'].markAsUntouched();
        this.formulario.controls['embalagem_produto_14']?.setValue('');
        this.formulario.controls['embalagem_produto_14'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_14']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_14'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_14']?.setValue('');
        this.formulario.controls['planta_de_expedicao_14'].markAsUntouched();

        this.formulario.controls['codigo_do_produto_15']?.setValue('');
        this.formulario.controls['codigo_do_produto_15'].markAsUntouched();
        this.formulario.controls['descricao_produto_15']?.setValue('');
        this.formulario.controls['descricao_produto_15'].markAsUntouched();
        this.formulario.controls['quantidade_produto_15']?.setValue('');
        this.formulario.controls['quantidade_produto_15'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_15']?.setValue('');
        this.formulario.controls['valor_unitario_produto_15'].markAsUntouched();
        this.formulario.controls['embalagem_produto_15']?.setValue('');
        this.formulario.controls['embalagem_produto_15'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_15']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_15'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_15']?.setValue('');
        this.formulario.controls['planta_de_expedicao_15'].markAsUntouched();

        this.formulario.controls['codigo_do_produto_16']?.setValue('');
        this.formulario.controls['codigo_do_produto_16'].markAsUntouched();
        this.formulario.controls['descricao_produto_16']?.setValue('');
        this.formulario.controls['descricao_produto_16'].markAsUntouched();
        this.formulario.controls['quantidade_produto_16']?.setValue('');
        this.formulario.controls['quantidade_produto_16'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_16']?.setValue('');
        this.formulario.controls['valor_unitario_produto_16'].markAsUntouched();
        this.formulario.controls['embalagem_produto_16']?.setValue('');
        this.formulario.controls['embalagem_produto_16'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_16']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_16'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_16']?.setValue('');
        this.formulario.controls['planta_de_expedicao_16'].markAsUntouched();

        this.formulario.controls['codigo_do_produto_17']?.setValue('');
        this.formulario.controls['codigo_do_produto_17'].markAsUntouched();
        this.formulario.controls['descricao_produto_17']?.setValue('');
        this.formulario.controls['descricao_produto_17'].markAsUntouched();
        this.formulario.controls['quantidade_produto_17']?.setValue('');
        this.formulario.controls['quantidade_produto_17'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_17']?.setValue('');
        this.formulario.controls['valor_unitario_produto_17'].markAsUntouched();
        this.formulario.controls['embalagem_produto_17']?.setValue('');
        this.formulario.controls['embalagem_produto_17'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_17']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_17'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_17']?.setValue('');
        this.formulario.controls['planta_de_expedicao_17'].markAsUntouched();

        this.formulario.controls['codigo_do_produto_18']?.setValue('');
        this.formulario.controls['codigo_do_produto_18'].markAsUntouched();
        this.formulario.controls['descricao_produto_18']?.setValue('');
        this.formulario.controls['descricao_produto_18'].markAsUntouched();
        this.formulario.controls['quantidade_produto_18']?.setValue('');
        this.formulario.controls['quantidade_produto_18'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_18']?.setValue('');
        this.formulario.controls['valor_unitario_produto_18'].markAsUntouched();
        this.formulario.controls['embalagem_produto_18']?.setValue('');
        this.formulario.controls['embalagem_produto_18'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_18']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_18'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_18']?.setValue('');
        this.formulario.controls['planta_de_expedicao_18'].markAsUntouched();

        this.formulario.controls['codigo_do_produto_19']?.setValue('');
        this.formulario.controls['codigo_do_produto_19'].markAsUntouched();
        this.formulario.controls['descricao_produto_19']?.setValue('');
        this.formulario.controls['descricao_produto_19'].markAsUntouched();
        this.formulario.controls['quantidade_produto_19']?.setValue('');
        this.formulario.controls['quantidade_produto_19'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_19']?.setValue('');
        this.formulario.controls['valor_unitario_produto_19'].markAsUntouched();
        this.formulario.controls['embalagem_produto_19']?.setValue('');
        this.formulario.controls['embalagem_produto_19'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_19']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_19'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_19']?.setValue('');
        this.formulario.controls['planta_de_expedicao_19'].markAsUntouched();

        this.formulario.controls['codigo_do_produto_20']?.setValue('');
        this.formulario.controls['codigo_do_produto_20'].markAsUntouched();
        this.formulario.controls['descricao_produto_20']?.setValue('');
        this.formulario.controls['descricao_produto_20'].markAsUntouched();
        this.formulario.controls['quantidade_produto_20']?.setValue('');
        this.formulario.controls['quantidade_produto_20'].markAsUntouched();
        this.formulario.controls['valor_unitario_produto_20']?.setValue('');
        this.formulario.controls['valor_unitario_produto_20'].markAsUntouched();
        this.formulario.controls['embalagem_produto_20']?.setValue('');
        this.formulario.controls['embalagem_produto_20'].markAsUntouched();
        this.formulario.controls['data_da_entrega_solicitada_produto_20']?.setValue('');
        this.formulario.controls['data_da_entrega_solicitada_produto_20'].markAsUntouched();
        this.formulario.controls['planta_de_expedicao_20']?.setValue('');
        this.formulario.controls['planta_de_expedicao_20'].markAsUntouched();
        break;

      default:
        break;
    }
  }

  abrirPipePublico() {
    window.open('https://app.pipefy.com/public/form/oC7ZC0u4', '_blank')
  }

  resetForm() {

    this.anexos = []
    this.anexosUrl = []

    for (let field in this.formulario.controls) {
      this.formulario.controls[field].setValue('');
    }
    this.formulario.markAsUntouched()

    this.formulario.get('empresa')?.clearValidators()
    this.formulario.get('empresa')?.updateValueAndValidity()

    this.formulario.get('categoria')?.clearValidators()
    this.formulario.get('categoria')?.updateValueAndValidity()

    this.formulario.get('tipo_de_venda')?.clearValidators()
    this.formulario.get('tipo_de_venda')?.updateValueAndValidity()

    this.formulario.get('tipo_de_cliente')?.clearValidators()
    this.formulario.get('tipo_de_cliente')?.updateValueAndValidity()

    this.formulario.get('razao_social')?.clearValidators()
    this.formulario.get('razao_social')?.updateValueAndValidity()

    this.formulario.get('cnpj')?.clearValidators()
    this.formulario.get('cnpj')?.updateValueAndValidity()

    this.formulario.get('nome_cliente')?.clearValidators()
    this.formulario.get('nome_cliente')?.updateValueAndValidity()

    this.formulario.get('cpf')?.clearValidators()
    this.formulario.get('cpf')?.updateValueAndValidity()

    this.formulario.get('inscricao_estadual')?.clearValidators()
    this.formulario.get('inscricao_estadual')?.updateValueAndValidity()

    this.formulario.get('nome_da_propriedade')?.clearValidators()
    this.formulario.get('nome_da_propriedade')?.updateValueAndValidity()

    this.formulario.get('cliente_novo')?.clearValidators()
    this.formulario.get('cliente_novo')?.updateValueAndValidity()

    this.formulario.get('cultura')?.clearValidators()
    this.formulario.get('cultura')?.updateValueAndValidity()

    this.formulario.get('tipo_de_pedido')?.clearValidators()
    this.formulario.get('tipo_de_pedido')?.updateValueAndValidity()

    this.formulario.get('campanha')?.clearValidators()
    this.formulario.get('campanha')?.updateValueAndValidity()

    this.formulario.get('numero_de_produtos_no_pedido')?.clearValidators()
    this.formulario.get('numero_de_produtos_no_pedido')?.updateValueAndValidity()

    this.quantidade_de_produtos_int = 0

    this.formulario.get('frete')?.clearValidators()
    this.formulario.get('frete')?.updateValueAndValidity()

    this.formulario.get('cif_valor_do_frete')?.clearValidators()
    this.formulario.get('cif_valor_do_frete')?.updateValueAndValidity()

    // this.formulario.get('pedido_contem_defensivo_quimico')?.clearValidators()
    // this.formulario.get('pedido_contem_defensivo_quimico')?.updateValueAndValidity()

    this.formulario.get('valor_total_do_pedido')?.clearValidators()
    this.formulario.get('valor_total_do_pedido')?.updateValueAndValidity()

    this.formulario.get('forma_de_pagamento')?.clearValidators()
    this.formulario.get('forma_de_pagamento')?.updateValueAndValidity()

    this.formulario.get('condicao_de_parcelamento')?.clearValidators()
    this.formulario.get('condicao_de_parcelamento')?.updateValueAndValidity()

    this.formulario.get('vencimento_condicao_de_pagamento')?.clearValidators()
    this.formulario.get('vencimento_condicao_de_pagamento')?.updateValueAndValidity()

    this.formulario.get('consultor_tecnico_de_vendas')?.clearValidators()
    this.formulario.get('consultor_tecnico_de_vendas')?.updateValueAndValidity()

    this.formulario.get('email_do_solicitante')?.clearValidators()
    this.formulario.get('email_do_solicitante')?.updateValueAndValidity()

    this.formulario.get('data_do_pedido')?.clearValidators()
    this.formulario.get('data_do_pedido')?.updateValueAndValidity()

    this.formulario.get('demanda_urgente')?.clearValidators()
    this.formulario.get('demanda_urgente')?.updateValueAndValidity()

    this.formulario.get('realizar_faturamento_imediato_nbs')?.clearValidators()
    this.formulario.get('realizar_faturamento_imediato_nbs')?.updateValueAndValidity()

    // this.formulario.get('descreva_a_urgencia')?.clearValidators()
    // this.formulario.get('descreva_a_urgencia')?.updateValueAndValidity()

    this.formulario.get('municipio_loja_nutrien')?.clearValidators()
    this.formulario.get('municipio_loja_nutrien')?.updateValueAndValidity()

    this.formulario.get('estado_loja_nutrien')?.clearValidators()
    this.formulario.get('estado_loja_nutrien')?.updateValueAndValidity()

    this.formulario.get('observacao')?.clearValidators()
    this.formulario.get('observacao')?.updateValueAndValidity()

    this.formulario.get('anexo')?.clearValidators()
    this.formulario.get('anexo')?.updateValueAndValidity()

    this.formulario.get('codigo_do_produto_1')?.clearValidators()
    this.formulario.get('descricao_produto_1')?.clearValidators()
    // this.formulario.get('familia_produto_1')?.clearValidators()
    this.formulario.get('quantidade_produto_1')?.clearValidators()
    this.formulario.get('valor_unitario_produto_1')?.clearValidators()
    this.formulario.get('embalagem_produto_1')?.clearValidators()
    this.formulario.get('data_da_entrega_solicitada_produto_1')?.clearValidators()
    this.formulario.get('planta_de_expedicao_1')?.clearValidators()
    // this.formulario.get('centro_de_expedicao_1')?.clearValidators()

    this.formulario.get('codigo_do_produto_1')?.updateValueAndValidity();
    this.formulario.get('descricao_produto_1')?.updateValueAndValidity();
    // this.formulario.get('familia_produto_1')?.updateValueAndValidity();
    this.formulario.get('quantidade_produto_1')?.updateValueAndValidity();
    this.formulario.get('valor_unitario_produto_1')?.updateValueAndValidity();
    this.formulario.get('embalagem_produto_1')?.updateValueAndValidity();
    this.formulario.get('data_da_entrega_solicitada_produto_1')?.updateValueAndValidity();
    this.formulario.get('planta_de_expedicao_1')?.updateValueAndValidity();
    // this.formulario.get('centro_de_expedicao_1')?.updateValueAndValidity();


    this.formulario.get('codigo_do_produto_2')?.clearValidators()
    this.formulario.get('descricao_produto_2')?.clearValidators()
    // this.formulario.get('familia_produto_2')?.clearValidators()
    this.formulario.get('quantidade_produto_2')?.clearValidators()
    this.formulario.get('valor_unitario_produto_2')?.clearValidators()
    this.formulario.get('embalagem_produto_2')?.clearValidators()
    this.formulario.get('data_da_entrega_solicitada_produto_2')?.clearValidators()
    this.formulario.get('planta_de_expedicao_2')?.clearValidators()
    // this.formulario.get('centro_de_expedicao_2')?.clearValidators()

    this.formulario.get('codigo_do_produto_2')?.updateValueAndValidity();
    this.formulario.get('descricao_produto_2')?.updateValueAndValidity();
    // this.formulario.get('familia_produto_2')?.updateValueAndValidity();
    this.formulario.get('quantidade_produto_2')?.updateValueAndValidity();
    this.formulario.get('valor_unitario_produto_2')?.updateValueAndValidity();
    this.formulario.get('embalagem_produto_2')?.updateValueAndValidity();
    this.formulario.get('data_da_entrega_solicitada_produto_2')?.updateValueAndValidity();
    this.formulario.get('planta_de_expedicao_2')?.updateValueAndValidity();
    // this.formulario.get('centro_de_expedicao_2')?.updateValueAndValidity();


    this.formulario.get('codigo_do_produto_3')?.clearValidators()
    this.formulario.get('descricao_produto_3')?.clearValidators()
    // this.formulario.get('familia_produto_3')?.clearValidators()
    this.formulario.get('quantidade_produto_3')?.clearValidators()
    this.formulario.get('valor_unitario_produto_3')?.clearValidators()
    this.formulario.get('embalagem_produto_3')?.clearValidators()
    this.formulario.get('data_da_entrega_solicitada_produto_3')?.clearValidators()
    this.formulario.get('planta_de_expedicao_3')?.clearValidators()
    // this.formulario.get('centro_de_expedicao_3')?.clearValidators()

    this.formulario.get('codigo_do_produto_3')?.updateValueAndValidity();
    this.formulario.get('descricao_produto_3')?.updateValueAndValidity();
    // this.formulario.get('familia_produto_3')?.updateValueAndValidity();
    this.formulario.get('quantidade_produto_3')?.updateValueAndValidity();
    this.formulario.get('valor_unitario_produto_3')?.updateValueAndValidity();
    this.formulario.get('embalagem_produto_3')?.updateValueAndValidity();
    this.formulario.get('data_da_entrega_solicitada_produto_3')?.updateValueAndValidity();
    this.formulario.get('planta_de_expedicao_3')?.updateValueAndValidity();
    // this.formulario.get('centro_de_expedicao_3')?.updateValueAndValidity();


    this.formulario.get('codigo_do_produto_4')?.clearValidators()
    this.formulario.get('descricao_produto_4')?.clearValidators()
    // this.formulario.get('familia_produto_4')?.clearValidators()
    this.formulario.get('quantidade_produto_4')?.clearValidators()
    this.formulario.get('valor_unitario_produto_4')?.clearValidators()
    this.formulario.get('embalagem_produto_4')?.clearValidators()
    this.formulario.get('data_da_entrega_solicitada_produto_4')?.clearValidators()
    this.formulario.get('planta_de_expedicao_4')?.clearValidators()
    // this.formulario.get('centro_de_expedicao_4')?.clearValidators()

    this.formulario.get('codigo_do_produto_4')?.updateValueAndValidity();
    this.formulario.get('descricao_produto_4')?.updateValueAndValidity();
    // this.formulario.get('familia_produto_4')?.updateValueAndValidity();
    this.formulario.get('quantidade_produto_4')?.updateValueAndValidity();
    this.formulario.get('valor_unitario_produto_4')?.updateValueAndValidity();
    this.formulario.get('embalagem_produto_4')?.updateValueAndValidity();
    this.formulario.get('data_da_entrega_solicitada_produto_4')?.updateValueAndValidity();
    this.formulario.get('planta_de_expedicao_4')?.updateValueAndValidity();
    // this.formulario.get('centro_de_expedicao_4')?.updateValueAndValidity();


    this.formulario.get('codigo_do_produto_5')?.clearValidators()
    this.formulario.get('descricao_produto_5')?.clearValidators()
    // this.formulario.get('familia_produto_5')?.clearValidators()
    this.formulario.get('quantidade_produto_5')?.clearValidators()
    this.formulario.get('valor_unitario_produto_5')?.clearValidators()
    this.formulario.get('embalagem_produto_5')?.clearValidators()
    this.formulario.get('data_da_entrega_solicitada_produto_5')?.clearValidators()
    this.formulario.get('planta_de_expedicao_5')?.clearValidators()
    // this.formulario.get('centro_de_expedicao_5')?.clearValidators()

    this.formulario.get('codigo_do_produto_5')?.updateValueAndValidity();
    this.formulario.get('descricao_produto_5')?.updateValueAndValidity();
    // this.formulario.get('familia_produto_5')?.updateValueAndValidity();
    this.formulario.get('quantidade_produto_5')?.updateValueAndValidity();
    this.formulario.get('valor_unitario_produto_5')?.updateValueAndValidity();
    this.formulario.get('embalagem_produto_5')?.updateValueAndValidity();
    this.formulario.get('data_da_entrega_solicitada_produto_5')?.updateValueAndValidity();
    this.formulario.get('planta_de_expedicao_5')?.updateValueAndValidity();
    // this.formulario.get('centro_de_expedicao_5')?.updateValueAndValidity();


    this.formulario.get('codigo_do_produto_6')?.clearValidators()
    this.formulario.get('descricao_produto_6')?.clearValidators()
    // this.formulario.get('familia_produto_6')?.clearValidators()
    this.formulario.get('quantidade_produto_6')?.clearValidators()
    this.formulario.get('valor_unitario_produto_6')?.clearValidators()
    this.formulario.get('embalagem_produto_6')?.clearValidators()
    this.formulario.get('data_da_entrega_solicitada_produto_6')?.clearValidators()
    this.formulario.get('planta_de_expedicao_6')?.clearValidators()
    // this.formulario.get('centro_de_expedicao_6')?.clearValidators()

    this.formulario.get('codigo_do_produto_6')?.updateValueAndValidity();
    this.formulario.get('descricao_produto_6')?.updateValueAndValidity();
    // this.formulario.get('familia_produto_6')?.updateValueAndValidity();
    this.formulario.get('quantidade_produto_6')?.updateValueAndValidity();
    this.formulario.get('valor_unitario_produto_6')?.updateValueAndValidity();
    this.formulario.get('embalagem_produto_6')?.updateValueAndValidity();
    this.formulario.get('data_da_entrega_solicitada_produto_6')?.updateValueAndValidity();
    this.formulario.get('planta_de_expedicao_6')?.updateValueAndValidity();
    // this.formulario.get('centro_de_expedicao_6')?.updateValueAndValidity();


    this.formulario.get('codigo_do_produto_7')?.clearValidators()
    this.formulario.get('descricao_produto_7')?.clearValidators()
    // this.formulario.get('familia_produto_7')?.clearValidators()
    this.formulario.get('quantidade_produto_7')?.clearValidators()
    this.formulario.get('valor_unitario_produto_7')?.clearValidators()
    this.formulario.get('embalagem_produto_7')?.clearValidators()
    this.formulario.get('data_da_entrega_solicitada_produto_7')?.clearValidators()
    this.formulario.get('planta_de_expedicao_7')?.clearValidators()
    // this.formulario.get('centro_de_expedicao_7')?.clearValidators()

    this.formulario.get('codigo_do_produto_7')?.updateValueAndValidity();
    this.formulario.get('descricao_produto_7')?.updateValueAndValidity();
    // this.formulario.get('familia_produto_7')?.updateValueAndValidity();
    this.formulario.get('quantidade_produto_7')?.updateValueAndValidity();
    this.formulario.get('valor_unitario_produto_7')?.updateValueAndValidity();
    this.formulario.get('embalagem_produto_7')?.updateValueAndValidity();
    this.formulario.get('data_da_entrega_solicitada_produto_7')?.updateValueAndValidity();
    this.formulario.get('planta_de_expedicao_7')?.updateValueAndValidity();
    // this.formulario.get('centro_de_expedicao_7')?.updateValueAndValidity();


    this.formulario.get('codigo_do_produto_8')?.clearValidators()
    this.formulario.get('descricao_produto_8')?.clearValidators()
    // this.formulario.get('familia_produto_8')?.clearValidators()
    this.formulario.get('quantidade_produto_8')?.clearValidators()
    this.formulario.get('valor_unitario_produto_8')?.clearValidators()
    this.formulario.get('embalagem_produto_8')?.clearValidators()
    this.formulario.get('data_da_entrega_solicitada_produto_8')?.clearValidators()
    this.formulario.get('planta_de_expedicao_8')?.clearValidators()
    // this.formulario.get('centro_de_expedicao_8')?.clearValidators()

    this.formulario.get('codigo_do_produto_8')?.updateValueAndValidity();
    this.formulario.get('descricao_produto_8')?.updateValueAndValidity();
    // this.formulario.get('familia_produto_8')?.updateValueAndValidity();
    this.formulario.get('quantidade_produto_8')?.updateValueAndValidity();
    this.formulario.get('valor_unitario_produto_8')?.updateValueAndValidity();
    this.formulario.get('embalagem_produto_8')?.updateValueAndValidity();
    this.formulario.get('data_da_entrega_solicitada_produto_8')?.updateValueAndValidity();
    this.formulario.get('planta_de_expedicao_8')?.updateValueAndValidity();
    // this.formulario.get('centro_de_expedicao_8')?.updateValueAndValidity();


    this.formulario.get('codigo_do_produto_9')?.clearValidators()
    this.formulario.get('descricao_produto_9')?.clearValidators()
    // this.formulario.get('familia_produto_9')?.clearValidators()
    this.formulario.get('quantidade_produto_9')?.clearValidators()
    this.formulario.get('valor_unitario_produto_9')?.clearValidators()
    this.formulario.get('embalagem_produto_9')?.clearValidators()
    this.formulario.get('data_da_entrega_solicitada_produto_9')?.clearValidators()
    this.formulario.get('planta_de_expedicao_9')?.clearValidators()
    // this.formulario.get('centro_de_expedicao_9')?.clearValidators()

    this.formulario.get('codigo_do_produto_9')?.updateValueAndValidity();
    this.formulario.get('descricao_produto_9')?.updateValueAndValidity();
    // this.formulario.get('familia_produto_9')?.updateValueAndValidity();
    this.formulario.get('quantidade_produto_9')?.updateValueAndValidity();
    this.formulario.get('valor_unitario_produto_9')?.updateValueAndValidity();
    this.formulario.get('embalagem_produto_9')?.updateValueAndValidity();
    this.formulario.get('data_da_entrega_solicitada_produto_9')?.updateValueAndValidity();
    this.formulario.get('planta_de_expedicao_9')?.updateValueAndValidity();
    // this.formulario.get('centro_de_expedicao_9')?.updateValueAndValidity();


    this.formulario.get('codigo_do_produto_10')?.clearValidators()
    this.formulario.get('descricao_produto_10')?.clearValidators()
    // this.formulario.get('familia_produto_10')?.clearValidators()
    this.formulario.get('quantidade_produto_10')?.clearValidators()
    this.formulario.get('valor_unitario_produto_10')?.clearValidators()
    this.formulario.get('embalagem_produto_10')?.clearValidators()
    this.formulario.get('data_da_entrega_solicitada_produto_10')?.clearValidators()
    this.formulario.get('planta_de_expedicao_10')?.clearValidators()
    // this.formulario.get('centro_de_expedicao_10')?.clearValidators()

    this.formulario.get('codigo_do_produto_10')?.updateValueAndValidity();
    this.formulario.get('descricao_produto_10')?.updateValueAndValidity();
    // this.formulario.get('familia_produto_10')?.updateValueAndValidity();
    this.formulario.get('quantidade_produto_10')?.updateValueAndValidity();
    this.formulario.get('valor_unitario_produto_10')?.updateValueAndValidity();
    this.formulario.get('embalagem_produto_10')?.updateValueAndValidity();
    this.formulario.get('data_da_entrega_solicitada_produto_10')?.updateValueAndValidity();
    this.formulario.get('planta_de_expedicao_10')?.updateValueAndValidity();
    // this.formulario.get('centro_de_expedicao_10')?.updateValueAndValidity();


    this.formulario.get('codigo_do_produto_11')?.clearValidators()
    this.formulario.get('descricao_produto_11')?.clearValidators()
    // this.formulario.get('familia_produto_11')?.clearValidators()
    this.formulario.get('quantidade_produto_11')?.clearValidators()
    this.formulario.get('valor_unitario_produto_11')?.clearValidators()
    this.formulario.get('embalagem_produto_11')?.clearValidators()
    this.formulario.get('data_da_entrega_solicitada_produto_11')?.clearValidators()
    this.formulario.get('planta_de_expedicao_11')?.clearValidators()
    // this.formulario.get('centro_de_expedicao_11')?.clearValidators()

    this.formulario.get('codigo_do_produto_11')?.updateValueAndValidity();
    this.formulario.get('descricao_produto_11')?.updateValueAndValidity();
    // this.formulario.get('familia_produto_11')?.updateValueAndValidity();
    this.formulario.get('quantidade_produto_11')?.updateValueAndValidity();
    this.formulario.get('valor_unitario_produto_11')?.updateValueAndValidity();
    this.formulario.get('embalagem_produto_11')?.updateValueAndValidity();
    this.formulario.get('data_da_entrega_solicitada_produto_11')?.updateValueAndValidity();
    this.formulario.get('planta_de_expedicao_11')?.updateValueAndValidity();
    // this.formulario.get('centro_de_expedicao_11')?.updateValueAndValidity();


    this.formulario.get('codigo_do_produto_12')?.clearValidators()
    this.formulario.get('descricao_produto_12')?.clearValidators()
    // this.formulario.get('familia_produto_12')?.clearValidators()
    this.formulario.get('quantidade_produto_12')?.clearValidators()
    this.formulario.get('valor_unitario_produto_12')?.clearValidators()
    this.formulario.get('embalagem_produto_12')?.clearValidators()
    this.formulario.get('data_da_entrega_solicitada_produto_12')?.clearValidators()
    this.formulario.get('planta_de_expedicao_12')?.clearValidators()
    // this.formulario.get('centro_de_expedicao_12')?.clearValidators()

    this.formulario.get('codigo_do_produto_12')?.updateValueAndValidity();
    this.formulario.get('descricao_produto_12')?.updateValueAndValidity();
    // this.formulario.get('familia_produto_12')?.updateValueAndValidity();
    this.formulario.get('quantidade_produto_12')?.updateValueAndValidity();
    this.formulario.get('valor_unitario_produto_12')?.updateValueAndValidity();
    this.formulario.get('embalagem_produto_12')?.updateValueAndValidity();
    this.formulario.get('data_da_entrega_solicitada_produto_12')?.updateValueAndValidity();
    this.formulario.get('planta_de_expedicao_12')?.updateValueAndValidity();
    // this.formulario.get('centro_de_expedicao_12')?.updateValueAndValidity();


    this.formulario.get('codigo_do_produto_13')?.clearValidators()
    this.formulario.get('descricao_produto_13')?.clearValidators()
    // this.formulario.get('familia_produto_13')?.clearValidators()
    this.formulario.get('quantidade_produto_13')?.clearValidators()
    this.formulario.get('valor_unitario_produto_13')?.clearValidators()
    this.formulario.get('embalagem_produto_13')?.clearValidators()
    this.formulario.get('data_da_entrega_solicitada_produto_13')?.clearValidators()
    this.formulario.get('planta_de_expedicao_13')?.clearValidators()
    // this.formulario.get('centro_de_expedicao_13')?.clearValidators()

    this.formulario.get('codigo_do_produto_13')?.updateValueAndValidity();
    this.formulario.get('descricao_produto_13')?.updateValueAndValidity();
    // this.formulario.get('familia_produto_13')?.updateValueAndValidity();
    this.formulario.get('quantidade_produto_13')?.updateValueAndValidity();
    this.formulario.get('valor_unitario_produto_13')?.updateValueAndValidity();
    this.formulario.get('embalagem_produto_13')?.updateValueAndValidity();
    this.formulario.get('data_da_entrega_solicitada_produto_13')?.updateValueAndValidity();
    this.formulario.get('planta_de_expedicao_13')?.updateValueAndValidity();
    // this.formulario.get('centro_de_expedicao_13')?.updateValueAndValidity();


    this.formulario.get('codigo_do_produto_14')?.clearValidators()
    this.formulario.get('descricao_produto_14')?.clearValidators()
    // this.formulario.get('familia_produto_14')?.clearValidators()
    this.formulario.get('quantidade_produto_14')?.clearValidators()
    this.formulario.get('valor_unitario_produto_14')?.clearValidators()
    this.formulario.get('embalagem_produto_14')?.clearValidators()
    this.formulario.get('data_da_entrega_solicitada_produto_14')?.clearValidators()
    this.formulario.get('planta_de_expedicao_14')?.clearValidators()
    // this.formulario.get('centro_de_expedicao_14')?.clearValidators()

    this.formulario.get('codigo_do_produto_14')?.updateValueAndValidity();
    this.formulario.get('descricao_produto_14')?.updateValueAndValidity();
    // this.formulario.get('familia_produto_14')?.updateValueAndValidity();
    this.formulario.get('quantidade_produto_14')?.updateValueAndValidity();
    this.formulario.get('valor_unitario_produto_14')?.updateValueAndValidity();
    this.formulario.get('embalagem_produto_14')?.updateValueAndValidity();
    this.formulario.get('data_da_entrega_solicitada_produto_14')?.updateValueAndValidity();
    this.formulario.get('planta_de_expedicao_14')?.updateValueAndValidity();
    // this.formulario.get('centro_de_expedicao_14')?.updateValueAndValidity();


    this.formulario.get('codigo_do_produto_15')?.clearValidators()
    this.formulario.get('descricao_produto_15')?.clearValidators()
    // this.formulario.get('familia_produto_15')?.clearValidators()
    this.formulario.get('quantidade_produto_15')?.clearValidators()
    this.formulario.get('valor_unitario_produto_15')?.clearValidators()
    this.formulario.get('embalagem_produto_15')?.clearValidators()
    this.formulario.get('data_da_entrega_solicitada_produto_15')?.clearValidators()
    this.formulario.get('planta_de_expedicao_15')?.clearValidators()
    // this.formulario.get('centro_de_expedicao_15')?.clearValidators()

    this.formulario.get('codigo_do_produto_15')?.updateValueAndValidity();
    this.formulario.get('descricao_produto_15')?.updateValueAndValidity();
    // this.formulario.get('familia_produto_15')?.updateValueAndValidity();
    this.formulario.get('quantidade_produto_15')?.updateValueAndValidity();
    this.formulario.get('valor_unitario_produto_15')?.updateValueAndValidity();
    this.formulario.get('embalagem_produto_15')?.updateValueAndValidity();
    this.formulario.get('data_da_entrega_solicitada_produto_15')?.updateValueAndValidity();
    this.formulario.get('planta_de_expedicao_15')?.updateValueAndValidity();
    // this.formulario.get('centro_de_expedicao_15')?.updateValueAndValidity();


    this.formulario.get('codigo_do_produto_16')?.clearValidators()
    this.formulario.get('descricao_produto_16')?.clearValidators()
    // this.formulario.get('familia_produto_16')?.clearValidators()
    this.formulario.get('quantidade_produto_16')?.clearValidators()
    this.formulario.get('valor_unitario_produto_16')?.clearValidators()
    this.formulario.get('embalagem_produto_16')?.clearValidators()
    this.formulario.get('data_da_entrega_solicitada_produto_16')?.clearValidators()
    this.formulario.get('planta_de_expedicao_16')?.clearValidators()
    // this.formulario.get('centro_de_expedicao_16')?.clearValidators()

    this.formulario.get('codigo_do_produto_16')?.updateValueAndValidity();
    this.formulario.get('descricao_produto_16')?.updateValueAndValidity();
    // this.formulario.get('familia_produto_16')?.updateValueAndValidity();
    this.formulario.get('quantidade_produto_16')?.updateValueAndValidity();
    this.formulario.get('valor_unitario_produto_16')?.updateValueAndValidity();
    this.formulario.get('embalagem_produto_16')?.updateValueAndValidity();
    this.formulario.get('data_da_entrega_solicitada_produto_16')?.updateValueAndValidity();
    this.formulario.get('planta_de_expedicao_16')?.updateValueAndValidity();
    // this.formulario.get('centro_de_expedicao_16')?.updateValueAndValidity();


    this.formulario.get('codigo_do_produto_17')?.clearValidators()
    this.formulario.get('descricao_produto_17')?.clearValidators()
    // this.formulario.get('familia_produto_17')?.clearValidators()
    this.formulario.get('quantidade_produto_17')?.clearValidators()
    this.formulario.get('valor_unitario_produto_17')?.clearValidators()
    this.formulario.get('embalagem_produto_17')?.clearValidators()
    this.formulario.get('data_da_entrega_solicitada_produto_17')?.clearValidators()
    this.formulario.get('planta_de_expedicao_17')?.clearValidators()
    // this.formulario.get('centro_de_expedicao_17')?.clearValidators()

    this.formulario.get('codigo_do_produto_17')?.updateValueAndValidity();
    this.formulario.get('descricao_produto_17')?.updateValueAndValidity();
    // this.formulario.get('familia_produto_17')?.updateValueAndValidity();
    this.formulario.get('quantidade_produto_17')?.updateValueAndValidity();
    this.formulario.get('valor_unitario_produto_17')?.updateValueAndValidity();
    this.formulario.get('embalagem_produto_17')?.updateValueAndValidity();
    this.formulario.get('data_da_entrega_solicitada_produto_17')?.updateValueAndValidity();
    this.formulario.get('planta_de_expedicao_17')?.updateValueAndValidity();
    // this.formulario.get('centro_de_expedicao_17')?.updateValueAndValidity();


    this.formulario.get('codigo_do_produto_18')?.clearValidators()
    this.formulario.get('descricao_produto_18')?.clearValidators()
    // this.formulario.get('familia_produto_18')?.clearValidators()
    this.formulario.get('quantidade_produto_18')?.clearValidators()
    this.formulario.get('valor_unitario_produto_18')?.clearValidators()
    this.formulario.get('embalagem_produto_18')?.clearValidators()
    this.formulario.get('data_da_entrega_solicitada_produto_18')?.clearValidators()
    this.formulario.get('planta_de_expedicao_18')?.clearValidators()
    // this.formulario.get('centro_de_expedicao_18')?.clearValidators()

    this.formulario.get('codigo_do_produto_18')?.updateValueAndValidity();
    this.formulario.get('descricao_produto_18')?.updateValueAndValidity();
    // this.formulario.get('familia_produto_18')?.updateValueAndValidity();
    this.formulario.get('quantidade_produto_18')?.updateValueAndValidity();
    this.formulario.get('valor_unitario_produto_18')?.updateValueAndValidity();
    this.formulario.get('embalagem_produto_18')?.updateValueAndValidity();
    this.formulario.get('data_da_entrega_solicitada_produto_18')?.updateValueAndValidity();
    this.formulario.get('planta_de_expedicao_18')?.updateValueAndValidity();
    // this.formulario.get('centro_de_expedicao_18')?.updateValueAndValidity();


    this.formulario.get('codigo_do_produto_19')?.clearValidators()
    this.formulario.get('descricao_produto_19')?.clearValidators()
    // this.formulario.get('familia_produto_19')?.clearValidators()
    this.formulario.get('quantidade_produto_19')?.clearValidators()
    this.formulario.get('valor_unitario_produto_19')?.clearValidators()
    this.formulario.get('embalagem_produto_19')?.clearValidators()
    this.formulario.get('data_da_entrega_solicitada_produto_19')?.clearValidators()
    this.formulario.get('planta_de_expedicao_19')?.clearValidators()
    // this.formulario.get('centro_de_expedicao_19')?.clearValidators()

    this.formulario.get('codigo_do_produto_19')?.updateValueAndValidity();
    this.formulario.get('descricao_produto_19')?.updateValueAndValidity();
    // this.formulario.get('familia_produto_19')?.updateValueAndValidity();
    this.formulario.get('quantidade_produto_19')?.updateValueAndValidity();
    this.formulario.get('valor_unitario_produto_19')?.updateValueAndValidity();
    this.formulario.get('embalagem_produto_19')?.updateValueAndValidity();
    this.formulario.get('data_da_entrega_solicitada_produto_19')?.updateValueAndValidity();
    this.formulario.get('planta_de_expedicao_19')?.updateValueAndValidity();
    // this.formulario.get('centro_de_expedicao_19')?.updateValueAndValidity();


    this.formulario.get('codigo_do_produto_20')?.clearValidators()
    this.formulario.get('descricao_produto_20')?.clearValidators()
    // this.formulario.get('familia_produto_20')?.clearValidators()
    this.formulario.get('quantidade_produto_20')?.clearValidators()
    this.formulario.get('valor_unitario_produto_20')?.clearValidators()
    this.formulario.get('embalagem_produto_20')?.clearValidators()
    this.formulario.get('data_da_entrega_solicitada_produto_20')?.clearValidators()
    this.formulario.get('planta_de_expedicao_20')?.clearValidators()
    // this.formulario.get('centro_de_expedicao_20')?.clearValidators()

    this.formulario.get('codigo_do_produto_20')?.updateValueAndValidity();
    this.formulario.get('descricao_produto_20')?.updateValueAndValidity();
    // this.formulario.get('familia_produto_20')?.updateValueAndValidity();
    this.formulario.get('quantidade_produto_20')?.updateValueAndValidity();
    this.formulario.get('valor_unitario_produto_20')?.updateValueAndValidity();
    this.formulario.get('embalagem_produto_20')?.updateValueAndValidity();
    this.formulario.get('data_da_entrega_solicitada_produto_20')?.updateValueAndValidity();
    this.formulario.get('planta_de_expedicao_20')?.updateValueAndValidity();
    // this.formulario.get('centro_de_expedicao_20')?.updateValueAndValidity();

  }

  clearValidatorsDetalhes(){
    this.formulario.get('codigo_do_produto_1')?.clearValidators()
    this.formulario.get('descricao_produto_1')?.clearValidators()
    // this.formulario.get('familia_produto_1')?.clearValidators()
    this.formulario.get('quantidade_produto_1')?.clearValidators()
    this.formulario.get('valor_unitario_produto_1')?.clearValidators()
    this.formulario.get('embalagem_produto_1')?.clearValidators()
    this.formulario.get('data_da_entrega_solicitada_produto_1')?.clearValidators()
    this.formulario.get('planta_de_expedicao_1')?.clearValidators()
    // this.formulario.get('centro_de_expedicao_1')?.clearValidators()

    this.formulario.get('codigo_do_produto_1')?.updateValueAndValidity();
    this.formulario.get('descricao_produto_1')?.updateValueAndValidity();
    // this.formulario.get('familia_produto_1')?.updateValueAndValidity();
    this.formulario.get('quantidade_produto_1')?.updateValueAndValidity();
    this.formulario.get('valor_unitario_produto_1')?.updateValueAndValidity();
    this.formulario.get('embalagem_produto_1')?.updateValueAndValidity();
    this.formulario.get('data_da_entrega_solicitada_produto_1')?.updateValueAndValidity();
    this.formulario.get('planta_de_expedicao_1')?.updateValueAndValidity();
    // this.formulario.get('centro_de_expedicao_1')?.updateValueAndValidity();


    this.formulario.get('codigo_do_produto_2')?.clearValidators()
    this.formulario.get('descricao_produto_2')?.clearValidators()
    // this.formulario.get('familia_produto_2')?.clearValidators()
    this.formulario.get('quantidade_produto_2')?.clearValidators()
    this.formulario.get('valor_unitario_produto_2')?.clearValidators()
    this.formulario.get('embalagem_produto_2')?.clearValidators()
    this.formulario.get('data_da_entrega_solicitada_produto_2')?.clearValidators()
    this.formulario.get('planta_de_expedicao_2')?.clearValidators()
    // this.formulario.get('centro_de_expedicao_2')?.clearValidators()

    this.formulario.get('codigo_do_produto_2')?.updateValueAndValidity();
    this.formulario.get('descricao_produto_2')?.updateValueAndValidity();
    // this.formulario.get('familia_produto_2')?.updateValueAndValidity();
    this.formulario.get('quantidade_produto_2')?.updateValueAndValidity();
    this.formulario.get('valor_unitario_produto_2')?.updateValueAndValidity();
    this.formulario.get('embalagem_produto_2')?.updateValueAndValidity();
    this.formulario.get('data_da_entrega_solicitada_produto_2')?.updateValueAndValidity();
    this.formulario.get('planta_de_expedicao_2')?.updateValueAndValidity();
    // this.formulario.get('centro_de_expedicao_2')?.updateValueAndValidity();


    this.formulario.get('codigo_do_produto_3')?.clearValidators()
    this.formulario.get('descricao_produto_3')?.clearValidators()
    // this.formulario.get('familia_produto_3')?.clearValidators()
    this.formulario.get('quantidade_produto_3')?.clearValidators()
    this.formulario.get('valor_unitario_produto_3')?.clearValidators()
    this.formulario.get('embalagem_produto_3')?.clearValidators()
    this.formulario.get('data_da_entrega_solicitada_produto_3')?.clearValidators()
    this.formulario.get('planta_de_expedicao_3')?.clearValidators()
    // this.formulario.get('centro_de_expedicao_3')?.clearValidators()

    this.formulario.get('codigo_do_produto_3')?.updateValueAndValidity();
    this.formulario.get('descricao_produto_3')?.updateValueAndValidity();
    // this.formulario.get('familia_produto_3')?.updateValueAndValidity();
    this.formulario.get('quantidade_produto_3')?.updateValueAndValidity();
    this.formulario.get('valor_unitario_produto_3')?.updateValueAndValidity();
    this.formulario.get('embalagem_produto_3')?.updateValueAndValidity();
    this.formulario.get('data_da_entrega_solicitada_produto_3')?.updateValueAndValidity();
    this.formulario.get('planta_de_expedicao_3')?.updateValueAndValidity();
    // this.formulario.get('centro_de_expedicao_3')?.updateValueAndValidity();


    this.formulario.get('codigo_do_produto_4')?.clearValidators()
    this.formulario.get('descricao_produto_4')?.clearValidators()
    // this.formulario.get('familia_produto_4')?.clearValidators()
    this.formulario.get('quantidade_produto_4')?.clearValidators()
    this.formulario.get('valor_unitario_produto_4')?.clearValidators()
    this.formulario.get('embalagem_produto_4')?.clearValidators()
    this.formulario.get('data_da_entrega_solicitada_produto_4')?.clearValidators()
    this.formulario.get('planta_de_expedicao_4')?.clearValidators()
    // this.formulario.get('centro_de_expedicao_4')?.clearValidators()

    this.formulario.get('codigo_do_produto_4')?.updateValueAndValidity();
    this.formulario.get('descricao_produto_4')?.updateValueAndValidity();
    // this.formulario.get('familia_produto_4')?.updateValueAndValidity();
    this.formulario.get('quantidade_produto_4')?.updateValueAndValidity();
    this.formulario.get('valor_unitario_produto_4')?.updateValueAndValidity();
    this.formulario.get('embalagem_produto_4')?.updateValueAndValidity();
    this.formulario.get('data_da_entrega_solicitada_produto_4')?.updateValueAndValidity();
    this.formulario.get('planta_de_expedicao_4')?.updateValueAndValidity();
    // this.formulario.get('centro_de_expedicao_4')?.updateValueAndValidity();


    this.formulario.get('codigo_do_produto_5')?.clearValidators()
    this.formulario.get('descricao_produto_5')?.clearValidators()
    // this.formulario.get('familia_produto_5')?.clearValidators()
    this.formulario.get('quantidade_produto_5')?.clearValidators()
    this.formulario.get('valor_unitario_produto_5')?.clearValidators()
    this.formulario.get('embalagem_produto_5')?.clearValidators()
    this.formulario.get('data_da_entrega_solicitada_produto_5')?.clearValidators()
    this.formulario.get('planta_de_expedicao_5')?.clearValidators()
    // this.formulario.get('centro_de_expedicao_5')?.clearValidators()

    this.formulario.get('codigo_do_produto_5')?.updateValueAndValidity();
    this.formulario.get('descricao_produto_5')?.updateValueAndValidity();
    // this.formulario.get('familia_produto_5')?.updateValueAndValidity();
    this.formulario.get('quantidade_produto_5')?.updateValueAndValidity();
    this.formulario.get('valor_unitario_produto_5')?.updateValueAndValidity();
    this.formulario.get('embalagem_produto_5')?.updateValueAndValidity();
    this.formulario.get('data_da_entrega_solicitada_produto_5')?.updateValueAndValidity();
    this.formulario.get('planta_de_expedicao_5')?.updateValueAndValidity();
    // this.formulario.get('centro_de_expedicao_5')?.updateValueAndValidity();


    this.formulario.get('codigo_do_produto_6')?.clearValidators()
    this.formulario.get('descricao_produto_6')?.clearValidators()
    // this.formulario.get('familia_produto_6')?.clearValidators()
    this.formulario.get('quantidade_produto_6')?.clearValidators()
    this.formulario.get('valor_unitario_produto_6')?.clearValidators()
    this.formulario.get('embalagem_produto_6')?.clearValidators()
    this.formulario.get('data_da_entrega_solicitada_produto_6')?.clearValidators()
    this.formulario.get('planta_de_expedicao_6')?.clearValidators()
    // this.formulario.get('centro_de_expedicao_6')?.clearValidators()

    this.formulario.get('codigo_do_produto_6')?.updateValueAndValidity();
    this.formulario.get('descricao_produto_6')?.updateValueAndValidity();
    // this.formulario.get('familia_produto_6')?.updateValueAndValidity();
    this.formulario.get('quantidade_produto_6')?.updateValueAndValidity();
    this.formulario.get('valor_unitario_produto_6')?.updateValueAndValidity();
    this.formulario.get('embalagem_produto_6')?.updateValueAndValidity();
    this.formulario.get('data_da_entrega_solicitada_produto_6')?.updateValueAndValidity();
    this.formulario.get('planta_de_expedicao_6')?.updateValueAndValidity();
    // this.formulario.get('centro_de_expedicao_6')?.updateValueAndValidity();


    this.formulario.get('codigo_do_produto_7')?.clearValidators()
    this.formulario.get('descricao_produto_7')?.clearValidators()
    // this.formulario.get('familia_produto_7')?.clearValidators()
    this.formulario.get('quantidade_produto_7')?.clearValidators()
    this.formulario.get('valor_unitario_produto_7')?.clearValidators()
    this.formulario.get('embalagem_produto_7')?.clearValidators()
    this.formulario.get('data_da_entrega_solicitada_produto_7')?.clearValidators()
    this.formulario.get('planta_de_expedicao_7')?.clearValidators()
    // this.formulario.get('centro_de_expedicao_7')?.clearValidators()

    this.formulario.get('codigo_do_produto_7')?.updateValueAndValidity();
    this.formulario.get('descricao_produto_7')?.updateValueAndValidity();
    // this.formulario.get('familia_produto_7')?.updateValueAndValidity();
    this.formulario.get('quantidade_produto_7')?.updateValueAndValidity();
    this.formulario.get('valor_unitario_produto_7')?.updateValueAndValidity();
    this.formulario.get('embalagem_produto_7')?.updateValueAndValidity();
    this.formulario.get('data_da_entrega_solicitada_produto_7')?.updateValueAndValidity();
    this.formulario.get('planta_de_expedicao_7')?.updateValueAndValidity();
    // this.formulario.get('centro_de_expedicao_7')?.updateValueAndValidity();


    this.formulario.get('codigo_do_produto_8')?.clearValidators()
    this.formulario.get('descricao_produto_8')?.clearValidators()
    // this.formulario.get('familia_produto_8')?.clearValidators()
    this.formulario.get('quantidade_produto_8')?.clearValidators()
    this.formulario.get('valor_unitario_produto_8')?.clearValidators()
    this.formulario.get('embalagem_produto_8')?.clearValidators()
    this.formulario.get('data_da_entrega_solicitada_produto_8')?.clearValidators()
    this.formulario.get('planta_de_expedicao_8')?.clearValidators()
    // this.formulario.get('centro_de_expedicao_8')?.clearValidators()

    this.formulario.get('codigo_do_produto_8')?.updateValueAndValidity();
    this.formulario.get('descricao_produto_8')?.updateValueAndValidity();
    // this.formulario.get('familia_produto_8')?.updateValueAndValidity();
    this.formulario.get('quantidade_produto_8')?.updateValueAndValidity();
    this.formulario.get('valor_unitario_produto_8')?.updateValueAndValidity();
    this.formulario.get('embalagem_produto_8')?.updateValueAndValidity();
    this.formulario.get('data_da_entrega_solicitada_produto_8')?.updateValueAndValidity();
    this.formulario.get('planta_de_expedicao_8')?.updateValueAndValidity();
    // this.formulario.get('centro_de_expedicao_8')?.updateValueAndValidity();


    this.formulario.get('codigo_do_produto_9')?.clearValidators()
    this.formulario.get('descricao_produto_9')?.clearValidators()
    // this.formulario.get('familia_produto_9')?.clearValidators()
    this.formulario.get('quantidade_produto_9')?.clearValidators()
    this.formulario.get('valor_unitario_produto_9')?.clearValidators()
    this.formulario.get('embalagem_produto_9')?.clearValidators()
    this.formulario.get('data_da_entrega_solicitada_produto_9')?.clearValidators()
    this.formulario.get('planta_de_expedicao_9')?.clearValidators()
    // this.formulario.get('centro_de_expedicao_9')?.clearValidators()

    this.formulario.get('codigo_do_produto_9')?.updateValueAndValidity();
    this.formulario.get('descricao_produto_9')?.updateValueAndValidity();
    // this.formulario.get('familia_produto_9')?.updateValueAndValidity();
    this.formulario.get('quantidade_produto_9')?.updateValueAndValidity();
    this.formulario.get('valor_unitario_produto_9')?.updateValueAndValidity();
    this.formulario.get('embalagem_produto_9')?.updateValueAndValidity();
    this.formulario.get('data_da_entrega_solicitada_produto_9')?.updateValueAndValidity();
    this.formulario.get('planta_de_expedicao_9')?.updateValueAndValidity();
    // this.formulario.get('centro_de_expedicao_9')?.updateValueAndValidity();


    this.formulario.get('codigo_do_produto_10')?.clearValidators()
    this.formulario.get('descricao_produto_10')?.clearValidators()
    // this.formulario.get('familia_produto_10')?.clearValidators()
    this.formulario.get('quantidade_produto_10')?.clearValidators()
    this.formulario.get('valor_unitario_produto_10')?.clearValidators()
    this.formulario.get('embalagem_produto_10')?.clearValidators()
    this.formulario.get('data_da_entrega_solicitada_produto_10')?.clearValidators()
    this.formulario.get('planta_de_expedicao_10')?.clearValidators()
    // this.formulario.get('centro_de_expedicao_10')?.clearValidators()

    this.formulario.get('codigo_do_produto_10')?.updateValueAndValidity();
    this.formulario.get('descricao_produto_10')?.updateValueAndValidity();
    // this.formulario.get('familia_produto_10')?.updateValueAndValidity();
    this.formulario.get('quantidade_produto_10')?.updateValueAndValidity();
    this.formulario.get('valor_unitario_produto_10')?.updateValueAndValidity();
    this.formulario.get('embalagem_produto_10')?.updateValueAndValidity();
    this.formulario.get('data_da_entrega_solicitada_produto_10')?.updateValueAndValidity();
    this.formulario.get('planta_de_expedicao_10')?.updateValueAndValidity();
    // this.formulario.get('centro_de_expedicao_10')?.updateValueAndValidity();


    this.formulario.get('codigo_do_produto_11')?.clearValidators()
    this.formulario.get('descricao_produto_11')?.clearValidators()
    // this.formulario.get('familia_produto_11')?.clearValidators()
    this.formulario.get('quantidade_produto_11')?.clearValidators()
    this.formulario.get('valor_unitario_produto_11')?.clearValidators()
    this.formulario.get('embalagem_produto_11')?.clearValidators()
    this.formulario.get('data_da_entrega_solicitada_produto_11')?.clearValidators()
    this.formulario.get('planta_de_expedicao_11')?.clearValidators()
    // this.formulario.get('centro_de_expedicao_11')?.clearValidators()

    this.formulario.get('codigo_do_produto_11')?.updateValueAndValidity();
    this.formulario.get('descricao_produto_11')?.updateValueAndValidity();
    // this.formulario.get('familia_produto_11')?.updateValueAndValidity();
    this.formulario.get('quantidade_produto_11')?.updateValueAndValidity();
    this.formulario.get('valor_unitario_produto_11')?.updateValueAndValidity();
    this.formulario.get('embalagem_produto_11')?.updateValueAndValidity();
    this.formulario.get('data_da_entrega_solicitada_produto_11')?.updateValueAndValidity();
    this.formulario.get('planta_de_expedicao_11')?.updateValueAndValidity();
    // this.formulario.get('centro_de_expedicao_11')?.updateValueAndValidity();


    this.formulario.get('codigo_do_produto_12')?.clearValidators()
    this.formulario.get('descricao_produto_12')?.clearValidators()
    // this.formulario.get('familia_produto_12')?.clearValidators()
    this.formulario.get('quantidade_produto_12')?.clearValidators()
    this.formulario.get('valor_unitario_produto_12')?.clearValidators()
    this.formulario.get('embalagem_produto_12')?.clearValidators()
    this.formulario.get('data_da_entrega_solicitada_produto_12')?.clearValidators()
    this.formulario.get('planta_de_expedicao_12')?.clearValidators()
    // this.formulario.get('centro_de_expedicao_12')?.clearValidators()

    this.formulario.get('codigo_do_produto_12')?.updateValueAndValidity();
    this.formulario.get('descricao_produto_12')?.updateValueAndValidity();
    // this.formulario.get('familia_produto_12')?.updateValueAndValidity();
    this.formulario.get('quantidade_produto_12')?.updateValueAndValidity();
    this.formulario.get('valor_unitario_produto_12')?.updateValueAndValidity();
    this.formulario.get('embalagem_produto_12')?.updateValueAndValidity();
    this.formulario.get('data_da_entrega_solicitada_produto_12')?.updateValueAndValidity();
    this.formulario.get('planta_de_expedicao_12')?.updateValueAndValidity();
    // this.formulario.get('centro_de_expedicao_12')?.updateValueAndValidity();


    this.formulario.get('codigo_do_produto_13')?.clearValidators()
    this.formulario.get('descricao_produto_13')?.clearValidators()
    // this.formulario.get('familia_produto_13')?.clearValidators()
    this.formulario.get('quantidade_produto_13')?.clearValidators()
    this.formulario.get('valor_unitario_produto_13')?.clearValidators()
    this.formulario.get('embalagem_produto_13')?.clearValidators()
    this.formulario.get('data_da_entrega_solicitada_produto_13')?.clearValidators()
    this.formulario.get('planta_de_expedicao_13')?.clearValidators()
    // this.formulario.get('centro_de_expedicao_13')?.clearValidators()

    this.formulario.get('codigo_do_produto_13')?.updateValueAndValidity();
    this.formulario.get('descricao_produto_13')?.updateValueAndValidity();
    // this.formulario.get('familia_produto_13')?.updateValueAndValidity();
    this.formulario.get('quantidade_produto_13')?.updateValueAndValidity();
    this.formulario.get('valor_unitario_produto_13')?.updateValueAndValidity();
    this.formulario.get('embalagem_produto_13')?.updateValueAndValidity();
    this.formulario.get('data_da_entrega_solicitada_produto_13')?.updateValueAndValidity();
    this.formulario.get('planta_de_expedicao_13')?.updateValueAndValidity();
    // this.formulario.get('centro_de_expedicao_13')?.updateValueAndValidity();


    this.formulario.get('codigo_do_produto_14')?.clearValidators()
    this.formulario.get('descricao_produto_14')?.clearValidators()
    // this.formulario.get('familia_produto_14')?.clearValidators()
    this.formulario.get('quantidade_produto_14')?.clearValidators()
    this.formulario.get('valor_unitario_produto_14')?.clearValidators()
    this.formulario.get('embalagem_produto_14')?.clearValidators()
    this.formulario.get('data_da_entrega_solicitada_produto_14')?.clearValidators()
    this.formulario.get('planta_de_expedicao_14')?.clearValidators()
    // this.formulario.get('centro_de_expedicao_14')?.clearValidators()

    this.formulario.get('codigo_do_produto_14')?.updateValueAndValidity();
    this.formulario.get('descricao_produto_14')?.updateValueAndValidity();
    // this.formulario.get('familia_produto_14')?.updateValueAndValidity();
    this.formulario.get('quantidade_produto_14')?.updateValueAndValidity();
    this.formulario.get('valor_unitario_produto_14')?.updateValueAndValidity();
    this.formulario.get('embalagem_produto_14')?.updateValueAndValidity();
    this.formulario.get('data_da_entrega_solicitada_produto_14')?.updateValueAndValidity();
    this.formulario.get('planta_de_expedicao_14')?.updateValueAndValidity();
    // this.formulario.get('centro_de_expedicao_14')?.updateValueAndValidity();


    this.formulario.get('codigo_do_produto_15')?.clearValidators()
    this.formulario.get('descricao_produto_15')?.clearValidators()
    // this.formulario.get('familia_produto_15')?.clearValidators()
    this.formulario.get('quantidade_produto_15')?.clearValidators()
    this.formulario.get('valor_unitario_produto_15')?.clearValidators()
    this.formulario.get('embalagem_produto_15')?.clearValidators()
    this.formulario.get('data_da_entrega_solicitada_produto_15')?.clearValidators()
    this.formulario.get('planta_de_expedicao_15')?.clearValidators()
    // this.formulario.get('centro_de_expedicao_15')?.clearValidators()

    this.formulario.get('codigo_do_produto_15')?.updateValueAndValidity();
    this.formulario.get('descricao_produto_15')?.updateValueAndValidity();
    // this.formulario.get('familia_produto_15')?.updateValueAndValidity();
    this.formulario.get('quantidade_produto_15')?.updateValueAndValidity();
    this.formulario.get('valor_unitario_produto_15')?.updateValueAndValidity();
    this.formulario.get('embalagem_produto_15')?.updateValueAndValidity();
    this.formulario.get('data_da_entrega_solicitada_produto_15')?.updateValueAndValidity();
    this.formulario.get('planta_de_expedicao_15')?.updateValueAndValidity();
    // this.formulario.get('centro_de_expedicao_15')?.updateValueAndValidity();


    this.formulario.get('codigo_do_produto_16')?.clearValidators()
    this.formulario.get('descricao_produto_16')?.clearValidators()
    // this.formulario.get('familia_produto_16')?.clearValidators()
    this.formulario.get('quantidade_produto_16')?.clearValidators()
    this.formulario.get('valor_unitario_produto_16')?.clearValidators()
    this.formulario.get('embalagem_produto_16')?.clearValidators()
    this.formulario.get('data_da_entrega_solicitada_produto_16')?.clearValidators()
    this.formulario.get('planta_de_expedicao_16')?.clearValidators()
    // this.formulario.get('centro_de_expedicao_16')?.clearValidators()

    this.formulario.get('codigo_do_produto_16')?.updateValueAndValidity();
    this.formulario.get('descricao_produto_16')?.updateValueAndValidity();
    // this.formulario.get('familia_produto_16')?.updateValueAndValidity();
    this.formulario.get('quantidade_produto_16')?.updateValueAndValidity();
    this.formulario.get('valor_unitario_produto_16')?.updateValueAndValidity();
    this.formulario.get('embalagem_produto_16')?.updateValueAndValidity();
    this.formulario.get('data_da_entrega_solicitada_produto_16')?.updateValueAndValidity();
    this.formulario.get('planta_de_expedicao_16')?.updateValueAndValidity();
    // this.formulario.get('centro_de_expedicao_16')?.updateValueAndValidity();


    this.formulario.get('codigo_do_produto_17')?.clearValidators()
    this.formulario.get('descricao_produto_17')?.clearValidators()
    // this.formulario.get('familia_produto_17')?.clearValidators()
    this.formulario.get('quantidade_produto_17')?.clearValidators()
    this.formulario.get('valor_unitario_produto_17')?.clearValidators()
    this.formulario.get('embalagem_produto_17')?.clearValidators()
    this.formulario.get('data_da_entrega_solicitada_produto_17')?.clearValidators()
    this.formulario.get('planta_de_expedicao_17')?.clearValidators()
    // this.formulario.get('centro_de_expedicao_17')?.clearValidators()

    this.formulario.get('codigo_do_produto_17')?.updateValueAndValidity();
    this.formulario.get('descricao_produto_17')?.updateValueAndValidity();
    // this.formulario.get('familia_produto_17')?.updateValueAndValidity();
    this.formulario.get('quantidade_produto_17')?.updateValueAndValidity();
    this.formulario.get('valor_unitario_produto_17')?.updateValueAndValidity();
    this.formulario.get('embalagem_produto_17')?.updateValueAndValidity();
    this.formulario.get('data_da_entrega_solicitada_produto_17')?.updateValueAndValidity();
    this.formulario.get('planta_de_expedicao_17')?.updateValueAndValidity();
    // this.formulario.get('centro_de_expedicao_17')?.updateValueAndValidity();


    this.formulario.get('codigo_do_produto_18')?.clearValidators()
    this.formulario.get('descricao_produto_18')?.clearValidators()
    // this.formulario.get('familia_produto_18')?.clearValidators()
    this.formulario.get('quantidade_produto_18')?.clearValidators()
    this.formulario.get('valor_unitario_produto_18')?.clearValidators()
    this.formulario.get('embalagem_produto_18')?.clearValidators()
    this.formulario.get('data_da_entrega_solicitada_produto_18')?.clearValidators()
    this.formulario.get('planta_de_expedicao_18')?.clearValidators()
    // this.formulario.get('centro_de_expedicao_18')?.clearValidators()

    this.formulario.get('codigo_do_produto_18')?.updateValueAndValidity();
    this.formulario.get('descricao_produto_18')?.updateValueAndValidity();
    // this.formulario.get('familia_produto_18')?.updateValueAndValidity();
    this.formulario.get('quantidade_produto_18')?.updateValueAndValidity();
    this.formulario.get('valor_unitario_produto_18')?.updateValueAndValidity();
    this.formulario.get('embalagem_produto_18')?.updateValueAndValidity();
    this.formulario.get('data_da_entrega_solicitada_produto_18')?.updateValueAndValidity();
    this.formulario.get('planta_de_expedicao_18')?.updateValueAndValidity();
    // this.formulario.get('centro_de_expedicao_18')?.updateValueAndValidity();


    this.formulario.get('codigo_do_produto_19')?.clearValidators()
    this.formulario.get('descricao_produto_19')?.clearValidators()
    // this.formulario.get('familia_produto_19')?.clearValidators()
    this.formulario.get('quantidade_produto_19')?.clearValidators()
    this.formulario.get('valor_unitario_produto_19')?.clearValidators()
    this.formulario.get('embalagem_produto_19')?.clearValidators()
    this.formulario.get('data_da_entrega_solicitada_produto_19')?.clearValidators()
    this.formulario.get('planta_de_expedicao_19')?.clearValidators()
    // this.formulario.get('centro_de_expedicao_19')?.clearValidators()

    this.formulario.get('codigo_do_produto_19')?.updateValueAndValidity();
    this.formulario.get('descricao_produto_19')?.updateValueAndValidity();
    // this.formulario.get('familia_produto_19')?.updateValueAndValidity();
    this.formulario.get('quantidade_produto_19')?.updateValueAndValidity();
    this.formulario.get('valor_unitario_produto_19')?.updateValueAndValidity();
    this.formulario.get('embalagem_produto_19')?.updateValueAndValidity();
    this.formulario.get('data_da_entrega_solicitada_produto_19')?.updateValueAndValidity();
    this.formulario.get('planta_de_expedicao_19')?.updateValueAndValidity();
    // this.formulario.get('centro_de_expedicao_19')?.updateValueAndValidity();


    this.formulario.get('codigo_do_produto_20')?.clearValidators()
    this.formulario.get('descricao_produto_20')?.clearValidators()
    // this.formulario.get('familia_produto_20')?.clearValidators()
    this.formulario.get('quantidade_produto_20')?.clearValidators()
    this.formulario.get('valor_unitario_produto_20')?.clearValidators()
    this.formulario.get('embalagem_produto_20')?.clearValidators()
    this.formulario.get('data_da_entrega_solicitada_produto_20')?.clearValidators()
    this.formulario.get('planta_de_expedicao_20')?.clearValidators()
    // this.formulario.get('centro_de_expedicao_20')?.clearValidators()

    this.formulario.get('codigo_do_produto_20')?.updateValueAndValidity();
    this.formulario.get('descricao_produto_20')?.updateValueAndValidity();
    // this.formulario.get('familia_produto_20')?.updateValueAndValidity();
    this.formulario.get('quantidade_produto_20')?.updateValueAndValidity();
    this.formulario.get('valor_unitario_produto_20')?.updateValueAndValidity();
    this.formulario.get('embalagem_produto_20')?.updateValueAndValidity();
    this.formulario.get('data_da_entrega_solicitada_produto_20')?.updateValueAndValidity();
    this.formulario.get('planta_de_expedicao_20')?.updateValueAndValidity();
    // this.formulario.get('centro_de_expedicao_20')?.updateValueAndValidity();

  }

  getDescricaoProduto(codigoDigitado:any, descricaoProdutoDigitado:any){
    if (!codigoDigitado) {
      this.erroCodigoInvalido = false;
      return
    }
    this.descricao = this.produtos.filter(e=> e.Material==codigoDigitado);
    if(this.descricao.length > 0){
      this.erroCodigoInvalido = false;
      this.formulario.get(descricaoProdutoDigitado)?.setValue(this.descricao[0]["Texto breve material"]);
    }else{
      this.formulario.get(descricaoProdutoDigitado)?.setValue("");
      this.erroCodigoInvalido = true;
    }
  }

  getProdutos() {
    this.msgSpinner = "Por favor, aguarde um instante";
    this.spinner.show();
    this.pipefy.getProdutosPedVendaFaturamento().subscribe((res: any) => {
      this.spinner.hide();
      this.produtos = res.body.data.attachments_url_pipefy ? res.body.data.attachments_url_pipefy : [];
      if (this.produtos.length == 0) {
        Swal.fire({
          title: 'Desculpe!',
          text: 'Ocorreu algum erro durante a Listagem de Produtos. Por favor, tente novamente.',
          icon: 'error',
          showConfirmButton: false,
          allowOutsideClick: false,
          allowEscapeKey: false
        })
      }
    },
    (error: any) => {
      this.spinner.hide();
      console.log(error);
      Swal.fire({
        title: 'Desculpe!',
        text: 'Ocorreu algum erro durante a Listagem de Produtos. Por favor, tente novamente.',
        icon: 'error',
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })
    })
  }
}
