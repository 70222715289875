import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Inject, Injectable } from "@angular/core";
import { Location } from "@angular/common";
import { Observable, forkJoin, from, of } from "rxjs";
import { concatMap, switchAll, switchMap } from "rxjs/operators";

import { MsalBroadcastService, MsalGuardConfiguration, MsalService, MSAL_GUARD_CONFIG } from "@azure/msal-angular";
import { BaseGuard } from "../base-guard/base-guard.guard";
import { environment } from "src/environments/environment";
import { AdsPerfil } from "src/app/componentes/formMat/form.enum";
import { UserInfosService } from "../usuario/user-infos.service";

@Injectable()
export class MsalGuardAd extends BaseGuard {

  constructor(
    @Inject(MSAL_GUARD_CONFIG) protected msalGuardConfig: MsalGuardConfiguration,
    protected msalBroadcastService: MsalBroadcastService,
    protected authService: MsalService,
    protected location: Location,
    protected router: Router,
    public userInfo: UserInfosService
  ) {
    super(msalGuardConfig, msalBroadcastService, authService, location, router);
  }

  private async aguardarUserInfo(): Promise<void> {
    while (this.userInfo.getName() == undefined && this.userInfo.getUserEmail() == undefined) {
      await new Promise((resolve) => setTimeout(resolve, 100)); // Aguarda 200 milissegundos
    }
  }

  activateHelper(state?: RouterStateSnapshot, route?: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    if (environment.localhost) {
      return of(true);
    }

    const result = super.activateHelper(state, route);

    const ads: AdsPerfil[] = !!route?.data?.ads ? route?.data?.ads : [];


    return from ( this.aguardarUserInfo()).pipe(
      switchMap( () => {
        return result.pipe(
          concatMap( () => {
            if(ads.length === 0) {
              return of(true);
            }
            const groups = this.userInfo.getUserGroups();
            const groupBoolean = ads.every((role: string) => groups.includes(role));
            return of(groupBoolean? true : super.parseUrl(''));
          }));
      })
    );
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean|UrlTree> {
    this.authService.getLogger().verbose("Guard - canActivate");
    return this.activateHelper(state, route);
}

canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean|UrlTree> {
    this.authService.getLogger().verbose("Guard - canActivateChild");
    return this.activateHelper(state, route);
}

canLoad(): Observable<boolean> {
    this.authService.getLogger().verbose("Guard - canLoad");
    // @ts-ignore
    return this.activateHelper();
}

}
