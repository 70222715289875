import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MsalService } from '@azure/msal-angular';
import { NgxSpinnerService } from 'ngx-spinner';
import { UserInfosService } from 'src/app/autenticacao/usuario/user-infos.service';
import { PipefyService } from 'src/app/services/pipefy.service';
import { TrataInputsService } from 'src/app/services/trata-inputs.service';
import Swal from 'sweetalert2';
import { pipe_fields_incidentes } from '../../pipe_fields_incidentes';
import { pipe_fields_ids } from './pipe_fields_ids';

@Component({
  selector: 'app-lancamentos-de-notas-massiva',
  templateUrl: './lancamentos-de-notas-massiva.component.html',
  styleUrls: ['./lancamentos-de-notas-massiva.component.css']
})
export class LancamentosDeNotasMassivaComponent implements OnInit {

  formulario!: FormGroup;

  cards: any [] = []
  notas: any[] = [];
  boletos: any[] = [];
  xml: any[] = []

  cardsUrl: any[] = []
  notasUrl: any[] = []
  boletosUrl: any[] = []
  xmlUrl:any[]=[]

  error_counter: number = 0;
  erro: boolean = false

  emailApi: string =''

  constructor(private formBuilder: FormBuilder,
              private trataInput: TrataInputsService,
              private pipefy: PipefyService,
              private spinner: NgxSpinnerService,
              private msalService: MsalService,
              private userInfo: UserInfosService) { }

  ngOnInit(): void {


    this.formulario = this.formBuilder.group({
      email_do_solicitante: ['', [Validators.required, Validators.email]],
      planilha_de_cards: ['', [Validators.required]],
      anexo_nota: ['', []],
      anexo_boleto: ['', []],
      anexo_xml: ['', []],
    })

    this.emailApi = this.userInfo.getUserEmail()
  }



  enviarFormulario() {
    this.spinner.show()

    const criarBody = async () => {

      await this.getPipesUrlCards(this.cards);
      if(this.cards.length >0){
        while (this.cards.length != this.cardsUrl.length) {
          await this.delay(300);
        }
      }

      if(this.cards.length>0){
        var cardsUrl2 = ''
         cardsUrl2 = '['
        for(var i = 0; i < this.cardsUrl.length ; i++){
           cardsUrl2 =  cardsUrl2+'"'+this.cardsUrl[i]+'",'
        }
         cardsUrl2 = cardsUrl2.slice(0, -1)
         cardsUrl2 = cardsUrl2+"]"

      }else{
        cardsUrl2 =''
      }

      await this.getPipesUrlNota(this.notas);
      if(this.notas.length >0){
        while (this.notas.length != this.notasUrl.length) {
          await this.delay(300);
        }
      }

      if(this.notas.length>0){
        var notasUrl2 = ''
         notasUrl2 = '['
        for(var i = 0; i < this.notasUrl.length ; i++){
           notasUrl2 =  notasUrl2+'"'+this.notasUrl[i]+'",'
        }
         notasUrl2 = notasUrl2.slice(0, -1)
         notasUrl2 = notasUrl2+"]"

      }else{
        notasUrl2 =''
      }

      await this.getPipesUrlBoleto(this.boletos);

      if(this.boletos.length >0){
        while (this.boletos.length != this.boletosUrl.length) {
          await this.delay(300);
        }
      }

      if(this.boletos.length>0){
        var boletosUrl2 = ''
         boletosUrl2 = '['
        for(var i = 0; i < this.boletosUrl.length ; i++){
           boletosUrl2 =  boletosUrl2+'"'+this.boletosUrl[i]+'",'
        }
         boletosUrl2 = boletosUrl2.slice(0, -1)
         boletosUrl2 = boletosUrl2+"]"

      }else{
        boletosUrl2 =''
      }

      await this.getPipesUrlXML(this.xml);

      if(this.xml.length >0){
        while (this.xml.length != this.xmlUrl.length) {
          await this.delay(300);
        }
      }

      if(this.xml.length>0){
        var xmlUrl2 = ''
         xmlUrl2 = '['
        for(var i = 0; i < this.xmlUrl.length ; i++){
           xmlUrl2 =  xmlUrl2+'"'+this.xmlUrl[i]+'",'
        }
         xmlUrl2 = xmlUrl2.slice(0, -1)
         xmlUrl2 = xmlUrl2+"]"

      }else{
        xmlUrl2 =''
      }

      let email_do_solicitante = this.trataInput.removeCaracters(this.formulario.getRawValue().email_do_solicitante)

      let pipe_fields = pipe_fields_ids
      let fields_values = [email_do_solicitante, cardsUrl2,notasUrl2, boletosUrl2, xmlUrl2]
      let pipe_id = "302691443"

      this.pipefy.createCard(this.pipefy.criaBody(pipe_fields, fields_values, pipe_id)).subscribe((res) => {
        // console.log(res)
        this.spinner.hide()

        if(res.code == 504){
          Swal.fire({
            title: 'Desculpe!',
            text: 'Ocorreu algum erro durante a sua solicitação. Por favor, tente novamente.',
            icon: 'error',
            confirmButtonText: 'Ok',
            confirmButtonColor: '#88D600',
            allowOutsideClick: false,
            allowEscapeKey: false
          })
        }

        if (res.data.result_creation_card.id != undefined) {
          let card_id = res.data.result_creation_card.id
          Swal.fire({
            title: 'Chamado aberto com Sucesso!',
            text: 'O chamado ' + card_id + ' foi aberto com sucesso. Nossa equipe irá tratar e retornar em breve. Obrigado!',
            icon: 'success',
            confirmButtonText: 'Ok',
            confirmButtonColor: '#88D600',
          })
          this.resetform()
        }

        if (res.data.result_creation_card.errors != undefined) {
          var erro = res.data.result_creation_card.errors[0].message
          Swal.fire({
            title: 'Desculpe!',
            html: '<p>Ocorreu um erro.</p>' + '<a href="https://app.pipefy.com/public/form/-bxxCixe" target="_blank">Clique aqui </a>' + '<span>para fazer sua solicitação</span>',
            text: erro,
            icon: 'error',
            confirmButtonColor: '#88D600',
          })
              this.cardsUrl = []
              this.notasUrl = []
              this.boletosUrl = []
              this.xmlUrl = []

          let pipe_fields_incidentes_values = ['Incidente', 'Correção', 'Portal NBS', 'F&A', 'Pagamentos', "Pagamentos - criação de card -- Lançamento de Notas Massiva", erro, 'Portal NBS Error', 'incidente@portal.com',
            'Sim', 'Outros', 'criação de card via portal', 'Página web com problemas', this.emailApi]

          var pipe_fields_incidentes_values2 = []
          for (var i = 0; i < pipe_fields_incidentes_values.length; i++) {
            pipe_fields_incidentes_values2.push(this.trataInput.removeCaractersPipeIncidentes(pipe_fields_incidentes_values[i]))
          }
          // console.log(pipe_fields_incidentes_values2)

          let pipe_incidentes_id = '302487533'

          this.pipefy.createCard(this.pipefy.criaBody(pipe_fields_incidentes, pipe_fields_incidentes_values2, pipe_incidentes_id)).subscribe((res) => {
            // console.log(res)
          })
        }
      }, (error: any) => {
        this.spinner.hide()
        // console.log(error.status)
        this.error_counter = this.error_counter + 1
        if (this.error_counter < 2) {
          if (error.status == 401) {
            // console.log("entrei em error.status")
            Swal.fire({
              title: 'Ops, sua sessão expirou!',
              text: 'Por favor, saia e logue novamente.',
              icon: 'error',
              confirmButtonText: 'Sair',
              confirmButtonColor: '#ff6961',
              allowOutsideClick: false,
              allowEscapeKey: false
            })
              .then(() => {
                this.msalService.logoutRedirect()
              }
              )
          } else {
            Swal.fire({
              title: 'Desculpe!',
              text: 'Ocorreu algum erro durante a sua solicitação. Por favor, tente novamente.',
              icon: 'error',
              confirmButtonText: 'Ok',
              confirmButtonColor: '#88D600',
              allowOutsideClick: false,
              allowEscapeKey: false
            })
          }
        }
        else {
          this.spinner.hide()
          Swal.fire({
            title: 'Desculpe!',
            html: '<p>Ocorreu um erro.</p>' + '<a href="https://app.pipefy.com/public/form/-bxxCixe" target="_blank">Clique aqui </a>' + '<span>para fazer sua solicitação</span>',
            icon: 'error',
            confirmButtonColor: '#88D600',
          })
              this.cardsUrl = []
              this.notasUrl = []
              this.boletosUrl = []
              this.xmlUrl = []
        }
      })
    }

    criarBody()
  }

  delay(time: any) {
    return new Promise(resolve => setTimeout(resolve, time));
  }

  saveAnexoCards(event: any): void {
    var files = event.target.files;

    for (var i = 0; i < files.length; i++) {
      this.cards.push(files[i])
    }
  }

  removeFileCards(file: File) {
    const index: number = this.cards.indexOf(file);
    this.cards.splice(index, 1)
    Swal.fire({
      position: 'top-end',
      icon: 'success',
      title: 'Arquivo Removido com Sucesso.',
      showConfirmButton: false,
      timer: 1500
    })
    if(this.cards.length == 0){
      this.formulario.controls['anexo_nota_ou_boleto'].markAsTouched()
      this.formulario.get('anexo_nota_ou_boleto')?.setValue('')
    }
  }

  async getPipesUrlCards(files: any) {
    this.erro = false;

  // Função utilitária para ler o arquivo usando FileReader como uma Promise
  const readFileAsBase64 = (file: File) => {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const result = reader.result?.toString();
        if (result) {
          const fileContentStart = result.indexOf(",");
          resolve(result.substring(fileContentStart + 1)); // Retorna o conteúdo Base64
        } else {
          reject(new Error("Falha ao ler o arquivo"));
        }
      };
      reader.onerror = (error) => {
        reject(error);
      };
    });
  };

  // Loop sobre os arquivos, esperando cada leitura e operação assíncrona
  for (let i = 0; i < files.length; i++) {
    const fileContent = files[i];
    const fileName = files[i].name;

    try {
      const fileBase64 = await readFileAsBase64(fileContent); // Aguarda a leitura do arquivo
      const urlResposta = await this.pipefy.getFileUrl3(fileBase64, fileName); // Obtém a URL após o upload do arquivo

      var fileUrlTemp = "";
        var startIndex = 0;

        if (urlResposta.indexOf("orgs") > 0) {
          startIndex = urlResposta.indexOf("orgs/");
        } else {
          startIndex = urlResposta.indexOf("uploads/");
        }

        var endIndex = urlResposta.indexOf("?");
        fileUrlTemp = urlResposta.substring(startIndex, endIndex);

        this.cardsUrl.push(fileUrlTemp)

      //this.invociesUrl.push(url); // Adiciona a URL à lista

    } catch (error: any) {
      this.erro = true;
      this.spinner.hide();
      this.error_counter = this.error_counter + 1;
      if (this.error_counter < 2) {
        if (error.status === 401) {
          Swal.fire({
            title: 'Ops, sua sessão expirou!',
            text: 'Por favor, saia e logue novamente.',
            icon: 'error',
            confirmButtonText: 'Sair',
            confirmButtonColor: '#ff6961',
            allowOutsideClick: false,
            allowEscapeKey: false
          }).then(() => {
            this.msalService.logoutRedirect();
          });
        } else {
          Swal.fire({
            title: 'Desculpe!',
            text: 'Ocorreu algum erro durante a sua solicitação. Por favor, tente novamente.',
            icon: 'error',
            confirmButtonText: 'Ok',
            confirmButtonColor: '#88D600',
            allowOutsideClick: false,
            allowEscapeKey: false
          });
        }
      } else {
        this.spinner.hide();
        Swal.fire({
          title: 'Desculpe!',
          html: '<p>Ocorreu um erro.</p>' + '<a href="https://app.pipefy.com/public/form/3Sd_ATrN" target="_blank">Clique aqui </a>' + '<span>para fazer sua solicitação</span>',
          icon: 'error',
          confirmButtonColor: '#88D600',
        });
        this.cardsUrl = [];
      }
    }
    }
    return new Promise<void>((resolve) => {resolve();});
  }



  saveAnexoNota(event: any): void {
    var files = event.target.files;

    for (var i = 0; i < files.length; i++) {
      this.notas.push(files[i])
    }
  }

  removeFileNota(file: File) {
    const index: number = this.notas.indexOf(file);
    this.notas.splice(index, 1)
    Swal.fire({
      position: 'top-end',
      icon: 'success',
      title: 'Arquivo Removido com Sucesso.',
      showConfirmButton: false,
      timer: 1500
    })
    if(this.notas.length == 0){
      this.formulario.controls['anexo_nota_ou_boleto'].markAsTouched()
      this.formulario.get('anexo_nota_ou_boleto')?.setValue('')
    }
  }

  async getPipesUrlNota(files: any) {
    this.erro = false;

  // Função utilitária para ler o arquivo usando FileReader como uma Promise
  const readFileAsBase64 = (file: File) => {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const result = reader.result?.toString();
        if (result) {
          const fileContentStart = result.indexOf(",");
          resolve(result.substring(fileContentStart + 1)); // Retorna o conteúdo Base64
        } else {
          reject(new Error("Falha ao ler o arquivo"));
        }
      };
      reader.onerror = (error) => {
        reject(error);
      };
    });
  };

  // Loop sobre os arquivos, esperando cada leitura e operação assíncrona
  for (let i = 0; i < files.length; i++) {
    const fileContent = files[i];
    const fileName = files[i].name;

    try {
      const fileBase64 = await readFileAsBase64(fileContent); // Aguarda a leitura do arquivo
      const urlResposta = await this.pipefy.getFileUrl3(fileBase64, fileName); // Obtém a URL após o upload do arquivo

      var fileUrlTemp = "";
        var startIndex = 0;

        if (urlResposta.indexOf("orgs") > 0) {
          startIndex = urlResposta.indexOf("orgs/");
        } else {
          startIndex = urlResposta.indexOf("uploads/");
        }

        var endIndex = urlResposta.indexOf("?");
        fileUrlTemp = urlResposta.substring(startIndex, endIndex);

        this.notasUrl.push(fileUrlTemp)

      //this.invociesUrl.push(url); // Adiciona a URL à lista

    } catch (error: any) {
      this.erro = true;
      this.spinner.hide();
      this.error_counter = this.error_counter + 1;
      if (this.error_counter < 2) {
        if (error.status === 401) {
          Swal.fire({
            title: 'Ops, sua sessão expirou!',
            text: 'Por favor, saia e logue novamente.',
            icon: 'error',
            confirmButtonText: 'Sair',
            confirmButtonColor: '#ff6961',
            allowOutsideClick: false,
            allowEscapeKey: false
          }).then(() => {
            this.msalService.logoutRedirect();
          });
        } else {
          Swal.fire({
            title: 'Desculpe!',
            text: 'Ocorreu algum erro durante a sua solicitação. Por favor, tente novamente.',
            icon: 'error',
            confirmButtonText: 'Ok',
            confirmButtonColor: '#88D600',
            allowOutsideClick: false,
            allowEscapeKey: false
          });
        }
      } else {
        this.spinner.hide();
        Swal.fire({
          title: 'Desculpe!',
          html: '<p>Ocorreu um erro.</p>' + '<a href="https://app.pipefy.com/public/form/3Sd_ATrN" target="_blank">Clique aqui </a>' + '<span>para fazer sua solicitação</span>',
          icon: 'error',
          confirmButtonColor: '#88D600',
        });
        this.notasUrl = [];
      }
    }
    }
    return new Promise<void>((resolve) => {resolve();});
  }

  saveAnexoBoleto(event: any): void {
    var files = event.target.files;

    for (var i = 0; i < files.length; i++) {
      this.boletos.push(files[i])
    }
  }

  removeFileBoleto(file: File) {
    const index: number = this.boletos.indexOf(file);
    this.boletos.splice(index, 1)
    Swal.fire({
      position: 'top-end',
      icon: 'success',
      title: 'Arquivo Removido com Sucesso.',
      showConfirmButton: false,
      timer: 1500
    })
    if(this.boletos.length == 0){
      this.formulario.controls['anexo_boleto'].markAsTouched()
      this.formulario.get('anexo_boleto')?.setValue('')
    }
  }

  async getPipesUrlBoleto(files: any) {
    this.erro = false;

  // Função utilitária para ler o arquivo usando FileReader como uma Promise
  const readFileAsBase64 = (file: File) => {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const result = reader.result?.toString();
        if (result) {
          const fileContentStart = result.indexOf(",");
          resolve(result.substring(fileContentStart + 1)); // Retorna o conteúdo Base64
        } else {
          reject(new Error("Falha ao ler o arquivo"));
        }
      };
      reader.onerror = (error) => {
        reject(error);
      };
    });
  };

  // Loop sobre os arquivos, esperando cada leitura e operação assíncrona
  for (let i = 0; i < files.length; i++) {
    const fileContent = files[i];
    const fileName = files[i].name;

    try {
      const fileBase64 = await readFileAsBase64(fileContent); // Aguarda a leitura do arquivo
      const urlResposta = await this.pipefy.getFileUrl3(fileBase64, fileName); // Obtém a URL após o upload do arquivo

      var fileUrlTemp = "";
        var startIndex = 0;

        if (urlResposta.indexOf("orgs") > 0) {
          startIndex = urlResposta.indexOf("orgs/");
        } else {
          startIndex = urlResposta.indexOf("uploads/");
        }

        var endIndex = urlResposta.indexOf("?");
        fileUrlTemp = urlResposta.substring(startIndex, endIndex);

        this.boletosUrl.push(fileUrlTemp)

      //this.invociesUrl.push(url); // Adiciona a URL à lista

    } catch (error: any) {
      this.erro = true;
      this.spinner.hide();
      this.error_counter = this.error_counter + 1;
      if (this.error_counter < 2) {
        if (error.status === 401) {
          Swal.fire({
            title: 'Ops, sua sessão expirou!',
            text: 'Por favor, saia e logue novamente.',
            icon: 'error',
            confirmButtonText: 'Sair',
            confirmButtonColor: '#ff6961',
            allowOutsideClick: false,
            allowEscapeKey: false
          }).then(() => {
            this.msalService.logoutRedirect();
          });
        } else {
          Swal.fire({
            title: 'Desculpe!',
            text: 'Ocorreu algum erro durante a sua solicitação. Por favor, tente novamente.',
            icon: 'error',
            confirmButtonText: 'Ok',
            confirmButtonColor: '#88D600',
            allowOutsideClick: false,
            allowEscapeKey: false
          });
        }
      } else {
        this.spinner.hide();
        Swal.fire({
          title: 'Desculpe!',
          html: '<p>Ocorreu um erro.</p>' + '<a href="https://app.pipefy.com/public/form/3Sd_ATrN" target="_blank">Clique aqui </a>' + '<span>para fazer sua solicitação</span>',
          icon: 'error',
          confirmButtonColor: '#88D600',
        });
        this.boletosUrl = [];
      }
    }
    }
    return new Promise<void>((resolve) => {resolve();});
  }

  saveAnexoXML(event: any): void {
    var files = event.target.files;

    for (var i = 0; i < files.length; i++) {
      this.xml.push(files[i])
    }
  }

  removeFileXML(file: File) {
    const index: number = this.xml.indexOf(file);
    this.xml.splice(index, 1)
    Swal.fire({
      position: 'top-end',
      icon: 'success',
      title: 'Arquivo Removido com Sucesso.',
      showConfirmButton: false,
      timer: 1500
    })
    if(this.xml.length == 0){
      this.formulario.controls['anexo_xml'].markAsTouched()
      this.formulario.get('anexo_xml')?.setValue('')
    }
  }

  async getPipesUrlXML(files: any) {
    this.erro = false;

  // Função utilitária para ler o arquivo usando FileReader como uma Promise
  const readFileAsBase64 = (file: File) => {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const result = reader.result?.toString();
        if (result) {
          const fileContentStart = result.indexOf(",");
          resolve(result.substring(fileContentStart + 1)); // Retorna o conteúdo Base64
        } else {
          reject(new Error("Falha ao ler o arquivo"));
        }
      };
      reader.onerror = (error) => {
        reject(error);
      };
    });
  };

  // Loop sobre os arquivos, esperando cada leitura e operação assíncrona
  for (let i = 0; i < files.length; i++) {
    const fileContent = files[i];
    const fileName = files[i].name;

    try {
      const fileBase64 = await readFileAsBase64(fileContent); // Aguarda a leitura do arquivo
      const urlResposta = await this.pipefy.getFileUrl3(fileBase64, fileName); // Obtém a URL após o upload do arquivo

      var fileUrlTemp = "";
        var startIndex = 0;

        if (urlResposta.indexOf("orgs") > 0) {
          startIndex = urlResposta.indexOf("orgs/");
        } else {
          startIndex = urlResposta.indexOf("uploads/");
        }

        var endIndex = urlResposta.indexOf("?");
        fileUrlTemp = urlResposta.substring(startIndex, endIndex);

        this.xmlUrl.push(fileUrlTemp)

      //this.invociesUrl.push(url); // Adiciona a URL à lista

    } catch (error: any) {
      this.erro = true;
      this.spinner.hide();
      this.error_counter = this.error_counter + 1;
      if (this.error_counter < 2) {
        if (error.status === 401) {
          Swal.fire({
            title: 'Ops, sua sessão expirou!',
            text: 'Por favor, saia e logue novamente.',
            icon: 'error',
            confirmButtonText: 'Sair',
            confirmButtonColor: '#ff6961',
            allowOutsideClick: false,
            allowEscapeKey: false
          }).then(() => {
            this.msalService.logoutRedirect();
          });
        } else {
          Swal.fire({
            title: 'Desculpe!',
            text: 'Ocorreu algum erro durante a sua solicitação. Por favor, tente novamente.',
            icon: 'error',
            confirmButtonText: 'Ok',
            confirmButtonColor: '#88D600',
            allowOutsideClick: false,
            allowEscapeKey: false
          });
        }
      } else {
        this.spinner.hide();
        Swal.fire({
          title: 'Desculpe!',
          html: '<p>Ocorreu um erro.</p>' + '<a href="https://app.pipefy.com/public/form/3Sd_ATrN" target="_blank">Clique aqui </a>' + '<span>para fazer sua solicitação</span>',
          icon: 'error',
          confirmButtonColor: '#88D600',
        });
        this.xmlUrl = [];
      }
    }
    }
    return new Promise<void>((resolve) => {resolve();});
  }
  resetform() {

    for (let field in this.formulario.controls) {
      this.formulario.controls[field].setValue('');
    }
    this.formulario.markAsUntouched()
    this.cards = []
    this.notas = [];
    this.boletos = []
    this.xml = []

    this.cardsUrl = []
    this.notasUrl = []
    this.boletosUrl = []
    this.xmlUrl = []

  }

  abrirPipePublico() {
    window.open('https://app.pipefy.com/public/form/-bxxCixe', '_blank')
  }
}

