import { Response } from './../../../node_modules/@types/express-serve-static-core/index.d';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators'
import { firstValueFrom, lastValueFrom, of } from 'rxjs';
import { UserInfosService } from './../autenticacao/usuario/user-infos.service';
import { environment } from 'src/environments/environment';
import { MsalService } from '@azure/msal-angular';
import Swal from 'sweetalert2';
import { error } from 'console';

export interface ReturnSecretsResponse {
  data: {
    authorization: ReturnSecrets;
  }
}
export interface ReturnSecrets {
  apikeys: {
    digibee: string,
    pipefy: string,
    zenviaChat: string,
    iogaKey: string,
    iogaProject: string,
    gcp: string
  },
  tokens: {
    mostqi: string,
    pipefy: string,
    jwt:string
  }
}
@Injectable({
  providedIn: 'root'
})
export class TokenServiceService {
  public getSecretAwait: Boolean = false;
   public secrets: ReturnSecrets = !environment.localhost? undefined as any:
   {apikeys: {digibee: environment.digibee_key, pipefy: environment.pipefy_key, zenviaChat: environment.zenviaChat},
   tokens: {mostqi: environment.most_qi_ocr_authorization, pipefy: environment.pipefy_queries_autorization, jwt: environment.gcp_authorization}};

   token: string | null = ''
   userAccessToken: any = ''
   jwt: any = ''

  constructor(private http: HttpClient, private userInfo: UserInfosService, private msalService: MsalService) {
    console.log((environment.homolog || environment.production || environment.localhost) && localStorage.getItem('sso') != 'accenture'? "sso nutrien": "sso accenture");
  }



    async getTokenFromGgp() {
      const token = (this.secrets.apikeys.pipefy);
    //const token = ((await this.getSecrets()).apikeys.pipefy);

    var userEmail = this.userInfo.getUserEmail()

    // console.log("UserEmail Token Service:", userEmail)
    let header: HttpHeaders;
    header = new HttpHeaders({
      'ContentType': 'application/json',
      'Access-Control-Allow-Methods': 'POST',
      'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept, Authorization, X-CSRF-Token',
      'Access-Control-Allow-Credentials': 'true',
      'Access-Control-Max-Age': '86400',
      'Accept': '*/*',
      'env': 'hml'
    })
    //caso vá para produção, comentar  env da headers.

    // dev
    var bodyJwt = environment.contigencia ? {
      "data": {
        "jwt-username": environment.gerar_jwt_user,
        "jwt-password": environment.gear_jwt_pass,
        "jwt-email": userEmail
      }
    }:
    {
      "data": {
        //"jwt-username": environment.gerar_jwt_user,
        //"jwt-password": environment.gear_jwt_pass,
        "jwt-email": userEmail
      }
    }

    // url dev
    const resposta: any = await this.http.post<any>(environment.gerar_jwt + "?key=" + token, bodyJwt, { headers: header, observe: 'response' }).

      pipe(
        tap(res => {
          console.log()
          const token = res.body.data.Authorization
          // console.log('authorization',token)
          this.setToken(token)
          // console.log('TOKEN=>',this.getToken())
        })
      ).toPromise()
    return resposta
  }

  async setToken(token: string | null) {
    this.token = token
  }

  getToken() {
    return "Bearer " + this.token
  }

  async getSecretsCont(): Promise<ReturnSecrets> {
    console.log("Entrou getSecretsCont");

    // Retorna o segredo armazenado em cache, se disponível
    if (!!this.secrets) {
      console.log("Segredo recuperado do cache.");
      return this.secrets;
    }

    // Configuração do cabeçalho
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    // Corpo da requisição
    const body = {
      data: {
        'jwt-username': environment.get_secrets_user,
        'jwt-password': environment.get_secrets_pass,
        'jwt-email': environment.get_secrets_email,
      },
    };

    console.log("Body da requisição:", body);

    // try {
    //   // Faz a requisição HTTP
    //   console.log("Iniciando chamada ao backend...");
    //   this.http.post<ReturnSecretsResponse>(
    //     "https://api.nutrien.nbs.dev.accenture.com/nutrien-gcpfn-authenticate-portal",
    //     body,
    //     { headers }
    //   ).subscribe(
    //     response => {
    //       console.log('Resposta recebida do backend:', response.data); // Trabalhe com a propriedade `data` aqui
    //       this.secrets = response.data.authorization; // Armazena no cache
    //       console.log('Secrets armazenados no cache:', this.secrets);
    //     },
    //     error => {
    //       console.error('Erro na requisição:', error);
    //       if (error.status === 0) {
    //         console.error("Erro de rede ou CORS.");
    //       } else if (error.status >= 400 && error.status < 500) {
    //         console.error("Erro no cliente (4xx).", error.message);
    //       } else if (error.status >= 500) {
    //         console.error("Erro no servidor (5xx).", error.message);
    //       }
    //     }
    //   );
    //   // const response = await lastValueFrom(
    //   //   this.http.post<ReturnSecretsResponse>(
    //   //     "https://api.nutrien.nbs.dev.accenture.com/nutrien-gcpfn-authenticate-portal",
    //   //     body,
    //   //     { headers }
    //   //   ).pipe(
    //   //     catchError(error => {

    //   //       console.error('HTTP error:', error);
    //   //       return of(null); // Retorna um valor padrão ou lança um erro
    //   //     })
    //   //   )
    //   // );

    //   // const response = await lastValueFrom(
    //   //   this.http.post(url, body, { headers }).pipe(
    //   //     catchError(error => {
    //   //       console.error('HTTP error:', error);
    //   //       return of(null); // Retorna um valor padrão ou lança um erro
    //   //     })
    //   //   )

    //   // Processa a resposta
    //   // console.log("Resposta recebida do backend:", response);
    //   // if (response === null) {
    //   //   throw new Error("Erro na requisição. Response é null.");
    //   // }
    //   // this.secrets = response.data.authorization;
    //   // console.log("Secrets armazenados no cache:", this.secrets);

    //   return this.secrets;
    // } catch (error) {
    //   console.error("Erro na requisição:", error);

    //   if (error instanceof Error) {
    //     // Tratamento de erros de JavaScript
    //     console.error("Erro JavaScript:", error.message);
    //   } else if (this.isHttpError(error)) {
    //     // Tratamento de erros HTTP
    //     const httpError = error as { status: number; message?: string };
    //     if (httpError.status === 0) {
    //       console.error("Erro de rede ou CORS.");
    //     } else if (httpError.status >= 400 && httpError.status < 500) {
    //       console.error("Erro no cliente (4xx).", httpError.message);
    //     } else if (httpError.status >= 500) {
    //       console.error("Erro no servidor (5xx).", httpError.message);
    //     }
    //   } else {
    //     // Tratamento genérico para erros desconhecidos
    //     console.error("Erro desconhecido:", error);
    //   }

    //   throw error; // Propaga o erro para o chamador tratar
    // }

    try {
      console.log("Iniciando chamada para obter segredos...");
      console.log("Envirement "+ environment);
      console.log("Api "+ environment.get_secrets);
      let api = environment.get_secrets;
      // Substituímos toPromise por firstValueFrom
      const response = await firstValueFrom(
        this.http.post<ReturnSecretsResponse>(api, body, { headers })
      );

      console.log("Resposta da API:", response);

      // Salvamos e retornamos os segredos
      this.secrets = response.data.authorization;
      return this.secrets;
    } catch (error) {
      console.error("Erro ao buscar segredos:", error);
      throw error;
    }

  }

  // Função para verificar se o erro possui a propriedade 'status'
  private isHttpError(error: any): error is { status: number; message?: string } {
    return error && typeof error.status === "number";
  }





  async getSecrets(): Promise<ReturnSecrets> {
    debugger;
    console.log("Function GetSecret");
    return new Promise<ReturnSecrets>( async (resolve, reject) => {
      if (!!this.secrets && this.getSecretAwait || environment.localhost) {
        resolve(this.secrets);
      } else {
        this.getSecretAwait = true;
        let header: HttpHeaders;
        header = new HttpHeaders({
          'Content-Type': 'application/json',
          // 'Token': this.userAccessToken
        });

        const body = {
          "data": { }
        };
        try {
          const response = await this.http.post<ReturnSecretsResponse>(environment.get_secrets, body, { headers: header }).toPromise();
          this.secrets = response!.data.authorization;
          resolve(response!.data.authorization);
          console.log(this.secrets)
          this.jwt = this.secrets.tokens.jwt
          console.log(this.jwt)
        }
        catch(e) {
          this.getSecretAwait = false;
          reject(e);
        }
      }
    });
  }

  verificaTempoDeSessao() {
    if (environment.localhost) {
      return;
    }
    const time = 14400000
    const myTme = setTimeout(() => {
      return Swal.fire({
        title: 'Ops, sua sessão expirou!',
        text: 'Por favor, saia e logue novamente.',
        icon: 'error',
        confirmButtonText: 'Sair',
        confirmButtonColor: '#ff6961',
        allowOutsideClick: false,
        allowEscapeKey: false
      })
        .then(() => {
          window.location.reload();
        }
        )
    }, time)
  }

  async getAccounts() {
    if (!!environment.localhost) {
      const token = localStorage.getItem("TOKEN");
      if(token == undefined || token == "seu token") {
        // console.log("Adicionar a propriedade TOKEN em localStorage!");
        localStorage.setItem("TOKEN", "seu token")
      }
        this.userAccessToken = token;
        return true;
    }
    return new Promise((resolve) => {
        const checkValue = async () => {
            const timeToken = setInterval(async () => {
                if (!!this.msalService.instance.getAllAccounts()[0]?.name && !!this.msalService.instance.getAllAccounts()[0]?.username) {
                  this.userInfo.setName(this.msalService.instance.getAllAccounts()[0]?.name);
                  this.userInfo.setUserEmail(this.msalService.instance.getAllAccounts()[0]?.username);
                    const accessTokenRequest = {
                        scopes: ["user.read"],
                        account: this.msalService.instance.getAllAccounts()[0],
                    };
                    const res: any = await this.msalService.instance.acquireTokenSilent(accessTokenRequest);
                    if (res.accessToken != undefined && res.accessToken != "") {
                      if(environment.dev) {
                        // console.log("TOKEN: ", res.accessToken);
                      }
                        this.userAccessToken = res.accessToken;
                        clearInterval(timeToken);
                        resolve(true); // Resolva a promessa quando o token estiver disponível
                    }
                }
            }, 50);
        };

        checkValue();
    });
}



}
