import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { PipefyService } from 'src/app/services/pipefy.service';
import Swal from 'sweetalert2';
import { TrataInputsService } from './../../../../services/trata-inputs.service';
import { Empresas } from './empresas';
import { pipe_fields_id } from './pipe_fields_id';
import { pipe_fields_incidentes } from '../../pipe_fields_incidentes';
import { MsalService } from '@azure/msal-angular';
import { UserInfosService } from './../../../../autenticacao/usuario/user-infos.service';


@Component({
  selector: 'app-pagamento-iof-tesouraria',
  templateUrl: './pagamento-iof-tesouraria.component.html',
  styleUrls: ['./pagamento-iof-tesouraria.component.css']
})
export class PagamentoIofTesourariaComponent implements OnInit {

  formulario!: FormGroup;
  apuracoes: any[] = [];
  contratos : any[] = [];

  apuracoesUrls: any[] = []
  contratosUrls: any[] = []

  empresas = Empresas

  error_counter: number = 0;
  erro: boolean = false

  emailApi: string = ''



  constructor(private formBuilder: FormBuilder,
    private trataInput: TrataInputsService,
    private pipefy: PipefyService ,
    private spinner: NgxSpinnerService,
    private msalService: MsalService,
    private userInfo: UserInfosService
    ) { }

  ngOnInit(): void {


    this.formulario = this.formBuilder.group({
      nome_responsavel_nutrien:['',[Validators.required]],
      email_do_responsavel_nutrien:['',[Validators.required, Validators.email]],
      empresa:['',[Validators.required]],
      data_do_inicio_do_decendio:['',[Validators.required]],
      data_final_do_decendio:['',[Validators.required]],
      observacao:['',[Validators.required]],
      apuracao:['',[Validators.required]],
      contrato:['',[Validators.required]]
    })

    this.emailApi = this.userInfo.getUserEmail()

    // console.log(this.apuracoes.length)


  }


  enviarFormulario(){

    this.spinner.show()


    const criarBody = async () => {
      // console.log(this.apuracoes)

      await this.getPipesUrlApuracoes(this.apuracoes);
      if(this.apuracoes.length >0){
        while (this.apuracoes.length != this.apuracoesUrls.length) {
          // console.log("esperando....")
          await this.delay(100);
          if(this.erro){
            break;
          }
        }
        if(this.erro){
          return
        }
      }

      if(this.apuracoes.length>0){
        var apuracoesUrls2 = ''
        apuracoesUrls2 = '['
        for(var i = 0; i < this.apuracoesUrls.length ; i++){
          apuracoesUrls2 = apuracoesUrls2+'"'+this.apuracoesUrls[i]+'",'
        }
        apuracoesUrls2 = apuracoesUrls2.slice(0, -1)
        apuracoesUrls2 = apuracoesUrls2+"]"

      }else{
        apuracoesUrls2 =''
      }

      await this.getPipesUrlContratos(this.contratos);
      if(this.contratos.length >0){
        while (this.contratos.length != this.contratosUrls.length) {
          // console.log("esperando....")
          await this.delay(100);
          if(this.erro){
            break;
          }
        }
        if(this.erro){
          return
        }
      }

      if(this.contratos.length>0){
        var contratosUrls2 = ''
         contratosUrls2 = '['
        for(var i = 0; i < this.contratosUrls.length ; i++){
           contratosUrls2 =  contratosUrls2+'"'+this.contratosUrls[i]+'",'
        }
         contratosUrls2 = contratosUrls2.slice(0, -1)
         contratosUrls2 = contratosUrls2+"]"

      }else{
        contratosUrls2 =''
      }

    let nome_responsavel_nutrien = this.trataInput.removeCaracters(this.formulario.getRawValue().nome_responsavel_nutrien)
    let email_do_responsavel_nutrien = this.trataInput.removeCaracters(this.formulario.getRawValue().email_do_responsavel_nutrien)
    let empresa = this.trataInput.removeCaracters(this.formulario.getRawValue().empresa)
    let data_do_inicio_do_decendio = this.trataInput.ajustaData(this.trataInput.removeCaracters(this.formulario.getRawValue().data_do_inicio_do_decendio))
    let data_final_do_decendio = this.trataInput.ajustaData(this.trataInput.removeCaracters(this.formulario.getRawValue().data_final_do_decendio))
    let observacao = this.trataInput.removeCaracters(this.formulario.getRawValue().observacao)

    let pipe_fields = pipe_fields_id
    let fields_values = [nome_responsavel_nutrien, email_do_responsavel_nutrien, empresa, data_do_inicio_do_decendio,data_final_do_decendio, observacao,apuracoesUrls2,contratosUrls2]
    let pipe_id = "302487538"

    this.pipefy.createCard(this.pipefy.criaBody(pipe_fields, fields_values, pipe_id)).subscribe((res) => {
      // console.log(res)
      this.spinner.hide()

      if (res.data.result_creation_card.id != undefined) {
        let card_id = res.data.result_creation_card.id
        Swal.fire({
          title: 'Chamado aberto com Sucesso!',
          text: 'O chamado ' + card_id + ' foi aberto com sucesso. Nossa equipe irá tratar e retornar em breve. Obrigado!',
          icon: 'success',
          confirmButtonText: 'Ok',
          confirmButtonColor: '#88D600',
        })
        this.resetform()
      }

      if (res.data.result_creation_card.errors != undefined) {
        var erro = res.data.result_creation_card.errors[0].message
        Swal.fire({
          title: 'Desculpe!',
          html:'<p>Ocorreu um erro.</p>'+'<a href="https://app.pipefy.com/public/form/Jun7pOSd" target="_blank">Clique aqui </a>'+'<span>para fazer sua solicitação</span>',
          text: erro,
          icon: 'error',
          confirmButtonColor: '#88D600',
        })
        this.apuracoesUrls = []
        this.contratosUrls = []

        let pipe_fields_incidentes_values = ['Incidente', 'Correção', 'Portal NBS', 'F&A', 'Fiscal', "Fiscal - criação de card -- Pagamento IOF - Tesouraria", erro, 'Portal NBS Error', 'incidente@portal.com',
          'Sim', 'Solicitação de Pagamento IOF - Contratos de Mútuos Tesouraria', 'criação de card via portal', 'Página web com problemas',this.emailApi]

        var pipe_fields_incidentes_values2 = []
        for (var i = 0; i<pipe_fields_incidentes_values.length; i++ ){
          pipe_fields_incidentes_values2.push(this.trataInput.removeCaractersPipeIncidentes(pipe_fields_incidentes_values[i]))
        }
        // console.log(pipe_fields_incidentes_values2)

        let pipe_incidentes_id = '302487533'

        this.pipefy.createCard(this.pipefy.criaBody(pipe_fields_incidentes, pipe_fields_incidentes_values2, pipe_incidentes_id)).subscribe((res) => {
          // console.log(res)
        })
      }
    },(error: any)=>{
      this.spinner.hide()
      // console.log("erro:",error)
      // console.log("error.status:",error.status)
      // console.log(typeof(error.status))
      if(error.status == 401){
        // console.log("entrei em error.status")
        Swal.fire({
          title: 'Ops, sua sessão expirou!',
          text: 'Por favor, saia e logue novamente.',
          icon: 'error',
          confirmButtonText: 'Sair',
          confirmButtonColor: '#ff6961',
          allowOutsideClick:false,
          allowEscapeKey:false
        }).then(()=>{
          this.msalService.logoutRedirect()
        })
      }else{
        // console.log("entrei em outro erro")
        Swal.fire({
          title: 'Desculpe!',
          text: 'Ocorreu algum erro durante a sua solicitação. Por favor, tente novamente.',
          icon: 'error',
          confirmButtonText: 'Ok',
          confirmButtonColor: '#88D600',
          allowOutsideClick:false,
          allowEscapeKey:false
        })
      }
    })
    }
    criarBody()
}

delay(time: any) {
  return new Promise(resolve => setTimeout(resolve, time));
}


  saveApuracoes(event: any): void {
    var files = event.target.files;

    for (var i = 0; i < files.length; i++){
      // console.log(files[i].name)
      this.apuracoes.push(files[i])
    }

    // console.log(this.apuracoes)
    // console.log(this.apuracoes.length)

  }

  removeFile(file: File){
    const index: number = this.apuracoes.indexOf(file);
      this.apuracoes.splice(index, 1)
      alert("Arquivo Removido")
}

async getPipesUrlApuracoes(files: any) {
  this.erro = false;

  // Função utilitária para ler o arquivo usando FileReader como uma Promise
  const readFileAsBase64 = (file: File) => {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const result = reader.result?.toString();
        if (result) {
          const fileContentStart = result.indexOf(",");
          resolve(result.substring(fileContentStart + 1)); // Retorna o conteúdo Base64
        } else {
          reject(new Error("Falha ao ler o arquivo"));
        }
      };
      reader.onerror = (error) => {
        reject(error);
      };
    });
  };

  // Loop sobre os arquivos, esperando cada leitura e operação assíncrona
  for (let i = 0; i < files.length; i++) {
    const fileContent = files[i];
    const fileName = files[i].name;

    try {
      const fileBase64 = await readFileAsBase64(fileContent); // Aguarda a leitura do arquivo
      const urlResposta = await this.pipefy.getFileUrl3(fileBase64, fileName); // Obtém a URL após o upload do arquivo

      var fileUrlTemp = "";
        var startIndex = 0;

        if (urlResposta.indexOf("orgs") > 0) {
          startIndex = urlResposta.indexOf("orgs/");
        } else {
          startIndex = urlResposta.indexOf("uploads/");
        }

        var endIndex = urlResposta.indexOf("?");
        fileUrlTemp = urlResposta.substring(startIndex, endIndex);

        this.apuracoesUrls.push(fileUrlTemp)

      //this.invociesUrl.push(url); // Adiciona a URL à lista

    } catch (error: any) {
      this.erro = true;
      this.spinner.hide();
      this.error_counter = this.error_counter + 1;
      if (this.error_counter < 2) {
        if (error.status === 401) {
          Swal.fire({
            title: 'Ops, sua sessão expirou!',
            text: 'Por favor, saia e logue novamente.',
            icon: 'error',
            confirmButtonText: 'Sair',
            confirmButtonColor: '#ff6961',
            allowOutsideClick: false,
            allowEscapeKey: false
          }).then(() => {
            this.msalService.logoutRedirect();
          });
        } else {
          Swal.fire({
            title: 'Desculpe!',
            text: 'Ocorreu algum erro durante a sua solicitação. Por favor, tente novamente.',
            icon: 'error',
            confirmButtonText: 'Ok',
            confirmButtonColor: '#88D600',
            allowOutsideClick: false,
            allowEscapeKey: false
          });
        }
      } else {
        this.spinner.hide();
        Swal.fire({
          title: 'Desculpe!',
          html: '<p>Ocorreu um erro.</p>' + '<a href="https://app.pipefy.com/public/form/3Sd_ATrN" target="_blank">Clique aqui </a>' + '<span>para fazer sua solicitação</span>',
          icon: 'error',
          confirmButtonColor: '#88D600',
        });
        this.apuracoesUrls = [];
      }
    }
  }
  return new Promise<void>((resolve) => {resolve();});
}

saveContratos(event: any): void {
  var files = event.target.files;

  for (var i = 0; i < files.length; i++){
    // console.log(files[i].name)
    this.contratos.push(files[i])
  }

  // console.log(this.contratos)
  // console.log(this.contratos.length)

}

removeFileContratos(file: File){
  const index: number = this.contratos.indexOf(file);
    this.contratos.splice(index, 1)
    alert("Arquivo Removido")
}

async getPipesUrlContratos(files: any) {
  this.erro = false;

  // Função utilitária para ler o arquivo usando FileReader como uma Promise
  const readFileAsBase64 = (file: File) => {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const result = reader.result?.toString();
        if (result) {
          const fileContentStart = result.indexOf(",");
          resolve(result.substring(fileContentStart + 1)); // Retorna o conteúdo Base64
        } else {
          reject(new Error("Falha ao ler o arquivo"));
        }
      };
      reader.onerror = (error) => {
        reject(error);
      };
    });
  };

  // Loop sobre os arquivos, esperando cada leitura e operação assíncrona
  for (let i = 0; i < files.length; i++) {
    const fileContent = files[i];
    const fileName = files[i].name;

    try {
      const fileBase64 = await readFileAsBase64(fileContent); // Aguarda a leitura do arquivo
      const urlResposta = await this.pipefy.getFileUrl3(fileBase64, fileName); // Obtém a URL após o upload do arquivo

      var fileUrlTemp = "";
        var startIndex = 0;

        if (urlResposta.indexOf("orgs") > 0) {
          startIndex = urlResposta.indexOf("orgs/");
        } else {
          startIndex = urlResposta.indexOf("uploads/");
        }

        var endIndex = urlResposta.indexOf("?");
        fileUrlTemp = urlResposta.substring(startIndex, endIndex);

        this.contratosUrls.push(fileUrlTemp)

      //this.invociesUrl.push(url); // Adiciona a URL à lista

    } catch (error: any) {
      this.erro = true;
      this.spinner.hide();
      this.error_counter = this.error_counter + 1;
      if (this.error_counter < 2) {
        if (error.status === 401) {
          Swal.fire({
            title: 'Ops, sua sessão expirou!',
            text: 'Por favor, saia e logue novamente.',
            icon: 'error',
            confirmButtonText: 'Sair',
            confirmButtonColor: '#ff6961',
            allowOutsideClick: false,
            allowEscapeKey: false
          }).then(() => {
            this.msalService.logoutRedirect();
          });
        } else {
          Swal.fire({
            title: 'Desculpe!',
            text: 'Ocorreu algum erro durante a sua solicitação. Por favor, tente novamente.',
            icon: 'error',
            confirmButtonText: 'Ok',
            confirmButtonColor: '#88D600',
            allowOutsideClick: false,
            allowEscapeKey: false
          });
        }
      } else {
        this.spinner.hide();
        Swal.fire({
          title: 'Desculpe!',
          html: '<p>Ocorreu um erro.</p>' + '<a href="https://app.pipefy.com/public/form/3Sd_ATrN" target="_blank">Clique aqui </a>' + '<span>para fazer sua solicitação</span>',
          icon: 'error',
          confirmButtonColor: '#88D600',
        });
        this.contratosUrls = [];
      }
    }
  }
  return new Promise<void>((resolve) => {resolve();});
}

resetform(){
  for (let field in this.formulario.controls) {
    this.formulario.controls[field].setValue('');
  }
  this.formulario.markAsUntouched()
  this.apuracoes = [];
  this.contratos  = [];

  this.apuracoesUrls = []
  this.contratosUrls = []

}

abrirPipePublico() {
  window.open('https://app.pipefy.com/public/form/Jun7pOSd', '_blank')
}

}
