import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MsalBroadcastService, MsalGuardConfiguration, MSAL_GUARD_CONFIG, MsalService } from '@azure/msal-angular';
import { InteractionStatus } from '@azure/msal-browser';
import { Subject, Subscription } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { AzureService } from './autenticacao/azure.service';
import { UserInfosService } from 'src/app/autenticacao/usuario/user-infos.service';
import { environment } from 'src/environments/environment';
import { ControleDeVersoesService } from './services/controle-de-versoes.service';
import { ActivatedRoute } from '@angular/router';
import { TokenServiceService } from './services/token-service.service';
import { CommomServicesService } from './services/commom-services.service';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'portal-front';
  isUserLoggedIn = false;
  groups: any;

  private readonly _destroy = new Subject<void>();
  private routeSubscription?: Subscription;

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private msalBroadcastService: MsalBroadcastService,
    private authService: MsalService,
    private controleVersaoS: ControleDeVersoesService,
    private spinner: NgxSpinnerService,
    private azureService: AzureService,
    private userInfo: UserInfosService,
    private route: ActivatedRoute,
    private tokenS: TokenServiceService,
    private commomS: CommomServicesService
  ) {
    console.log(
      (environment.contigencia || environment.homolog || environment.production || environment.localhost) && localStorage.getItem('sso') !== 'accenture' ?
      "sso nutrien" : "sso accenture"
    );
    debugger;

    if (environment.contigencia) {
      this.getAccountsCont();
    }
  }

  ngOnInit(): void {
    if (environment.contigencia) {
      this.initializeForContingencyEnvironment();
    } else {
      this.initializeForStandardEnvironment();
    }
  }

  ngOnDestroy(): void {
    this._destroy.next();
    this._destroy.complete();
    if (this.routeSubscription) {
      this.routeSubscription.unsubscribe();
    }
  }

  private initializeForContingencyEnvironment(): void {
    this.controleVersaoS.atualizarPortal();
    this.verificaTempoDeSessao();

    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroy)
      )
      .subscribe(() => {
        this.isUserLoggedIn = this.authService.instance.getAllAccounts().length > 0;
        this.azureService.isUserLoggedIn.next(this.isUserLoggedIn);
      });

    console.log(`${environment.versao} - ${environment.versaoData} - ${environment.versaoHora}`);

    if (environment.dev) {
      this.popUp();
    }

    this.setUserEmail();
    this.setUserName();

    setTimeout(() => {
      this.consultaVersao();
    }, 1000);

    this.routeSubscription = this.route.data.subscribe((data) => {
      this.groups = data.groups;
    });

    if (!(environment.production || environment.homolog)) {
      this.popUp();
    }
  }

  private initializeForStandardEnvironment(): void {
    this.tokenS.getAccounts()
      .then(() => {
        this.tokenS.getSecrets();
      });

    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroy)
      )
      .subscribe(() => {
        this.isUserLoggedIn = this.authService.instance.getAllAccounts().length > 0;
        this.azureService.isUserLoggedIn.next(this.isUserLoggedIn);
      });

    this.routeSubscription = this.route.data.subscribe((data) => {
      this.groups = data.groups;
    });
  }

  acessaIpe(): void {
    this.controleVersaoS.podeAcessarIpe().then((res) => {
      // handle response
    });
  }

  isLoggedIn(): boolean {
    return this.authService.instance.getActiveAccount() != null;
  }

  logout(): void {
    this.authService.logoutRedirect();
  }

  getInfos(): string | undefined {
    return this.authService.instance.getActiveAccount()?.name;
  }

  verificaPerfil(group: any): boolean {
    return this.userInfo.getUserGroups().includes(group);
  }

  getUserProfile(): void {
    this.userInfo.getUserGroupsFromAzure().subscribe((res: any) => {
      this.userInfo.setUserGroups(res);
    });
  }

  openForm(): void {
    const userEmail = this.userInfo.getUserEmail();
    const userName = this.userInfo.getName().replace(" ", "_").replace(",", "");
    const url = `${environment.sala_de_suporte}?nome_do_solicitante=${userName}&e_mail_do_solicitante=${userEmail}`;
    this.commomS.openNewWindow(url);
  }

  private consultaVersao(): void {
    if (environment.localhost) return;

    this.controleVersaoS.consultaVersao().then((res) => {
      const resposta = res?.data?.dados?.status;

      if (["não existe o email no pipe!", "não ok"].includes(resposta)) {
        this.atualizaVersao();
        this.popUp();
      }
    });
  }

  private atualizaVersao(): void {
    this.controleVersaoS.atualizaVersao();
  }

  private verificaTempoDeSessao(): void {
    if (environment.localhost || environment.dev) return;

    const time = 14400000; // 4 horas em milissegundos
    setTimeout(() => {
      Swal.fire({
        title: 'Ops, sua sessão expirou!',
        text: 'Por favor, saia e logue novamente.',
        icon: 'error',
        confirmButtonText: 'Sair',
        confirmButtonColor: '#ff6961',
        allowOutsideClick: false,
        allowEscapeKey: false
      }).then(() => {
        this.authService.logoutRedirect();
      });
    }, time);
  }

  private popUp(): void {
    Swal.fire({
      title: 'Comunicação de Melhoria',
      html: this.buildPopUpHtml(),
      iconColor: '#88D600',
      confirmButtonColor: '#88D600',
      customClass: {
        container: "comunicadoMelhoria",
      }
    });
  }

  private buildPopUpHtml(): string {
    // Construir o HTML do popup
    return `<div style="text-align:justify; padding:20px">
      <h4 style="text-align:center">Versão ${environment.versao} - ${environment.versaoData}</h4>
      <p>... (conteúdo específico do pop-up) ...</p>
    </div>`;
  }

  private setUserName(): void {
    this.userInfo.setName(this.authService.instance.getAllAccounts()[0]?.name);
  }

  private setUserEmail(): void {
    this.userInfo.setUserEmail(this.authService.instance.getAllAccounts()[0]?.username);
  }

  private async getAccounts(): Promise<void> {
    await new Promise<void>((resolve) => {
      const checkValue = () => {
        const account = this.authService.instance.getAllAccounts()[0];
        if (account?.name && account?.username) {
          this.userInfo.setName(account.name);
          this.userInfo.setUserEmail(account.username);
          resolve();
        } else {
          setTimeout(checkValue, 50); // Espera 50ms
        }
      };
      checkValue();
    });
  }

  async getAccountsCont(): Promise<any> {
    return await new Promise(resolve => {
      const checkValue = () => {
        if (!!this.authService.instance.getAllAccounts()[0]?.name && !!this.authService.instance.getAllAccounts()[0]?.username) {
          this.userInfo.setName(this.authService.instance.getAllAccounts()[0]?.name);
          this.userInfo.setUserEmail(this.authService.instance.getAllAccounts()[0]?.username);
          resolve(true);
        } else {
          setTimeout(checkValue, 50); // Espera 100ms
        }
      };
      checkValue();
    });
  }
}
